import React, { Fragment, useRef, useState, useEffect } from "react";

import { useMutation, useQuery, useQueryClient } from "react-query";

import MyPageBodyFrame from "../../../Block/IncUserMyPage/MyPageBodyFrame";
import MyPageMenuBox from "../../../Block/IncUserMyPage/MyPageMenuBox";
import { BrandContents } from "../../../Block/IncUserMyPage/BrandContents";

import DialogMessage, { DialogAlertMessage } from "../../../components/DialogMessage";
import { RequestUserBrandInfo, RequestBrandImageUrl, UpdateBrandInfo, RequestUpload } from "../../../utils/requestToSrv";

export default function BrandContainer() {
  // react-query queryClient
  const queryClient = useQueryClient();

  // 각 textField를 위한 ref
  const brandPhotoRef = useRef(null);
  const brandNameRef = useRef(null);
  const brandHomepageUrlRef = useRef(null);
  const brandDescriptionRef = useRef(null);

  const refSet = {
    brandPhotoRef: brandPhotoRef,
    brandNameRef: brandNameRef,
    brandHomepageUrlRef: brandHomepageUrlRef,
    brandDescriptionRef: brandDescriptionRef,
  };

  // blob 데이터 state
  const [blobDictState, setBlobDictState] = useState(new Object());
  // blob 이미지 src 변환 state
  const [blobImgSrcState, blobImgSrcSetState] = useState("");

  // brandInfo value state
  const [brandValueState, brandValueSetState] = useState({
    brandPhoto: "",
    brandName: "",
    brandHomepageUrl: "",
    brandDescription: "",
  });

  // brand data load function
  const brandInfo = useQuery("brandInfo", RequestUserBrandInfo, {
    onSuccess: (data) => {
      brandValueSetState({
        brandPhoto: data.photo,
        brandName: data.name,
        brandHomepageUrl: data.homepageUrl,
        brandDescription: data.description,
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 이미지 변경 버튼 클릭 event
  const changeImageButtonFunc = () => {
    brandPhotoRef.current.click();
  };

  // 이미지 get upload url
  const brandImageUrl = useQuery("brandImageUrl", () => RequestBrandImageUrl(brandPhotoRef.current.files[0].name), {
    enabled: false,
    onSuccess: (data) => {
      brandValueSetState((prevState) => ({ ...prevState, brandPhoto: "https://storage.googleapis.com/project-reble-dev/brand/" + queryClient.getQueryData("userInfo").uid + "/" + brandPhotoRef.current.files[0].name }));
      setBlobDictState({
        url: data,
        blob: brandPhotoRef.current.files[0],
      });
      blobImgSrcSetState(window.URL.createObjectURL(brandPhotoRef.current.files[0]));
    },
  });

  // 화면 로드 시 맨 위로 이동
  // brandInfo data가 있을 경우 brandValueSetState에 data set
  useEffect(() => {
    window.scrollTo(0, 0);

    if (brandInfo.data) {
      brandValueSetState({
        brandPhoto: brandInfo.data.photo,
        brandName: brandInfo.data.name,
        brandHomepageUrl: brandInfo.data.homepageUrl,
        brandDescription: brandInfo.data.description,
      });
    }

    // 컴포넌트 unmount 시 blob url revoke
    return () => {
      window.URL.revokeObjectURL(blobImgSrcState);
    };
  }, []);

  // alert dialog message text state
  const [alertDialogMessageState, alertDialogMessageSetState] = useState("");
  // alert dialog message custom hook
  const { alertDialogCall, DialogAlertComponent } = DialogAlertMessage(alertDialogMessageState);

  // 이미지 변경 event
  const changeImageFunc = () => {
    if (brandPhotoRef.current.files[0]) {
      if (!brandPhotoRef.current.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
        alertDialogMessageSetState("파일 형식을 확인해주세요(jpg, jpeg, png, gif)");
        alertDialogCall();
        return false;
      } else {
        brandImageUrl.refetch();
      }
    } else {
      alertDialogMessageSetState("파일 선택을 취소하셨습니다.");
      alertDialogCall();
    }
  };

  // brandInfo value state 변경 event
  const brandValueChange = (key, newValue) => {
    brandValueSetState((prevState) => ({
      ...prevState,
      [key]: newValue,
    }));
  };

  // dialog message text state
  const [dialogMessageState, dialogMessageSetState] = useState("");
  // dialog message custom hook
  const { dialogCall, DialogComponent } = DialogMessage(dialogMessageState);

  // brand info update function
  const updateBrandInfo = useMutation(
    () => {
      // const markdownInstance = brandDescriptionRef.current.getInstance();
      UpdateBrandInfo({
        photo: brandValueState.brandPhoto,
        name: brandValueState.brandName,
        homepageUrl: brandValueState.brandHomepageUrl,
        description: brandValueState.brandDescription,
      });
    },
    {
      onSuccess: (data) => {
        dialogMessageSetState("변경사항이 저장되었습니다.");
        dialogCall();
        brandInfo.refetch();
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  // 이미지 파일 업로드 function
  const fileUploadFunc = async () => {
    RequestUpload({
      formData: blobDictState.blob,
      signedUrl: blobDictState.url,
    });
  };

  // brand info submit event
  const submitFunc = () => {
    if (brandValueState.brandName.length < 1) {
      alertDialogMessageSetState("브랜드명을 입력해주세요.");
      alertDialogCall();
      return false;
    } else if (brandValueState.brandHomepageUrl.length < 1) {
      alertDialogMessageSetState("브랜드 홈페이지 주소를 입력해주세요.");
      alertDialogCall();
      return false;
    } else if (brandValueState.brandDescription.length < 1) {
      alertDialogMessageSetState("브랜드 소개를 입력해주세요.");
      alertDialogCall();
      return false;
    } else {
      if (Object.keys(blobDictState).length > 0) {
        fileUploadFunc().then(updateBrandInfo.mutate());
      } else {
        updateBrandInfo.mutate();
      }
    }
  };

  // enter key press event
  const onEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      submitFunc();
    }
  };

  // 브랜드 관리 화면
  return (
    <Fragment>
      <MyPageBodyFrame title="브랜드 관리" MyPageLeftContents={<MyPageMenuBox page="/brand" />} MyPageRightContents={<BrandContents ref={refSet} brandValueState={brandValueState} brandValueChange={brandValueChange} blobImgSrcState={blobImgSrcState} changeImageButtonFunc={changeImageButtonFunc} changeImageFunc={changeImageFunc} onEnterKeyPress={onEnterKeyPress} submitFunc={submitFunc} buttonActiveState={updateBrandInfo.isLoading} />} />
      <DialogComponent />
      <DialogAlertComponent />
    </Fragment>
  );
}
