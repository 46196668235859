import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import moment from "moment";

import { RequestAppliedProjectList, RequestReadDefaultUserProfile, UpdateCancelProjectApplication } from "../../../utils/requestToSrv";

import MyPageBodyFrame from "../../../Block/MyPage/MyPageBodyFrame";
import MyPageMenuBox from "../../../Block/MyPage/MyPageMenuBox";
import AppliedProjectContents from "../../../Block/MyPage/AppliedProjectContents";
import DialogWithButton, { DialogWithOneButton, ComplexDialogWithThreeButton, DialogWithTwoButton } from "../../../components/DialogWithButton";
import LoadingComponent from "../../../components/LoadingComponent";

import AppliedProjectDialogContents, { AppliedProjectDialogContentsWithAddress, OngoingProjectCancelDialogContents } from "../../../Block/MyPage/AppliedProjectDialogContents";
import DialogMessage from "../../../components/DialogMessage";
import MyPageMenuUpperBox from "../../../Block/MyPage/MyPageMenuUpperBox";

// 지원한 프로젝트 페이지
export default function AppliedProjectContainer() {
  // 화면 로드 시 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // react-router-dom navigate
  const navigate = useNavigate();

  // react-query queryClient
  const queryClient = useQueryClient();

  // 포트폴리오 data load
  const portfolioData = useQuery("userPortfolio", RequestReadDefaultUserProfile, {
    onError: (error) => {
      console.log(error);
    },
  });

  // 지원한 프로젝트 data load
  const appliedProjectList = useQuery("appliedProjectList", RequestAppliedProjectList, {
    onError: (error) => {
      console.log(error);
    },
  });

  // 지원한 프로젝트 table page state
  const [tableDataPageState, tableDataPageSetState] = useState(1);

  // table page 이동 function
  const tableDataPageChange = (event, newPage) => {
    tableDataPageSetState(newPage);
  };

  // table row 클릭 시 프로젝트 상세 페이지 이동 function
  const tableRowClickFunc = (index) => {
    navigate("/project-detail/" + index);
  };

  // 내 정보 확인 dialog open state
  const [userInfoDialogState, userInfoDialogSetState] = useState(false);
  // 정보 확인 시 프로젝트 유형에 따라 다른 dialog 출력 > 프로젝트 유형 state
  const [projectTypeInfoState, projectTypeInfoSetState] = useState("purchase");

  // 내 정보 확인 dialog open function
  const userInfoCheckFunc = (projectType) => {
    projectTypeInfoSetState(projectType);
    userInfoDialogSetState(true);
  };

  // 내 정보 확인 dialog close function
  const userInfoDialogCloseFunc = () => {
    userInfoDialogSetState(false);
  };

  // 내 정보 수정 > 마이페이지로 이동 function
  const linkToMyPage = () => {
    navigate("/my-page");
  };

  // 모집 중 프로젝트 지원 취소 dialog open state
  const [recruitingProjectCancelDialogState, recruitingProjectCancelDialogSetState] = useState(false);
  // 진행 중 프로젝트 지원 취소 dialog open state
  const [ongoingProjectCancelDialogState, ongoingProjectCancelDialogSetState] = useState(false);
  // 종료 프로젝트 지원 취소 dialog open state
  const [finishedProjectCancelDialogState, finishedProjectCancelDialogSetState] = useState(false);
  // 지원 취소 시 프로젝트 doc id state
  const [targetProjectIdState, targetProjectIdSetState] = useState("");

  // 지원 취소 dialog open function
  const applicationCancelFunc = (projectId) => {
    targetProjectIdSetState(projectId);
    if (moment().isAfter(moment(appliedProjectList.data[projectId].endDate * 1000))) {
      finishedProjectCancelDialogSetState(true);
    } else if (moment().isBetween(moment(appliedProjectList.data[projectId].appointmentDate * 1000), moment(appliedProjectList.data[projectId].endDate * 1000).add(-1, "d"))) {
      ongoingProjectCancelDialogSetState(true);
    } else {
      recruitingProjectCancelDialogSetState(true);
    }
  };

  // 지원 취소 dialog close function
  const applicationCancelDialogCloseFunc = () => {
    recruitingProjectCancelDialogSetState(false);
    ongoingProjectCancelDialogSetState(false);
    finishedProjectCancelDialogSetState(false);
  };

  // dialog message custom hook
  const { dialogCall, DialogComponent } = DialogMessage("신청 취소되었습니다.");

  // 지원 취소 update function
  const cancelProjectApplication = useMutation(() => UpdateCancelProjectApplication(targetProjectIdState), {
    onSuccess: () => {
      appliedProjectList.refetch();
      applicationCancelDialogCloseFunc();
      dialogCall();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 지원 취소 최종 확인 function
  const confirmApplicationCancelFunc = () => {
    cancelProjectApplication.mutate();
  };

  // 가이드라인 링크 open function
  const openGuidelineLink = () => {
    // window.open(guidelineLink);
    console.log("guide open");
  };

  // 지원한 프로젝트 화면
  if (portfolioData.isFetching || appliedProjectList.isFetching) {
    return <LoadingComponent />;
  } else {
    return (
      <Fragment>
        <MyPageBodyFrame title="신청 프로젝트" text="신청한 프로젝트 목록입니다." menu={<MyPageMenuBox page="applied-project" portfolioData={portfolioData.data} valueState={queryClient.getQueryData("userInfo")} />} menuUpper={<MyPageMenuUpperBox page="applied-project" portfolioData={portfolioData.data} valueState={queryClient.getQueryData("userInfo")} />} contents={<AppliedProjectContents projectListData={appliedProjectList.data} tableDataPageState={tableDataPageState} tableDataPageChange={tableDataPageChange} tableRowClickFunc={tableRowClickFunc} userInfoCheckFunc={userInfoCheckFunc} applicationCancelFunc={applicationCancelFunc} openGuidelineLink={openGuidelineLink} />} />
        <ComplexDialogWithThreeButton title={"신청정보 확인"} context={projectTypeInfoState === "delivery" ? <AppliedProjectDialogContentsWithAddress userInfoValue={queryClient.getQueryData("userInfo")} /> : <AppliedProjectDialogContents userInfoValue={queryClient.getQueryData("userInfo")} />} dialogOpenState={userInfoDialogState} dialogClose={userInfoDialogCloseFunc} button1Text="닫기" button2Text="정보 수정하기" button1Func={userInfoDialogCloseFunc} button2Func={linkToMyPage} maxWidth={800} />
        <ComplexDialogWithThreeButton title={"취소 전, 확인해주세요."} context={<OngoingProjectCancelDialogContents />} dialogOpenState={ongoingProjectCancelDialogState} dialogClose={applicationCancelDialogCloseFunc} button1Text="닫기" button2Text="네, 취소해주세요." button1Func={applicationCancelDialogCloseFunc} button2Func={confirmApplicationCancelFunc} maxWidth={700} />
        <DialogWithOneButton width={380} message="종료된 프로젝트입니다." dialogOpenState={finishedProjectCancelDialogState} buttonText="확인" buttonFunc={applicationCancelDialogCloseFunc} />
        <DialogWithTwoButton width={554} icon={"cancel"} title={"신청을 취소하시겠습니까?"} dialogOpenState={recruitingProjectCancelDialogState} dialogClose={applicationCancelDialogCloseFunc} button1Text="닫기" button2Text="신청 취소" button1Func={applicationCancelDialogCloseFunc} button2Func={confirmApplicationCancelFunc} />
        <DialogComponent />
      </Fragment>
    );
  }
}
