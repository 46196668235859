import React from "react";
import RequireAuthRoute from "../../routes/requireAuth";

import UniversalAppBar from "../../Block/Appbar/UniversalAppBar";
import UniversalAppFooter from "../../Block/Footer/UniversalAppFooter";

// import Payment from "../../containers/Service/Payment/PaymentContainer";
import Payment from "../../containers/Service/Payment/ProductSelectPaymentContainer";

export default function PaymentView() {
  return (
    <React.Fragment>
      <UniversalAppBar />
      <RequireAuthRoute Component={Payment} />
      <UniversalAppFooter />
    </React.Fragment>
  );
}
