import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from "@mui/material/Typography";

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const CustomBox = styled(Box)({
    width: "100%",
    borderBottom: 1,
    borderColor: "divider",
    background: "#FFFFFF",
});

const CustomTabs = styled(Tabs)({
    paddingTop: "12px",
    
    "& .Mui-selected": {
        borderBottom: "3px solid #222222",
    },
});

const CustomTab = styled(Tab)({
    padding: 0,
    marginRight: "60px",
});

const SelectedTabLabelTypo = styled(Typography)(({ theme }) => ({
    fontSize: "20px",
    fontWeight: 700,
    color: theme.palette.black[200],
    lineHeight: "28px",
    display: "flex",
}))

const TabLabelTypo = styled(Typography)(({ theme }) => ({
    fontSize: "20px",
    fontWeight: 700,
    color: theme.palette.black[900],
    lineHeight: "28px",
    display: "flex",
}))

export function UnderlinedTabs(props) {
    const { tabs, value } = props;

    return (
        <CustomBox sx={{ width: "100%", borderBottom: 1, borderColor: 'divider' }}>
            <CustomTabs value={value} indicatorColor="black.200" TabIndicatorProps={{ sx: { transition: "none" } }}>
                {tabs.map((tabValue, index) => {
                    return (
                        <CustomTab value={index} label={index === value ? <SelectedTabLabelTypo>{tabValue.label} {tabValue.openInNew ? <OpenInNewIcon sx={{paddingLeft: "12.5px"}} /> : null}</SelectedTabLabelTypo> : <TabLabelTypo>{tabValue.label} {tabValue.openInNew ? <OpenInNewIcon sx={{marginLeft: "12.5px"}} /> : null}</TabLabelTypo>} onClick={() => tabValue.tabClickEvent()} />
                    );
                })}
            </CustomTabs>
        </CustomBox>
    );
}