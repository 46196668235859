import React, { useRef, useState, Fragment, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { TextFieldSetWithCaption } from "../../../Block/Common/TextFieldSet";
import AuthButton from "../../../components/AuthComponents";
import AuthBodyFrame from "../../../Block/Auth/AuthBodyFrame";
import { DialogWithOneButton } from "../../../components/DialogWithButton";

import { doUpdatePassword, doSignOut } from "../../../utils/firebaseUtils";
import { DialogAlertMessage } from "../../../components/DialogMessage";

// 정규표현식
const passwordRegEx = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{12,}$/);

// 비밀번호 변경
export default function ChangePasswordContainer() {
  // 화면 로드 시 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // react-router-dom navigate
  const navigate = useNavigate();

  // data loading state
  const [loadState, loadSetState] = useState(false);

  // react-query queryClient
  const queryClient = useQueryClient();

  // 각 textField를 위한 ref
  const passwordRef = useRef(null);
  const passwordConfirmRef = useRef(null);

  // 각 textField의 value state
  const [passwordValueState, passwordValueSetState] = useState({
    password: "",
    passwordConfirm: "",
  });

  // 버튼 활성화 여부 state
  const [buttonActiveState, buttonActiveSetState] = useState(false);

  const [passwordErrorCodeState, passwordErrorCodeSetState] = useState(0);
  const [passwordConfirmErrorCodeState, passwordConfirmErrorCodeSetState] = useState(0);
  
  const [passwordErrorMsgState, passwordErrorMsgSetState] = useState("");
  const [passwordConfirmErrorMsgState, passwordConfirmErrorMsgSetState] = useState("");

  // alert message text state
  const [dialogErrorMessageState, dialogErrorMessageSetState] = useState("");
  // alert dialog message custom hook
  const { alertDialogCall, DialogAlertComponent } = DialogAlertMessage(dialogErrorMessageState);

  // textfield 모두 입력 시 버튼 활성화
  useEffect(() => {
    if (passwordValueState.password && passwordValueState.passwordConfirm) {
      buttonActiveSetState(true);
    } else {
      buttonActiveSetState(false);
    }
    if (!passwordRegEx.test(passwordValueState.password)) {
      passwordErrorCodeSetState(1);
      passwordErrorMsgSetState("영문, 숫자, 기호를 모두 조합하여 12자 이상 입력해주세요.");
    } else {
      passwordErrorCodeSetState(0);
      passwordErrorMsgSetState("");
    }
    if (passwordValueState.password !== passwordValueState.passwordConfirm) {
      passwordConfirmErrorCodeSetState(1);
      passwordConfirmErrorMsgSetState("비밀번호가 일치하지 않습니다.");
    } else {
      passwordConfirmErrorCodeSetState(0);
      passwordConfirmErrorMsgSetState("");
    }
  }, [passwordValueState]);

  // text field value change event
  const passwordValueChangeEvent = (key, event) => {
    passwordValueSetState((prevState) => ({ ...prevState, [key]: event.target.value }));
  };

  const passwordInputBlurEvent = () => {
    if (!passwordValueState.password) {
      passwordErrorCodeSetState(1);
      passwordErrorMsgSetState("비밀번호를 입력해주세요.");
    }
  };

  const passwordConfirmInputBlurEvent = () => {
    if (!passwordValueState.passwordConfirm) {
      passwordConfirmErrorCodeSetState(1);
      passwordConfirmErrorMsgSetState("비밀번호를 다시 입력해주세요.");
    }
  };

  // 비밀번호 변경 완료 dialog open state
  const [dialogOpenState, dialogOpenSetState] = useState(false);

  // dialog open event
  const dialogOpen = () => {
    dialogOpenSetState(true);
  };

  // dialog close event
  // const dialogClose = () => {
  //   dialogOpenSetState(false);
  // };

  // sign out > queryClient cache clear
  const querySignOut = () => {
    queryClient.removeQueries("userInfo");
  };

  // 비밀번호 변경 완료 event
  const changePasswordFinishEvent = () => {
    doSignOut(querySignOut);
    // dialogClose();
    navigate("/signin");
  };

  // 비밀번호 변경 submit event
  const passwordChangeEvent = () => {
    if (!passwordRegEx.test(passwordValueState.password)) {
      passwordRef.current.focus();
    } else if (!passwordValueState.passwordConfirm) {
      passwordConfirmRef.current.focus();
    } else if (passwordValueState.password !== passwordValueState.passwordConfirm) {
      passwordConfirmRef.current.focus();
    } else {
      loadSetState(true);
      doUpdatePassword({ loadSetState: loadSetState, callBack: dialogOpen, newPassword: passwordValueState.password, setAlertDialogMessage: dialogErrorMessageSetState, alertDialogCall: alertDialogCall });
    }
  };

  // enter key press event
  const onEnterKeyPress = (event) => {
    if (buttonActiveState) {
      if (event.key === "Enter") {
        passwordChangeEvent();
      }
    }
  };

  // 비밀번호 변경 화면
  const changePasswordContent = () => {
    return (
      <Fragment>
        <Grid container item rowSpacing={5.3}>
          <Grid container item rowSpacing={4}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={700} color="black.200">
                새 비밀번호 입력
              </Typography>
              <Typography variant="subtitle1" fontWeight={400} color="black.300">
                새롭게 사용하실 비밀번호를 입력해주세요.
              </Typography>
            </Grid>
            <Grid container item xs={12} rowSpacing={1}>
              <TextFieldSetWithCaption label="새 비밀번호" errorState={passwordErrorCodeState === 1} caption={passwordErrorCodeState === 1 ? passwordErrorMsgState : ""} type="password" placeholder="비밀번호를 입력해주세요" ref={passwordRef} valueState={passwordValueState.password} setValueEvent={(event) => passwordValueChangeEvent("password", event)} onBlur={(event) => passwordInputBlurEvent(event)} onKeyPress={(event) => onEnterKeyPress(event)} />
              <TextFieldSetWithCaption label="새 비밀번호 확인" errorState={passwordConfirmErrorCodeState === 1} caption={passwordConfirmErrorCodeState === 1 ? passwordConfirmErrorMsgState : ""} type="password" placeholder="비밀번호를 한 번 더 입력해주세요" ref={passwordConfirmRef} valueState={passwordValueState.passwordConfirm} setValueEvent={(event) => passwordValueChangeEvent("passwordConfirm", event)} onBlur={(event) => passwordConfirmInputBlurEvent(event)} onKeyPress={(event) => onEnterKeyPress(event)} />
            </Grid>
            <Grid item xs={12}>
              <AuthButton loadState={loadState} buttonText="비밀번호 변경" activated={true} onClickEvent={passwordChangeEvent} disabled={!buttonActiveState} />
            </Grid>
          </Grid>
        </Grid>
        <DialogWithOneButton width={380} message={["비밀번호가 변경되었습니다.", <br />, "새 비밀번호로 로그인해주세요."]} dialogOpenState={dialogOpenState} buttonText="확인" buttonFunc={changePasswordFinishEvent} />
        <DialogAlertComponent />
      </Fragment>
    );
  };

  return <AuthBodyFrame title="비밀번호 변경" content={changePasswordContent()} />;
}
