import React from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import MyPortfolioSNSBox from "../MyPage/MyPortfolioSNSBox";
import MyPortfolioProjectBox from "../MyPage/MyPortfolioProjectBox";

const CustomGridContainer = styled(Grid)({
  paddingLeft: "28px",
});

const ProfileTitleButtonBox = styled(Box)({
  padding: "7px 25px",
  borderRadius: "100px",
  background: "#333333",
});

const CustomPaper = styled(Paper)({ padding: "30px 35px" });

// 포트폴리오 프로필 정보 화면
export default function MyPortfolioProfileContents(props) {
  const { portfolioData } = props;

  const profileIntroduction = () => {
    return (
      <Grid container item rowSpacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight={700} color="#333333">
            소개
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" lineHeight="24px" color="#333333">
            {portfolioData.introduction}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <CustomGridContainer container item rowSpacing={3.1}>
      <Grid item>
        <ProfileTitleButtonBox>
          <Typography variant="body2" color="primary">
            프로필
          </Typography>
        </ProfileTitleButtonBox>
      </Grid>
      {portfolioData.introduction !== "" || portfolioData.sns.instagram !== "" || portfolioData.sns.youtube !== "" || portfolioData.sns.naverBlog !== "" || portfolioData.sns.naverPost !== "" || portfolioData.project.project1.url !== "" || portfolioData.project.project2.url !== "" || portfolioData.project.project3.url !== "" ? (
        <Grid item xs={12}>
          <CustomPaper>
            <Grid container rowSpacing={4.4}>
              {portfolioData.introduction !== "" ? profileIntroduction() : null}
              {portfolioData.instagram !== "" || portfolioData.youtube !== "" || portfolioData.naverBlog !== "" || portfolioData.naverPost !== "" ? <MyPortfolioSNSBox portfolioData={portfolioData} /> : null}
              {portfolioData.project.project1.url !== "" || portfolioData.project.project2.url !== "" || portfolioData.project.project3.url !== "" ? <MyPortfolioProjectBox portfolioData={portfolioData} /> : null}
            </Grid>
          </CustomPaper>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <CustomPaper>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2" color="#999999" align="center" lineHeight="24px">
                  등록한 프로필 정보가 없습니다.
                  <br />
                </Typography>
              </Grid>
            </Grid>
          </CustomPaper>
        </Grid>
      )}
    </CustomGridContainer>
  );
}
