import React, { forwardRef } from "react";

import { UploadImageBoxComponent } from "../../components/UploadImageBoxComponent";
import { AuthTextFieldWithLabel, TextFieldMultiline } from "../../components/CustomTextField";
import { ContainedButton } from "../../components/ButtonComponents";

// import { ToastUIEditorWithNoImageBlock } from "../Common/ToastUIEditorBlock";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const CustomDivider = styled(Divider)({
  borderColor: "#999999",
  paddingTop: "16px",
});

const SubCustomDivider = styled(Divider)({
  borderColor: "#F2F2F2",
});

const ContentGrid = styled(Grid)({
  padding: "0 10px",
});

// 브랜드 정보
export const BrandContents = forwardRef((props, forwardRef) => {
  const { brandValueState, brandValueChange, blobImgSrcState, changeImageButtonFunc, changeImageFunc, onEnterKeyPress, submitFunc, buttonActiveState } = props;
  const { brandPhotoRef, brandNameRef, brandHomepageUrlRef, brandDescriptionRef } = forwardRef;

  return (
    <Grid container rowSpacing={3.75}>
      <Grid item xs={12}>
        <Grid container>
          <Grid container rowSpacing={4}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight={700} color="#333333">
                브랜드 정보
              </Typography>
              <Grid item xs={12}>
                <CustomDivider />
              </Grid>
            </Grid>
            <ContentGrid container item rowSpacing={3}>
              <Grid container item rowGap={1}>
                <Grid container item xs={12} columnSpacing={1}>
                  <Grid container item xs={2.5}>
                    <UploadImageBoxComponent ref={brandPhotoRef} valueState={brandValueState.brandPhoto} blobImgSrcState={blobImgSrcState} changeImageButtonFunc={changeImageButtonFunc} changeImageFunc={changeImageFunc} />
                  </Grid>
                  <Grid container item xs={9.5} alignContent="space-between">
                    <AuthTextFieldWithLabel label="브랜드 명" type="text" placeholder="브랜드명을 입력해주세요." ref={brandNameRef} valueState={brandValueState.brandName} setValueEvent={() => brandValueChange("brandName", brandNameRef.current.value)} onKeyPress={onEnterKeyPress} onBlur={() => {}} autoFocus={false} inputProps={{ maxLength: 20 }} />
                    <AuthTextFieldWithLabel label="브랜드 URL" type="text" placeholder="www.on1016.com" ref={brandHomepageUrlRef} valueState={brandValueState.brandHomepageUrl} setValueEvent={() => brandValueChange("brandHomepageUrl", brandHomepageUrlRef.current.value)} onKeyPress={onEnterKeyPress} onBlur={() => {}} autoFocus={false} />
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Typography variant="body3" fontWeight={300} lineHeight="16px" color="#B3B3B3">
                    *10MB 이하의 JPG, PNG 파일만 등록 가능합니다.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item>
                {/* <ToastUIEditorWithNoImageBlock ref={brandDescriptionRef} placeholder="브랜드 소개를 입력해주세요." initialValue={brandValueState.brandDescription} editorSize="40vh" /> */}
                <TextFieldMultiline label="브랜드 소개" rows={5} type="text" characterLimit={300} placeholder="브랜드 소개" ref={brandDescriptionRef} valueState={brandValueState.brandDescription} setValueEvent={() => brandValueChange("brandDescription", brandDescriptionRef.current.value)}  />
              </Grid>
            </ContentGrid>
            <Grid item xs={12}>
              <SubCustomDivider />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item justifyContent="center">
        <Grid item xs={5.8}>
          <ContainedButton buttonEvent={submitFunc} buttonText="브랜드 정보 저장하기" buttonDisabled={buttonActiveState} />
        </Grid>
      </Grid>
    </Grid>
  );
});
