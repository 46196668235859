import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "react-query";

import moment from "moment";

import { RequestIncUserInfo, UpdateIncUserInfo } from "../../../utils/requestToSrv";
import { doSendEmailVarification } from "../../../utils/firebaseUtils";

import { useRecoilState } from "recoil";
import { emailVarified } from "../../../recoil/UserState";

import { doSignOutWithNoQuery } from "../../../utils/firebaseUtils";

import { MyPageContents } from "../../../Block/IncUserMyPage/MyPageContents";
import MyPageBodyFrame from "../../../Block/IncUserMyPage/MyPageBodyFrame";
import MyPageMenuBox from "../../../Block/IncUserMyPage/MyPageMenuBox";
import { DialogWithOneButton } from "../../../components/DialogWithButton";
import DialogMessage, { DialogAlertMessage } from "../../../components/DialogMessage";

const phoneNumRegEx = new RegExp(/([0-9]{2,3})-([0-9]{3,4})-([0-9]{4})/);
const valueChangePhoneNumRegEx = new RegExp(/([0-9]{2,3})([0-9]{3,4})([0-9]{4})/);

export default function IncUserMyPageContainer() {
  // react-router-dom navigate
  const navigate = useNavigate();

  // react-query queryClient
  const queryClient = useQueryClient();

  // 각 textField를 위한 ref
  const emailRef = useRef(null);
  const nameRef = useRef(null);
  const birthRef = useRef(null);
  const phoneRef = useRef(null);
  const companyNameRef = useRef(null);
  const companyHomepageRef = useRef(null);

  // 이메일 인증 여부 state - firebase에서 확인
  const [emailVarifiedState, emailVarifiedSetState] = useRecoilState(emailVarified);

  // user info state - 원본
  const [originalValueState, originalValueSetState] = useState({
    email: "",
    name: "",
    birth: null,
    phone: "",
    companyName: "",
    companyHomepage: "",
    marketingAgreement: {
      email: false,
      sms: false,
    },
    signUpDate: "",
    authState: false,
    membershipState: false,
    subscribingState: false,
    paymentMethod: "",
    paymentCard: "",
  });

  // user info state - 변경 가능한 값
  const [valueState, valueSetState] = useState({
    email: "",
    name: "",
    birth: null,
    phone: "",
    companyName: "",
    companyHomepage: "",
    marketingAgreement: {
      email: false,
      sms: false,
    },
    signUpDate: "",
    authState: false,
    membershipState: false,
    subscribingState: false,
    paymentMethod: "",
    paymentCard: "",
  });

  // 화면 로드 시 맨 위로 이동
  // my page 진입 시 sign in 완료 한 상태를 가정하여 queryClient에 userInfo data set
  // user info state - 원본 값 설정
  // user info state - 변경 가능한 값 설정
  useEffect(() => {
    window.scrollTo(0, 0);

    originalValueSetState({
      email: queryClient.getQueryData("userInfo").email,
      name: queryClient.getQueryData("userInfo").name,
      birth: queryClient.getQueryData("userInfo").birthDate ? moment(queryClient.getQueryData("userInfo").birthDate).format("yyyy.MM.DD") : null,
      phone: queryClient.getQueryData("userInfo").contact,
      companyName: queryClient.getQueryData("userInfo").companyName,
      companyHomepage: queryClient.getQueryData("userInfo").companyHomepage,
      marketingAgreement: {
        email: queryClient.getQueryData("userInfo").marketingAgreement.email,
        sms: queryClient.getQueryData("userInfo").marketingAgreement.sms,
      },
      signUpDate: moment(queryClient.getQueryData("userInfo").signUpDate).format("yyyy.MM.DD"),
      authState: queryClient.getQueryData("userInfo").authState,
      membershipState: queryClient.getQueryData("userInfo").membershipState,
      subscribingState: queryClient.getQueryData("userInfo").subscribingState,
      paymentMethod: queryClient.getQueryData("userInfo").paymentMethod,
      paymentCard: queryClient.getQueryData("userInfo").paymentCard,
    });
    valueSetState({
      email: queryClient.getQueryData("userInfo").email,
      name: queryClient.getQueryData("userInfo").name,
      birth: queryClient.getQueryData("userInfo").birthDate ? moment(queryClient.getQueryData("userInfo").birthDate).format("yyyy.MM.DD") : null,
      phone: queryClient.getQueryData("userInfo").contact,
      companyName: queryClient.getQueryData("userInfo").companyName,
      companyHomepage: queryClient.getQueryData("userInfo").companyHomepage,
      marketingAgreement: {
        email: queryClient.getQueryData("userInfo").marketingAgreement.email,
        sms: queryClient.getQueryData("userInfo").marketingAgreement.sms,
      },
      signUpDate: moment(queryClient.getQueryData("userInfo").signUpDate).format("yyyy.MM.DD"),
      authState: queryClient.getQueryData("userInfo").authState,
      membershipState: queryClient.getQueryData("userInfo").membershipState,
      subscribingState: queryClient.getQueryData("userInfo").subscribingState,
      paymentMethod: queryClient.getQueryData("userInfo").paymentMethod,
      paymentCard: queryClient.getQueryData("userInfo").paymentCard,
    });
  }, []);

  // 버튼 활성화 여부 state
  const [buttonActiveState, buttonActiveSetState] = useState(false);

  // 버튼 활성화 여부 state - 변경한 값이 있는 경우 활성화
  useEffect(() => {
    const originalValue = JSON.stringify(originalValueState);
    const newValue = JSON.stringify(valueState);

    if (originalValue !== newValue) {
      buttonActiveSetState(true);
    } else {
      buttonActiveSetState(false);
    }
  }, [valueState]);

  // text field value change event - 연락처는 숫자만 입력 가능
  const setValueEvent = (key, newValue) => {
    const onlyNums = newValue.replace(/[^0-9]/g, "");

    if (key === "phone") {
      newValue = onlyNums.replace(valueChangePhoneNumRegEx, "$1-$2-$3");
    }

    valueSetState((prevState) => ({
      ...prevState,
      [key]: newValue,
    }));
  };

  // checkbox value change event
  const setCheckValueEvent = (key, event) => {
    valueSetState((prevState) => ({
      ...prevState,
      marketingAgreement: { ...prevState.marketingAgreement, [key]: event.target.checked },
    }));
  };

  // date picker value change event
  const setDateValueEvent = (newValue) => {
    valueSetState((prevState) => ({
      ...prevState,
      birth: moment(newValue).format("yyyy.MM.DD"),
    }));
  };

  // 인증 메일 전송 완료 dialog open state
  const [dialogOpenState, dialogOpenSetState] = useState(false);
  // dialog message text state
  const [dialogMessageState, dialogMessageSetState] = useState("");
  // alert dialog message text state
  const [dialogAlertMessageState, dialogAlertMessageSetState] = useState("");
  // dialog custom hook
  const { dialogCall, DialogComponent } = DialogMessage(dialogMessageState);
  // alert dialog custom hook
  const { alertDialogCall, DialogAlertComponent } = DialogAlertMessage(dialogAlertMessageState);

  // 인증 메일 전송 button function
  const emailVerifyButtonFunc = () => {
    const callBack = () => {
      dialogOpenSetState(true);
    };
    doSendEmailVarification(callBack);
  };

  // 인증 메일 전송 완료 dialog close function
  const dialogCloseFunc = () => {
    doSignOutWithNoQuery();
    queryClient.clear();
    dialogOpenSetState(false);
    navigate("/signin");
  };

  // 기업 회원 정보 request function
  const requestUserInfo = useQuery("userInfo", RequestIncUserInfo, {
    enabled: false,
    onSuccess: (data) => {
      originalValueSetState({
        email: data.email,
        name: data.name,
        birth: data.birthDate ? moment(data.birthDate).format("yyyy.MM.DD") : null,
        phone: data.contact,
        companyName: data.companyName,
        companyHomepage: data.companyHomepage,
        marketingAgreement: {
          email: data.marketingAgreement.email,
          sms: data.marketingAgreement.sms,
        },
        signUpDate: moment(data.signUpDate).format("yyyy.MM.DD"),
        authState: data.authState,
        membershipState: data.membershipState,
        subscribingState: data.subscribingState,
        paymentMethod: data.paymentMethod,
        paymentCard: data.paymentCard,
      });
      valueSetState({
        email: data.email,
        name: data.name,
        birth: data.birthDate ? moment(data.birthDate).format("yyyy.MM.DD") : null,
        phone: data.contact,
        companyName: data.companyName,
        companyHomepage: data.companyHomepage,
        marketingAgreement: {
          email: data.marketingAgreement.email,
          sms: data.marketingAgreement.sms,
        },
        signUpDate: moment(data.signUpDate).format("yyyy.MM.DD"),
        authState: data.authState,
        membershipState: data.membershipState,
        subscribingState: data.subscribingState,
        paymentMethod: data.paymentMethod,
        paymentCard: data.paymentCard,
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 기업 회원 정보 update function
  const requestUpdateUserInfo = useMutation((userInfoState) => UpdateIncUserInfo(userInfoState), {
    onSuccess: () => {
      dialogMessageSetState("변경 사항이 저장되었습니다.");
      dialogCall();
      requestUserInfo.refetch();
    },
  });

  // 기업 회원 정보 저장 button submit function
  const submitEvent = () => {
    if (valueState.phone && !phoneNumRegEx.test(valueState.phone)) {
      dialogAlertMessageSetState("연락처가 형식에 맞지 않습니다.");
      alertDialogCall();
      phoneRef.current.focus();
    } else {
      requestUpdateUserInfo.mutate({
        email: valueState.email,
        name: valueState.name,
        birthDate: valueState.birth === "Invalid date" ? "" : valueState.birth,
        contact: valueState.phone,
        companyName: valueState.companyName,
        companyHomepage: valueState.companyHomepage,
        marketingAgreement: valueState.marketingAgreement,
        membershipState: valueState.membershipState,
        subscribingState: valueState.subscribingState,
        paymentMethod: valueState.paymentMethod,
        paymentCard: valueState.paymentCard,
      });
    }
  };

  // enter key press event
  const onEnterKeyPress = (event) => {
    if (buttonActiveState) {
      if (event.key === "Enter") {
        submitEvent();
      }
    }
  };

  // ref 묶음
  const refSet = { emailRef: emailRef, nameRef: nameRef, birthRef: birthRef, phoneRef: phoneRef, companyNameRef: companyNameRef, companyHomepageRef: companyHomepageRef };

  // 기업 회원 마이페이지 화면
  return (
    <Fragment>
      <MyPageBodyFrame title="계정설정" MyPageLeftContents={<MyPageMenuBox page="/my-page" />} MyPageRightContents={<MyPageContents ref={refSet} valueState={valueState} setValueEvent={setValueEvent} setCheckValueEvent={setCheckValueEvent} setDateValueEvent={setDateValueEvent} onEnterKeyPress={onEnterKeyPress} emailVarifiedState={emailVarifiedState} emailVerifyButtonFunc={emailVerifyButtonFunc} submitEvent={submitEvent} buttonActiveState={buttonActiveState} />} />
      <DialogWithOneButton message={["인증 메일을 전송했습니다.", <br />, "메일함에 없을 경우 스팸 메일함을 확인해주세요."]} dialogOpenState={dialogOpenState} buttonText="확인" buttonFunc={dialogCloseFunc} />
      <DialogComponent />
      <DialogAlertComponent />
    </Fragment>
  );
}
