import React from "react";

import moment from "moment";

import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Checkbox from "@mui/material/Checkbox";
import Pagination from "@mui/material/Pagination";
import Divider from "@mui/material/Divider";

// import ToastUIViewer from "../../components/ToastUIViewer";

import NaverBlogTag, { InstagramTag, NaverPostTag, YoutubeTag, FlexibleThumbnailBox } from "../../components/ProjectComponent";
import { ProfilePhotoBox } from "../../components/ProjectComponent";
import { portfolioProjectDefaultImageUrl } from "../../configs/urls";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CustomTableHeadRow = styled(TableRow)({
  borderTop: "1px solid #111111",
});

const CustomTableBodyRow = styled(TableRow)({
  backgroundColor: "#FFFFFF",
});

const ProfileBelowTableBodyCell = styled(TableCell)({
  cursor: "pointer",
});

const CustomTabButtonTypo = styled(Typography)(({ selectedTab, type, theme }) => ({
  color: selectedTab === type ? theme.palette.black[200] : theme.palette.black[900],
}));

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#B3B3B3",
  "&.Mui-checked": {
    color: theme.palette.secondary.main,
  },
}));

const CustomPagination = styled(Pagination)({
  "& .Mui-selected": {
    background: "#ffffff",
    fontWeight: 700,
  },
});

const PaginationGridContainer = styled(Grid)({
  position: "relative",
});

const PaginationGridItem = styled(Grid)({
  zIndex: 20,
});

const CustomDivider = styled(Divider)({
  borderColor: "#666666",
  height: "18px",
});

const GreyBoxCustomGrid = styled(Grid)({
  padding:"10px 13px",
  backgroundColor: "#FBFBFB",
  borderRadius: "10px",
});

// 모집 중 프로젝트 상세 페이지 하단 참여자 정보 테이블
export default function RecruitingProjectBelowTable(props) {
  const { belowTabSelectState, belowTabSelectFunc, projectDetailValue, applicantsListValue, appointersListValue, applicantsSelectArray, appointerSelectArray, applicantsCheckBoxEvent, appointerCheckBoxEvent, snsOpenFunc, portfolioPageOpenFunc, applicantsPageState, appointerPageState, applicantsTableDataPageChange, appointerTableDataPageChange } = props;

  const dataPerPage = 15;

  const applicantsTableBody = () => {
    return Object.keys(applicantsListValue)
      .slice((applicantsPageState - 1) * dataPerPage, (applicantsPageState - 1) * dataPerPage + dataPerPage)
      .map((applicant, index) => {
        return (
          <CustomTableBodyRow key={"applicants" + index}>
            <TableCell>
              <Grid container justifyContent="center">
                <CustomCheckbox icon={<CheckCircleOutlineIcon />} checkedIcon={<CheckCircleIcon />} variant="contained" checked={applicantsSelectArray.includes(applicant)} onChange={() => applicantsCheckBoxEvent(applicant)} />
              </Grid>
            </TableCell>
            <ProfileBelowTableBodyCell onClick={() => portfolioPageOpenFunc(applicant)}>
              <Grid container alignItems="center" columnSpacing={3}>
                <Grid item xs="auto">
                  <ProfilePhotoBox profilePhotoUrl={applicantsListValue[applicant].profilePhotoUrl} />
                </Grid>
                <Grid container item xs columnGap={1}>
                  <Grid item>
                    <Typography variant="subtitle1" lineHeight="24px" fontWeight={700} color="black.300">
                      {applicantsListValue[applicant].name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" lineHeight="24px" color="#B6B6B6">
                      {moment().diff(moment(applicantsListValue[applicant].birthDate).format("YYYY"), "years") + 1}세 / {applicantsListValue[applicant].city}
                    </Typography>
                  </Grid>
                  <GreyBoxCustomGrid item xs={12}>
                    {/* <ToastUIViewer markdownText={applicantsListValue[applicant].introduction} /> */}
                    <Typography variant="body1" lineHeight="20px" color="black.600">
                      {applicantsListValue[applicant].introduction}
                    </Typography>
                  </GreyBoxCustomGrid>
                </Grid>
              </Grid>
            </ProfileBelowTableBodyCell>
            <TableCell>
              <Grid container justifyContent="center" columnSpacing={1}>
                {applicantsListValue[applicant].sns.naverBlog !== "" && projectDetailValue.channel === "naverBlog" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(applicantsListValue[applicant].sns.naverBlog)}>
                      <NaverBlogTag />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {applicantsListValue[applicant].sns.naverPost !== "" && projectDetailValue.channel === "naverPost" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(applicantsListValue[applicant].sns.naverPost)}>
                      <NaverPostTag />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {applicantsListValue[applicant].sns.instagram !== "" && projectDetailValue.channel === "instagram" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(applicantsListValue[applicant].sns.instagram)}>
                      <InstagramTag />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {applicantsListValue[applicant].sns.youtube !== "" && projectDetailValue.channel === "youtube" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(applicantsListValue[applicant].sns.youtube)}>
                      <YoutubeTag />
                    </ButtonBase>
                  </Grid>
                ) : null}
              </Grid>
            </TableCell>
            <TableCell>
              <Grid container justifyContent="center" columnSpacing={1}>
                {applicantsListValue[applicant].project.project1.url ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(applicantsListValue[applicant].project.project1.url)}>
                      <FlexibleThumbnailBox thumbnailUrl={applicantsListValue[applicant].project.project1.image ? applicantsListValue[applicant].project.project1.image : portfolioProjectDefaultImageUrl} size="80px" />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {applicantsListValue[applicant].project.project2.url ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(applicantsListValue[applicant].project.project2.url)}>
                      <FlexibleThumbnailBox thumbnailUrl={applicantsListValue[applicant].project.project2.image ? applicantsListValue[applicant].project.project2.image : portfolioProjectDefaultImageUrl} size="80px" />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {applicantsListValue[applicant].project.project3.url ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(applicantsListValue[applicant].project.project3.url)}>
                      <FlexibleThumbnailBox thumbnailUrl={applicantsListValue[applicant].project.project3.image ? applicantsListValue[applicant].project.project3.image : portfolioProjectDefaultImageUrl} size="80px" />
                    </ButtonBase>
                  </Grid>
                ) : null}
              </Grid>
            </TableCell>
          </CustomTableBodyRow>
        );
      });
  };

  const appointerTableBody = () => {
    return Object.keys(appointersListValue)
      .slice((appointerPageState - 1) * dataPerPage, (appointerPageState - 1) * dataPerPage + dataPerPage)
      .map((appointer, index) => {
        return (
          <CustomTableBodyRow key={"appointers" + index}>
            <TableCell>
              <CustomCheckbox icon={<CheckCircleOutlineIcon />} checkedIcon={<CheckCircleIcon />} variant="contained" checked={appointerSelectArray.includes(appointer)} onChange={() => appointerCheckBoxEvent(appointer)} />
            </TableCell>
            <TableCell>
              <Grid container alignItems="center" columnSpacing={3}>
                <Grid item xs="auto">
                  <ProfilePhotoBox profilePhotoUrl={appointersListValue[appointer].profilePhotoUrl} />
                </Grid>
                <Grid container item xs columnGap={1}>
                  <Grid item>
                    <Typography variant="subtitle1" lineHeight="24px" fontWeight={700} color="black.300">
                      {appointersListValue[appointer].name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" lineHeight="24px" color="#B6B6B6">
                      {moment().diff(moment(appointersListValue[appointer].birthDate).format("YYYY"), "years") + 1}세 / {appointersListValue[appointer].city}
                    </Typography>
                  </Grid>
                  <GreyBoxCustomGrid item xs={12}>
                    {/* <ToastUIViewer markdownText={appointersListValue[appointer].introduction} /> */}
                    <Typography variant="body1" lineHeight="20px" color="black.600">
                      {applicantsListValue[appointer].introduction}
                    </Typography>
                  </GreyBoxCustomGrid>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid container justifyContent="center" columnSpacing={1}>
                {appointersListValue[appointer].sns.naverBlog !== "" && projectDetailValue.channel === "naverBlog" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(appointersListValue[appointer].sns.naverBlog)}>
                      <NaverBlogTag />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {appointersListValue[appointer].sns.naverPost !== "" && projectDetailValue.channel === "naverPost" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(appointersListValue[appointer].sns.naverPost)}>
                      <NaverPostTag />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {appointersListValue[appointer].sns.instagram !== "" && projectDetailValue.channel === "instagram" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(appointersListValue[appointer].sns.instagram)}>
                      <InstagramTag />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {appointersListValue[appointer].sns.youtube !== "" && appointer.channel === "youtube" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(appointersListValue[appointer].sns.youtube)}>
                      <YoutubeTag />
                    </ButtonBase>
                  </Grid>
                ) : null}
              </Grid>
            </TableCell>
            <TableCell>
              <Grid container justifyContent="center" columnSpacing={1}>
                {appointersListValue[appointer].project.project1.url ? (
                  <Grid item>
                    <ButtonBase onclick={() => snsOpenFunc(appointersListValue[appointer].project.project1.url)}>
                      <FlexibleThumbnailBox thumbnailUrl={appointersListValue[appointer].project.project1.image ? appointersListValue[appointer].project.project1.image : portfolioProjectDefaultImageUrl} size="80px" />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {appointersListValue[appointer].project.project2.url ? (
                  <Grid item>
                    <ButtonBase onclick={() => snsOpenFunc(appointersListValue[appointer].project.project2.url)}>
                      <FlexibleThumbnailBox thumbnailUrl={appointersListValue[appointer].project.project2.image ? appointersListValue[appointer].project.project2.image : portfolioProjectDefaultImageUrl} size="80px" />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {appointersListValue[appointer].project.project3.url ? (
                  <Grid item>
                    <ButtonBase onclick={() => snsOpenFunc(appointersListValue[appointer].project.project3.url)}>
                      <FlexibleThumbnailBox thumbnailUrl={appointersListValue[appointer].project.project3.image ? appointersListValue[appointer].project.project3.image : portfolioProjectDefaultImageUrl} size="80px" />
                    </ButtonBase>
                  </Grid>
                ) : null}
              </Grid>
            </TableCell>
          </CustomTableBodyRow>
        );
      });
  };

  return (
    <Grid container item rowSpacing={3}>
      <Grid container item xs={4} columnSpacing={2}>
        <Grid item alignSelf="center">
          <ButtonBase onClick={() => belowTabSelectFunc("applicants")}>
            <CustomTabButtonTypo variant="h5" fontWeight={700} type="applicants" selectedTab={belowTabSelectState}>
              신청자 {Object.keys(applicantsListValue).length}
            </CustomTabButtonTypo>
          </ButtonBase>
        </Grid>
        <Grid item>
          <CustomDivider orientation="vertical" variant="middle"/>
        </Grid>
        <Grid item alignSelf="center">
          <ButtonBase onClick={() => belowTabSelectFunc("appointer")}>
            <CustomTabButtonTypo variant="h5" fontWeight={700} type="appointer" selectedTab={belowTabSelectState}>
              선정자 {Object.keys(appointersListValue).length}/{projectDetailValue.recruitmentLimitNumber}
            </CustomTabButtonTypo>
          </ButtonBase>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <CustomTableHeadRow>
                <TableCell align="center" width="5%">
                  <Typography variant="body1" lineHeight="24px" color="#4C4C4C">
                    선택
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1" lineHeight="24px" color="#4C4C4C">
                    프로필
                  </Typography>
                </TableCell>
                <TableCell align="center" width="20%">
                  <Typography variant="body1" lineHeight="24px" color="#4C4C4C">
                    채널
                  </Typography>
                </TableCell>
                <TableCell align="center" width="30%">
                  <Typography variant="body1" lineHeight="24px" color="#4C4C4C">
                    대표 프로젝트
                  </Typography>
                </TableCell>
              </CustomTableHeadRow>
            </TableHead>
            <TableBody>
              {/* 신청자 */}
              {belowTabSelectState === "applicants" ? applicantsTableBody() : appointerTableBody()}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <PaginationGridContainer container item justifyContent="center" alignItems="center">
        <PaginationGridItem item>{belowTabSelectState === "applicants" ? <CustomPagination shape="rounded" count={Math.ceil(Object.keys(applicantsListValue).length / dataPerPage)} page={applicantsPageState} onChange={applicantsTableDataPageChange} /> : <CustomPagination shape="rounded" count={Math.ceil(Object.keys(appointersListValue).length / dataPerPage)} page={appointerPageState} onChange={appointerTableDataPageChange} />}</PaginationGridItem>
      </PaginationGridContainer>
    </Grid>
  );
}
