import { forwardRef } from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Divider from "@mui/material/Divider";

import { TextFieldWithoutLabelSet } from "../Common/TextFieldSet";
import { DashedUploadImageBoxComponent } from "../../components/UploadImageBoxComponent";

import { deleteIconUrl } from "../../configs/urls";

const DeleteIconButtonBase = styled(ButtonBase)({
  width: "60px",
  aspectRatio: "1",
  borderRadius: "10px",
  border: "1px solid #222222",
});

// 포트폴리오 수정 페이지 - 추가 정보
export const UpdatePortfolioAdditionalInfo = forwardRef((props, forwardRef) => {
  const { blobImgSrcState, myProfileValueState, myProfileValueChange, onKeyPress, deleteProjectEvent, changeThumbnailClickFunc, changeThumbnailFunc } = props;
  const { project1Ref, project1ThumbnailRef, project2Ref, project2ThumbnailRef, project3Ref, project3ThumbnailRef } = forwardRef;

  const projects = () => {
    return (
      <Grid container item rowSpacing={5}>
        <Grid container item xs={3.4} rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="black.200">프로젝트 01</Typography>
          </Grid>
          <Grid item xs={12}>
            <DashedUploadImageBoxComponent ref={project1ThumbnailRef} valueState={myProfileValueState.project1Thumbnail} blobImgSrcState={blobImgSrcState.project1} changeImageButtonFunc={() => changeThumbnailClickFunc("project1")} changeImageFunc={() => changeThumbnailFunc("project1")} />
          </Grid>
          <Grid container item justifyContent="space-between" xs={12} columnSpacing={1}>
            <Grid item xs={9.5}>
              <TextFieldWithoutLabelSet type="text" placeholder="https://www.url.com" ref={project1Ref} valueState={myProfileValueState.project1} setValueEvent={(event) => myProfileValueChange("project1", event)} onKeyPress={onKeyPress} autoFocus={false} />
            </Grid>
            <Grid item xs={2.5} textAlign="right">
              <DeleteIconButtonBase onClick={() => deleteProjectEvent("project1")}>
                <img src={deleteIconUrl} alt="삭제" />
              </DeleteIconButtonBase>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={0.9} alignSelf="end" justifyContent="center" paddingBottom="8px">
          <Divider orientation="vertical" sx={{ height: "412px" }} />
        </Grid>
        <Grid container item xs={3.4} rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="black.200">프로젝트 02</Typography>
          </Grid>
          <Grid item xs={12}>
            <DashedUploadImageBoxComponent ref={project2ThumbnailRef} valueState={myProfileValueState.project2Thumbnail} blobImgSrcState={blobImgSrcState.project2} changeImageButtonFunc={() => changeThumbnailClickFunc("project2")} changeImageFunc={() => changeThumbnailFunc("project2")} />
          </Grid>
          <Grid container item justifyContent="space-between" xs={12}>
            <Grid item xs={9.5}>
              <TextFieldWithoutLabelSet type="text" placeholder="https://www.url.com" ref={project2Ref} valueState={myProfileValueState.project2} setValueEvent={(event) => myProfileValueChange("project2", event)} onKeyPress={onKeyPress} autoFocus={false} />
            </Grid>
            <Grid item xs={2.5} textAlign="right">
              <DeleteIconButtonBase onClick={() => deleteProjectEvent("project2")}>
                <img src={deleteIconUrl} alt="삭제" />
              </DeleteIconButtonBase>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={0.9} alignSelf="end" justifyContent="center" paddingBottom="8px">
          <Divider orientation="vertical" sx={{ height: "412px" }} />
        </Grid>
        <Grid container item xs={3.4} rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="black.200">프로젝트 03</Typography>
          </Grid>
          <Grid item xs={12}>
            <DashedUploadImageBoxComponent ref={project3ThumbnailRef} valueState={myProfileValueState.project3Thumbnail} blobImgSrcState={blobImgSrcState.project3} changeImageButtonFunc={() => changeThumbnailClickFunc("project3")} changeImageFunc={() => changeThumbnailFunc("project3")} />
          </Grid>
          <Grid container item justifyContent="space-between" xs={12}>
            <Grid item xs={9.5}>
              <TextFieldWithoutLabelSet type="text" placeholder="https://www.url.com" ref={project3Ref} valueState={myProfileValueState.project3} setValueEvent={(event) => myProfileValueChange("project3", event)} onKeyPress={onKeyPress} autoFocus={false} />
            </Grid>
            <Grid item xs={2.5} textAlign="right">
              <DeleteIconButtonBase onClick={() => deleteProjectEvent("project3")}>
                <img src={deleteIconUrl} alt="삭제" />
              </DeleteIconButtonBase>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container item rowSpacing={4.6}>
      {projects()}
    </Grid>
  );
});
