import React from "react";
import RequireAuthRoute from "../../routes/requireAuth";

import UniversalAppBar from "../../Block/Appbar/UniversalAppBar";
import UniversalAppFooter from "../../Block/Footer/UniversalAppFooter";

import NewProject from "../../containers/Service/Project/NewProjectContainer";

export default function ProjectDetailView() {
  return (
    <React.Fragment>
      <UniversalAppBar />
      <RequireAuthRoute Component={NewProject} />
      <UniversalAppFooter />
    </React.Fragment>
  );
}
