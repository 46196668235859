import React from "react";

import AdminSignIn from "../../containers/Admin/AdminSignInContainer";
import RequireAuthRoute from "../../routes/requireAuth";

export default function AdminSignInView() {
  return (
    <React.Fragment>
      <RequireAuthRoute Component={AdminSignIn} />
    </React.Fragment>
  );
}
