import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import { cancelApplIconUrl, circleCheckIconUrl, circleNoticeIconUrl, modalCloseUrl } from "../configs/urls";

import { ContainedButton, LightContainedButton, OutlinedButton } from "./ButtonComponents";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    maxHeight: "70%",
    borderRadius: "30px 30px 30px 0px",
    borderShadow: "0px 0px 16px rgba(0, 0, 0, 0.09)",
    padding: "45px 48px",
    position: "absolute",
    justifyContent: "center",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
}));

const IconStrToIcon = (iconStr) => {
  switch(iconStr) {
    case "check":
      return <img src={circleCheckIconUrl} alt="" />;
    case "notice":
      return <img src={circleNoticeIconUrl} alt="" />;
    case "cancel":
      return <img src={cancelApplIconUrl} alt="" />;
    default:
      return null;
  }
};

// dialog - text : 버튼 3개 (X버튼 + 하단버튼1 + 하단버튼2)
export default function DialogWithButton(props) {
  const { width, icon, title, subtitle, message, dialogOpenState, dialogClose, button1Text, button2Text, button1Func, button2Func } = props;

  return (
    <CustomDialog open={dialogOpenState} onClose={dialogClose} PaperProps={{ sx: { width: width } }}>
      <Grid container item rowSpacing={5} position="relative">
        <Grid container item justifyContent="flex-end" position="absolute">
          <Grid item>
            <IconButton onClick={() => dialogClose()}>
              <img width="19px" src={modalCloseUrl} alt="창 닫기" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container item xs={12} rowGap={1}>
          <Grid item xs={12} textAlign="center" paddingTop="13px">
            {IconStrToIcon(icon)}
          </Grid>
          { title ?
          <Grid item xs={12} textAlign="center">
            <Typography variant="h3" fontWeight={500} lineHeight="27px" color="secondary">
              {title}
            </Typography>
          </Grid>
          : null
          }
        </Grid>
        <Grid container item xs={12} rowGap={1}>
          {subtitle ?
          <Grid item xs={12} textAlign="center">
            <Typography variant="subtitle1" fontWeight={400} lineHeight="24px" color="black.600">
              {subtitle}
            </Typography>
          </Grid>
          : null
          }
          <Grid item xs={12} textAlign="center">
            <Typography variant="h5" fontWeight={400} lineHeight="27px" color="secondary">
              {message}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item columnSpacing={1}>
          <Grid item xs={6}>
            <OutlinedButton buttonEvent={button1Func} buttonText={button1Text} />
          </Grid>
          <Grid item xs={6}>
            <ContainedButton buttonEvent={button2Func} buttonText={button2Text} buttonDisabled={false} />
          </Grid>
        </Grid>
      </Grid>
    </CustomDialog>
  );
}

// dialog - text : 버튼 2개 (하단버튼1 + 하단버튼2)
export function DialogWithTwoButton(props) {
  const {width, icon, title, subtitle, message, dialogOpenState, button1Text, button1Func, button2Text, button2Func} = props;

  return (
      <CustomDialog open={dialogOpenState} PaperProps={{sx: {width: width}}}>
        <Grid container item rowSpacing={3}>
          {icon ?
          <Grid container item xs={12} rowGap={1}>
            <Grid item xs={12} textAlign="center">
              {IconStrToIcon(icon)}
            </Grid>
            {title ?
            <Grid item xs={12} textAlign="center">
              <Typography variant="h3" fontWeight={500} lineHeight="27px" color="secondary">
                {title}
              </Typography>
            </Grid>
            : null
            }
          </Grid>
          : null
          }
          <Grid container item xs={12} rowGap={1}>
            {subtitle ?
            <Grid item xs={12} textAlign="center">
              <Typography variant="subtitle1" fontWeight={400} lineHeight="24px" color="black.600">
                {subtitle}
              </Typography>
            </Grid>
            : null
            }
            <Grid item xs={12} textAlign="center">
              <Typography variant="h5" fontWeight={400} lineHeight="27px" color="secondary">
                {message}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item columnSpacing={2.3}>
            <Grid item xs={6}>
              <LightContainedButton buttonEvent={button1Func} buttonText={button1Text} />
            </Grid>
            <Grid item xs={6}>
              <ContainedButton buttonEvent={button2Func} buttonText={button2Text} buttonDisabled={false} />
            </Grid>
          </Grid>
        </Grid>
      </CustomDialog>
  );
}

// dialog - text : 버튼 1개 (하단버튼 1)
export function DialogWithOneButton(props) {
  const {width, icon, title, subtitle, message, dialogOpenState, buttonText, buttonFunc} = props;

  return (
      <CustomDialog open={dialogOpenState} PaperProps={{ sx: {width: width} }}>
        <Grid container item rowSpacing={3}>
          {icon ?
          <Grid container item xs={12} rowGap={1}>
            <Grid item xs={12} textAlign="center">
              {IconStrToIcon(icon)}
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography variant="h3" fontWeight={500} lineHeight="27px" color="secondary">
                {title}
              </Typography>
            </Grid>
          </Grid>
          : null
          }
          <Grid container item xs={12} rowGap={1}>
            <Grid item xs={12} textAlign="center">
              <Typography variant="subtitle1" fontWeight={400} lineHeight="24px" color="black.600">
                {subtitle}
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography variant="h6" fontWeight={400} lineHeight="27px" color="secondary">
                {message}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ContainedButton buttonEvent={buttonFunc} buttonText={buttonText} buttonDisabled={false}/>
          </Grid>
        </Grid>
      </CustomDialog>
  );
}

// dialog - Grid : 버튼 1개 (하단버튼1)
export function ComplexDialogWithOneButton(props) {
  const { title, context, dialogOpenState, dialogClose, buttonText, buttonFunc, maxWidth } = props;

  return (
    <CustomDialog open={dialogOpenState} onClose={dialogClose} PaperProps={{ sx: { maxWidth: maxWidth ? maxWidth : 400 } }}>
      <Grid container item rowGap={4.5}>
        {title ? 
        <Grid container item alignItems="center">
          <Typography variant="h2" fontWeight={700} color="black.200">
            {title}
          </Typography>
        </Grid>
        : null}
        <Grid container item justifyContent="flex-end">
          <Grid item xs={12}>
            {context}
          </Grid>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <ContainedButton buttonEvent={buttonFunc} buttonText={buttonText} buttonDisabled={false} maxWidth={284} />
        </Grid>
      </Grid>
    </CustomDialog>
  );
}

// dialog - Grid : 버튼 3개 (X버튼 + 하단버튼1 + 하단버튼2)
export function ComplexDialogWithThreeButton(props) {
  const { title, context, dialogOpenState, dialogClose, button1Text, button2Text, button1Func, button2Func, maxWidth } = props;

  return (
    <CustomDialog open={dialogOpenState} onClose={dialogClose} PaperProps={{ sx: { maxWidth: maxWidth ? maxWidth : 400 } }}>
      <Grid container item rowGap={4.5}>
        <Grid container item justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h2" fontWeight={700}>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => dialogClose()}>
              <img width="19px" src={modalCloseUrl} alt="창 닫기" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {context}
        </Grid>
        <Grid container item columnSpacing={2} justifyContent="center">
          <Grid item xs={6} textAlign="right">
            <LightContainedButton buttonEvent={button1Func} buttonText={button1Text} maxWidth={220} />
          </Grid>
          <Grid item xs={6} textAlign="left">
            <ContainedButton buttonEvent={button2Func} buttonText={button2Text} buttonDisabled={false} maxWidth={220} />
          </Grid>
        </Grid>
      </Grid>
    </CustomDialog>
  );
}