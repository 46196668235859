import React from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ToastUIViewer from "../../components/ToastUIViewer";

const TermsTextGrid = styled(Grid)(({ theme }) => ({
  height: "90%",
  padding: 16,
  background: theme.palette.grey[100],
  overflowY: "scroll",
  borderRadius: "4px",
  "&::-webkit-scrollbar": {
    width: 3,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#e6e6e6",
    borderRadius: "2px",
  },
}));

// 멤버십 정책 dialog
export default function TermsDialogContext(props) {
  const { membershipTermsText } = props;
  return (
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight={500} align="center">
          멤버십 정책
        </Typography>
      </Grid>
      <TermsTextGrid item xs={12}>
        <ToastUIViewer markdownText={membershipTermsText} />
      </TermsTextGrid>
    </Grid>
  );
}
