import React from "react";

import { styled } from "@mui/material/styles";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { UnderlinedTabs } from "../../components/TapComponents";

const BackgroundBox = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  height: "100%",
  background: "#FFFFFF",
}));

const BackgroundContainer = styled(Box)({
  height: "150px",
  background: "#EFEFEF",
  marginTop: "84px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const CustomContainer = styled(Container)({
  minHeight: "100vh",
  padding: "30px 0 90px !important",
});

export default function UpdatePortfolioBodyFrame(props) {
  const { MyPortfolioRightContents, myPageEvent } = props;
  return (
    <BackgroundBox>
      <BackgroundContainer>
        <Grid container maxWidth="lg">
          <Typography variant="h1" color="black.200">
            포트폴리오 관리
          </Typography>
        </Grid>
      </BackgroundContainer>
      <CustomContainer>
        <Grid container rowGap={5} padding="0 0">
          <UnderlinedTabs value={0} tabs={[ {label: "프로필 정보", tabClickEvent: () => {}}, {label: "계정 설정", tabClickEvent: () => myPageEvent(), openInNew: true} ]}></UnderlinedTabs>
          {MyPortfolioRightContents}
        </Grid>
      </CustomContainer>
    </BackgroundBox>
  );
}