import React, { forwardRef, Fragment } from "react";

import { styled } from "@mui/material/styles";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { ProjectListItem, FilteringButton, ExceptClosedProjectButton, SortingButton } from "../../components/ProjectComponent";
import { TypeFilteringMenu, ChannelFilteringMenu, SortingMenu } from "./ProjectListFilteringMenus";

const CustomContainer = styled(Container)({
  minHeight: "100vh",
  paddingTop: "170px",
});

const FetchingBox = styled(Box)({
  width: "100%",
  height: "150px",
});

// 프로젝트 리스트
export const ProjectListContents = forwardRef((props, forwardRef) => {
  const { projectListValues, dataLoadingState, dataLoadedState, projectItemClickFunc, openedFilteringMenuState, filteringMenuOpenFunc, filteringMenuCloseFunc, filterState, sortingOptionState, closedProjectBtnClickFunc, typeMenuSelectFunc, channelMenuSelectFunc, channelMenuCloseFunc, sortingMenuSelectFunc } = props;
  const { typeFilterButtonRef, channelFilterButtonRef, sortingButtonRef, fetchingBoxRef } = forwardRef;

  // filtering 아이템 - 유형
  const typeFilterItems = {
    all: "전체",
    delivery: "배송형",
    visit: "방문형",
    purchase: "구매형",
  };

  // filtering 아이템 - 채널
  const channelFilterItems = {
    instagram: "인스타그램",
    naverBlog: "네이버 블로그",
    naverPost: "네이버 포스트",
    youtube: "유튜브",
  };

  // sorting 아이템
  const sortingItems = {
    registrationDate: "최신순",
    recruitmentNumber: "인기순",
    recruitmentEndDate: "마감순",
  };

  // filtering button
  const projectListFilteringButtons = () => {
    return (
      <Grid container item justifyContent="space-between" alignItems="center">
        <Grid container item xs={6} columnGap={2}>
          <Grid item xs="auto">
            <FilteringButton ref={typeFilterButtonRef} filterName="유형" filterType="type" filterMenuOpenedState={openedFilteringMenuState === "type"} filteringMenuOpenFunc={filteringMenuOpenFunc} selectedItemLabel={filterState.typeFilterItems.length === 3 ? typeFilterItems.all : typeFilterItems[filterState.typeFilterItems]} />
            <TypeFilteringMenu ref={typeFilterButtonRef} filterItems={typeFilterItems} selectedFilterItemState={filterState.typeFilterItems} filterMenuOpenedState={openedFilteringMenuState === "type"} filteringMenuCloseFunc={filteringMenuCloseFunc} typeMenuSelectFunc={typeMenuSelectFunc} />
          </Grid>
          <Grid item xs={5.5}>
            <FilteringButton ref={channelFilterButtonRef} filterName="채널" filterType="channel" filterMenuOpenedState={openedFilteringMenuState === "channel"} filteringMenuOpenFunc={filteringMenuOpenFunc} selectedItemLabel={filterState.channelFilterItems.length > 0 ? (filterState.channelFilterItems.length === 4 ? "전체" : filterState.channelFilterItems.length > 1 ? channelFilterItems[filterState.channelFilterItems[0]] + " 외 " + (filterState.channelFilterItems.length - 1) : channelFilterItems[filterState.channelFilterItems[0]]) : "선택 없음"} />
            <ChannelFilteringMenu ref={channelFilterButtonRef} filterItems={channelFilterItems} selectedFilterItemState={filterState.channelFilterItems} filterMenuOpenedState={openedFilteringMenuState === "channel"} filteringMenuCloseFunc={channelMenuCloseFunc} channelMenuSelectFunc={channelMenuSelectFunc} />
          </Grid>
        </Grid>
        <Grid container item xs={6} columnGap={2} justifyContent="flex-end">
          <Grid item xs="auto" alignSelf="center">
            <ExceptClosedProjectButton filterState={filterState.exceptClosedProject} closedProjectBtnClickFunc={closedProjectBtnClickFunc} />
          </Grid>
          <Grid item xs={3}>
            <SortingButton ref={sortingButtonRef} filterMenuOpenedState={openedFilteringMenuState === "sorting"} filteringMenuOpenFunc={filteringMenuOpenFunc} selectedItemLabel={sortingItems[sortingOptionState]} />
            <SortingMenu ref={sortingButtonRef} sortingItems={sortingItems} selectedFilterItemState={sortingOptionState} filterMenuOpenedState={openedFilteringMenuState === "sorting"} filteringMenuCloseFunc={filteringMenuCloseFunc} sortingMenuSelectFunc={sortingMenuSelectFunc} />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  // 프로젝트 리스트 아이템
  const projectListItems = () => {
    return (
      <Grid container item spacing={3.8}>
        {projectListValues.pages.map((projectValue, index) => {
          return projectValue.map((projectData, dataIndex) => {
            return (
              <Grid item xs={3} key={"project" + index + "_" + dataIndex}>
                <ProjectListItem projectValue={projectData.document} projectItemClickFunc={() => projectItemClickFunc(projectData.document.id, index, dataIndex)} />
              </Grid>
            );
          });
        })}
      </Grid>
    );
  };

  return (
    <Fragment>
      <CustomContainer maxWidth="lg">
        <Grid container rowGap={3.5}>
          <Grid container item rowGap={10.5}>
            <Grid container item rowGap={2.5}>
              <Grid item xs={12}>
                <Typography fontSize="48px" lineHeight="54px" fontWeight={700} color="black.200">
                  온라인 리뷰 프로젝트
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" component="p" fontWeight={400} lineHeight="21px" color="black.300">
                  제품 또는 서비스 체험 후 솔직하고 자세한 리뷰를 나의 SNS에 공유해보세요.
                </Typography>
              </Grid>
            </Grid>
            {projectListFilteringButtons()}
          </Grid>
          {dataLoadingState ? (
            <Backdrop open={true}>
              <CircularProgress color="secondary" />
            </Backdrop>
          ) : dataLoadedState ? (
            projectListItems()
          ) : null}
        </Grid>
      </CustomContainer>
      <FetchingBox ref={fetchingBoxRef} />
    </Fragment>
  );
});
