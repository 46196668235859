import React from "react";

import { styled } from "@mui/material/styles";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const BackgroundBox = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  height: "100%",
  background: theme.palette.primary,
}));

const CustomContainer = styled(Container)({
  minHeight: "100vh",
  padding: "140px 0 90px",
});

const MyPageContentsGridContainer = styled(Grid)({
  paddingLeft: "58px",
});

// 기업 회원 마이페이지 Frame
export default function MyPageBodyFrame(props) {
  const { MyPageLeftContents, title, text, MyPageRightContents } = props;
  return (
    <BackgroundBox>
      <CustomContainer maxWidth="lg">
        <Grid container rowSpacing={6}>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h1" color="black.200">
              마이 페이지
            </Typography>
          </Grid>
          <Grid item xs={2.8}>
            {MyPageLeftContents}
          </Grid>
          <Grid item xs={9.2}>
            <MyPageContentsGridContainer container item rowGap={3.5}>
              <Grid container item xs={12} paddingTop="20px" rowSpacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h3" color="black.300">
                    {title}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" fontWeight={400} color="black.600">
                    {text}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item>
                {MyPageRightContents}
              </Grid>
            </MyPageContentsGridContainer>
          </Grid>
        </Grid>
      </CustomContainer>
    </BackgroundBox>
  );
}
