import React, { Fragment, useState, useRef, useEffect } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import moment from "moment";

import NewProjectBodyFrame from "../../../Block/IncUserMyPage/NewProjectBodyFrame";
import { NewProjectContents, PeriodInfoGuideContext } from "../../../Block/IncUserMyPage/NewProjectContents";
import NewProjectNextFixedBox, { NewProjectSubmitFixedBox } from "../../../Block/IncUserMyPage/NewProjectFixedBox";

import { DialogWithOneButton, ComplexDialogWithOneButton } from "../../../components/DialogWithButton";
import { DialogAlertMessage } from "../../../components/DialogMessage";
import LoadingComponent from "../../../components/LoadingComponent";

import { RequestUserBrandInfo, RequestProjectThumbnailUrl, RequestProjectDocId, RequestUpload, SetNewProjectInfo } from "../../../utils/requestToSrv";

const phoneNumRegEx = new RegExp(/([0-9]{2,3})-([0-9]{3,4})-([0-9]{4})/);
const valueChangePhoneNumRegEx = new RegExp(/([0-9]{2,3})([0-9]{3,4})([0-9]{4})/);

// 프로젝트 등록 페이지
export default function NewProjectContainer() {
  // 등록 단계 (basic > additional) state
  const [projectStepState, projectStepSetState] = useState(0);

  // react-query queryClient
  const queryClient = useQueryClient();

  // react-router-dom navigate
  const navigate = useNavigate();

  // basic info refs
  const titleRef = useRef(null);
  const brandRef = useRef(null);
  const channelRef = useRef(null);
  const typeRef = useRef(null);
  const recruitmentStartDateRef = useRef(null);
  const recruitmentEndDateRef = useRef(null);
  const appointmentDateRef = useRef(null);
  const submissionStartDateRef = useRef(null);
  const submissionEndDateRef = useRef(null);
  const endDateRef = useRef(null);
  const recruitmentNumberRef = useRef(null);
  const contactRef = useRef(null);

  // additional info refs
  const thumbnailRef = useRef(null);
  const uploadedThumbnailRef = useRef(null);
  const detailedImageRef = useRef(null);
  const offeringRef = useRef(null);
  const hashTagsRef = useRef(null);
  const guideRef = useRef(null);
  const visitingInfoRef = useRef(null);

  // 전체 ref 묶음
  const refSet = {
    titleRef: titleRef,
    brandRef: brandRef,
    channelRef: channelRef,
    typeRef: typeRef,
    recruitmentStartDateRef: recruitmentStartDateRef,
    recruitmentEndDateRef: recruitmentEndDateRef,
    appointmentDateRef: appointmentDateRef,
    submissionStartDateRef: submissionStartDateRef,
    submissionEndDateRef: submissionEndDateRef,
    endDateRef: endDateRef,
    recruitmentNumberRef: recruitmentNumberRef,
    contactRef: contactRef,
    thumbnailRef: thumbnailRef,
    uploadedThumbnailRef: uploadedThumbnailRef,
    detailedImageRef: detailedImageRef,
    offeringRef: offeringRef,
    hashTagsRef: hashTagsRef,
    guideRef: guideRef,
    visitingInfoRef: visitingInfoRef,
  };

  // 프로젝트 value state
  const [projectValueState, projectValueSetState] = useState({
    title: "",
    channel: "",
    type: "",
    recruitmentStartDate: null,
    recruitmentEndDate: null,
    appointmentDate: null,
    submissionStartDate: null,
    submissionEndDate: null,
    endDate: null,
    recruitmentLimitNumber: null,
    contact: "",
    thumbnail: "",
    detailedImage: "",
    offering: "",
    hashTags: "",
    guide: "",
    visitingInfo: "",
  });

  // 등록할 프로젝트 doc id 생성 request
  const projectDocId = useQuery("projectDocId", RequestProjectDocId, {
    onError: (error) => {
      console.log(error);
    },
  });

  // 브랜드 정보 data load
  const brandInfo = useQuery("brandInfo", RequestUserBrandInfo, {
    onError: (error) => {
      console.log(error);
    },
  });

  // 프로젝트 value state 변경 함수
  // 모집 인원은 숫자만 입력 가능
  // 연락처는 숫자만 입력 가능하며, 010-1234-5678 형식으로 변환
  const projectValueChange = (key, newValue) => {
    const onlyNums = newValue.replace(/[^0-9]/g, "");

    if (key === "recruitmentLimitNumber") {
      newValue = onlyNums;
    } else if (key === "contact") {
      newValue = onlyNums.replace(valueChangePhoneNumRegEx, "$1-$2-$3");
    }

    projectValueSetState((prevState) => ({
      ...prevState,
      [key]: newValue,
    }));
  };

  // 프로젝트 value state 중 date picker 변경 함수
  const projectDateValueChange = (key, newValue) => {
    projectValueSetState((prevState) => ({
      ...prevState,
      [key]: moment(newValue).format("yyyy.MM.DD"),
    }));
  };

  // 프로젝트 value state 중 select 변경 함수
  const projectSelectValueChange = (key, event) => {
    projectValueSetState((prevState) => ({
      ...prevState,
      [key]: event.target.value,
    }));
  };

  const linkToMembership = () => {
    navigate("/membership");
  };

  // alert dialog message text state
  const [alertDialogMessageState, alertDialogMessageSetState] = useState("");

  // alert dialog message custom hook
  const { alertDialogCall, DialogAlertComponent } = DialogAlertMessage(alertDialogMessageState);
  const { alertDialogCall: membershipAlertDialogCall, DialogAlertComponent: MembershipDialogAlertComponent } = DialogAlertMessage(alertDialogMessageState, linkToMembership);
  // 다음 단계 버튼 클릭 시 실행 function
  // 프로젝트 제목, 채널, 유형 입력 확인
  // 프로젝트 일정 입력 확인
  // 프로젝트 일정 각 단계가 이전 단계 이후 날짜인지 확인
  const nextStepButtonFunc = () => {
    // 230130 멤버십 결제 안 한 경우 프로젝트 등록 불가
    if (!queryClient.getQueryData("userInfo").membershipState) {
      alertDialogMessageSetState("멤버십 구독 후 등록 가능합니다.");
      membershipAlertDialogCall();
      return false;
    } else {
      if (projectValueState.title === "") {
        alertDialogMessageSetState("프로젝트 제목을 입력해주세요");
        alertDialogCall();
        titleRef.current.focus();
        return false;
      } else if (!projectValueState.channel) {
        alertDialogMessageSetState("채널을 선택해주세요");
        alertDialogCall();
        channelRef.current.focus();
        return false;
      } else if (!projectValueState.type) {
        alertDialogMessageSetState("유형을 선택해주세요");
        alertDialogCall();
        typeRef.current.focus();
        return false;
      } else if (!projectValueState.recruitmentStartDate) {
        alertDialogMessageSetState("모집 시작일을 입력해주세요");
        alertDialogCall();
        recruitmentStartDateRef.current.focus();
        return false;
      } else if (!projectValueState.recruitmentEndDate) {
        alertDialogMessageSetState("모집 마감일을 입력해주세요");
        alertDialogCall();
        recruitmentEndDateRef.current.focus();
        return false;
      } else if (moment(projectValueState.recruitmentEndDate).isBefore(moment(projectValueState.recruitmentStartDate))) {
        alertDialogMessageSetState("모집 마감일을 다시 확인해주세요");
        alertDialogCall();
        recruitmentEndDateRef.current.focus();
        return false;
      } else if (!projectValueState.appointmentDate) {
        alertDialogMessageSetState("선정일을 입력해주세요");
        alertDialogCall();
        appointmentDateRef.current.focus();
        return false;
      } else if (moment(projectValueState.appointmentDate).isBefore(moment(projectValueState.recruitmentEndDate))) {
        alertDialogMessageSetState("선정일을 다시 확인해주세요");
        alertDialogCall();
        recruitmentEndDateRef.current.focus();
        return false;
      } else if (!projectValueState.submissionStartDate) {
        alertDialogMessageSetState("제출 시작일을 입력해주세요");
        alertDialogCall();
        submissionStartDateRef.current.focus();
        return false;
      } else if (moment(projectValueState.submissionStartDate).isBefore(moment(projectValueState.appointmentDate))) {
        alertDialogMessageSetState("제출 시작일을 다시 확인해주세요");
        alertDialogCall();
        submissionStartDateRef.current.focus();
        return false;
      } else if (!projectValueState.submissionEndDate) {
        alertDialogMessageSetState("제출 마감일을 입력해주세요");
        alertDialogCall();
        submissionEndDateRef.current.focus();
        return false;
      } else if (moment(projectValueState.submissionEndDate).isBefore(moment(projectValueState.submissionStartDate))) {
        alertDialogMessageSetState("제출 마감일을 다시 확인해주세요");
        alertDialogCall();
        submissionEndDateRef.current.focus();
        return false;
      } else if (!projectValueState.endDate) {
        alertDialogMessageSetState("종료일을 입력해주세요");
        alertDialogCall();
        endDateRef.current.focus();
        return false;
      } else if (moment(projectValueState.endDate).isBefore(moment(projectValueState.submissionEndDate))) {
        alertDialogMessageSetState("종료일을 다시 확인해주세요");
        alertDialogCall();
        endDateRef.current.focus();
        return false;
      } else if (!projectValueState.recruitmentLimitNumber) {
        alertDialogMessageSetState("모집 인원을 입력해주세요");
        alertDialogCall();
        recruitmentNumberRef.current.focus();
        return false;
      } else if (!projectValueState.contact) {
        alertDialogMessageSetState("관리자 연락처를 입력해주세요");
        alertDialogCall();
        contactRef.current.focus();
        return false;
      } else if (projectValueState.contact && !phoneNumRegEx.test(projectValueState.contact)) {
        alertDialogMessageSetState("연락처가 형식에 맞지 않습니다.");
        alertDialogCall();
        contactRef.current.focus();
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        projectStepSetState(1);
      }
    }
  };

  // enter key press event
  const onNextKeyPress = (event) => {
    if (event.key === "Enter") {
      nextStepButtonFunc();
    }
  };

  // value / label 매칭
  const channelMenu = { instagram: "인스타그램", naverBlog: "네이버 블로그", naverPost: "네이버 포스트", youtube: "유튜브" };
  const typeMenu = { delivery: "배송형", visit: "방문형" };
  // 구매형 보류
  // const typeMenu = { delivery: "배송형", visit: "방문형", purchase: "구매형" };

  // blob 데이터 state
  const [blobDictState, blobDictSetState] = useState(new Object());
  // blob 이미지 src 변환 state
  const [blobImgSrcState, blobImgSrcSetState] = useState("");

  // 일정 안내 dialog open state
  const [periodInfoGuideDialogOpenState, periodInfoGuideDialogOpenSetState] = useState(false);

  // 일정 안내 dialog open function
  const periodInfoGuideDialogOpenFunc = () => {
    periodInfoGuideDialogOpenSetState(true);
  };

  // 일정 안내 dialog close function
  const periodInfoGuideDialogCloseFunc = () => {
    periodInfoGuideDialogOpenSetState(false);
  };

  // 등록 완료 dialog open state
  const [completeDialogOpenState, completeDialogOpenSetState] = useState(false);

  // 등록 완료 dialog open function
  const completeDialogOpenFunc = () => {
    completeDialogOpenSetState(true);
  };

  // 등록 완료 dialog close function
  // const completeDialogCloseFunc = () => {
  //   completeDialogOpenSetState(false);
  // };

  // 등록 완료 후 모집 중인 프로젝트 리스트로 이동
  const linkToRecruitingProject = () => {
    setTimeout(() => {
      navigate("/recruiting-project");
    }, 1000);
  };

  // 이전 단계(basic info)로 이동
  const previousEvent = () => {
    projectStepSetState(0);
  };

  // 미리보기 페이지로 이동하기 전 데이터를 local storage에 저장
  const setItemInLocalStorage = async () => {
    localStorage.setItem("previewData", JSON.stringify({ ...projectValueState, detailedImage: detailedImageRef.current.getInstance().getMarkdown(), guide: guideRef.current.getInstance().getMarkdown(), visitingInfo: visitingInfoRef.current ? visitingInfoRef.current.getInstance().getMarkdown() : "" }));
  };

  // 미리보기 페이지로 이동
  const previewEvent = () => {
    // local storage 저장
    setItemInLocalStorage().then(() => {
      window.open("/project-preview");
    });
  };

  // 프로젝트 등록 set function
  const newProjectDataSubmit = useMutation(
    () => {
      SetNewProjectInfo(projectDocId.data, {
        type: projectValueState.type,
        title: projectValueState.title,
        recruitmentStartDate: projectValueState.recruitmentStartDate,
        recruitmentEndDate: projectValueState.recruitmentEndDate,
        appointmentDate: projectValueState.appointmentDate,
        submissionStartDate: projectValueState.submissionStartDate,
        submissionEndDate: projectValueState.submissionEndDate,
        endDate: projectValueState.endDate,
        thumbnail: projectValueState.thumbnail,
        detailedImage: detailedImageRef.current.getInstance().getMarkdown(),
        offering: projectValueState.offering,
        hashTags: projectValueState.hashTags,
        guide: guideRef.current.getInstance().getMarkdown(),
        contactManager: projectValueState.contact,
        visitingInfo: visitingInfoRef.current ? visitingInfoRef.current.getInstance().getMarkdown() : "",
        recruitmentLimitNumber: projectValueState.recruitmentLimitNumber,
        channel: projectValueState.channel,
      });
    },
    {
      onSuccess: (result) => {
        if (result === "period error") {
          alertDialogMessageSetState("일정을 다시 확인해주세요.");
          alertDialogCall();
          return;
        } else if (result === "membership error") {
          alertDialogMessageSetState("멤버십 구독 후 등록 가능합니다.");
          membershipAlertDialogCall();
          return;
        } else {
          completeDialogOpenFunc();
        }
      },
      onError: () => {
        alertDialogMessageSetState("프로필 저장 실패");
        alertDialogCall();
      },
    }
  );

  // 썸네일 이미지 파일 업로드 function
  const fileUploadFunc = async () => {
    RequestUpload({
      formData: blobDictState.blob,
      signedUrl: blobDictState.url,
    });
  };

  // 프로젝트 등록 function
  const submitEvent = () => {
    if (Object.keys(blobDictState).length > 0) {
      fileUploadFunc().then(() => {
        newProjectDataSubmit.mutate();
      });
    } else {
      alertDialogMessageSetState("썸네일을 추가해주세요.");
      alertDialogCall();
    }
  };

  // 썸네일 이미지 변경 버튼 클릭 function
  const changeThumbnailClickFunc = () => {
    thumbnailRef.current.click();
  };

  // 썸네일 이미지 get upload url
  const projectThumbnailUrl = useQuery("projectThumbnailUrl", () => RequestProjectThumbnailUrl(projectDocId.data, thumbnailRef.current.files[0].name), {
    enabled: false,
    onSuccess: (data) => {
      projectValueSetState((prevState) => ({ ...prevState, thumbnail: "https://storage.googleapis.com/project-reble-dev/project/" + queryClient.getQueryData("userInfo").uid + "/" + projectDocId.data + "/projectThumbnail/" + thumbnailRef.current.files[0].name }));
      blobDictSetState({
        url: data,
        blob: thumbnailRef.current.files[0],
      });
      blobImgSrcSetState(window.URL.createObjectURL(thumbnailRef.current.files[0]));
    },
  });

  // 화면 로드 시 맨 위로 이동
  // 컴포넌트 unmount 시 blob url revoke
  useEffect(() => {
    window.scrollTo(0, 0);
    // 230130 멤버십 결제 안 한 경우 화면 진입 불가
    if (!queryClient.getQueryData("userInfo").membershipState) {
      alertDialogMessageSetState("멤버십 구독 후 등록 가능합니다.");
      membershipAlertDialogCall();
    }
    return () => {
      window.URL.revokeObjectURL(blobImgSrcState);
    };
  }, []);

  // 썸네일 이미지 변경 function
  const changeThumbnailFunc = () => {
    if (thumbnailRef.current.files[0]) {
      if (!thumbnailRef.current.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
        alertDialogMessageSetState("파일 형식을 확인해주세요(jpg, jpeg, png, gif)");
        alertDialogCall();
        return false;
      } else {
        projectThumbnailUrl.refetch();
      }
    } else {
      alertDialogMessageSetState("파일 선택을 취소하셨습니다.");
      alertDialogCall();
    }
  };

  // 썸네일 이미지 삭제 function
  const deleteThumbnailFunc = () => {
    projectValueSetState((prevState) => ({ ...prevState, thumbnail: "" }));
    window.URL.revokeObjectURL(blobImgSrcState);
    blobImgSrcSetState("");
    blobDictSetState(new Object());
  };

  // 프로젝트 등록 화면
  if (brandInfo.isLoading) {
    return <LoadingComponent />;
  } else {
    return (
      <Fragment>
        <NewProjectBodyFrame title="프로젝트 등록" text="새 프로젝트를 등록합니다." newProjectContents={<NewProjectContents ref={refSet} uid={queryClient.getQueryData("userInfo").uid} projectDocId={projectDocId.data} projectStepState={projectStepState} projectValueState={projectValueState} projectSelectValueChange={projectSelectValueChange} projectValueChange={projectValueChange} projectDateValueChange={projectDateValueChange} nextStepButtonFunc={nextStepButtonFunc} onNextKeyPress={onNextKeyPress} brandInfo={brandInfo.data} channelMenu={channelMenu} typeMenu={typeMenu} dialogOpenEvent={periodInfoGuideDialogOpenFunc} changeThumbnailClickFunc={changeThumbnailClickFunc} blobImgSrcState={blobImgSrcState} changeThumbnailFunc={changeThumbnailFunc} deleteThumbnailFunc={deleteThumbnailFunc} />} />
        {projectStepState === 0 ? <NewProjectNextFixedBox onClickEvent={nextStepButtonFunc} /> : <NewProjectSubmitFixedBox previousEvent={previousEvent} previewEvent={previewEvent} submitEvent={submitEvent} />}
        <ComplexDialogWithOneButton title="일정안내" context={<PeriodInfoGuideContext />} dialogOpenState={periodInfoGuideDialogOpenState} dialogClose={periodInfoGuideDialogCloseFunc} buttonText="확인" buttonFunc={periodInfoGuideDialogCloseFunc} maxWidth={800} />
        <DialogWithOneButton message="프로젝트 등록을 완료했습니다." dialogOpenState={completeDialogOpenState} buttonText="확인" buttonFunc={linkToRecruitingProject} />
        <DialogAlertComponent />
        <MembershipDialogAlertComponent />
      </Fragment>
    );
  }
}
