import React from "react";
import DaumPostcode from "react-daum-postcode";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import { modalCloseUrl } from "../configs/urls";

const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    maxHeight: "70%",
    overflowY: "hidden",
    borderRadius: "10px",
    border: "1px solid #D9D9D9",
    padding: "25px",
    minWidth: 400,
  },
});

// 다음 주소 찾기 modal
export default function Postcode(props) {
  const { dialogOpenState, dialogClose, getAddressFunc } = props;

  return (
    <CustomDialog open={dialogOpenState} onClose={dialogClose} PaperProps={{ sx: { maxWidth: 400 } }}>
      <Grid container rowGap={2}>
        <Grid container item justifyContent="flex-end">
          <Grid item>
            <IconButton onClick={() => dialogClose()}>
              <img src={modalCloseUrl} alt="창 닫기" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DaumPostcode onComplete={getAddressFunc} onClose={dialogClose} {...props} />
        </Grid>
      </Grid>
    </CustomDialog>
  );
}
