import React, { useState, useRef, Fragment } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { useQueryClient } from "react-query";

import { useRecoilState } from "recoil";
import { userTypeTab } from "../../recoil/AuthState";
import { emailVarified } from "../../recoil/UserState";

import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";
import IconButton from "@mui/material/IconButton";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { logoUrl, infoIconUrl } from "../../configs/urls";

import { MyMenu, IncUserNoEmailMenu, IncUserNoAuthMenu, IncUserNoMemberMenu, IncUserMemberMenu } from "../../components/AppbarComponent";

import { doSignOut } from "../../utils/firebaseUtils";
// import { doSignOutKakao } from "../../utils/thirdPartyUtils";

import { DialogWithOneButton } from "../../components/DialogWithButton";

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.background.paper,
  height: "84px",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
  justifyContent: "center"
}));

const LogoButtonbase = styled(ButtonBase)({
  width: "100%",
});

const LogoImg = styled("img")({
  width: "100%",
});

const UserTypeButton = styled(ButtonBase)({
  borderRadius: "16px",
});

const UserTypeTypo = styled(Typography)({
  padding: "7px 12px",
  borderRadius: "16px",
  border: "1px solid #E6E6E6",
});

const MemberTag = styled(Typography)({
  padding: "3px 8px",
  borderRadius: "4px",
  background: "#D2EA32",
  fontSize: "11px",
});

const CustomButtonBase = styled(ButtonBase)({
  borderRadius: "4px",
});

// App bar 구성
export default function UniversalAppBar() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const location = useLocation();
  const currentPage = location.pathname;

  const scrollTrigger = useScrollTrigger({
    target: window ? window : undefined,
    disableHysteresis: true,
  });

  const myMenuRef = useRef(null);
  const [myMenuOpenState, myMenuOpenSetState] = useState(false);

  const myMenuOpenEvent = () => {
    myMenuOpenSetState(true);
  };

  const myMenuCloseEvent = () => {
    myMenuOpenSetState(false);
  };

  const [dialogOpenState, dialogOpenSetState] = useState(false);

  const dialogOpenEvent = () => {
    dialogOpenSetState(true);
  };

  const dialogCloseEvent = () => {
    dialogOpenSetState(false);
    navigate("/");
  };

  // sign out 시 react-query 캐시 초기화
  const querySignOut = () => {
    queryClient.clear();
    dialogOpenEvent();
  };

  const signOutFunction = () => {
    doSignOut(querySignOut);
  };

  const [emailVarifiedState, emailVarifiedSetState] = useRecoilState(emailVarified);
  const [userTypeTabState, userTypeTabSetState] = useRecoilState(userTypeTab);

  // 기업 페이지 버튼 클릭 sign out call back function
  const switchSignOut = () => {
    queryClient.clear();
    userTypeTabSetState(1);
    navigate("/signin");
  };

  // 기업 페이지 버튼 클릭 시 firebase sign out function
  const switchUserType = () => {
    doSignOut(switchSignOut);
  };

  // 기업 페이지 버튼 클릭 시 기업 회원 sign in 페이지로 이동
  const linkToIncUserService = () => {
    userTypeTabSetState(1);
    navigate("/signin");
  };

  // 개인 회원 app bar
  const SignedInAppbar = () => {
    return (
      <Fragment>
        <CustomAppBar position="fixed" elevation={0} scrollTrigger={scrollTrigger}>
          <Container disableGutters maxWidth="lg">
            <Grid container direction="row" justifyContent="space-between" alignContent="center">
              <Grid container item xs="auto" alignItems="center" columnSpacing={9}>
                <Grid item>
                  <LogoButtonbase component={Link} to="/">
                    <LogoImg src={logoUrl.logo} alt="로고" />
                  </LogoButtonbase>
                </Grid>
                <Grid item>
                  <CustomButtonBase component={Link} to="/project-list">
                    <Typography variant="h6" fontWeight={currentPage === "/project-list" ? 700 : 500} padding="0 2px" color={currentPage === "/project-list" ? "secondary" : "#333333"} sx={{ borderBottom: currentPage === "/project-list" ? "4px solid #DBF227" : "" }}>
                      프로젝트
                    </Typography>
                  </CustomButtonBase>
                </Grid>
              </Grid>
              <Grid container item xs="auto" alignItems="center" columnSpacing={3}>
                <Grid container item xs="auto" alignItems="center">
                  <Grid item>
                    <Typography variant="subtitle2" fontWeight={500} color="black.300">
                      {queryClient.getQueryData("userInfo").name} 회원님
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => myMenuOpenEvent()} ref={myMenuRef}>
                      <ExpandMoreIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item>
                  <MyMenu ref={myMenuRef} myMenuOpenState={myMenuOpenState} myMenuCloseEvent={myMenuCloseEvent} signOutFunction={signOutFunction} />
                </Grid>
                <Grid item>
                  <UserTypeButton onClick={() => switchUserType()}>
                    <UserTypeTypo variant="body3" fontWeight={400} color="#999999">
                      기업서비스
                    </UserTypeTypo>
                  </UserTypeButton>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </CustomAppBar>
        <DialogWithOneButton message="로그아웃 되었습니다." dialogOpenState={dialogOpenState} buttonText="확인" buttonFunc={dialogCloseEvent} />
      </Fragment>
    );
  };

  // 기업 회원 App bar
  const IncUserSignedInAppbar = () => {
    return (
      <Fragment>
        <CustomAppBar position="fixed" elevation={0} scrollTrigger={scrollTrigger}>
          <Container disableGutters maxWidth="lg">
            <Grid container direction="row" justifyContent="space-between" alignContent="center">
              <Grid container item xs="auto" columnSpacing={3}>
                <Grid container item alignItems="center" xs="auto">
                  <Grid item>
                    <LogoButtonbase component={Link} to="/my-page">
                      <LogoImg src={logoUrl.logoBusiness} alt="로고" />
                    </LogoButtonbase>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs="auto" alignItems="center" columnSpacing={2.5}>
                <Grid container item xs="auto" alignItems="center" columnSpacing={1}>
                  {!queryClient.getQueryData("userInfo").authState || !emailVarifiedState ? <img src={infoIconUrl} alt="미인증 아이콘" /> : queryClient.getQueryData("userInfo").membershipState ? <MemberTag color="#ffffff">멤버</MemberTag> : null}
                  <Grid item>
                    <Typography variant="subtitle2" fontWeight={500} color="#333333">
                      {queryClient.getQueryData("userInfo").name} 담당자님
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => myMenuOpenEvent()} ref={myMenuRef} sx={{ padding: 0 }}>
                      <ExpandMoreIcon />
                    </IconButton>
                    {!queryClient.getQueryData("userInfo").authState ? <IncUserNoAuthMenu ref={myMenuRef} myMenuOpenState={myMenuOpenState} myMenuCloseEvent={myMenuCloseEvent} signOutFunction={signOutFunction} userEmail={queryClient.getQueryData("userInfo").email} /> : !emailVarifiedState ? <IncUserNoEmailMenu ref={myMenuRef} myMenuOpenState={myMenuOpenState} myMenuCloseEvent={myMenuCloseEvent} signOutFunction={signOutFunction} userEmail={queryClient.getQueryData("userInfo").email} /> : queryClient.getQueryData("userInfo").subscribeState ? <IncUserMemberMenu ref={myMenuRef} myMenuOpenState={myMenuOpenState} myMenuCloseEvent={myMenuCloseEvent} signOutFunction={signOutFunction} userEmail={queryClient.getQueryData("userInfo").email} /> : <IncUserNoMemberMenu ref={myMenuRef} myMenuOpenState={myMenuOpenState} myMenuCloseEvent={myMenuCloseEvent} signOutFunction={signOutFunction} userEmail={queryClient.getQueryData("userInfo").email} />}
                    {/* {!queryClient.getQueryData("userInfo").authState ? <IncUserNoAuthMenu ref={myMenuRef} myMenuOpenState={myMenuOpenState} myMenuCloseEvent={myMenuCloseEvent} signOutFunction={signOutFunction} userEmail={queryClient.getQueryData("userInfo").email} emailVarifiedState={emailVarifiedState} /> : !emailVarifiedState ? <IncUserNoEmailMenu ref={myMenuRef} myMenuOpenState={myMenuOpenState} myMenuCloseEvent={myMenuCloseEvent} signOutFunction={signOutFunction} userEmail={queryClient.getQueryData("userInfo").email} emailVarifiedState={emailVarifiedState} /> : null} */}
                  </Grid>
                </Grid>
                <Grid item>
                  <UserTypeButton component={Link} to="/">
                    <UserTypeTypo variant="body2" fontWeight={400} color="#999999">
                      on1016 바로가기
                    </UserTypeTypo>
                  </UserTypeButton>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </CustomAppBar>
        <DialogWithOneButton message="로그아웃 되었습니다." dialogOpenState={dialogOpenState} buttonText="확인" buttonFunc={dialogCloseEvent} />
      </Fragment>
    );
  };

  // sign in 전 App bar
  const BeforeSignedInAppbar = () => {
    return (
      <Fragment>
        <CustomAppBar position="fixed" elevation={0} scrollTrigger={scrollTrigger}>
          <Container disableGutters maxWidth="lg">
            <Grid container direction="row" justifyContent="space-between" alignContent="center">
              <Grid container item xs="auto" alignItems="center" columnSpacing={9}>
                <Grid item>
                  <LogoButtonbase component={Link} to="/">
                    <LogoImg src={logoUrl.logo} alt="로고" />
                  </LogoButtonbase>
                </Grid>
                <Grid item>
                  <CustomButtonBase component={Link} to="/project-list">
                    <Typography variant="h6" fontWeight={currentPage === "/project-list" ? 700 : 500} color={currentPage === "/project-list" ? "secondary" : "#333333"} sx={{ borderBottom: currentPage === "/project-list" ? "4px solid #DBF227" : "" }}>
                      프로젝트
                    </Typography>
                  </CustomButtonBase>
                </Grid>
              </Grid>
              <Grid container item xs="auto" alignItems="center" columnSpacing={3}>
                <Grid item>
                  <ButtonBase component={Link} to="/signin">
                    <Typography variant="subtitle2" fontWeight={500} color="black.300">
                      로그인
                    </Typography>
                  </ButtonBase>
                </Grid>
                <Grid item>
                  <UserTypeButton onClick={() => linkToIncUserService()}>
                    <UserTypeTypo variant="body3" color="#999999">
                      기업서비스
                    </UserTypeTypo>
                  </UserTypeButton>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </CustomAppBar>
        <DialogWithOneButton message="로그아웃 되었습니다." dialogOpenState={dialogOpenState} buttonText="확인" buttonFunc={dialogCloseEvent} />
      </Fragment>
    );
  };

  if (queryClient.getQueryData("userInfo")) {
    if (queryClient.getQueryData("userInfo").userType === "incUser") {
      return IncUserSignedInAppbar();
    } else {
      return SignedInAppbar();
    }
  } else {
    return BeforeSignedInAppbar();
  }
}
