import React, { forwardRef } from "react";
import { Link } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ButtonBase from "@mui/material/ButtonBase";
import Divider from "@mui/material/Divider";

import { SelectTextField } from "../../components/CustomTextField";
import { DateTextFieldSet, TextFieldWithoutLabelSet } from "../Common/TextFieldSet";

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const InfoInnerGrid = styled(Grid)({
  padding: "16px 16px",
});

const LinkToBrandTypo = styled(Typography)({
  textDecoration: "underline",
});

const TildeTypo = styled(Typography)({
  paddingTop: "32px",
});

const SubCustomDivider = styled(Divider)({
  borderColor: "#F2F2F2",
});

// 프로젝트 등록 기본 정보
export const NewProjectBasicInfo = forwardRef((props, forwardRef) => {
  const { projectValueState, projectValueChange, projectSelectValueChange, projectDateValueChange, onNextKeyPress, brandInfo, channelMenu, typeMenu, dialogOpenEvent } = props;
  const { titleRef, brandRef, channelRef, typeRef, recruitmentStartDateRef, recruitmentEndDateRef, appointmentDateRef, submissionStartDateRef, submissionEndDateRef, endDateRef, recruitmentNumberRef, contactRef } = forwardRef;

  return (
    <Grid container item>
      <InfoInnerGrid container item alignItems="center">
        <Grid item xs={2.1}>
          <Typography variant="body1" color="black.400">
            프로젝트 제목
          </Typography>
        </Grid>
        <Grid item xs={9.9}>
          <TextFieldWithoutLabelSet type="text" placeholder="프로젝트 제목을 입력해주세요." ref={titleRef} valueState={projectValueState.title} setValueEvent={() => projectValueChange("title", titleRef.current.value)} onKeyPress={onNextKeyPress} autoFocus={false} inputProps={{ maxLength: 80 }} />
        </Grid>
      </InfoInnerGrid>
      <Grid item xs={12}>
        <SubCustomDivider />
      </Grid>
      <InfoInnerGrid container item alignItems="center">
        <Grid item xs={2.1}>
          <Typography variant="body1" color="black.400">
            브랜드
          </Typography>
        </Grid>
        <Grid container item xs={9.9}>
          {/* brandInfo.name === "" ? */}
          {!brandInfo ? (
            <Grid container item rowGap={1}>
              <Grid item xs={12}>
                <TextFieldWithoutLabelSet ref={brandRef} type="text" placeholder="브랜드를 등록해주세요" valueState={""} setValueEvent={() => {}} onKeyPress={() => {}} readOnly={true} />
              </Grid>
              <Grid item xs={12}>
                <ButtonBase component={Link} to="/brand">
                  <LinkToBrandTypo variant="body3" component="p" color="#5E5E5E">
                    브랜드 등록하러 가기
                  </LinkToBrandTypo>
                </ButtonBase>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <TextFieldWithoutLabelSet ref={brandRef} type="text" placeholder="브랜드를 등록해주세요" valueState={brandInfo.name} setValueEvent={() => {}} onKeyPress={() => {}} readOnly={true} />
              {/* <SelectTextField ref={brandRef} menu={brandInfo} placeholder="브랜드를 선택해주세요" selectedValue={projectValueState.brand} selectedLabel={brandInfo[projectValueState.brand]} valueKey="brand" selectChange={projectSelectValueChange} /> */}
            </Grid>
          )}
        </Grid>
      </InfoInnerGrid>
      <Grid item xs={12}>
        <SubCustomDivider />
      </Grid>
      <InfoInnerGrid container item alignItems="center">
        <Grid item xs={2.1}>
          <Typography variant="body1" color="black.400">
            채널/유형
          </Typography>
        </Grid>
        <Grid container item xs={9.9} columnSpacing={3}>
          <Grid item xs={6}>
            <SelectTextField ref={channelRef} menu={channelMenu} placeholder="채널을 선택해주세요" selectedValue={projectValueState.channel} selectedLabel={channelMenu[projectValueState.channel]} valueKey="channel" selectChange={projectSelectValueChange} />
          </Grid>
          <Grid item xs={6}>
            <SelectTextField ref={typeRef} menu={typeMenu} placeholder="유형을 선택해주세요" selectedValue={projectValueState.type} selectedLabel={typeMenu[projectValueState.type]} valueKey="type" selectChange={projectSelectValueChange} />
          </Grid>
        </Grid>
      </InfoInnerGrid>
      <Grid item xs={12}>
        <SubCustomDivider />
      </Grid>
      <InfoInnerGrid container item alignItems="center">
        <Grid container item alignItems="center" xs={2.1}>
          <Grid item xs="auto">
            <Typography variant="body1" color="black.400">
              일정
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <IconButton onClick={dialogOpenEvent}>
              <HelpOutlineIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container xs={9.9} rowSpacing={3}>
          <Grid container item alignItems="center" justifyContent="space-between">
            <Grid item xs={3.7}>
              <DateTextFieldSet ref={recruitmentStartDateRef} label="모집시작일" inputFormat="yyyy.MM.DD" placeholder="YYYY.MM.DD" value={projectValueState.recruitmentStartDate} onChange={(newValue) => projectDateValueChange("recruitmentStartDate", newValue)} onKeyPress={onNextKeyPress} disablePast={true} disableFuture={false} />
            </Grid>
            <Grid item xs={0.4}>
              <TildeTypo variant="body1" color="#333333" align="center">
                ~
              </TildeTypo>
            </Grid>
            <Grid item xs={3.7}>
              <DateTextFieldSet ref={recruitmentEndDateRef} label="모집마감일" inputFormat="yyyy.MM.DD" placeholder="YYYY.MM.DD" value={projectValueState.recruitmentEndDate} onChange={(newValue) => projectDateValueChange("recruitmentEndDate", newValue)} onKeyPress={onNextKeyPress} disablePast={true} disableFuture={false} />
            </Grid>
            <Grid item xs={0.4} />
            <Grid item xs={3.7}>
              <DateTextFieldSet ref={appointmentDateRef} label="선정일" inputFormat="yyyy.MM.DD" placeholder="YYYY.MM.DD" value={projectValueState.appointmentDate} onChange={(newValue) => projectDateValueChange("appointmentDate", newValue)} onKeyPress={onNextKeyPress} disablePast={true} disableFuture={false} />
            </Grid>
          </Grid>
          <Grid container item alignItems="center" justifyContent="space-between">
            <Grid item xs={3.7}>
              <DateTextFieldSet ref={submissionStartDateRef} label="제출시작일" inputFormat="yyyy.MM.DD" placeholder="YYYY.MM.DD" value={projectValueState.submissionStartDate} onChange={(newValue) => projectDateValueChange("submissionStartDate", newValue)} onKeyPress={onNextKeyPress} disablePast={true} disableFuture={false} />
            </Grid>
            <Grid item xs={0.4}>
              <TildeTypo variant="body1" color="#333333" align="center">
                ~
              </TildeTypo>
            </Grid>
            <Grid item xs={3.7}>
              <DateTextFieldSet ref={submissionEndDateRef} label="제출마감일" inputFormat="yyyy.MM.DD" placeholder="YYYY.MM.DD" value={projectValueState.submissionEndDate} onChange={(newValue) => projectDateValueChange("submissionEndDate", newValue)} onKeyPress={onNextKeyPress} disablePast={true} disableFuture={false} />
            </Grid>
            <Grid item xs={0.4} />
            <Grid item xs={3.7}>
              <DateTextFieldSet ref={endDateRef} label="종료일" inputFormat="yyyy.MM.DD" placeholder="YYYY.MM.DD" value={projectValueState.endDate} onChange={(newValue) => projectDateValueChange("endDate", newValue)} onKeyPress={onNextKeyPress} disablePast={true} disableFuture={false} />
            </Grid>
          </Grid>
        </Grid>
      </InfoInnerGrid>
      <Grid item xs={12}>
        <SubCustomDivider />
      </Grid>
      <InfoInnerGrid container item alignItems="center">
        <Grid item xs={2.1}>
          <Typography variant="body1" color="black.400">
            모집인원
          </Typography>
        </Grid>
        <Grid container item xs={9.9} alignItems="center" columnSpacing={1}>
          <Grid item xs={2.6}>
            <TextFieldWithoutLabelSet type="text" placeholder="0" ref={recruitmentNumberRef} valueState={projectValueState.recruitmentLimitNumber} setValueEvent={() => projectValueChange("recruitmentLimitNumber", recruitmentNumberRef.current.value)} onKeyPress={onNextKeyPress} autoFocus={false} />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" color="black.300">
              명
            </Typography>
          </Grid>
        </Grid>
      </InfoInnerGrid>
      <Grid item xs={12}>
        <SubCustomDivider />
      </Grid>
      <InfoInnerGrid container item alignItems="center">
        <Grid item xs={2.1}>
          <Typography variant="body1" color="black.400">
            담당자 연락처
          </Typography>
        </Grid>
        <Grid container item xs={9.9} alignItems="center" columnSpacing={1}>
          <Grid item xs={6}>
            <TextFieldWithoutLabelSet type="text" placeholder="010-0000-0000" ref={contactRef} valueState={projectValueState.contact} setValueEvent={() => projectValueChange("contact", contactRef.current.value)} onKeyPress={onNextKeyPress} inputProps={{ maxLength: 13 }} autoFocus={false} />
          </Grid>
        </Grid>
      </InfoInnerGrid>
      <Grid item xs={12}>
        <SubCustomDivider />
      </Grid>
    </Grid>
  );
});
