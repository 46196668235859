import React from "react";

import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const BackgroundBox = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  height: "100%",
  background: theme.palette.primary,
}));

const CustomContainer = styled(Container)({
  minHeight: "100vh",
  padding: "173px 0 90px",
  paddingLeft: "0px !important",
  paddingRight: "0px !important",
});

// 기업 회원 프로젝트 등록 Frame
export default function NewProjectBodyFrame(props) {
  const { newProjectContents } = props;
  return (
    <BackgroundBox>
      <CustomContainer maxWidth="lg">
        <Grid container rowSpacing={5.6}>
          <Grid item xs={12}>
            <Typography variant="h3" color="black.200">
              프로젝트 등록
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container item>
              {newProjectContents}
            </Grid>
          </Grid>
        </Grid>
      </CustomContainer>
    </BackgroundBox>
  );
}
