import React, { useRef, useState } from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Divider from "@mui/material/Divider";

import ToastUIViewer from "../../components/ToastUIViewer";

import { UnderlinedTabs } from "../../components/TapComponents";

import CheckIcon from '@mui/icons-material/Check';

const CustomDivider = styled(Divider)({
  borderColor: "#D9D9D9",
});

const NoticeCustomDivider = styled(Divider)({
  borderColor: "#F1DEDE",
});

const DotBox = styled(Box)({
  width: 2,
  height: 2,
  borderRadius: "50%",
  background: "#666666",
  marginTop: "14px",
});

const HideDetailImageGrid = styled(Grid)(({ detailImageOpenState }) => ({
  display: detailImageOpenState ? "none" : "block",
}));

const FullDetailImageGrid = styled(Grid)(({ detailImageOpenState }) => ({
  display: detailImageOpenState ? "block" : "none",
}));

const ThumbnailImageGrid = styled(Grid)({
  width: "100%",
  borderRadius: "20px",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid #F2F2F2",
  background: "#F2F2F2",
})

const ThumbnailImage = styled("img")({
  width: "100%",
  aspectRatio: "1.06",
  objectFit: "contain",
})

const NoticeCustomGrid = styled(Grid)(({ theme }) => ({
   background: "#FCF7F7",
   padding: "20px 28px",
   borderRadius: "10px",
}));

const ScrollCustomGrid = styled(Grid)({
  scrollMargin: "110px",
});

// 프로젝트 상세 페이지 상세 정보
export default function ProjectDetailInfo(props) {
  const { projectDetailValue, detailImageOpenState } = props;

  const [tabValueState, tabValueSetState] = useState(0);
  const projectInfoRef = useRef(null);
  const detailImageRef = useRef(null);

  const projectInfoTabClickEvent = () => {
    tabValueSetState(0);
    projectInfoRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  const detailImageTabClickEvent = () => {
    tabValueSetState(1);
    detailImageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  // 프로젝트 상세페이지 상세 이미지 더보기 / 접기 버튼
  const ProjectDetailContextImageButton = () => {
    return (
      <ScrollCustomGrid container item ref={detailImageRef}>
        <FullDetailImageGrid item xs={12} detailImageOpenState={detailImageOpenState}>
          <ToastUIViewer markdownText={projectDetailValue.detailedImage} />
        </FullDetailImageGrid>
        <HideDetailImageGrid item xs={12} detailImageOpenState={detailImageOpenState}>
          {projectDetailValue.detailedImage ? <ToastUIViewer markdownText={"![image]" + projectDetailValue.detailedImage.split("![image]")[1]} /> : null}
        </HideDetailImageGrid>
        {/* 상세이미지 더보기/접기 버튼 주석처리
        {!detailImageOpenState ? (
          <DetailImageButtonBase onClick={() => detailImageOpenEvent()}>
            <DetailImageButtonBox>
              <Grid container justifyContent="center" alignItems="center" columnSpacing={1}>
                <Grid item>
                  <Typography variant="body2" fontWeight={500} lineHeight="16px" color="#333333">
                    상세 이미지 더보기
                  </Typography>
                </Grid>
                <Grid item>
                  <img src={arrowIconUrl.arrowDown} alt="아래 화살표" />
                </Grid>
              </Grid>
            </DetailImageButtonBox>
          </DetailImageButtonBase>
        ) : (
          <DetailImageButtonBase onClick={() => detailImageCloseEvent()}>
            <DetailImageButtonBox>
              <Grid container justifyContent="center" alignItems="center" columnSpacing={1}>
                <Grid item>
                  <Typography variant="body2" fontWeight={500} lineHeight="16px" color="#333333">
                    상세 이미지 접기
                  </Typography>
                </Grid>
                <Grid item>
                  <img src={arrowIconUrl.arrowUp} alt="위 화살표" />
                </Grid>
              </Grid>
            </DetailImageButtonBox>
          </DetailImageButtonBase>
        )} */}
      </ScrollCustomGrid>
    );
  };
  
  // 프로젝트 상세페이지 하단 정보
  const ProjectDetailContextText = () => {
    return (
      <ScrollCustomGrid container item rowGap={5} ref={projectInfoRef}>
        <Grid container item>
          <Grid item xs={12}>
            <Typography variant="subtitle1" fontWeight={700} color="secondary">
              제공항목
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="secondary">
              {projectDetailValue.offering}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item rowGap={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" fontWeight={700} color="secondary">
              해시태그
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography variant="body1" color="secondary">
                {projectDetailValue.hashTags}
              </Typography>
            </Grid>
            <Grid container item columnGap={1}>
              <Grid item xs="auto">
                <Typography variant="caption1" color="black.900">
                  -
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption1" fontWeight={400} color="black.900">
                  #유료광고, #협찬 등 표시광고법과 관련된 해시태그는 맨 앞에 필수로 넣어주세요.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item columnGap={1}>
              <Grid item xs="auto">
                <Typography variant="caption1" color="black.900">
                  -
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption1" fontWeight={400} color="black.900">
                  안내된 키워드는 모두 내용에 기재해주세요.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {projectDetailValue.visitingInfo ? (
          <Grid container item rowSpacing={3}>
            <Grid container item>
              <Grid item xs={12}>
                <Typography variant="subtitle1" fontWeight={700} color="secondary">
                  방문정보
                </Typography>
              </Grid>
              <Grid item xs={12} className="projectDetail">
                <ToastUIViewer markdownText={projectDetailValue.visitingInfo} />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        <Grid container item>
          <Grid item xs={12}>
            <Typography variant="subtitle1" fontWeight={700} color="secondary">
              가이드
            </Typography>
          </Grid>
          <Grid item xs={12} className="projectDetail">
            <ToastUIViewer markdownText={projectDetailValue.guide} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CustomDivider />
        </Grid>
        <Grid container item>
          <Grid item xs={2}>
            <Typography variant="subtitle1" fontWeight={700} color="secondary">
              담당자 연락처
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="body1" fontWeight={400} color="secondary">
              {/* {projectDetailValue.ContactOfManager} */}
              선정된 회원만 확인할 수 있습니다.
            </Typography>
          </Grid>
        </Grid>
        <NoticeCustomGrid container item rowGap={2}>
          <Grid item xs={12}>
            <Grid container item columnGap={1}>
              <Grid item xs="auto" display="flex" alignItems="center">
                <CheckIcon fontSize="12px" />
              </Grid>
              <Grid item>
                <Typography variant="caption1" fontWeight={700} color="secondary">
                  프로젝트 참여 전, 주의사항을 꼭 확인해주세요!
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <NoticeCustomDivider />
          </Grid>
          <Grid item xs={12}>
            <Grid container item columnGap={1}>
              <Grid item xs="auto">
                <DotBox />
              </Grid>
              <Grid item xs>
                <Typography variant="body1" lineHeight="30px" color="#4D4D4D">
                  제공받은 제품은 타인에게 양도 및 판매, 교환을 절대 허용하지 않으며, 적발 시 제품 가격 환불 및 캠페인 참여 제한됩니다.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item columnGap={1}>
              <Grid item xs="auto">
                <DotBox />
              </Grid>
              <Grid item xs>
                <Typography variant="body1" lineHeight="30px" color="#4D4D4D">
                  콘텐츠 작성 시 선정 된 본 캠페인의 제품만으로 단독 촬영하여 진행되어야 합니다.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item columnGap={1}>
              <Grid item xs="auto">
                <DotBox />
              </Grid>
              <Grid item xs>
                <Typography variant="body1" lineHeight="30px" color="#4D4D4D">
                  작성하신 콘텐츠는 6개월 유지해야 하며, 유지하지 않을 경우 페널티가 부과됩니다.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </NoticeCustomGrid>
      </ScrollCustomGrid>
    );
  };

  return (
    <Grid container item rowSpacing={5}>
      <Grid container item paddingBottom="28px">
        <ThumbnailImageGrid>
          <ThumbnailImage src={projectDetailValue.thumbnail} alt="프로젝트 썸네일" />
        </ThumbnailImageGrid>
      </Grid>
      <Grid container item position="sticky" top="82px" paddingTop="0px !important">
        <UnderlinedTabs value={tabValueState} tabs={[ {label: "프로젝트 정보", tabClickEvent: () => projectInfoTabClickEvent()}, {label: "상세이미지", tabClickEvent: () => detailImageTabClickEvent()} ]} ></UnderlinedTabs>
      </Grid>
      {ProjectDetailContextText()}
      {ProjectDetailContextImageButton()}
    </Grid>
  );
}
