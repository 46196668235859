import { styled } from "@mui/material/styles";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";

import ToastUIViewer from "../../components/ToastUIViewer";

const ModalPaper = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  maxWidth: 400,
  height: "60%",
  transform: "translate(-50%, -50%)",
  borderRadius: "5px",
  padding: "18px",
}));

const ModalContainer = styled(Container)({
  height: "100%",
});

const ModalGridContainer = styled(Grid)({
  height: "100%",
});

const ModalTermsTextGridContainer = styled(Grid)({
  height: "85%",
});

const TermsTextGrid = styled(Grid)(({ theme }) => ({
  height: "90%",
  padding: 16,
  background: theme.palette.grey[100],
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: 3,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#e6e6e6",
    borderRadius: "2px",
  },
}));

// 회원 가입 시 약관 확인 modal
export function SignUpAgreementModal(props) {
  const { modalOpenState, modalcloseEvent, title, userType, text } = props;

  return (
    <Modal open={modalOpenState} onClose={() => modalcloseEvent()}>
      <ModalPaper>
        <ModalContainer maxWidth={false} disableGutters={true}>
          <ModalGridContainer container justifyContent="center">
            <Grid container item justifyContent="space-between" alignContent="flex-start" alignItems="center">
              <Grid item xs={1} />
              <Grid item>
                <Typography variant="body1" fontWeight={500}>
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => modalcloseEvent()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <ModalTermsTextGridContainer container item xs={11} rowSpacing={2} justifyContent="center">
              <Grid item xs={12}>
                <Typography variant="body1" fontWeight={500}>
                  {userType === "default" ? "개인회원 이용 약관" : "기업회원 이용 약관"}
                </Typography>
              </Grid>
              <ModalGridContainer container item xs={12} justifyContent="center">
                <TermsTextGrid item>
                  <ToastUIViewer markdownText={text} />
                </TermsTextGrid>
              </ModalGridContainer>
            </ModalTermsTextGridContainer>
          </ModalGridContainer>
        </ModalContainer>
      </ModalPaper>
    </Modal>
  );
}
