import { forwardRef } from "react";
import { Link } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import AuthButton from "../../components/AuthComponents";
import { TextFieldSetWithCaption } from "../Common/TextFieldSet";
import { Divider } from "@mui/material";

const TabLabelTypo = styled(Typography)({
  color: "#B3B3B3",
});

const SelectedTabLabelTypo = styled(Typography)({
  color: "#222222",
});

const CustomTabs = styled(Tabs)({
  backgroundColor: "#F2F2F2",
  borderRadius: 10,

  "& .Mui-selected": {
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    border: "2px solid #4C4C4C",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  },
});

const CustomTab = styled(Tab)({
  padding: "16px 16px",
});

// email sign in
export const SignInUpperSection = forwardRef((props, forwardRef) => {
  const { userTypeTabState, signInTabChangeEvent, loadState, signInFunction, valueState, setValueEvent, onEnterKeyPress, emailBlurEvent, passwordBlurEvent, emailErrorCode, passwordErrorCode, emailErrorMsg, passwordErrorMsg } = props;
  const { emailRef, passwordRef } = forwardRef;

  return (
    <Grid container item rowSpacing={4.5}>
      <Grid item xs={12}>
        <CustomTabs value={userTypeTabState} onChange={signInTabChangeEvent} variant="fullWidth" indicatorColor="#4C4C4C" TabIndicatorProps={{ sx: { transition: "none" } }}>
          <CustomTab label={userTypeTabState === 0 ? <SelectedTabLabelTypo variant="h6">개인회원</SelectedTabLabelTypo> : <TabLabelTypo variant="h6">개인회원</TabLabelTypo>} value={0} />
          <CustomTab label={userTypeTabState === 1 ? <SelectedTabLabelTypo variant="h6">기업회원</SelectedTabLabelTypo> : <TabLabelTypo variant="h6">기업회원</TabLabelTypo>} value={1} />
        </CustomTabs>
      </Grid>
      <Grid container item>
        <Grid container item rowSpacing={1}>
          <TextFieldSetWithCaption label="이메일" errorState={emailErrorCode === 1} caption={emailErrorCode === 1 ? emailErrorMsg : ""} type="email" placeholder="이메일을 입력해주세요" ref={emailRef} valueState={valueState.email} setValueEvent={(event) => setValueEvent("email", event)} onBlur={emailBlurEvent} onKeyPress={onEnterKeyPress} autoFocus={true} />
          <TextFieldSetWithCaption label="비밀번호" errorState={passwordErrorCode === 1} caption={passwordErrorCode === 1 ? passwordErrorMsg : ""} type="password" placeholder="비밀번호를 입력해주세요" ref={passwordRef} valueState={valueState.password} setValueEvent={(event) => setValueEvent("password", event)} onBlur={passwordBlurEvent} onKeyPress={(event) => onEnterKeyPress(event)} />
        </Grid>
      </Grid>
      <Grid container item>
        <Grid container item rowSpacing={2}>
          <Grid item xs={12}>
            <AuthButton loadState={loadState} disabled={false} buttonText="로그인" activated={true} onClickEvent={() => signInFunction()} />
          </Grid>
          <Grid container item justifyContent="center" alignItems="center">
            <Grid item>
              <ButtonBase component={Link} to="/find-password">
                <Typography component="p" variant="body1" color="black.300" fontFamily="SpoqaHanSansNeo, Noto Sans KR">
                  비밀번호 찾기
                </Typography>
              </ButtonBase>
            </Grid>
            <Divider orientation="vertical" variant="middle" sx={{ marginLeft: "10px", marginRight: "10px", height: "15px" }} />
            <Grid item>
              <ButtonBase component={Link} to="/signup">
                <Typography component="p" variant="body1" color="black.300" fontFamily="SpoqaHanSansNeo, Noto Sans KR">
                  회원가입
                </Typography>
              </ButtonBase>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
