import React, { Fragment, useState, useRef } from "react";

import { useQuery, useMutation } from "react-query";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { RequestBannerData, RequestBannerImageUrl, UpdateBannerData, RequestUpload } from "../../utils/adminRequestToSrv";

import AdminBodyFrame from "../../Block/Admin/AdminBodyFrame";
import { CurrentBannerImageBox, NewBannerDialogContents, CurrentBannerDialogContents } from "../../Block/Admin/AdminBannerContents";

import { CancelButton } from "../../components/AdminComponents";
import { ComplexDialogWithOneButton, ComplexDialogWithThreeButton } from "../../components/DialogWithButton";
import LoadingComponent from "../../components/LoadingComponent";
import { DialogAlertMessage } from "../../components/DialogMessage";

// 배너 관리
export default function AdminBannerContainer() {
  const [loadState, loadSetState] = useState(false);

  // 배너 데이터 state
  const [bannerValueState, bannerValueSetState] = useState([]);

  // blob 데이터 state
  const [blobDictState, setBlobDictState] = useState(new Object());
  // blob 이미지 src 변환 state
  const [blobImgSrcState, blobImgSrcSetState] = useState("");

  // alert dialog text state
  const [alertDialogMessageState, alertDialogMessageSetState] = useState("");
  // alert dialog custom hook
  const { alertDialogCall, DialogAlertComponent } = DialogAlertMessage(alertDialogMessageState);

  // banner data load function
  const bannerData = useQuery("bannerData", RequestBannerData, {
    onSuccess: (data) => {
      bannerValueSetState(data.banner.list);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 배너 추가 image box ref
  const newBannerImageBoxRef = useRef(null);
  // 배너 추가 url textfield ref
  const newBannerUrlInputRef = useRef(null);

  const newBannerRefSet = {
    newBannerImageBoxRef: newBannerImageBoxRef,
    newBannerUrlInputRef: newBannerUrlInputRef,
  };

  // 기존 배너 image box ref
  const currentBannerImageBoxRef = useRef(null);
  // 기존 배너 url textfield ref
  const currentBannerUrlInputRef = useRef(null);

  const currentBannerRefSet = {
    currentBannerImageBoxRef: currentBannerImageBoxRef,
    currentBannerUrlInputRef: currentBannerUrlInputRef,
  };

  // 배너 추가 function
  const updateBannerData = useMutation(() => UpdateBannerData(bannerValueState), {
    onSuccess: (data) => {
      bannerData.refetch();
      newBannerDialogCloseFunc();
      currentBannerDialogCloseFunc();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 이미지 파일 업로드 function
  const fileUploadFunc = async () => {
    RequestUpload({
      formData: blobDictState.blob,
      signedUrl: blobDictState.url,
    });
  };

  // 배너 추가 value state
  const [newBannerDialogValueState, newBannerDialogValueSetState] = useState({
    image: "",
    linkUrl: "",
    newPage: false,
  });

  // 배너 추가 이미지 get upload url
  const newBannerImageUrl = useMutation((fileName) => RequestBannerImageUrl(fileName), {
    onSuccess: (data) => {
      newBannerDialogValueSetState((prevState) => ({ ...prevState, image: data.split("?X-Goog-Algorithm")[0] }));
      setBlobDictState({
        url: data,
        blob: newBannerImageBoxRef.current.files[0],
      });
      blobImgSrcSetState(window.URL.createObjectURL(newBannerImageBoxRef.current.files[0]));
    },
  });

  // 배너 추가 - 이미지 변경 버튼 클릭 event : button function
  const newBannerChangeImageButtonFunc = () => {
    bannerIndexSetState(bannerValueState.length);
    newBannerImageBoxRef.current.click();
  };

  // 배너 추가 - 이미지 변경 event : input file function
  const newBannerChangeImageFunc = () => {
    if (newBannerImageBoxRef.current.files[0]) {
      if (!newBannerImageBoxRef.current.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
        alertDialogMessageSetState("파일 형식을 확인해주세요(jpg, jpeg, png, gif)");
        alertDialogCall();
        return false;
      } else {
        newBannerImageUrl.mutate(newBannerImageBoxRef.current.files[0].name);
      }
    } else {
      alertDialogMessageSetState("파일 선택을 취소하셨습니다.");
      alertDialogCall();
    }
  };

  // 배너 추가 - url 변경 event
  const newBannerUrlChangeFunc = () => {
    newBannerDialogValueSetState((prevState) => ({
      ...prevState,
      url: newBannerUrlInputRef.current.value,
    }));
  };

  // 배너 추가 - 새창 여부 변경 event
  const newBannerNewPageSetFunc = (event) => {
    newBannerDialogValueSetState((prevState) => ({
      ...prevState,
      newPage: event.target.checked,
    }));
  };

  // 배너 추가 - dialog open state
  const [newBannerDialogOpenState, newBannerDialogOpenSetState] = useState(false);

  // 배너 추가 - dialog open function
  const newBannerDialogOpenFunc = () => {
    newBannerDialogOpenSetState(true);
  };

  // 배너 추가 - dialog close function
  const newBannerDialogCloseFunc = () => {
    newBannerDialogValueSetState({
      image: "",
      url: "",
      newPage: false,
    });
    setBlobDictState(new Object());
    blobImgSrcSetState("");
    window.URL.revokeObjectURL(blobImgSrcState);
    bannerIndexSetState(0);
    newBannerDialogOpenSetState(false);
  };

  // 배너 추가 - dialog 저장 버튼 function
  const newBannerDialogSubmitFunc = () => {
    if (!blobDictState.url) {
      alertDialogMessageSetState("이미지를 등록해주세요.");
      alertDialogCall();
    } else {
      newBannerDialogValueSetState(bannerValueState.push(newBannerDialogValueState));
      fileUploadFunc()
        .then(() => {
          updateBannerData.mutate();
        })
        .catch((error) => {
          console.log(error);
          alertDialogMessageSetState("이미지 파일 업로드 중 에러가 발생했습니다.");
          alertDialogCall();
        });
    }
  };

  // 배너 추가 - dialog enter key press function
  const newBannerKeyPressFunc = (event) => {
    if (event.key === "Enter") {
      newBannerDialogSubmitFunc();
    }
  };

  // 배너 수정 - index state
  const [bannerIndexState, bannerIndexSetState] = useState(0);
  // 배너 수정 - value state
  const [currentBannerDialogValueState, currentBannerDialogValueSetState] = useState({
    image: "",
    url: "",
    newPage: false,
  });

  // 배너 수정 - get upload url
  const currentBannerImageUrl = useMutation((fileName) => RequestBannerImageUrl(fileName), {
    onSuccess: (data) => {
      currentBannerDialogValueSetState((prevState) => ({ ...prevState, image: data.split("?X-Goog-Algorithm")[0] }));
      setBlobDictState({
        url: data,
        blob: currentBannerImageBoxRef.current.files[0],
      });
      blobImgSrcSetState(window.URL.createObjectURL(currentBannerImageBoxRef.current.files[0]));
    },
  });

  // 배너 수정 - 이미지 변경 버튼 클릭 event : button function
  const currentBannerChangeImageButtonFunc = () => {
    currentBannerImageBoxRef.current.click();
  };

  // 배너 수정 - 이미지 변경 event : input file function
  const currentBannerChangeImageFunc = () => {
    if (currentBannerImageBoxRef.current.files[0]) {
      if (!currentBannerImageBoxRef.current.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
        alertDialogMessageSetState("파일 형식을 확인해주세요(jpg, jpeg, png, gif)");
        alertDialogCall();
        return false;
      } else {
        currentBannerImageUrl.mutate(currentBannerImageBoxRef.current.files[0].name);
      }
    } else {
      alertDialogMessageSetState("파일 선택을 취소하셨습니다.");
      alertDialogCall();
    }
  };

  // 배너 수정 - url 변경 event
  const currentBannerUrlChangeFunc = () => {
    currentBannerDialogValueSetState((prevState) => ({
      ...prevState,
      url: currentBannerUrlInputRef.current.value,
    }));
  };

  // 배너 수정 - 새창 여부 변경 event
  const currentBannerNewPageSetFunc = (event) => {
    currentBannerDialogValueSetState((prevState) => ({
      ...prevState,
      newPage: event.target.checked,
    }));
  };

  // 배너 수정 - dialog open function
  const [currentBannerDialogOpenState, currentBannerDialogOpenSetState] = useState(false);

  // 배너 수정 - dialog open function
  const currentBannerDialogOpenFunc = (index) => {
    currentBannerDialogValueSetState(bannerValueState[index]);
    bannerIndexSetState(index);
    currentBannerDialogOpenSetState(true);
  };

  // 배너 수정 - dialog close function
  const currentBannerDialogCloseFunc = () => {
    currentBannerDialogValueSetState({
      image: "",
      url: "",
      newPage: false,
    });
    bannerValueSetState(bannerData.data.banner.list);
    setBlobDictState(new Object());
    blobImgSrcSetState("");
    window.URL.revokeObjectURL(blobImgSrcState);
    currentBannerDialogOpenSetState(false);
  };

  // 배너 수정 - index 변경 function
  const currentBannerSetIndexFunc = (newIndex) => {
    const tempArray = [...bannerValueState];
    const bannerDeleteFunc = async () => {
      tempArray.splice(bannerIndexState, 1);
      tempArray.splice(newIndex, 0, currentBannerDialogValueState);
    };
    bannerDeleteFunc().then(() => {
      bannerIndexSetState(newIndex);
      bannerValueSetState(tempArray);
    });
  };

  // 배너 수정 - delete function
  const bannerDeleteFunc = async () => {
    const tempArray = [...bannerValueState];
    tempArray.splice(bannerIndexState, 1);
    bannerValueSetState(tempArray);
  };

  // 배너 수정 - delete 후 server data update function
  const currentBannerDialogDeleteFunc = () => {
    bannerDeleteFunc().then(() => {
      updateBannerData.mutate();
    });
  };

  // 배너 수정 - submit function
  const currentBannerDialogSubmitFunc = () => {
    const tempArray = [...bannerValueState];
    tempArray.splice(bannerIndexState, 1, currentBannerDialogValueState);
    bannerValueSetState(tempArray);
    fileUploadFunc()
      .then(() => {
        updateBannerData.mutate();
      })
      .catch((error) => {
        console.log(error);
        alertDialogMessageSetState("이미지 파일 업로드 중 에러가 발생했습니다.");
        alertDialogCall();
      });
  };

  // 배너 수정 - enter key press event
  const currentBannerKeyPressFunc = (event) => {
    if (event.key === "Enter") {
      currentBannerDialogSubmitFunc();
    }
  };

  // 배너 이미지 관리 화면
  const AdminBannerContainer = () => {
    return (
      <Grid container rowSpacing={3}>
        <Backdrop open={loadState}>
          <CircularProgress />
        </Backdrop>
        <Grid item container xs={12} spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-end">
          <Grid item xs={6}>
            <Typography variant="h4">배너 이미지 관리</Typography>
          </Grid>
        </Grid>
        <Grid container item justifyContent="flex-end">
          <Grid item xs={1}>
            <CancelButton buttonText="추가" onClickEvent={newBannerDialogOpenFunc} />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          {bannerValueState.map((bannerData, index) => {
            return (
              <Grid item xs={4} key={"currentBanner" + index}>
                <CurrentBannerImageBox bannerImageUrl={bannerData.image} bannerLinkUrl={bannerData.url} bannerNewPage={bannerData.newPage} changeValueFunc={() => currentBannerDialogOpenFunc(index)} bannerIndex={index} dialogBannerIndex={bannerIndexState} blobImgSrcState={blobImgSrcState} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  if (bannerData.isFetching) {
    return <LoadingComponent />;
  } else {
    return (
      <Fragment>
        <AdminBodyFrame contentComponent={AdminBannerContainer()} />
        <ComplexDialogWithOneButton context={<NewBannerDialogContents ref={newBannerRefSet} newBannerState={newBannerDialogValueState} newBannerNewPageSetFunc={newBannerNewPageSetFunc} newBannerUrlSetFunc={newBannerUrlChangeFunc} urlKeyPressFunc={newBannerKeyPressFunc} blobImgSrcState={blobImgSrcState} changeImageButtonFunc={newBannerChangeImageButtonFunc} changeImageFunc={newBannerChangeImageFunc} />} dialogOpenState={newBannerDialogOpenState} dialogClose={newBannerDialogCloseFunc} buttonText="저장" buttonFunc={newBannerDialogSubmitFunc} maxWidth={800} />
        <ComplexDialogWithThreeButton context={<CurrentBannerDialogContents ref={currentBannerRefSet} currentBannerState={currentBannerDialogValueState} currentBannerNewPageSetFunc={currentBannerNewPageSetFunc} currentBannerUrlSetFunc={currentBannerUrlChangeFunc} urlKeyPressFunc={currentBannerKeyPressFunc} blobImgSrcState={blobImgSrcState} changeImageButtonFunc={currentBannerChangeImageButtonFunc} changeImageFunc={currentBannerChangeImageFunc} setIndexFunc={currentBannerSetIndexFunc} currentBannerIndex={bannerIndexState} bannerList={bannerValueState} />} dialogOpenState={currentBannerDialogOpenState} dialogClose={currentBannerDialogCloseFunc} button1Text="삭제" button2Text="저장" button1Func={currentBannerDialogDeleteFunc} button2Func={currentBannerDialogSubmitFunc} maxWidth={600} />
        <DialogAlertComponent />
      </Fragment>
    );
  }
}
