import React, { Fragment, forwardRef } from "react";

import moment from "moment";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import InputBase from "@mui/material/InputBase";
import TextField from "@mui/material/TextField";

import { FlexibleThumbnailBox, ChannelTypeTag } from "../../components/ProjectComponent";
import { TypoButton } from "../../components/ButtonComponents";

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const GreyBoxGridContainer = styled(Grid)({
  position: "relative",
  padding: "33px 37px",
  background: "#F4F5F5",
  borderRadius: "10px",
});

const CustomLinkGrid = styled(Grid)({
  position: "absolute",
  top: "24px",
  right: "36px",
});

const CustomTableHeadRow = styled(TableRow)({
  borderTop: "1px solid #111111",
});

const HeadTableCell = styled(TableCell)({
  color: "#4C4C4C",
  fontSize: "14px",
});

const CustomTableBodyRow = styled(TableRow)({
  backgroundColor: "#FFFFFF",
});

const TitleNOfferingTypo = styled(Typography)({
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
});

const CustomLinkTypo = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  fontSize: "13px",
  textDecoration: "underline",
  color: theme.palette.black[600],
}));

const CustomInputBase = styled(InputBase)(({ theme }) => ({
  borderRadius: "10px",
  border: "1px solid #D9D9D9",
  padding: "6px 14px",
  fontSize: "14px",
  color: theme.palette.secondary.main,
}));

const CustomDateTextField = styled(TextField)(({ theme }) => ({
  height: "48px",
  ".MuiOutlinedInput-root": {
    borderRadius: 10,
    height: "48px",
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "#D9D9D9",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#D9D9D9",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#D9D9D9",
    },
  },
  input: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    "&::placeholder": {
      fontSize: 14,
    },
  },
}));

// 모집 중 프로젝트 상세 페이지 내 상단 프로젝트 정보 테이블
export const RecruitingProjectUpperTable = forwardRef((props, forwardRef) => {
  const { projectDetailOriginalValue, projectDetailValue, projectDetailPageOpenFunc, valueRevisingState, reviseButtonFunc, projectCancelButtonFunc, submitButtonFunc, projectRecruiteNumberChange, projectDateValueChange, onKeyPress } = props;
  const { recruitmentStartDateRef, recruitmentEndDateRef, appointmentDateRef, submissionStartDateRef, submissionEndDateRef, endDateRef, recruitmentLimitNumberRef } = forwardRef;

  // 날짜 형식 변환(초 -> 년/월/일)
  const recuitmentStartDate = moment(projectDetailOriginalValue.recruitmentStartDate * 1000).format("YYYY.MM.DD");
  const recuitmentEndDate = moment(projectDetailOriginalValue.recruitmentEndDate * 1000).format("YYYY.MM.DD");
  const appointmentDate = moment(projectDetailOriginalValue.appointmentDate * 1000).format("YYYY.MM.DD");
  const submissionStartDate = moment(projectDetailOriginalValue.submissionStartDate * 1000).format("YYYY.MM.DD");
  const submissionEndDate = moment(projectDetailOriginalValue.submissionEndDate * 1000).format("YYYY.MM.DD");
  const endDate = moment(projectDetailOriginalValue.endDate * 1000).format("YYYY.MM.DD");

  const DateInput = ({ forwardRef, value, onChange, onKeyPress }) => {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          components={{ OpenPickerIcon: CalendarMonthIcon }} 
          inputFormat="YYYY.MM.DD"
          ref={forwardRef}
          value={value}
          onChange={onChange}
          renderInput={(params) => (
            <CustomDateTextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "YYYY.MM.DD",
              }}
            />
          )}
          onKeyPress={onKeyPress}
        />
      </LocalizationProvider>
    );
  };

  return (
    <Grid container justifyContent="flex-end" rowGap={2.8}>
      <GreyBoxGridContainer container xs={12}>
        <CustomLinkGrid item xs={12}>
          <CustomLinkTypo onClick={() => projectDetailPageOpenFunc()}>상세보기</CustomLinkTypo>
        </CustomLinkGrid>
        <Grid item xs={1.2}>
          <FlexibleThumbnailBox thumbnailUrl={projectDetailOriginalValue.thumbnail} size="88px" />
        </Grid>
        <Grid container item xs={10.8} rowSpacing={1}>
          <Grid container item columnSpacing={1}>
            <Grid item><ChannelTypeTag channelType={projectDetailOriginalValue.channel} size="24px" fontSize="12px" /></Grid>
            <Grid item><ChannelTypeTag channelType={projectDetailOriginalValue.type} size="24px" fontSize="12px" /></Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={12}>
              <TitleNOfferingTypo variant="h5" lineHeight="28px" fontWeight={700} color="black.300">
                {projectDetailOriginalValue.title}
              </TitleNOfferingTypo>
            </Grid>
            {projectDetailOriginalValue.offering ? (
              <Grid item xs={12}>
                <TitleNOfferingTypo variant="body1" lineHeight="24px" color="black.600">
                  제공품목 | {projectDetailOriginalValue.offering}
                </TitleNOfferingTypo>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </GreyBoxGridContainer>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <CustomTableHeadRow>
                <HeadTableCell align="center" width="10%">
                  <Typography variant="body1" lineHeight="24px" color="black.300">
                    모집인원
                  </Typography>
                </HeadTableCell>
                <HeadTableCell align="center" width="30%">
                  <Typography variant="body1" lineHeight="24px" color="black.300">
                    모집기간
                  </Typography>
                </HeadTableCell>
                <HeadTableCell align="center" width="15%">
                  <Typography variant="body1" lineHeight="24px" color="black.300">
                    선정일
                  </Typography>
                </HeadTableCell>
                <HeadTableCell align="center" width="30%">
                  <Typography variant="body1" lineHeight="24px" color="black.300">
                    제출기간
                  </Typography>
                </HeadTableCell>
                <HeadTableCell align="center" width="15%">
                  <Typography variant="body1" lineHeight="24px" color="black.300">
                    종료일
                  </Typography>
                </HeadTableCell>
              </CustomTableHeadRow>
            </TableHead>
            <TableBody>
              <CustomTableBodyRow>
                {valueRevisingState ? (
                  <Fragment>
                    <TableCell>
                      <Grid container justifyContent="center" alignItems="center" columnSpacing={1}>
                        <Grid item xs>
                          <CustomInputBase inputRef={recruitmentLimitNumberRef} fullWidth value={projectDetailValue.recruitmentLimitNumber} onChange={() => projectRecruiteNumberChange()} />
                        </Grid>
                        <Grid item xs="auto">
                          <Typography variant="body2" lineHeight="24px" color="#333333" align="center">
                            명
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container>
                        <Grid item xs={5.5}>
                          <DateInput forwardRef={recruitmentStartDateRef} value={projectDetailValue.recruitmentStartDate} onChange={(newValue) => projectDateValueChange("recruitmentStartDate", newValue)} onKeyPress={(event) => onKeyPress(event)} />
                        </Grid>
                        <Grid item xs={1}>
                          <Typography variant="subtitle1" lineHeight="24px" fontWeight={700} align="center">
                            ~
                          </Typography>
                        </Grid>
                        <Grid item xs={5.5}>
                          <DateInput forwardRef={recruitmentEndDateRef} value={projectDetailValue.recruitmentEndDate} onChange={(newValue) => projectDateValueChange("recruitmentEndDate", newValue)} onKeyPress={(event) => onKeyPress(event)} />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <DateInput forwardRef={appointmentDateRef} value={projectDetailValue.appointmentDate} onChange={(newValue) => projectDateValueChange("appointmentDate", newValue)} onKeyPress={(event) => onKeyPress(event)} />
                    </TableCell>
                    <TableCell>
                      <Grid container>
                        <Grid item xs={5.5}>
                          <DateInput forwardRef={submissionStartDateRef} value={projectDetailValue.submissionStartDate} onChange={(newValue) => projectDateValueChange("submissionStartDate", newValue)} onKeyPress={(event) => onKeyPress(event)} />
                        </Grid>
                        <Grid item xs={1}>
                          <Typography variant="subtitle1" lineHeight="24px" fontWeight={700} align="center">
                            ~
                          </Typography>
                        </Grid>
                        <Grid item xs={5.5}>
                          <DateInput forwardRef={submissionEndDateRef} value={projectDetailValue.submissionEndDate} onChange={(newValue) => projectDateValueChange("submissionEndDate", newValue)} onKeyPress={(event) => onKeyPress(event)} />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <DateInput forwardRef={endDateRef} value={projectDetailValue.endDate} onChange={(newValue) => projectDateValueChange("endDate", newValue)} onKeyPress={(event) => onKeyPress(event)} />
                    </TableCell>
                  </Fragment>
                ) : (
                  <Fragment>
                    <TableCell>
                      <Typography variant="subtitle1" lineHeight="24px" color="black.200" align="center">
                        {projectDetailOriginalValue.recruitmentLimitNumber}명
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" lineHeight="24px" fontWeight={700} color="black.200" align="center">
                        {recuitmentStartDate} ~ {recuitmentEndDate}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" lineHeight="24px" color="black.200" align="center">
                        {appointmentDate}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" lineHeight="24px" color="black.200" align="center">
                        {submissionStartDate} ~ {submissionEndDate}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" lineHeight="24px" color="black.200" align="center">
                        {endDate}
                      </Typography>
                    </TableCell>
                  </Fragment>
                )}
              </CustomTableBodyRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {valueRevisingState ? (
        <Grid item>
          <ButtonBase onClick={() => submitButtonFunc()}>
            <TypoButton buttonText="변경사항 저장" buttonColor="reverse" />
          </ButtonBase>
        </Grid>
      ) : (
        <Grid container justifyContent="end" columnGap={1}>
          <Grid item>
            <ButtonBase onClick={() => reviseButtonFunc()}>
              <TypoButton buttonText="프로젝트 수정" buttonColor="secondary" />
            </ButtonBase>
          </Grid>
          <Grid item>
            <ButtonBase onClick={() => projectCancelButtonFunc()}>
              <TypoButton buttonText="프로젝트 취소" buttonColor="error" />
            </ButtonBase>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
});
