// const logoUrl = "https://shop-phinf.pstatic.net/20220429_266/1651221797955Xzkag_PNG/52357632786177989_1444597365.png?type=m120";

const logoUrl = {
  logo: "/icons/on1016Logo.svg",
  logoG: "/icons/rebleLogoG.png",
  logoW: "/icons/rebleLogoW.png",
  logoB: "/icons/rebleLogoB.png",
  logoBusiness: "/icons/on1016LogoBusiness.svg",
  logoFooter: "/icons/on1016LogoFooter.svg",
};

const starUrl = "/icons/starIcon.svg";

const defaultProfileIconUrl = "/icons/defaultProfileIcon.png";

const naverUrl = { large: "/icons/NaverLogo_L.png", disabled: "/icons/NaverLogo_D.png", medium: "/icons/NaverLogo_M.png" };

const kakaoUrl = { large: "/icons/KakaoLogo_L.png", disabled: "/icons/KakaoLogo_D.png", medium: "/icons/KakaoLogo_M.png" };

const googleUrl = { large: "/icons/GoogleLogo_L.png", disabled: "/icons/GoogleLogo_D.png", medium: "/icons/GoogleLogo_M.png" };

const personIconUrl = {
  personIconB: "/icons/personIconB.png",
  personIconW: "/icons/personIconW.png",
};

const notificationUrl = {
  notificationB: "/icons/notificationIconB.png",
  notificationW: "",
};

const documentIconUrl = {
  documentIconB: "/icons/documentIconB.png",
  documentIconG: "/icons/documentIconG.png",
};

const bookmarkIconUrl = {
  bookmarkIconB: "/icons/bookmarkIconB.png",
  bookmarkIconW: "/icons/bookmarkIconW.png",
};

const footerUrl = {
  youtube: "/icons/footer_youtubeIcon.png",
  instagram: "/icons/footer_instagramIcon.png",
};

const snsUrl = { instagram: "/icons/instagramIcon.svg", youtube: "/icons/youtubeIcon.svg", naverBlogUrl: "/icons/naverBlogIcon.svg", naverPostUrl: "/icons/naverPostIcon.svg" };
const typeUrl = { visit: "/icons/visitIcon.svg", delivery: "/icons/deliveryIcon.svg" };

const checkIconUrl = "/icons/checkIcon.png";
const alertIconUrl = "/icons/alertIcon.png";

const passwordTextFieldIconUrl = {
  passwordVisible: "/icons/passwordVisible.svg",
  passwordInvisible: "/icons/passwordInvisible.svg",
};

const modalCloseUrl = "/icons/modalClose.png";

const addImageIconUrl = "/icons/addIcon.svg";

const deleteIconUrl = "/icons/deleteIcon.svg";

const sharingIconUrl = "/icons/sharingIcon.png";

const arrowIconUrl = {
  arrowUp: "/icons/arrowUp.png",
  arrowDown: "/icons/arrowDown.png",
};

const plusIconUrl = "/icons/plusIcon.png";

const infoIconUrl = "/icons/infoIcon.svg";

const circleCheckIconUrl = "/icons/circleCheckIcon.svg";
const circleNoticeIconUrl = "/icons/circleNoticeIcon.svg";
const cancelApplIconUrl = "/icons/cancelApplIcon.svg";

const portfolioArrowUrl = "/icons/portfolioArrow.svg";

const substituteImageUrl = "/icons/substituteImage.png";

const paymentUrl = {
  tossPay: "/icons/logo_toss_pay.png",
  kakaoPay: "/icons/logo_kakao_pay.png",
};

const portfolioProjectDefaultImageUrl = "/icons/portfolioProjectDefaultImage.png";
const portfolioProfileDefaultImageUrl = "/icons/portfolioProfileDefaultImage.png";

export { logoUrl, starUrl, defaultProfileIconUrl, naverUrl, kakaoUrl, googleUrl, personIconUrl, notificationUrl, documentIconUrl, bookmarkIconUrl, snsUrl, typeUrl, checkIconUrl, alertIconUrl, passwordTextFieldIconUrl, modalCloseUrl, footerUrl, addImageIconUrl, deleteIconUrl, sharingIconUrl, arrowIconUrl, plusIconUrl, infoIconUrl, circleCheckIconUrl, circleNoticeIconUrl, cancelApplIconUrl, portfolioArrowUrl, substituteImageUrl, paymentUrl, portfolioProjectDefaultImageUrl, portfolioProfileDefaultImageUrl };
