import React from "react";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

import { naverUrl, kakaoUrl, googleUrl, snsUrl } from "../configs/urls";

const SnsLogo = styled("img")({
  width: 48,
});

const DisabledIconButton = styled(Box)({
  width: 48,
  height: 48,
  borderRadius: "50%",
  backgroundColor: "#eeeeee ",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const DisabledSnsLogo = styled("img")({
  width: 24,
});

const InstagramIconBox = styled(Box)(({ size }) => ({
  width: size,
  height: size,
  borderRadius: "50%",
  background: "linear-gradient(to right bottom, #833ab4, #fd1d1d, #fcb045 )",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}));

const YoutubeIconBox = styled(Box)(({ size }) => ({
  width: size,
  height: size,
  borderRadius: "50%",
  backgroundColor: "#D4272D ",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}));

const NaverIconBox = styled(Box)(({ size }) => ({
  width: size,
  height: size,
  borderRadius: "50%",
  backgroundColor: "#2FB44A ",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}));

const MyPageSnsIcon = styled("img")({
  width: "50%",
  height: "50%"
});

// SNS 로그인 버튼 - 네이버
export default function NaverIconButton(props) {
  const { buttonFunc } = props;

  if (buttonFunc) {
    return (
      <IconButton onClick={() => buttonFunc()}>
        <SnsLogo src={naverUrl.large} alt="네이버 로그인" />
      </IconButton>
    );
  } else {
    return (
      <IconButton>
        <SnsLogo src={naverUrl.large} alt="네이버 로그인" />
      </IconButton>
    );
  }
}

// SNS 로그인 버튼 - 네이버 : 비활성화
export function NaverGrayIconButton(props) {
  const { buttonFunc } = props;
  return (
    <IconButton onClick={() => buttonFunc()}>
      <DisabledIconButton>
        <DisabledSnsLogo src={naverUrl.disabled} alt="네이버 로그인" />
      </DisabledIconButton>
    </IconButton>
  );
}

// SNS 로그인 버튼 - 카카오
export function KakaoIconButton(props) {
  const { buttonFunc } = props;

  if (buttonFunc) {
    return (
      <IconButton onClick={() => buttonFunc()}>
        <SnsLogo src={kakaoUrl.large} alt="카카오 로그인" />
      </IconButton>
    );
  } else {
    return (
      <IconButton>
        <SnsLogo src={kakaoUrl.large} alt="카카오 로그인" />
      </IconButton>
    );
  }
}

// SNS 로그인 버튼 - 카카오 : 비활성화
export function KakaoGrayIconButton(props) {
  const { buttonFunc } = props;
  return (
    <IconButton onClick={() => buttonFunc()}>
      <DisabledIconButton>
        <DisabledSnsLogo src={kakaoUrl.disabled} alt="카카오 로그인" />
      </DisabledIconButton>
    </IconButton>
  );
}

// SNS 로그인 버튼 - 구글
export function GoogleIconButton(props) {
  const { buttonFunc } = props;

  if (buttonFunc) {
    return (
      <IconButton onClick={() => buttonFunc()}>
        <SnsLogo src={googleUrl.large} alt="구글 로그인" />
      </IconButton>
    );
  } else {
    return (
      <IconButton>
        <SnsLogo src={googleUrl.large} alt="구글 로그인" />
      </IconButton>
    );
  }
}

// SNS 로그인 버튼 - 구글 : 비활성화
export function GoogleGrayIconButton(props) {
  const { buttonFunc } = props;
  return (
    <IconButton onClick={() => buttonFunc()}>
      <DisabledIconButton>
        <DisabledSnsLogo src={googleUrl.disabled} alt="구글 로그인" />
      </DisabledIconButton>
    </IconButton>
  );
}

// SNS 아이콘 - 인스타그램
export function InstagramIcon(props) {
  const { size = "28px" } = props;
  return (
    <InstagramIconBox size={size}>
      <MyPageSnsIcon src={snsUrl.instagram} alt="인스타그램" />
    </InstagramIconBox>
  );
}

// SNS 아이콘 - 유튜브
export function YoutubeIcon(props) {
  const { size = "28px" } = props;
  return (
    <YoutubeIconBox size={size}>
      <MyPageSnsIcon src={snsUrl.youtube} alt="유튜브" />
    </YoutubeIconBox>
  );
}

// SNS 아이콘 - 네이버 블로그
export function NaverBlogIcon(props) {
  const { size = "28px" } = props;
  return (
    <NaverIconBox size={size}>
      <MyPageSnsIcon src={snsUrl.naverBlogUrl} alt="네이버 블로그" />
    </NaverIconBox>
  );
}

// SNS 아이콘 - 네이버 포스트
export function NaverPostIcon(props) {
  const { size = "28px" } = props;
  return (
    <NaverIconBox size={size}>
      <MyPageSnsIcon src={snsUrl.naverPostUrl} alt="네이버 포스트" />
    </NaverIconBox>
  );
}
