import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import FormControlLabel from "@mui/material/FormControlLabel";

import { AuthCheckbox } from "../../components/AuthComponents";

// 약관 전체 동의 체크박스
export default function AgreementAllCheckBoxSet(props) {
  const { checkedState, checkEvent } = props;
  return (
    <FormControlLabel
      label={
        <Typography variant="body2" fontWeight={500}>
          약관 전체 동의
        </Typography>
      }
      control={<AuthCheckbox checkedState={checkedState} checkEvent={checkEvent} />}
    />
  );
}

// 멤버십 정책 동의 체크박스
export function PolicyAgreementCheckBoxSet(props) {
  const { checkedState, checkEvent, title, modalOpenEvent } = props;

  return (
    <Grid container item alignItems="center">
      <Grid item xs="auto">
        <FormControlLabel
          label={
            <Typography variant="body3" component="p">
              {title}
            </Typography>
          }
          control={<AuthCheckbox checkedState={checkedState} checkEvent={checkEvent} />}
        />
      </Grid>
      <Grid container item xs justifyContent="flex-end">
        <Grid item>
          <ButtonBase onClick={modalOpenEvent}>
            <Typography variant="body3" component="p" color="secondary" align="right">
              전체보기 &gt;
            </Typography>
          </ButtonBase>
        </Grid>
      </Grid>
    </Grid>
  );
}

// 멤버십 자동 결제 동의 체크박스
export function SubscribeAgreementCheckBoxSet(props) {
  const { checkedState, checkEvent, title } = props;

  return (
    <Grid item xs={12}>
      <FormControlLabel
        label={
          <Typography variant="body3" component="p">
            {title}
          </Typography>
        }
        control={<AuthCheckbox checkedState={checkedState} checkEvent={checkEvent} />}
      />
    </Grid>
  );
}
