import React, { Fragment, useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { useMutation, useQuery, useQueryClient } from "react-query";

import { RequestProjectDetail, RequestFinishedProjectAppointersList, UpdateDeleteProject } from "../../../utils/requestToSrv";

import ProjectDetailBodyFrame from "../../../Block/IncUserMyPage/ProjectDetailBodyFrame";
import FinishedProjectUpperTable from "../../../Block/IncUserMyPage/FinishedProjectUpperTable";
import FinishedProjectBelowTable from "../../../Block/IncUserMyPage/FinishedProjectBelowTable";

import DialogMessage from "../../../components/DialogMessage";
import DialogWithTwoButton from "../../../components/DialogWithButton";
import LoadingComponent from "../../../components/LoadingComponent";

// 종료된 프로젝트 상세 페이지
export default function FinishedProjectDetailContainer() {
  // 프로젝트 doc id url 파라미터
  const { targetDocId } = useParams();

  // react-router-dom navigate
  const navigate = useNavigate();

  // react-query queryClient
  const queryClient = useQueryClient();

  // 화면 로드 시 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // sns 링크 새창으로 열기
  const snsOpenFunc = (url) => {
    window.open(url);
  };

  // 프로젝트 data load
  const finishedProjectDetailInfo = useQuery(["finishedProjectDetail", targetDocId], () => RequestProjectDetail(targetDocId), {
    onError: (error) => {
      console.log(error);
    },
  });

  // 프로젝트 선정자 data load
  const appointersList = useQuery(["appointersList", targetDocId], () => RequestFinishedProjectAppointersList(targetDocId), {
    onError: (error) => console.log(error),
  });

  // 테이블 페이지 state
  const [tablePageState, tablePageSetState] = useState(1);

  // 테이블 페이지 이동
  const tablePageChange = (event, newPage) => {
    tablePageSetState(newPage);
  };

  // 프로젝트 삭제 후 프로젝트 목록 페이지로 이동
  const linkToFinishedProject = () => {
    setTimeout(() => {
      navigate("/finished-project");
    }, 1000);
  };

  // dialog message text state
  const [dialogMessageState, dialogMessageSetState] = useState("");
  // dialog message custom hook
  const { dialogCall, DialogComponent } = DialogMessage(dialogMessageState, linkToFinishedProject);

  // 프로젝트 삭제 dialog open state
  const [projectDeleteDialogOpenState, projectDeleteDialogOpenSetState] = useState(false);

  // 프로젝트 삭제 dialog open function
  const dialogOpenFunc = () => {
    projectDeleteDialogOpenSetState(true);
  };

  // 프로젝트 삭제 dialog close function
  const dialogCloseFunc = () => {
    projectDeleteDialogOpenSetState(false);
  };

  // 프로젝트 삭제 update function
  const deleteProject = useMutation(() => UpdateDeleteProject(targetDocId), {
    onSuccess: () => {
      projectDeleteDialogOpenSetState(false);
      dialogMessageSetState("프로젝트가 삭제되었습니다.");
      dialogCall();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 프로젝트 삭제 dialog submit function
  const dialogSubmitFunc = () => {
    deleteProject.mutate();
  };

  // 프로젝트 상세 페이지로 이동
  const projectDetailPageOpenFunc = () => {
    window.open(`/project-detail/${targetDocId}`);
  };

  // 종료 프로젝트 상세 페이지 화면
  if (queryClient.getQueryData(["finishedProjectDetail", targetDocId]) && appointersList.isFetched) {
    return (
      <Fragment>
        <ProjectDetailBodyFrame title="종료 프로젝트" upperTable={<FinishedProjectUpperTable projectDetailValue={finishedProjectDetailInfo.data} projectDetailPageOpenFunc={projectDetailPageOpenFunc} dialogOpenFunc={dialogOpenFunc} />} belowTable={<FinishedProjectBelowTable projectDetailValue={finishedProjectDetailInfo.data} appointersListValue={appointersList.data} snsOpenFunc={snsOpenFunc} tablePageState={tablePageState} tablePageChange={tablePageChange} dialogOpenFunc={dialogOpenFunc} dialogCloseFunc={dialogCloseFunc} dialogSubmitFunc={dialogSubmitFunc} />} />
        <DialogComponent />
        <DialogWithTwoButton width={554} icon="notice" title="프로젝트를 삭제하시겠습니까?" subtitle={["삭제한 프로젝트는 다시 복구할 수 없습니다.", <br />, "해당 프로젝트를 목록에서 삭제하시겠습니까?"]} dialogOpenState={projectDeleteDialogOpenState} dialogClose={dialogCloseFunc} button1Text="취소" button2Text="확인" button1Func={dialogCloseFunc} button2Func={dialogSubmitFunc} />
      </Fragment>
    );
  } else {
    return <LoadingComponent />;
  }
}
