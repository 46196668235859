import React from "react";

import RequireAuthRoute from "../../routes/requireAuth";

import UniversalAppBar from "../../Block/Appbar/UniversalAppBar";
import UniversalAppFooter from "../../Block/Footer/UniversalAppFooter";

import OngoingProject from "../../containers/Service/Project/OngoingProjectContainer";

export default function OngoingProjectView() {
  return (
    <React.Fragment>
      <UniversalAppBar />
      <RequireAuthRoute Component={OngoingProject} />
      <UniversalAppFooter />
    </React.Fragment>
  );
}
