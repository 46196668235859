import React, { Fragment } from "react";

import { Link } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ButtonBase from "@mui/material/ButtonBase";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EastIcon from "@mui/icons-material/East";

import { ProjectListItem } from "../../components/ProjectComponent";
import { starUrl } from "../../configs/urls";

const BannerImageContainerBox = styled(Box)({
  position: "relative",
  width: "100%",
  height: "40vh",
});

const BannerImageBox = styled(Box)(({ boxPosition }) => ({
  position: "absolute",
  display: boxPosition === "none" ? "none" : "block",
  left: boxPosition === "main" ? "0" : boxPosition === "prev" ? "-100%" : boxPosition === "next" ? "100%" : boxPosition === "prevPrev" ? "-200%" : boxPosition === "nextNext" ? "200%" : "0",
  width: "100%",
  transitionDuration: "1.3s",
  transitionTimingFunction: "ease",
  zIndex: 3,
  opacity: boxPosition === "main" ? 1 : 0.5,
  height: "100%",
}));

const BannerButtonGrid = styled(Grid)({
  position: "absolute",
  bottom: "10%",
});

const BannerButton = styled(Grid)({
  background: "rgba(0, 0, 0, 0.6)",
  borderRadius: "100px",
  zIndex: 5,
});

const BannerButtonBase = styled(ButtonBase)({
  width: "100%",
  height: "100%",
});

const CustomImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const ProjectListContainer = styled(Container)({
  padding: "100px 0 40px",
});

const LinkIcon = styled(EastIcon)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export default function RootContents(props) {
  const { bannerImages, dataLoadedState, bannerClickFunc, baseArrayLength, mainBannerImage, backwardBtnClickFunc, frontwardBtnClickFunc, projectListValues, projectItemClickFunc } = props;

  const SlideInBanners = () => {
    return (
      <BannerImageContainerBox>
        {bannerImages.map((bannerImage, index) => {
          return (
            <BannerImageBox key={index} boxPosition={index === mainBannerImage.main ? "main" : index === mainBannerImage.prev ? "prev" : index === mainBannerImage.next ? "next" : index === mainBannerImage.prevPrev ? "prevPrev" : index === mainBannerImage.nextNext ? "nextNext" : "none"}>
              <BannerButtonBase onClick={() => bannerClickFunc(bannerImage.url, bannerImage.newPage)} disabled={index !== mainBannerImage.main}>
                <CustomImage src={bannerImage.image} alt={"배너" + index + 1} />
              </BannerButtonBase>
            </BannerImageBox>
          );
        })}
        {bannerImages.length === 1 ? null : (
          <BannerButtonGrid container justifyContent="center">
            <BannerButton container item xs={1} justifyContent="space-evenly" alignItems="center">
              <Grid item xs="auto">
                <IconButton onClick={() => backwardBtnClickFunc()}>
                  <ArrowBackIosNewIcon size="small" color="primary" />
                </IconButton>
              </Grid>
              <Grid item xs="auto">
                <Typography variant="caption1" component="p" color="primary">
                  {(mainBannerImage.main + 1) % baseArrayLength === 0 ? baseArrayLength : (mainBannerImage.main + 1) % baseArrayLength} / {baseArrayLength}
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <IconButton onClick={() => frontwardBtnClickFunc()}>
                  <ArrowForwardIosIcon size="small" color="primary" />
                </IconButton>
              </Grid>
            </BannerButton>
          </BannerButtonGrid>
        )}
      </BannerImageContainerBox>
    );
  };

  const RootProjectList = () => {
    return (
      <ProjectListContainer maxWidth="lg">
        <Grid container>
          <Grid container item xs={3}>
            <Grid item xs={2} paddingTop="12px">
              <img src={starUrl} alt="" />
            </Grid>
            <Grid item xs={10} rowSpacing={0.2}>
              <Grid item>
                <Typography variant="h1" fontSize="36px" lineHeight="52px" color="black.100" fontFamily="Gmarket Sans" letterSpacing="0">
                  ONLINE
                  <br />
                  REVIEW
                  <br />
                  PROJECT
                </Typography>
              </Grid>
              <Grid item paddingTop="12px">
                <Typography variant="subtitle2" component="p" fontWeight={400} lineHeight="26px" color="black.300">
                  제품 또는 서비스 체험 후
                  <br />
                  솔직하고 자세한 리뷰를
                  <br />
                  나의 SNS에 공유해보세요.
                </Typography>
              </Grid>
              <ButtonBase component={Link} to={"/project-list"}>
                <Grid container alignItems="center" spacing={1} paddingTop="28px">
                  <Grid item>
                    <Typography variant="subtitle1" lineHeight="52px" color="secondary">
                      전체보기
                    </Typography>
                  </Grid>
                  <Grid item>
                    <LinkIcon size="small" color="secondary" />
                  </Grid>
                </Grid>
              </ButtonBase>
            </Grid>
          </Grid>
          <Grid container item xs={9} spacing={3.8}>
            {projectListValues != null
              ? projectListValues.map((projectValue, index) => {
                  if (index < 6) {
                    return (
                      <Grid item xs={4} key={"project" + index}>
                        <ProjectListItem projectValue={projectValue.document} projectItemClickFunc={() => projectItemClickFunc(projectValue.document.id, index)} />
                      </Grid>
                    );
                  }
                })
              : null}
          </Grid>
        </Grid>
      </ProjectListContainer>
    );
  };

  return (
    <Fragment>
      {SlideInBanners()}
      {dataLoadedState ? RootProjectList() : null}
    </Fragment>
  );
}
