import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: (themeParam) => ({
        body: {
          "::-webkit-scrollbar": {
            display: "none",
          },
        },
      }),
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1740,
    },
  },
  palette: {
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#222222",
      // dark: "#00AD98",
    },
    error:{
      light: "#FCF7F7",
      main: "#F45452",
    },
    info: {
      light: "#FBFCF3",
      main: "#BEDA00",
    },
    text: {
      primary: "#000000",
      secondary: "#00AD98",
    },
    background: {
      default: "#FFFFFF",
    },
    grey: {
      50: "#F8F8F8",
      100: "#FAFAFA",
      // 100: "#EAECEE",
      // 200: "#DCDFE1",
      200: "#d9d9d9",
      300: "#B3B3B3",
      // 300: "#BBBDBF",
      400: "#9DA0A2",
      500: "#606264",
      600: "#464646",
      // 600: "#404244",
      700: "#212121",
      800: "#151515",
      900: "#0C0C0C",
    },
    black: {
      100: "#111111",
      200: "#222222",
      300: "#333333",
      400: "#444444",
      500: "#555555",
      600: "#666666",
      900: "#999999",
    },
    neutral: {
      main: "#0C0C0C",
      contrastText: "#FFFFFF",
    },
    naver: {
      main: "#32b34a",
    },
    kakao: {
      main: "#FFE800",
    },
    google: {
      main: "#F5F6F7",
    },
    lightButton: {
      main: "#F5F5F5",
    }
  },
  typography: {
    fontFamily: ["SpoqaHanSansNeo", "NotoSansCJKkr", "Noto Sans KR"].join(","),

    h1: {
      fontSize: 30,
      fontWeight: 700,
      letterSpacing: "-0.03em",
    },
    h2: {
      fontSize: 26,
      fontWeight: 600,
      letterSpacing: "-0.03em",
    },
    h3: {
      fontSize: 24,
      fontWeight: 600,
      letterSpacing: "-0.03em",
    },
    h4: {
      fontSize: 22,
      fontWeight: 600,
      letterSpacing: "-0.03em",
    },
    h5: {
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: "-0.03em",
    },
    h6: {
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: "-0.03em",
    },

    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: "-0.03em",
    },
    subtitle2: {
      fontSize: 15,
      fontWeight: 500,
      letterSpacing: "-0.03em",
    },

    body1: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: "-0.03em",
    },
    body2: {
      fontSize: 13,
      fontWeight: 400,
      letterSpacing: "-0.03em",
    },
    body3: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: "-0.03em",
    },

    caption1: {
      fontSize: 12,
      fontWeight: 300,
      letterSpacing: "-0.03em",
    },
    caption2: {
      fontSize: 10,
      fontWeight: 300,
      letterSpacing: "-0.03em",
    },
  },
});

export default theme;
