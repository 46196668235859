import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { RequestDefaultUsersList, RequestWithdrawal } from "../../utils/adminRequestToSrv";
import { doSendPasswordResetEmail } from "../../utils/firebaseUtils";

import AdminBodyFrame from "../../Block/Admin/AdminBodyFrame";
import AdminUserTable, { AdminUserModal } from "../../Block/Admin/AdminUsersContents";
import { AdminSearchBox } from "../../Block/Admin/AdminSearchBox";

import DialogMessage, { DialogAlertMessage } from "../../components/DialogMessage";
import DialogWithButton from "../../components/DialogWithButton";
import LoadingComponent from "../../components/LoadingComponent";

// admin - 개인 회원 관리
export default function AdminUsersContainer() {
  // react-router-dom navigate
  const navigate = useNavigate();

  // 개인 회원 리스트 load
  const defaultUserList = useQuery("defaultUserList", RequestDefaultUsersList, {
    onError: (error) => {
      console.log(error);
    },
  });

  // data loading state
  const [loadState, loadSetState] = useState(false);
  // 화면 render 확인 state
  const [renderState, renderSetState] = useState(false);

  // 검색 카테고리 버튼 ref
  const categoryButtonRef = useRef(null);
  // 검색 input ref
  const searchInputRef = useRef(null);

  const searchBoxRefSet = {
    categoryButtonRef: categoryButtonRef,
    searchInputRef: searchInputRef,
  };

  // 페이지 state
  const [tableDataPageState, tableDataPageSetState] = useState(0);

  // 회원 정보 modal에 출력할 user id
  const [userIdState, userIdSetState] = useState("");
  // 회원 정보 수정 modal open state
  const [userInfoModalOpenState, userInfoModalOpenSetState] = useState(false);

  // 검색 카테고리 메뉴 open state
  const [categoryMenuOpenedState, categoryMenuOpenedSetState] = useState(false);
  // 검색 카테고리 state
  const [searchCategoryState, searchCategorySetState] = useState("name");
  // 검색어 state
  const [searchValueState, searchValueSetState] = useState("");

  // 개인 회원 정보 수정 modal 활성화 이벤트
  const userInfoModalOpenEvent = (userId) => {
    userIdSetState(userId);
    userInfoModalOpenSetState(true);
  };

  // 개인 회원 정보 수정 modal 종료 이벤트
  const userInfoModalCloseEvent = () => {
    userIdSetState("");
    userInfoModalOpenSetState(false);
  };

  // 검색 카테고리 메뉴 open function
  const categoryMenuOpenFunc = () => {
    categoryMenuOpenedSetState(true);
  };

  // 검색 카테고리 메뉴 close function
  const categoryMenuCloseFunc = () => {
    categoryMenuOpenedSetState(false);
  };

  // 검색 카테고리 설정 이벤트
  const categoryMenuSelectFunc = (value) => {
    searchCategorySetState(value);
    searchValueSetState("");
    categoryMenuCloseFunc();
  };

  // 검색어 입력 이벤트
  const searchValueChangeFunc = () => {
    searchValueSetState(searchInputRef.current.value);
  };

  // dialog message text state
  const [dialogMessageState, dialogMessageSetState] = useState("");
  // dialog message custom hook
  const { dialogCall, DialogComponent } = DialogMessage(dialogMessageState);

  // alert dialog message text state
  const [dialogAlertMessageState, dialogAlertMessageSetState] = useState("");
  // alert dialog message custom hook
  const { dialogAlertCall, DialogAlertComponent } = DialogAlertMessage(dialogAlertMessageState);

  // 비밀번호 초기화 메일 발송 function => account type이 email인 경우에만 허용
  const sendPasswordResetFunc = () => {
    // 비밀번호 초기화 method
    if (defaultUserList.data[userIdState].accountType !== "email") {
      dialogAlertMessageSetState("이메일로 가입한 회원만 비밀번호 재설정이 가능합니다.");
      dialogAlertCall();
    } else {
      doSendPasswordResetEmail({ email: defaultUserList.data[userIdState].email, loadSetState: loadSetState, dialogCall: dialogCall, setDialogMessage: dialogMessageSetState, alertDialogCall: dialogAlertCall, setAlertDialogMessage: dialogAlertMessageSetState });
    }
  };

  // 회원 탈퇴 dialog open state
  const [withdrawalDialogOpenState, withdrawalDialogOpenSetState] = useState(false);

  // 회원 탈퇴 dialog open function
  const withdrawalFunc = () => {
    withdrawalDialogOpenSetState(true);
  };

  // 회원 탈퇴 dialog close function
  const withdrawalDialogCloseFunc = () => {
    withdrawalDialogOpenSetState(false);
  };

  // 회원 탈퇴 function
  const requestWithdrawal = useMutation(() => RequestWithdrawal(userIdState), {
    onSuccess: () => {
      dialogMessageSetState("회원 탈퇴가 완료되었습니다.");
      dialogCall();
      userInfoModalCloseEvent();
      defaultUserList.refetch();
    },
    onError: () => {
      dialogAlertMessageSetState("회원 탈퇴에 실패했습니다. 잠시 후 다시 시도해주세요.");
      dialogAlertCall();
    },
  });

  // 회원 탈퇴 submit event
  const withdrawalConfirmFunc = () => {
    withdrawalDialogOpenSetState(false);
    requestWithdrawal.mutate();
  };

  // 검색어 입력 후 0.5초 대기 시 데이터 필터링 작동
  useEffect(() => {
    if (renderState) {
      const debouncingTimer = setTimeout(() => {
        // loadSetState(true);
        // 검색 method
      }, 500);
      return () => clearTimeout(debouncingTimer);
    } else {
      renderSetState(true);
    }
  }, [searchValueState]);

  // 데이터 테이블 페이지 전환 이벤트
  const tableDataPageChange = (event, newPage) => {
    tableDataPageSetState(newPage);
  };

  // 개인 회원 포트폴리오 이동 이벤트
  const linkToPortfolioEvent = (userId) => {
    navigate(`/admin-portfolio/${userId}`);
  };

  // 개인 회원 신청한 프로젝트 목록 이동 이벤트
  const linkToAppliedProjectEvent = (userId) => {
    navigate(`/admin-applied-project/${userId}`);
  };

  // 개인 회원 화면
  const DefaultUsersComponent = () => {
    return (
      <Grid container rowSpacing={3}>
        <Grid item container xs={12} spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-end">
          <Grid item xs={6}>
            <Typography variant="h4">개인회원 관리</Typography>
          </Grid>
          <AdminSearchBox ref={searchBoxRefSet} page="users" categoryMenuOpenedState={categoryMenuOpenedState} categoryMenuOpenFunc={categoryMenuOpenFunc} categoryMenuCloseFunc={categoryMenuCloseFunc} categoryMenuSelectFunc={categoryMenuSelectFunc} selectedCategoryItemState={searchCategoryState} searchValueChangeFunc={searchValueChangeFunc} searchValueState={searchValueState} />
        </Grid>
        <AdminUserTable tableData={defaultUserList.data} tableDataPageState={tableDataPageState} tableDataPageChange={tableDataPageChange} editButtonEvent={userInfoModalOpenEvent} linkToPortfolioEvent={linkToPortfolioEvent} linkToAppliedProjectEvent={linkToAppliedProjectEvent} />
        <AdminUserModal loadState={loadState} modalOpenState={userInfoModalOpenState} modalCloseEvent={userInfoModalCloseEvent} userInfo={defaultUserList.data[userIdState]} sendPasswordResetFunc={sendPasswordResetFunc} withdrawalFunc={withdrawalFunc} />
        <DialogComponent />
        <DialogAlertComponent />
        <DialogWithButton message="탈퇴 처리 하시겠습니까?" dialogOpenState={withdrawalDialogOpenState} dialogClose={withdrawalDialogCloseFunc} button1Text="취소" button2Text="확인" button1Func={withdrawalDialogCloseFunc} button2Func={withdrawalConfirmFunc} />
      </Grid>
    );
  };

  if (defaultUserList.isFetching) {
    return <LoadingComponent />;
  } else {
    return <AdminBodyFrame contentComponent={DefaultUsersComponent()} />;
  }
}
// }
