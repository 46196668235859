import { forwardRef } from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { InstagramIcon, YoutubeIcon, NaverBlogIcon, NaverPostIcon } from "../../components/SnsIconButton";
import { TextFieldWithoutLabelSet } from "../Common/TextFieldSet";

const IconGridItem = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

export const UpdatePortfolioSnsInfo = forwardRef((props, forwardRef) => {
  const { myProfileValueState, myProfileValueChange, onKeyPress } = props;
  const { instagramRef, naverBlogRef, naverPostRef, youtubeRef } = forwardRef;

  const snsLink = () => {
    return (
      <Grid container item>
        <Grid container item rowSpacing={1.5}>
          <Grid container item>
            <Grid container item xs={3.2} alignItems="center" columnSpacing={1.5}>
              <IconGridItem item>
                <InstagramIcon />
              </IconGridItem>
              <Grid item>
                <Typography variant="subtitle1" fontWeight={500} color="black.200">
                  인스타그램
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={8.8} alignSelf="center">
              <TextFieldWithoutLabelSet type="text" placeholder="https://www.instagram.com" ref={instagramRef} valueState={myProfileValueState.instagram} setValueEvent={(event) => myProfileValueChange("instagram", event)} onKeyPress={onKeyPress} autoFocus={false} />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid container item xs={3.2} alignItems="center" columnSpacing={1.5}>
              <IconGridItem item>
                <NaverBlogIcon />
              </IconGridItem>
              <Grid item>
                <Typography variant="subtitle1" fontWeight={500} color="black.200">
                  네이버블로그
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={8.8} alignSelf="center">
              <TextFieldWithoutLabelSet type="text" placeholder="https://www.blog.naver.com" ref={naverBlogRef} valueState={myProfileValueState.naverBlog} setValueEvent={(event) => myProfileValueChange("naverBlog", event)} onKeyPress={onKeyPress} autoFocus={false} />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid container item xs={3.2} alignItems="center" columnSpacing={1.5}>
              <IconGridItem item>
                <NaverPostIcon />
              </IconGridItem>
              <Grid item>
                <Typography variant="subtitle1" fontWeight={500} color="black.200">
                  네이버포스트
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={8.8} alignSelf="center">
              <TextFieldWithoutLabelSet type="text" placeholder="https://www.post.naver.com" ref={naverPostRef} valueState={myProfileValueState.naverPost} setValueEvent={(event) => myProfileValueChange("naverPost", event)} onKeyPress={onKeyPress} autoFocus={false} />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid container item xs={3.2} alignItems="center" columnSpacing={1.5}>
              <IconGridItem item>
                <YoutubeIcon />
              </IconGridItem>
              <Grid item>
                <Typography variant="subtitle1" fontWeight={500} color="black.200">
                  유튜브
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={8.8} alignSelf="center">
              <TextFieldWithoutLabelSet type="text" placeholder="https://www.youtube.com" ref={youtubeRef} valueState={myProfileValueState.youtube} setValueEvent={(event) => myProfileValueChange("youtube", event)} onKeyPress={onKeyPress} autoFocus={false} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container item alignItems="space-between" height="100%">
      {snsLink()}
    </Grid>
  );
});
