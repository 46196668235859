import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";

import { RequestOngoingProjectList } from "../../../utils/requestToSrv";

import MyPageBodyFrame from "../../../Block/IncUserMyPage/MyPageBodyFrame";
import MyPageMenuBox from "../../../Block/IncUserMyPage/MyPageMenuBox";

import ProjectListBodyFrame, { OngoingProjectHead, OngoingProjectList } from "../../../Block/IncUserMyPage/ProjectList";
import LoadingComponent from "../../../components/LoadingComponent";

// 진행 중 프로젝트 페이지
export default function OngoingProjectContainer() {
  // react-router-dom navigate
  const navigate = useNavigate();
  // react-query queryClient
  const queryClient = useQueryClient();

  // 진행 중 프로젝트 data load
  const ongoingProjectList = useQuery("ongoingProjectList", RequestOngoingProjectList, {
    onError: (error) => {
      console.log(error);
    },
  });

  // 화면 로드 시 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // table page 당 데이터 수
  const dataPerPage = 10;

  // table page state
  const [tableDataPageState, tableDataPageSetState] = useState(1);

  // table page 이동 function
  const tableDataPageChange = (event, newPage) => {
    tableDataPageSetState(newPage);
  };

  // table row 클릭 시 진행 중인 프로젝트 상세 페이지로 이동
  const tableRowClickFunc = (index, projectId) => {
    queryClient.setQueryData(["ongoingProjectDetail", projectId], ongoingProjectList.data[index].document);
    navigate("/ongoing-project/" + projectId);
  };

  // 진행 중 프로젝트 리스트 페이지 화면
  if (ongoingProjectList.isFetching) {
    return <LoadingComponent />;
  } else {
    return <MyPageBodyFrame title="진행중 프로젝트" text="작업을 진행 중인 프로젝트 목록입니다." MyPageLeftContents={<MyPageMenuBox page="/ongoing-project" />} MyPageRightContents={<ProjectListBodyFrame tableHeadContents={<OngoingProjectHead />} tableBodyContents={<OngoingProjectList tableDataArray={ongoingProjectList.data} dataPerPage={dataPerPage} tableDataPageState={tableDataPageState} tableRowClickFunc={tableRowClickFunc} />} tableDataArray={ongoingProjectList.data} dataPerPage={dataPerPage} tableDataPageState={tableDataPageState} tableDataPageChange={tableDataPageChange} />} />;
  }
}
