import React from "react";

import moment from "moment";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Radio from '@mui/material/Radio';

import AgreementAllCheckBoxSet, { SubscribeAgreementCheckBoxSet, PolicyAgreementCheckBoxSet } from "./CheckBoxSet";

// import { paymentUrl } from "../../configs/urls";

const GreyGridItem = styled(Grid)({
  background: "#F4F5F5",
  borderRadius: "10px",
  padding: "19px 21px",
});

const GreyBorderGrid = styled(Grid)({
  border: "1px solid #D9D9D9",
  borderRadius: "10px",
  padding: "20px"
});

const BorderTopBottomGrid = styled(Grid)({
  borderTop: "1px solid #666666",
  borderBottom: "1px solid #E6E6E6",
  padding: "16px 8px",
});

const BorderTopGrid = styled(Grid)({
  borderTop: "1px solid #666666",
  padding: "16px 8px",
});

const CustomRadiio = styled(Radio)(({ theme }) => ({
  color: "#CCCCCC",
  ".MuiRadio-root": {
    padding: "0px !important",
  },
}));

const PaymentButtonBox = styled(Box)(({ theme, paymentSelectState, method }) => ({
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: paymentSelectState === method ? theme.palette.secondary.main : "#DDDDDD",
  borderRadius: "10px",
  width: "100%",
  height: "60px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "0 10px",
}));

const CustomButtonBase = styled(ButtonBase)({
  width: "100%",
  borderRadius: "10px",
});

const SubmitButtonBase = styled(ButtonBase)({
  width: "100%",
  borderRadius: "10px",
});

const SubmitButtonTypo = styled(Typography)(({ theme, agreementAllChkState }) => ({
  width: "100%",
  padding: "16px 0",
  background: agreementAllChkState ? theme.palette.secondary.main : "#999999",
  borderRadius: "10px",
}));

// 카카오 페이를 위한 결제 페이지 (Lite / Basic / Premium)
export function PaymentContents(props) {
  const { productSelectState, paymentSelectState, paymentSelectFunc, agreementAllChkState, agreementChkState, agreementAllChkEvent, agreementChkEvent, termsDialogOpenFunc, submitFunc } = props;

  return (
    <Grid container item rowGap={5}>
      <Grid container item rowGap={1.8}>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={700} color="black.300">
            멤버십 정보
          </Typography>
        </Grid>
        <BorderTopBottomGrid container item>
          <Grid container item rowSpacing={1}>
            <Grid container item justifyContent="space-between">
              <Grid item>
                <Typography variant="body2" lineHeight="24px" color="black.600">
                  결제상품
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" fontWeight={600} lineHeight="24px" color="black.200">
                  {productSelectState === "lite" ? "on1016 비즈니스 월간 멤버십 (Lite)" : productSelectState === "basic" ? "on1016 비즈니스 월간 멤버십 (Basic)" : "on1016 비즈니스 월간 멤버십 (Premium)"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs="auto">
                <Typography variant="body2" lineHeight="24px" color="black.600">
                  이용기간
                </Typography>
              </Grid>
              <Grid container item justifyContent="flex-end" xs columnSpacing={1}>
                <Grid item>
                  <Typography variant="body2" lineHeight="24px" color="black.200">
                    {moment().format("YYYY.MM.DD")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" lineHeight="24px" color="black.200">
                    ~
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" lineHeight="24px" color="black.200">
                    {moment().add(1, "month").format("YYYY.MM.DD")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item justifyContent="space-between">
              <Grid item>
                <Typography variant="body2" lineHeight="24px" color="black.600">
                  다음결제
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" lineHeight="24px" color="black.200">
                  {moment().add(1, "month").format("YYYY.MM.DD")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </BorderTopBottomGrid>
      </Grid>
      <Grid container item rowGap={1.8}>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={700} color="black.300">
            결제금액
          </Typography>
        </Grid>
        <BorderTopGrid container item>
          <Grid container item rowSpacing={1}>
            <Grid container item justifyContent="space-between">
              <Grid item>
                <Typography variant="body2" lineHeight="24px" color="black.600">
                  상품금액
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" lineHeight="24px" color="black.300">
                  {productSelectState === "lite" ? "100,000원" : productSelectState === "basic" ? "200,000원" : "400,000원"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs="auto">
                <Typography variant="body2" lineHeight="24px" color="black.600">
                  할인금액
                </Typography>
              </Grid>
              <Grid container item justifyContent="flex-end" xs columnSpacing={1}>
                <Grid item>
                  <Typography variant="body2" lineHeight="24px" color="black.300">
                    {productSelectState === "lite" ? "50,000원" : productSelectState === "basic" ? "100,000원" : "200,000원"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs="auto">
                <Typography variant="body2" lineHeight="24px" color="black.600">
                  부가세(10%)
                </Typography>
              </Grid>
              <Grid container item justifyContent="flex-end" xs columnSpacing={1}>
                <Grid item>
                  <Typography variant="body2" lineHeight="24px" color="black.300">
                    {productSelectState === "lite" ? "5,000원" : productSelectState === "basic" ? "10,000원" : "20,000원"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </BorderTopGrid>
        <GreyGridItem container item>
          <Grid container item justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="body1" lineHeight="22px" fontWeight={600} color="black.600">
                총 결제금액
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" lineHeight="22px" fontWeight={700} color="black.300">
                {productSelectState === "lite" ? "55,000원" : productSelectState === "basic" ? "110,000원" : "220,000원"}
              </Typography>
            </Grid>
          </Grid>
        </GreyGridItem>
      </Grid>
      <Grid container item rowGap={1.8}>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={700} color="black.300">
            결제 수단
          </Typography>
        </Grid>
        <Grid container item rowGap={0.8}>
          <Grid item xs={12}>
            <CustomButtonBase onClick={() => paymentSelectFunc("toss")}>
              <PaymentButtonBox paymentSelectState={paymentSelectState} method="toss">
                {/* <img src={paymentUrl.tossPay} alt="토스페이" /> */}
                <CustomRadiio checked={paymentSelectState === 'toss'} value="toss" color="secondary" size="small" />
                <Typography variant="subtitle1" color={paymentSelectState === "toss" ? "black.200" : "black.600"}>
                  토스페이
                </Typography>
              </PaymentButtonBox>
            </CustomButtonBase>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption1" component="p" lineHeight="21px" color="#4C4C4C">
              * 토스 가입 여부에 관계 없이, 법인 카드도 결제 가능합니다.
            </Typography>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={12}>
            <CustomButtonBase onClick={() => paymentSelectFunc("kakao")}>
              <PaymentButtonBox paymentSelectState={paymentSelectState} method="kakao">
                {/* <img src={paymentUrl.kakaoPay} alt="카카오페이" /> */}
                <CustomRadiio checked={paymentSelectState === 'kakao'} value="kakao" color="secondary" size="small" />
                <Typography variant="subtitle1" color={paymentSelectState === "kakao" ? "black.200" : "black.600"}>
                  카카오페이
                </Typography>
              </PaymentButtonBox>
            </CustomButtonBase>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item rowGap={2.6}>
        <Grid container item>
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight={700} color="black.300">
              이용약관
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <AgreementAllCheckBoxSet checkedState={agreementAllChkState} checkEvent={(event) => agreementAllChkEvent(event)} />
            <GreyBorderGrid container item>
              <SubscribeAgreementCheckBoxSet checkedState={agreementChkState.subscribe} checkEvent={(event) => agreementChkEvent("subscribe", event)} title="[필수] 멤버십 자동 결제에 동의합니다." />
              <PolicyAgreementCheckBoxSet checkedState={agreementChkState.policy} checkEvent={(event) => agreementChkEvent("policy", event)} title="[필수] 멤버십 정책에 동의합니다." modalOpenEvent={() => termsDialogOpenFunc()} />
            </GreyBorderGrid>
          </Grid>
        </Grid>
        <GreyGridItem container item rowGap={1}>
          <Grid item xs={12}>
            <Typography variant="body2" fontWeight={600} component="p" lineHeight="21px" color="black.600">
              유의사항
            </Typography>
          </Grid>
          <Grid container item xs={12} rowGap={0.5}>
            <Grid container item columnGap={1}>
              <Grid item xs="auto">
                <Typography variant="caption1" lineHeight="20px" color="#5E5E5E">
                  -
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption1" component="p" lineHeight="20px" color="#5E5E5E">
                  제품 가이드라인에 위반되는 프로젝트의 경우 담당자의 동의 없이 취소될 수 있습니다.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item columnGap={1}>
              <Grid item xs="auto">
                <Typography variant="caption1" lineHeight="20px" color="#5E5E5E">
                  -
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption1" component="p" lineHeight="20px" color="#5E5E5E">
                  자동결제는 이용기간 종료일에 결제됩니다.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item columnGap={1}>
              <Grid item xs="auto">
                <Typography variant="caption1" lineHeight="20px" color="#5E5E5E">
                  -
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption1" component="p" lineHeight="20px" color="#5E5E5E">
                  자동결제 갱신은 이용기간 종료 하루 전까지 가능합니다.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item columnGap={1}>
              <Grid item xs="auto">
                <Typography variant="caption1" lineHeight="20px" color="#5E5E5E">
                  -
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption1" component="p" lineHeight="20px" color="#5E5E5E">
                  결제 후 7일간 이용 내역이 없는 경우 전액 환불이 가능합니다.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item columnGap={1}>
              <Grid item xs="auto">
                <Typography variant="caption1" lineHeight="20px" color="#5E5E5E">
                  -
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption1" component="p" lineHeight="20px" color="#5E5E5E">
                  결제 취소는 고객센터 문의를 통해 신청하실 수 있습니다.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item columnGap={1}>
              <Grid item xs="auto">
                <Typography variant="caption1" lineHeight="20px" color="#5E5E5E">
                  -
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption1" component="p" lineHeight="20px" color="#5E5E5E">
                  결제 수단의 정지, 한도 초과 등의 사유로 결제가 불가능한 경우 정기 결제가 취소되며, 다시 정기 결제를 신청해 서비스를 이용하실 수 있습니다.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item columnGap={1}>
              <Grid item xs="auto">
                <Typography variant="caption1" lineHeight="20px" color="#5E5E5E">
                  -
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption1" component="p" lineHeight="20px" color="#5E5E5E">
                  멤버십은 타인에게 양도하거나 다른 아이디로 이전할 수 없습니다.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item columnGap={1}>
              <Grid item xs="auto">
                <Typography variant="caption1" lineHeight="20px" color="#5E5E5E">
                  -
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption1" component="p" lineHeight="20px" color="#5E5E5E">
                  미성년자가 법정 대리인의 동의 없이 계약을 체결한 경우, 미성년자 또는 법정 대리인이 이를 취소할 수 있습니다.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </GreyGridItem>
      </Grid>
      <Grid item xs={12}>
        <SubmitButtonBase disabled={!agreementAllChkState} onClick={() => submitFunc()}>
          <SubmitButtonTypo agreementAllChkState={agreementAllChkState} variant="subtitle1" lineHeight="28px" color="#ffffff" align="center">
            멤버십 결제
          </SubmitButtonTypo>
        </SubmitButtonBase>
      </Grid>
    </Grid>
  );
}
