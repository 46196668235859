import React from "react";
import RequireAuthRoute from "../../routes/requireAuth";

import UniversalAppBar from "../../Block/Appbar/UniversalAppBar";
import UniversalAppFooter from "../../Block/Footer/UniversalAppFooter";

// import MembershipInfo from "../../containers/Service/Membership/MembershipInfoContainer";
import MembershipInfo from "../../containers/Service/Membership/ProductSelectMembershipInfoContainer";

export default function MembershipInfoView() {
  return (
    <React.Fragment>
      <UniversalAppBar />
      <RequireAuthRoute Component={MembershipInfo} />
      <UniversalAppFooter />
    </React.Fragment>
  );
}
