import React from "react";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";

import NaverBlogTag, { NaverPostTag, InstagramTag, YoutubeTag, ProjectTypeTag } from "../../components/ProjectComponent";

import { ThumbnailBox } from "../../components/ProjectComponent";

const CustomDivider = styled(Divider)({
  borderColor: "#333333",
});

// 글자 수 제한 및 ... 표시
const ListTitleTypo = styled(Typography)({
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
});

const ButtonTableCell = styled(TableCell)({
  cursor: "pointer",
});

const CustomPagination = styled(Pagination)({
  "& .Mui-selected": {
    backgroundColor: "#ffffff",
    fontWeight: 700,
  },
});

// 지원한 프로젝트 리스트
export default function AppliedProjectContents(props) {
  const { projectListValue, tableRowClickFunc, tableDataPageState, tableDataPageChange } = props;

  // 테이블 페이지 당 데이터 수
  const dataPerPage = 10;

  // 프로젝트 리스트
  const ProjectList = () => {
    return (
      <Grid container justifyContent="center" rowSpacing={4.8}>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableBody>
                {Object.keys(projectListValue)
                  .slice((tableDataPageState - 1) * dataPerPage, (tableDataPageState - 1) * dataPerPage + dataPerPage)
                  .map((data, index) => {
                    return (
                      <TableRow key={"AppliedProject" + index} onClick={() => tableRowClickFunc(data)}>
                        <ButtonTableCell align="center">
                          <ThumbnailBox thumbnailUrl={projectListValue[data].thumbnail} />
                        </ButtonTableCell>
                        <ButtonTableCell align="center" width="70%">
                          <ListTitleTypo variant="body1" lineHeight="28px" align="left">
                            {projectListValue[data].title}
                          </ListTitleTypo>
                        </ButtonTableCell>
                        <TableCell align="center" width="15%">
                          {projectListValue[data].channel === "naverBlog" ? <NaverBlogTag /> : projectListValue[data].channel === "naverPost" ? <NaverPostTag /> : projectListValue[data].channel === "instagram" ? <InstagramTag /> : projectListValue[data].channel === "youtube" ? <YoutubeTag /> : null}
                        </TableCell>
                        <TableCell align="center" width="15%">
                          {projectListValue[data].type === "delivery" ? <ProjectTypeTag projectType="배송형" /> : projectListValue[data].type === "purchase" ? <ProjectTypeTag projectType="구매형" /> : projectListValue[data].type === "visit" ? <ProjectTypeTag projectType="방문형" /> : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item>
          <CustomPagination shape="rounded" count={Math.ceil(Object.keys(projectListValue).length / dataPerPage)} page={tableDataPageState} onChange={tableDataPageChange} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container item rowGap={3}>
      <Grid item xs={12}>
        <Typography variant="h5" lineHeight="24px" color="#333333">
          신청 프로젝트
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomDivider />
      </Grid>
      {ProjectList()}
    </Grid>
  );
}
