import React from "react";

import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const CustomGridContainer = styled(Grid)({
  minHeight: "100vh",
  padding: "120px 0 90px",
});

const MyPageContentsGridContainer = styled(Grid)({
  padding: "0 58px",
});

// 개인 회원 마이페이지 frame
export default function MyPageBodyFrame(props) {
  const { menu, menuUpper, contents, title } = props;

  return (
    <Container maxWidth="lg">
      <CustomGridContainer container rowGap={5}>
        <Grid container item xs={12}>
          {menuUpper}
        </Grid>
        <Grid container item direction="column" xs={2.8}>
          {menu}
        </Grid>
        <MyPageContentsGridContainer container item xs={9.2} rowSpacing={5.2} minHeight="80vh" alignContent="baseline">
          <Grid container item>
            <Grid item xs={12}>
              <Typography variant="h3" fontWeight={700} color="black.300">
                {title}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item>
            {contents}
          </Grid>
        </MyPageContentsGridContainer>
      </CustomGridContainer>
    </Container>
  );
}
