import React from "react";

import { styled } from "@mui/material/styles";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";

const LoginButton = styled(Button)({
  height: 60,
  borderRadius: 10,
  boxShadow: "none",
});

const DeactivatedButton = styled(Button)({
  height: 60,
  backgroundColor: "#d9d9d9",
  border: "1px solid #cccccc",
  borderRadius: 10,
  boxShadow: "none",
});

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.grey[300],
}));

// auth 화면에서 사용하는 버튼
export default function AuthButton(props) {
  const { loadState, disabled, activated, onClickEvent, buttonText } = props;

  if (activated) {
    if (loadState) {
      return (
        <LoginButton variant="contained" color="secondary" fullWidth>
          <CircularProgress />
        </LoginButton>
      );
    } else {
      return (
        <LoginButton variant="contained" color="secondary" fullWidth disabled={disabled} onClick={() => onClickEvent()}>
          <Typography variant="subtitle1" fontWeight={500} color="#ffffff">
            {buttonText}
          </Typography>
        </LoginButton>
      );
    }
  } else {
    if (loadState) {
      return (
        <DeactivatedButton variant="contained" color="secondary" fullWidth>
          <CircularProgress />
        </DeactivatedButton>
      );
    } else {
      return (
        <DeactivatedButton variant="contained" fullWidth disabled={disabled} onClick={() => onClickEvent()}>
          <Typography variant="subtitle1" fontWeight={500} color="#ffffff">
            {buttonText}
          </Typography>
        </DeactivatedButton>
      );
    }
  }
}

// auth 화면에서 사용하는 체크박스
export function AuthCheckbox(props) {
  const { checkedState, checkEvent } = props;

  return <CustomCheckbox variant="contained" color="secondary" checked={checkedState} onChange={(event) => checkEvent(event)} />;
}
