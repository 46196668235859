import React, { useState, useRef, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery, useMutation, useQueryClient } from "react-query";

import { RequestReadDefaultUserProfile, RequestUpdateDefaultUserProfile, RequestProfilePhotoUrl, RequestUpload, RequestProfileProjectPhotoUrl } from "../../../utils/requestToSrv";

import UpdatePortfolioBodyFrame from "../../../Block/MyPage/UpdatePortfolioBodyFrame";

import { UpdatePortfolioContents } from "../../../Block/MyPage/UpdatePortfolioContents";
import UpdatePortfolioSaveFixedBox from "../../../Block/MyPage/UpdatePortfolioSaveFixedBox";
import DialogWithButton, { DialogWithOneButton, DialogWithTwoButton } from "../../../components/DialogWithButton";
import { DialogAlertMessage } from "../../../components/DialogMessage";

// 포트폴리오 수정
export default function UpdatePortfolioContainer() {
  // react-router-dom navigate
  const navigate = useNavigate();
  // react-query queryClient
  const queryClient = useQueryClient();

  // 각 textField를 위한 ref
  const nameRef = useRef(null);
  const introduceRef = useRef(null);
  const instagramRef = useRef(null);
  const naverBlogRef = useRef(null);
  const naverPostRef = useRef(null);
  const youtubeRef = useRef(null);
  const project1Ref = useRef(null);
  const project1ThumbnailRef = useRef(null);
  const project2Ref = useRef(null);
  const project2ThumbnailRef = useRef(null);
  const project3Ref = useRef(null);
  const project3ThumbnailRef = useRef(null);

  // 이미지 파일 업로드 input ref
  const fileUploadRef = useRef(null);

  // 포트폴리오 value state
  const [myProfileValueState, myProfileValueSetState] = useState({
    name: "",
    profilePhotoUrl: "",
    introduction: "",
    instagram: "",
    naverBlog: "",
    naverPost: "",
    youtube: "",
    project1: "",
    project1Thumbnail: "",
    project2: "",
    project2Thumbnail: "",
    project3: "",
    project3Thumbnail: "",
  });

  // 포트폴리오 data load - update 이후
  const portfolioData = useQuery("userPortfolio", RequestReadDefaultUserProfile, {
    enabled: false,
    onSuccess: (data) => {
      myProfileValueSetState({
        name: data.name,
        profilePhotoUrl: data.profilePhotoUrl,
        introduction: data.introduction,
        instagram: data.sns.instagram,
        naverBlog: data.sns.naverBlog,
        naverPost: data.sns.naverPost,
        youtube: data.sns.youtube,
        project1: data.project.project1.url,
        project1Thumbnail: data.project.project1.image,
        project2: data.project.project2.url,
        project2Thumbnail: data.project.project2.image,
        project3: data.project.project3.url,
        project3Thumbnail: data.project.project3.image,
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 화면 로드 시 맨 위로 이동
  // 포트폴리오 data가 있으면 state에 저장, 없으면 서버에서 가져옴
  // 컴포넌트 unmount 시 blob url revoke
  useEffect(() => {
    window.scrollTo(0, 0);

    if (queryClient.getQueryData("userPortfolio")) {
      myProfileValueSetState({
        name: queryClient.getQueryData("userPortfolio").name,
        profilePhotoUrl: queryClient.getQueryData("userPortfolio").profilePhotoUrl,
        introduction: queryClient.getQueryData("userPortfolio").introduction,
        instagram: queryClient.getQueryData("userPortfolio").sns.instagram,
        naverBlog: queryClient.getQueryData("userPortfolio").sns.naverBlog,
        naverPost: queryClient.getQueryData("userPortfolio").sns.naverPost,
        youtube: queryClient.getQueryData("userPortfolio").sns.youtube,
        project1: queryClient.getQueryData("userPortfolio").project.project1.url,
        project1Thumbnail: queryClient.getQueryData("userPortfolio").project.project1.image,
        project2: queryClient.getQueryData("userPortfolio").project.project2.url,
        project2Thumbnail: queryClient.getQueryData("userPortfolio").project.project2.image,
        project3: queryClient.getQueryData("userPortfolio").project.project3.url,
        project3Thumbnail: queryClient.getQueryData("userPortfolio").project.project3.image,
      });
    } else {
      portfolioData.refetch();
    }

    return () => {
      window.URL.revokeObjectURL(blobImgSrcState.profileImage);
      window.URL.revokeObjectURL(blobImgSrcState.project1);
      window.URL.revokeObjectURL(blobImgSrcState.project2);
      window.URL.revokeObjectURL(blobImgSrcState.project3);
    };
  }, []);

  // 포트폴리오 value state 변경 함수
  const myProfileValueChange = (key, event) => {
    myProfileValueSetState((prevState) => ({
      ...prevState,
      [key]: event.target.value,
    }));
  };

  // blob 데이터 state
  const [blobDictState, setBlobDictState] = useState(new Object());
  // blob 이미지 src 변환 state
  const [blobImgSrcState, blobImgSrcSetState] = useState({
    profileImage: "",
    project1: "",
    project2: "",
    project3: "",
  });

  // 프로필 이미지 변경 버튼 클릭 event
  const changeProfileImgClickFunc = () => {
    fileUploadRef.current.click();
  };

  // 대표 프로젝트 썸네일 이미지 변경 버튼 클릭 event
  const changeThumbnailClickFunc = (projectNum) => {
    if (projectNum === "project1") {
      project1ThumbnailRef.current.click();
    } else if (projectNum === "project2") {
      project2ThumbnailRef.current.click();
    } else if (projectNum === "project3") {
      project3ThumbnailRef.current.click();
    }
  };

  // 프로필 이미지 get upload url
  const profilePhotoUrl = useQuery("profilePictureUrl", () => RequestProfilePhotoUrl(fileUploadRef.current.files[0].name), {
    enabled: false,
    onSuccess: (data) => {
      myProfileValueSetState((prevState) => ({ ...prevState, profilePhotoUrl: "https://storage.googleapis.com/project-reble-dev/profile/" + queryClient.getQueryData("userInfo").uid + "/profilePhoto/" + fileUploadRef.current.files[0].name }));
      setBlobDictState((prevState) => ({
        ...prevState,
        profileImage: {
          url: data,
          blob: fileUploadRef.current.files[0],
        },
      }));
      blobImgSrcSetState((prevState) => ({ ...prevState, profileImage: window.URL.createObjectURL(fileUploadRef.current.files[0]) }));
    },
  });

  // 대표 프로젝트 1 썸네일 이미지 get upload url
  const project1ThumbnailUrl = useQuery("project1ThumbnailUrl", () => RequestProfileProjectPhotoUrl(project1ThumbnailRef.current.files[0].name), {
    enabled: false,
    onSuccess: (data) => {
      myProfileValueSetState((prevState) => ({ ...prevState, project1Thumbnail: "https://storage.googleapis.com/project-reble-dev/profile/" + queryClient.getQueryData("userInfo").uid + "/profileProject/" + project1ThumbnailRef.current.files[0].name }));
      setBlobDictState((prevState) => ({
        ...prevState,
        project1: {
          url: data,
          blob: project1ThumbnailRef.current.files[0],
        },
      }));
      blobImgSrcSetState((prevState) => ({ ...prevState, project1: window.URL.createObjectURL(project1ThumbnailRef.current.files[0]) }));
    },
  });

  // 대표 프로젝트 2 썸네일 이미지 get upload url
  const project2ThumbnailUrl = useQuery("project2ThumbnailUrl", () => RequestProfileProjectPhotoUrl(project2ThumbnailRef.current.files[0].name), {
    enabled: false,
    onSuccess: (data) => {
      myProfileValueSetState((prevState) => ({ ...prevState, project2Thumbnail: "https://storage.googleapis.com/project-reble-dev/profile/" + queryClient.getQueryData("userInfo").uid + "/profileProject/" + project2ThumbnailRef.current.files[0].name }));
      setBlobDictState((prevState) => ({
        ...prevState,
        project2: {
          url: data,
          blob: project2ThumbnailRef.current.files[0],
        },
      }));
      blobImgSrcSetState((prevState) => ({ ...prevState, project2: window.URL.createObjectURL(project2ThumbnailRef.current.files[0]) }));
    },
  });

  // 대표 프로젝트 3 썸네일 이미지 get upload url
  const project3ThumbnailUrl = useQuery("project3ThumbnailUrl", () => RequestProfileProjectPhotoUrl(project3ThumbnailRef.current.files[0].name), {
    enabled: false,
    onSuccess: (data) => {
      myProfileValueSetState((prevState) => ({ ...prevState, project3Thumbnail: "https://storage.googleapis.com/project-reble-dev/profile/" + queryClient.getQueryData("userInfo").uid + "/profileProject/" + project3ThumbnailRef.current.files[0].name }));
      setBlobDictState((prevState) => ({
        ...prevState,
        project3: {
          url: data,
          blob: project3ThumbnailRef.current.files[0],
        },
      }));
      blobImgSrcSetState((prevState) => ({ ...prevState, project3: window.URL.createObjectURL(project3ThumbnailRef.current.files[0]) }));
    },
  });

  // alert dialog message text state
  const [alertDialogMessageState, alertDialogMessageSetState] = useState("");
  // alert dialog message custom hook
  const { alertDialogCall, DialogAlertComponent } = DialogAlertMessage(alertDialogMessageState);

  // 프로필 이미지 변경 event
  const changeProfileImgFunc = () => {
    if (fileUploadRef.current.files[0]) {
      if (!fileUploadRef.current.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
        alertDialogMessageSetState("파일 형식을 확인해주세요(jpg, jpeg, png, gif)");
        alertDialogCall();
        return false;
      } else {
        profilePhotoUrl.refetch();
      }
    } else {
      alertDialogMessageSetState("파일 선택을 취소하셨습니다.");
      alertDialogCall();
    }
  };

  // 대표 프로젝트 썸네일 이미지 변경 event
  const changeThumbnailFunc = (projectNum) => {
    if (projectNum === "project1") {
      if (project1ThumbnailRef.current.files[0]) {
        if (!project1ThumbnailRef.current.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
          alertDialogMessageSetState("파일 형식을 확인해주세요(jpg, jpeg, png, gif)");
          alertDialogCall();
          return false;
        } else {
          project1ThumbnailUrl.refetch();
        }
      } else {
        alertDialogMessageSetState("파일 선택을 취소하셨습니다.");
        alertDialogCall();
      }
    } else if (projectNum === "project2") {
      if (project2ThumbnailRef.current.files[0]) {
        if (!project2ThumbnailRef.current.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
          alertDialogMessageSetState("파일 형식을 확인해주세요(jpg, jpeg, png, gif)");
          alertDialogCall();
          return false;
        } else {
          project2ThumbnailUrl.refetch();
        }
      } else {
        alertDialogMessageSetState("파일 선택을 취소하셨습니다.");
        alertDialogCall();
      }
    } else if (projectNum === "project3") {
      if (project3ThumbnailRef.current.files[0]) {
        if (!project3ThumbnailRef.current.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
          alertDialogMessageSetState("파일 형식을 확인해주세요(jpg, jpeg, png, gif)");
          alertDialogCall();
          return false;
        } else {
          project3ThumbnailUrl.refetch();
        }
      } else {
        alertDialogMessageSetState("파일 선택을 취소하셨습니다.");
        alertDialogCall();
      }
    }
  };

  // 프로필 이미지 delete event
  const deleteProfileImgFunc = () => {
    setBlobDictState(new Object());
    window.URL.revokeObjectURL(blobImgSrcState.profileImage);
    blobImgSrcSetState((prevState) => ({ ...prevState, profileImage: "" }));
    myProfileValueSetState((prevState) => ({ ...prevState, profilePhotoUrl: "" }));
  };

  // 프로젝트 썸네일 delete event
  const deleteProjectFunc = (projectNum) => {
    setBlobDictState(new Object());
    window.URL.revokeObjectURL(blobImgSrcState[projectNum]);
    blobImgSrcSetState((prevState) => ({ ...prevState, [projectNum]: "" }));
    myProfileValueSetState((prevState) => ({ ...prevState, [projectNum + "Thumbnail"]: "", [projectNum]: "" }));
  };

  // 프로필 수정 중 페이지 이동 시 출력할 dialog open state
  const [exitProfileDialogOpenState, exitProfileDialogOpenSetState] = useState(false);
  // 프로젝트 삭제 dialog open state
  const [deleteProjectDialogOpenState, deleteProjectDialogOpenSetState] = useState(false);
  // 삭제할 프로젝트 번호 state
  const [deleteProjectNumState, deleteProjectNumSetState] = useState("");

  // 프로필 수정 중 페이지 이동 시 출력할 dialog open event
  const exitProfileDialogOpenEvent = () => {
    exitProfileDialogOpenSetState(true);
  };

  // 프로필 수정 중 페이지 이동 시 출력할 dialog close event
  const exitProfileDialogCloseEvent = () => {
    exitProfileDialogOpenSetState(false);
  };

  // 프로젝트 삭제 dialog open event
  const deleteProjectDialogOpenEvent = (projectNum) => {
    deleteProjectDialogOpenSetState(true);
    deleteProjectNumSetState(projectNum);
  };

  // 프로젝트 삭제 dialog close event
  const deleteProjectDialogCloseEvent = () => {
    deleteProjectDialogOpenSetState(false);
  };

  // 포트폴리오 저장 완료 dialog open state
  const [oneButtonDialogOpenState, oneButtonDialogOpenSetState] = useState(false);

  // 포트폴리오 저장 완료 dialog open event
  const oneButtonDialogOpenEvent = () => {
    oneButtonDialogOpenSetState(true);
  };

  // 포트폴리오 저장 완료 dialog close event
  // const oneButtonDialogCloseEvent = () => {
  //   oneButtonDialogOpenSetState(false);
  // };

  // 포트폴리오 update function
  const updateMyProfile = useMutation(
    () => {
      RequestUpdateDefaultUserProfile({
        name: myProfileValueState.name,
        profilePhotoUrl: myProfileValueState.profilePhotoUrl,
        introduction: myProfileValueState.introduction,
        sns: { instagram: myProfileValueState.instagram, naverBlog: myProfileValueState.naverBlog, naverPost: myProfileValueState.naverPost, youtube: myProfileValueState.youtube },
        project: { project1: { image: myProfileValueState.project1Thumbnail, url: myProfileValueState.project1 }, project2: { image: myProfileValueState.project2Thumbnail, url: myProfileValueState.project2 }, project3: { image: myProfileValueState.project3Thumbnail, url: myProfileValueState.project3 } },
      });
    },
    {
      onSuccess: () => {
        oneButtonDialogOpenEvent();
        portfolioData.refetch();
      },
      onError: (error) => {
        alertDialogMessageSetState("프로필 저장 실패");
        alertDialogCall();
      },
    }
  );

  // 파일 업로드 이벤트
  const fileUploadFunc = async () => {
    Object.keys(blobDictState).map((key) => {
      RequestUpload({
        formData: blobDictState[key].blob,
        signedUrl: blobDictState[key].url,
      });
    });
  };

  // 포트폴리오 저장 submit function
  const profileSaveEvent = () => {
    if (Object.keys(blobDictState).length > 0) {
      fileUploadFunc().then(updateMyProfile.mutate());
    } else {
      updateMyProfile.mutate();
    }
  };

  // 프로필 수정 중 페이지 이동 function
  const exitProfileEvent = () => {
    exitProfileDialogCloseEvent();
    navigate("/my-page");
  };

  // 프로젝트 삭제 function
  const deleteProjectEvent = (projectNum) => {
    deleteProjectDialogCloseEvent();
    deleteProjectFunc(projectNum);
  };

  // enter key press event
  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      profileSaveEvent();
    }
  };

  // 프로필 저장 완료 후 페이지 이동 function
  const profileSaveFinishEvent = () => {
    setTimeout(() => {
      navigate("/my-portfolio");
    }, 1000);
  };

  // ref 묶음
  const refSet = { fileUploadRef: fileUploadRef, nameRef: nameRef, introduceRef: introduceRef, instagramRef: instagramRef, naverBlogRef: naverBlogRef, naverPostRef: naverPostRef, youtubeRef: youtubeRef, project1Ref: project1Ref, project1ThumbnailRef: project1ThumbnailRef, project2Ref: project2Ref, project2ThumbnailRef: project2ThumbnailRef, project3Ref: project3Ref, project3ThumbnailRef: project3ThumbnailRef };

  // 포트폴리오 수정 화면
  return (
    <Fragment>
      <UpdatePortfolioBodyFrame myPageEvent={() => exitProfileDialogOpenEvent()} MyPortfolioRightContents={<UpdatePortfolioContents ref={refSet} myProfileValueState={myProfileValueState} blobImgSrcState={blobImgSrcState} myProfileValueChange={myProfileValueChange} changeProfileImgClickFunc={changeProfileImgClickFunc} changeProfileImgFunc={changeProfileImgFunc} deleteProfileImgFunc={deleteProfileImgFunc} onKeyPress={onKeyPress} deleteProjectEvent={deleteProjectDialogOpenEvent} changeThumbnailClickFunc={changeThumbnailClickFunc} changeThumbnailFunc={changeThumbnailFunc} />} />
      <UpdatePortfolioSaveFixedBox onClickEvent={profileSaveEvent} />
      <DialogWithTwoButton width={554} icon={"notice"}  message={["작성중인 내용이 있습니다", <br />, "저장하지 않고 나가시겠습니까?"]} dialogOpenState={exitProfileDialogOpenState} dialogClose={exitProfileDialogCloseEvent} button1Text="취소" button2Text="확인" button1Func={exitProfileDialogCloseEvent} button2Func={exitProfileEvent} />
      <DialogWithTwoButton width={554} message={["해당 프로젝트에 등록한", <br />, "썸네일과 URL을 삭제하시겠습니까?"]} dialogOpenState={deleteProjectDialogOpenState} dialogClose={deleteProjectDialogCloseEvent} button1Text="취소" button2Text="확인" button1Func={deleteProjectDialogCloseEvent} button2Func={() => deleteProjectEvent(deleteProjectNumState)} />
      <DialogWithOneButton width={380} message="프로필 저장 완료" dialogOpenState={oneButtonDialogOpenState} buttonText="확인" buttonFunc={profileSaveFinishEvent} />
      <DialogAlertComponent />
    </Fragment>
  );
}
