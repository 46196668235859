import React, { Fragment, useState, useRef, useEffect } from "react";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { ProjectListContents } from "../../../Block/Project/ProjectListContents";
import { DialogAlertMessage } from "../../../components/DialogMessage";

import { RequestProjectList } from "../../../utils/requestToSrv";

// 프로젝트 리스트 페이지
export default function ProjectListContainer() {
  const fetchingBoxRef = useRef(null);

  const projectList = useInfiniteQuery("projectList", ({ pageParam = 1 }) => RequestProjectList(filterState, sortingOptionState, pageParam), {
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = allPages.length + 1;
      return nextPage;
    },
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1].length === 0) {
        fetchingBoxObserver.unobserve(fetchingBoxRef.current);
      } else {
        fetchingBoxObserver.observe(fetchingBoxRef.current);
      }
    },
  });

  const fetchingBoxInterCallback = ([entry], observer) => {
    if (entry.isIntersecting) {
      observer.unobserve(fetchingBoxRef.current);
      projectList.fetchNextPage();
    }
  };

  const observerOption = {
    threshold: 1,
  };

  const fetchingBoxObserver = new IntersectionObserver(fetchingBoxInterCallback, observerOption);

  // 화면 로드 시 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchingBoxObserver.observe(fetchingBoxRef.current);
  }, []);

  // react-router-dom navigate
  const navigate = useNavigate();
  // react-query queryClient
  const queryClient = useQueryClient();

  // alert dialog message custom hook
  const { alertDialogCall, DialogAlertComponent } = DialogAlertMessage("1개 이상 선택해주세요.");

  // 유형 필터 버튼 ref
  const typeFilterButtonRef = useRef(null);
  // 채널 필터 버튼 ref
  const channelFilterButtonRef = useRef(null);
  // 정렬 버튼 ref
  const sortingButtonRef = useRef(null);

  // 필터링 적용 state
  const [filterState, filterSetState] = useState({
    typeFilterItems: ["delivery", "visit", "purchase"],
    channelFilterItems: ["instagram", "naverBlog", "naverPost", "youtube"],
    exceptClosedProject: false,
  });

  // 정렬 옵션 state
  const [sortingOptionState, sortingOptionSetState] = useState("registrationDate");

  // 프로젝트 리스트 data load
  // const projectList = useQuery("projectList", () => RequestProjectList(filterState, sortingOptionState), {
  //   onError: (error) => {
  //     console.log(error);
  //   },
  // });

  // 종료된 프로젝트 제외 function
  const closedProjectSetFunc = async () => {
    filterSetState((prevState) => ({ ...prevState, exceptClosedProject: !prevState.exceptClosedProject }));
  };

  // 종료된 프로젝트 제외 버튼 클릭 function
  const closedProjectBtnClickFunc = () => {
    closedProjectSetFunc().then(() => {
      projectList.refetch().then(() => {
        filteringMenuCloseFunc();
      });
    });
  };

  // ref 묶음
  const refSet = { typeFilterButtonRef, channelFilterButtonRef, sortingButtonRef, fetchingBoxRef };

  // 프로젝트 아이템 클릭 function
  const projectItemClickFunc = (projectId, index, dataIndex) => {
    queryClient.setQueryData("projectDetailInfo", projectList.data.pages[index][dataIndex].document);
    navigate("/project-detail/" + projectId);
  };

  // 활성화 된 필터링 메뉴 state
  const [openedFilteringMenuState, openedFilteringMenuSetState] = useState("");

  // 필터링 메뉴 버튼 클릭 function
  const filteringMenuOpenFunc = (filteringMenuName) => {
    openedFilteringMenuSetState(filteringMenuName);
  };

  // 필터링 메뉴 닫기 function
  const filteringMenuCloseFunc = () => {
    openedFilteringMenuSetState("");
  };

  // 필터 메뉴 적용 function
  const typeMenuSetFunc = async (item) => {
    if (item === "all") {
      filterSetState((prevState) => ({ ...prevState, typeFilterItems: ["delivery", "visit", "purchase"] }));
    } else {
      filterSetState((prevState) => ({ ...prevState, typeFilterItems: [item] }));
    }
  };

  // 유형 필터 메뉴 아이템 클릭 function
  const typeMenuSelectFunc = (item) => {
    typeMenuSetFunc(item).then(() => {
      projectList.refetch().then(() => {
        filteringMenuCloseFunc();
      });
    });
  };

  // 채널 필터 메뉴 아이템 클릭 function
  const channelMenuSelectFunc = async (item) => {
    if (filterState.channelFilterItems.includes(item)) {
      const tempArr = filterState.channelFilterItems.filter((typeItem) => typeItem !== item);
      filterSetState((prevState) => ({ ...prevState, channelFilterItems: [...tempArr] }));
    } else {
      filterSetState((prevState) => ({ ...prevState, channelFilterItems: [...prevState.channelFilterItems, item] }));
    }
  };

  // 채널 필터 메뉴 닫기 function
  const channelMenuCloseFunc = () => {
    if (filterState.channelFilterItems.length === 0) {
      alertDialogCall();
    } else {
      projectList.refetch().then(() => {
        filteringMenuCloseFunc();
      });
    }
  };

  // 정렬 메뉴 아이템 클릭 function
  const sortingOpenSetFunc = async (item) => {
    sortingOptionSetState(item);
  };

  // 정렬 메뉴 아이템 클릭 function
  const sortingMenuSelectFunc = (item) => {
    sortingOpenSetFunc(item).then(() => {
      projectList.refetch().then(() => {
        filteringMenuCloseFunc();
      });
    });
  };

  // 프로젝트 리스트 화면
  return (
    <Fragment>
      <ProjectListContents ref={refSet} projectListValues={projectList.data} dataLoadingState={projectList.isFetching} dataLoadedState={projectList.isFetched} projectItemClickFunc={projectItemClickFunc} filterState={filterState} sortingOptionState={sortingOptionState} closedProjectBtnClickFunc={closedProjectBtnClickFunc} openedFilteringMenuState={openedFilteringMenuState} filteringMenuOpenFunc={filteringMenuOpenFunc} filteringMenuCloseFunc={filteringMenuCloseFunc} typeMenuSelectFunc={typeMenuSelectFunc} channelMenuSelectFunc={channelMenuSelectFunc} channelMenuCloseFunc={channelMenuCloseFunc} sortingMenuSelectFunc={sortingMenuSelectFunc} />
      <DialogAlertComponent />
    </Fragment>
  );
}
