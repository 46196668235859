import React, { useState, Fragment, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useMutation, useQuery } from "react-query";

import { RequestProjectDetail, RequestProjectApplicantsList, RequestProjectAppointersList, DeleteProject } from "../../utils/adminRequestToSrv";

import AdminBodyFrame from "../../Block/Admin/AdminBodyFrame";
import AdminRecruitingProjectUpperTable, { AdminRecruitingProjectBelowTable } from "../../Block/Admin/AdminRecruitingProjectDetailContents";

import LoadingComponent from "../../components/LoadingComponent";
import DialogMessage from "../../components/DialogMessage";
import DialogWithButton from "../../components/DialogWithButton";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// 모집 중 프로젝트 상세 페이지
export default function RecruitingProjectDetailContainer() {
  // 프로젝트 doc id url 파라미터
  const { targetDocId } = useParams();
  // react-router-dom navigate
  const navigate = useNavigate();

  // 프로젝트 data load
  const projectDetailInfo = useQuery(["projectDetailInfo", targetDocId], () => RequestProjectDetail(targetDocId), {
    onError: (error) => {
      console.log(error);
    },
  });

  // 프로젝트 신청자 data load
  const applicantsList = useQuery(["applicantsList", targetDocId], () => RequestProjectApplicantsList(targetDocId), {
    onError: (error) => console.log(error),
  });

  // 프로젝트 선정자 data load
  const appointersList = useQuery(["appointersList", targetDocId], () => RequestProjectAppointersList(targetDocId), {
    onError: (error) => console.log(error),
  });

  // 화면 로드 시 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // 하단 탭 (신청자 / 선정자 선택) state
  const [belowTabSelectState, belowTabSelectSetState] = useState("applicants");

  // 하단 탭 click function
  const belowTabSelectFunc = (tabName) => {
    belowTabSelectSetState(tabName);
  };

  // 프로젝트 상세 페이지 open function
  const projectDetailPageOpenFunc = () => {
    window.open(`/admin-project-detail/${targetDocId}`);
  };

  // 개인 회원 포트폴리오 open function
  const portfolioPageOpenFunc = (userId) => {
    window.open(`/admin-portfolio/${userId}`);
  };

  // sns open function
  const snsOpenFunc = (url) => {
    window.open(url);
  };

  // 신청자 table page state
  const [applicantsPageState, applicantsPageSetState] = useState(1);

  // 선정자 table page state
  const [appointerPageState, appointerPageSetState] = useState(1);

  // 신청자 table page 이동 function
  const applicantsTableDataPageChange = (event, newPage) => {
    applicantsPageSetState(newPage);
  };

  // 선정자 table page 이동 function
  const appointerTableDataPageChange = (event, newPage) => {
    appointerPageSetState(newPage);
  };

  // 프로젝트 취소 후 종료된 프로젝트 이동 function
  const linkToFinishedProject = () => {
    navigate("/admin-finished-project");
  };

  // dialog message text state
  const [dialogMessageState, dialogMessageSetState] = useState("");
  // dialog message custom hook
  const { dialogCall, DialogComponent } = DialogMessage(dialogMessageState, linkToFinishedProject);

  // 프로젝트 삭제 dialog state
  const [projectDeleteDialogOpenState, projectDeleteDialogOpenSetState] = useState(false);

  // 프로젝트 삭제 dialog open function
  const dialogOpenFunc = () => {
    projectDeleteDialogOpenSetState(true);
  };

  // 프로젝트 삭제 dialog close function
  const dialogCloseFunc = () => {
    projectDeleteDialogOpenSetState(false);
  };

  // 프로젝트 삭제 function
  const deleteProject = useMutation(() => DeleteProject(targetDocId), {
    onSuccess: () => {
      projectDeleteDialogOpenSetState(false);
      dialogMessageSetState("프로젝트가 삭제되었습니다.");
      dialogCall();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 프로젝트 삭제 dialog submit function
  const dialogSubmitFunc = () => {
    deleteProject.mutate();
  };

  // 모집 중 프로젝트 상세 페이지 화면
  if (projectDetailInfo.isFetching || applicantsList.isFetching || appointersList.isFetching) {
    return <LoadingComponent />;
  } else {
    return (
      <Fragment>
        <AdminBodyFrame
          contentComponent={
            <Grid container item rowGap={2}>
              <Grid item xs={12}>
                <Typography variant="h5" fontSize={22} lineHeight="28px" color="#333333">
                  모집 중 프로젝트
                </Typography>
              </Grid>
              <AdminRecruitingProjectUpperTable projectDetailValue={projectDetailInfo.data} projectDetailPageOpenFunc={projectDetailPageOpenFunc} />
              <AdminRecruitingProjectBelowTable projectDetailValue={projectDetailInfo.data} applicantsListValue={applicantsList.data} appointersListValue={appointersList.data} belowTabSelectState={belowTabSelectState} belowTabSelectFunc={belowTabSelectFunc} portfolioPageOpenFunc={portfolioPageOpenFunc} snsOpenFunc={snsOpenFunc} applicantsPageState={applicantsPageState} appointerPageState={appointerPageState} applicantsTableDataPageChange={applicantsTableDataPageChange} appointerTableDataPageChange={appointerTableDataPageChange} projectDeleteButtonFunc={dialogOpenFunc} />
            </Grid>
          }
        />
        <DialogComponent />
        <DialogWithButton message={["프로젝트를 삭제하시겠습니까?"]} dialogOpenState={projectDeleteDialogOpenState} dialogClose={dialogCloseFunc} button1Text="취소" button2Text="확인" button1Func={dialogCloseFunc} button2Func={dialogSubmitFunc} />
      </Fragment>
    );
  }
}
