import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import { useRecoilState } from "recoil";
import { emailVarified } from "../../recoil/UserState";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from '@mui/icons-material/Add';

import { ContainedButtonWithComplex } from "../../components/ButtonComponents";


const MenuButton = styled(ButtonBase)(({ page, menu, theme }) => ({
  width: "100%",
  padding: "14px 30px",
  borderRadius: "10px",
  background: page === menu ? theme.palette.info.light : "transparent",
  justifyContent: "flex-start",
}));

const ProjectMenuButton = styled(ButtonBase)(({ active, theme }) => ({
  width: "100%",
  padding: "14px 30px",
  borderRadius: "10px",
  background: active ? theme.palette.info.light : "transparent",
  justifyContent: "flex-start",
}));

const SubMenuButton = styled(ButtonBase)({
  padding: "9px 30px",
  width: "100%",
  borderRadius: "10px",
  justifyContent: "flex-start",
});

// 기업 회원 마이페이지 메뉴 사이드바
export default function MyPageMenuBox(props) {
  const { page } = props;

  // const [emailVarifiedState, emailVarifiedSetState] = useRecoilState(emailVarified);

  const [projectMenuOpenState, projectMenuOpenSetState] = useState(false);

  const projectMenuHandleEvent = () => {
    projectMenuOpenSetState((prevState) => !prevState);
  };

  const navigate = useNavigate();

  const linkToNewProjectEvent = () => {
    navigate("/new-project");
  };

  // 프로젝트 등록 버튼
  const LinkButtonText = () => {
    return (
      <Grid container justifyContent="center" alignItems="center" columnSpacing={2}>
        <Grid item display="flex">
          <AddIcon color="info" />
        </Grid>
        <Grid item>
          <Typography variant="h6" fontWeight={700} color="info.main">
            프로젝트 등록
          </Typography>
        </Grid>
      </Grid>
    );
  };

  // 프로젝트 관리 포함 메뉴
  const projectMenus = ["/recruiting-project", "/ongoing-project", "/finished-project", "/new-project"];

  // 프로젝트 관리 하위 페이지 활성화 여부
  const projectMenuActiveState = projectMenus.includes(page);

  const ProjectManageMenu = () => {
    return (
      <Grid container item rowSpacing={1}>
        <Grid item xs={12}>
          <ProjectMenuButton page={page} active={projectMenuActiveState} onClick={() => projectMenuHandleEvent()}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6" fontWeight={700} color={projectMenuActiveState ? "info.main" : "secondary"}>
                  프로젝트 관리
                </Typography>
              </Grid>
              <Grid item>{projectMenuOpenState || projectMenuActiveState ? <ExpandLessIcon color={projectMenuActiveState ? "info" : "secondary"} /> : <ExpandMoreIcon color={projectMenuActiveState ? "info" : "secondary"} />}</Grid>
            </Grid>
          </ProjectMenuButton>
        </Grid>
        {projectMenuOpenState || projectMenuActiveState ? (
          <Grid container item>
            <Grid item xs={12}>
              <SubMenuButton component={Link} to="/recruiting-project">
                <Typography variant="subtitle1" fontWeight={page === "/recruiting-project" ? 600 : 400} color="#333333">
                  모집중 프로젝트
                </Typography>
              </SubMenuButton>
            </Grid>
            <Grid item xs={12}>
              <SubMenuButton component={Link} to="/ongoing-project">
                <Typography variant="subtitle1" fontWeight={page === "/ongoing-project" ? 600 : 400} color="#333333">
                  진행중 프로젝트
                </Typography>
              </SubMenuButton>
            </Grid>
            <Grid item xs={12}>
              <SubMenuButton component={Link} to="/finished-project">
                <Typography variant="subtitle1" fontWeight={page === "/finished-project" ? 600 : 400} color="#333333">
                  종료된 프로젝트
                </Typography>
              </SubMenuButton>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    );
  };

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <ContainedButtonWithComplex buttonEvent={() => linkToNewProjectEvent()} buttonText={LinkButtonText()} buttonDisabled={false} />
      </Grid>
      <Grid container item rowSpacing={1}>
        <Grid container item>
          {ProjectManageMenu()}
        </Grid>
        <Grid item xs={12}>
          <MenuButton page={page} menu="/brand" component={Link} to="/brand">
            <Typography variant="h6" fontWeight={700} color={page === "/brand" ? "info.main" : "secondary"}>
              브랜드 관리
            </Typography>
          </MenuButton>
        </Grid>
        <Grid item xs={12}>
          <MenuButton page={page} menu="/membership" component={Link} to="/membership">
            <Typography variant="h6" fontWeight={700} color={page === "/membership" ? "info.main" : "secondary"}>
              멤버쉽 관리
            </Typography>
          </MenuButton>
        </Grid>
        <Grid item xs={12}>
          <MenuButton page={page} menu="/my-page" component={Link} to="/my-page">
            <Typography variant="h6" fontWeight={700} color={page === "/my-page" ? "info.main" : "secondary"}>
              계정설정
            </Typography>
          </MenuButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
