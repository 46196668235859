import React from "react";

import AdminBanner from "../../containers/Admin/AdminBannerContainer";
import RequireAuthRoute from "../../routes/requireAuth";

export default function AdminBannerView() {
  return (
    <React.Fragment>
      <RequireAuthRoute Component={AdminBanner} />
    </React.Fragment>
  );
}
