import React, { forwardRef } from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Divider from "@mui/material/Divider";

import { UploadImageBoxComponent } from "../../components/UploadImageBoxComponent";

import { ImageToastUIEditorBlock, ToastUIEditorWithNoImageBlock } from "../Common/ToastUIEditorBlock";
import { TextFieldWithoutLabelSet } from "../Common/TextFieldSet";

import { deleteIconUrl } from "../../configs/urls";

const InfoInnerGrid = styled(Grid)({
  padding: "16px 16px",
});

const DeleteIconButtonBase = styled(ButtonBase)({
  borderRadius: "4px",
});

const SubCustomDivider = styled(Divider)({
  borderColor: "#F2F2F2",
});

// 프로젝트 등록 추가 정보
export const NewProjectAdditionalInfo = forwardRef((props, forwardRef) => {
  const { uid, projectDocId, projectValueState, projectValueChange, onNextKeyPress, changeThumbnailClickFunc, blobImgSrcState, changeThumbnailFunc, deleteThumbnailFunc } = props;
  const { thumbnailRef, uploadedThumbnailRef, detailedImageRef, offeringRef, hashTagsRef, guideRef, visitingInfoRef } = forwardRef;

  return (
    <Grid container item>
      <InfoInnerGrid container item alignItems="center">
        <Grid item xs={2.1}>
          <Typography variant="body1" color="black.400">
            썸네일
          </Typography>
        </Grid>
        {!projectValueState.thumbnail ? (
          <Grid container item xs={9.9} columnSpacing={2} alignItems="center">
            <Grid item xs="auto">
              <UploadImageBoxComponent ref={thumbnailRef} valueState={projectValueState.thumbnail} blobImgSrcState={blobImgSrcState} changeImageButtonFunc={changeThumbnailClickFunc} changeImageFunc={changeThumbnailFunc} size={160} />
            </Grid>
            <Grid item xs alignSelf="flex-end" paddingBottom="10px">
              <Typography variant="body3" component="p" lineHeight="16px" color="#B3B3B3">
                *10MB 이하의 JPG, PNG 파일만 등록 가능합니다.
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container item xs={9.9} columnSpacing={3} alignItems="center">
            <Grid item xs>
              <TextFieldWithoutLabelSet ref={uploadedThumbnailRef} placeholder="파일명" type="text" valueState={projectValueState.thumbnail.substring(projectValueState.thumbnail.lastIndexOf("/") + 1)} readOnly={true} />
            </Grid>
            <Grid item xs="auto">
              <DeleteIconButtonBase onClick={() => deleteThumbnailFunc()}>
                <img src={deleteIconUrl} alt="삭제" />
              </DeleteIconButtonBase>
            </Grid>
          </Grid>
        )}
      </InfoInnerGrid>
      <Grid item xs={12}>
        <SubCustomDivider />
      </Grid>
      <InfoInnerGrid container item alignItems="center">
        <Grid item xs={2.1}>
          <Typography variant="body1" color="black.400">
            상세이미지
          </Typography>
        </Grid>
        <Grid container item xs={9.9}>
          <ImageToastUIEditorBlock ref={detailedImageRef} uid={uid} projectDocId={projectDocId} placeholder={"이미지를 첨부해주세요."} initialValue={" "} editorSize="40vh" />
        </Grid>
      </InfoInnerGrid>
      <Grid item xs={12}>
        <SubCustomDivider />
      </Grid>
      <InfoInnerGrid container item alignItems="center">
        <Grid item xs={2.1}>
          <Typography variant="body1" color="black.400">
            제공항목
          </Typography>
        </Grid>
        <Grid container item xs={9.9}>
          <TextFieldWithoutLabelSet type="text" placeholder="제공하는 제품을 입력해주세요." ref={offeringRef} valueState={projectValueState.offering} setValueEvent={() => projectValueChange("offering", offeringRef.current.value)} onKeyPress={onNextKeyPress} autoFocus={false} inputProps={{ maxLength: 80 }} />
        </Grid>
      </InfoInnerGrid>
      <Grid item xs={12}>
        <SubCustomDivider />
      </Grid>
      <InfoInnerGrid container item alignItems="center">
        <Grid item xs={2.1}>
          <Typography variant="body1" color="black.400">
            해시태그
          </Typography>
        </Grid>
        <Grid container item xs={9.9}>
          <TextFieldWithoutLabelSet type="text" placeholder="해시태그를 입력해주세요. (예: #광고 #on1016)" ref={hashTagsRef} valueState={projectValueState.hashTags} setValueEvent={() => projectValueChange("hashTags", hashTagsRef.current.value)} onKeyPress={onNextKeyPress} autoFocus={false} inputProps={{ maxLength: 80 }} />
        </Grid>
      </InfoInnerGrid>
      <Grid item xs={12}>
        <SubCustomDivider />
      </Grid>
      {projectValueState.type === "visit" ? (
        <>
          <InfoInnerGrid container item alignItems="center">
            <Grid item xs={2.1}>
              <Typography variant="body1" color="black.400">
                방문정보
              </Typography>
            </Grid>
            <Grid container item xs={9.9}>
              <ToastUIEditorWithNoImageBlock ref={visitingInfoRef} placeholder={"방문에 필요한 정보를 입력해주세요.\n예)\n- 주소: 서울시 관악구 낙성대로 10000 레블빌딩 2층\n- 방문 가능 요일, 시간: 월, 화, 수, 목, 금 / 13:00 ~ 17:00\n- 참고사항: 방문 전 유선상으로 예약을 먼저 해주세요."} initialValue={" "} editorSize="40vh" />
            </Grid>
          </InfoInnerGrid>
          <Grid item xs={12}>
            <SubCustomDivider />
          </Grid>
        </>
      ) : null}
      <InfoInnerGrid container item alignItems="center">
        <Grid item xs={2.1}>
          <Typography variant="body1" color="black.400">
            가이드
          </Typography>
        </Grid>
        <Grid container item xs={9.9}>
          <ToastUIEditorWithNoImageBlock ref={guideRef} placeholder="프로젝트 가이드를 입력해주세요." initialValue={" "} editorSize="40vh" />
        </Grid>
      </InfoInnerGrid>
      <Grid item xs={12}>
        <SubCustomDivider />
      </Grid>
    </Grid>
  );
});
