import React from "react";

import AdminIncUsers from "../../containers/Admin/AdminIncUsersContainer";
import RequireAuthRoute from "../../routes/requireAuth";

export default function AdminIncUsersView() {
  return (
    <React.Fragment>
      <RequireAuthRoute Component={AdminIncUsers} />
    </React.Fragment>
  );
}
