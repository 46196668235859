import React from "react";

import UniversalAppBar from "../../Block/Appbar/UniversalAppBar";
import UniversalAppFooter from "../../Block/Footer/UniversalAppFooter";

import ProjectList from "../../containers/Service/Project/ProjectListContainer";

export default function ProjectListView() {
  return (
    <React.Fragment>
      <UniversalAppBar />
      <ProjectList />
      <UniversalAppFooter />
    </React.Fragment>
  );
}
