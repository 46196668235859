import React, { forwardRef } from "react";
import { Link } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import Divider from "@mui/material/Divider";

import { TextFieldWithoutLabelSet, DateTextFieldSetWithoutLabel } from "../Common/TextFieldSet";
import { alertIconUrl, infoIconUrl } from "../../configs/urls";

import MyPageMarketingAgreement from "../MyPage/MyPageMarketingAgreement";

const NoVarifiedGridContainer = styled(Grid)(({ theme }) => ({
  padding: "12px 20px",
  borderRadius: "10px",
  backgroundColor: theme.palette.error.light,
}));

const CustomAdornmentButton = styled(Button)({
  borderRadius: "7px",
  marginLeft: "10px",
});

const PasswordChangeButton = styled(Button)({
  width: "121px",
  height: "48px",
  borderRadius: "10px",
});

const CustomButton = styled(Button)({
  paddingTop: "16px",
  paddingBottom: "16px",
  borderRadius: "10px",
  boxShadow: "none",
});

const WithdrawalTypo = styled(Typography)({
  textDecoration: "underline",
});

const CustomDivider = styled(Divider)({
  borderColor: "#999999",
  paddingTop: "16px",
});

const SubCustomDivider = styled(Divider)({
  borderColor: "#F2F2F2",
});

const CustomGrid = styled(Grid)({
  padding: "0 16px",
});

const LabelGrid = styled(Grid)({
  paddingTop: "19px",
});

const StyledButton = styled(Button)({
  height: "60px",
  borderRadius: "10px"
});

// 기업 회원 마이페이지 화면 구성
export const MyPageContents = forwardRef((props, forwardRef) => {
  const { valueState, setDateValueEvent, setCheckValueEvent, setValueEvent, onEnterKeyPress, emailVerifyButtonFunc, submitEvent, buttonActiveState, emailVarifiedState } = props;
  const { emailRef, nameRef, birthRef, phoneRef, companyNameRef, companyHomepageRef } = forwardRef;

  // end adornment 이메일 인증 버튼
  const emailTextFieldAdornment = (
    <InputAdornment position="end">
      <img src={alertIconUrl} alt="경고" />
      <CustomAdornmentButton variant="contained" color="secondary" onClick={() => emailVerifyButtonFunc()}>
        <Typography color="#ffffff">인증</Typography>
      </CustomAdornmentButton>
    </InputAdornment>
  );

  return (
    <Grid container item rowSpacing={3}>
      {!valueState.authState ? (
        <Grid container item>
          <NoVarifiedGridContainer container item>
            <Grid item xs={2.5} display="flex" alignSelf="baseline" columnGap={0.5}>
              <img src={infoIconUrl} alt="info" width="14px" padding />
              <Typography variant="body3" fontWeight={700} color="error.main" lineHeight="24px">
                가입승인 대기중입니다.
              </Typography>
            </Grid>
            <Grid item xs={9.5}>
              <Typography variant="body3" fontWeight={400} color="error.main" lineHeight="24px">
                안전한 서비스 거래를 위한 가입 승인 절차가 있으며 승인 여부는 영업일 기준 1~2일 소요됩니다.
                <br />
                (가입일: {valueState.signUpDate})
              </Typography>
            </Grid>
          </NoVarifiedGridContainer>
        </Grid>
      ) : null}
      <Grid container item>
        <Grid container item rowSpacing={2.5}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" fontWeight={700} color="#333333">
              회원정보
            </Typography>
            <Grid item xs={12}>
              <CustomDivider />
            </Grid>
          </Grid>
          <Grid container item rowSpacing={3}>
            <CustomGrid container item columnSpacing={1.2}>
              <LabelGrid item xs={2.8}>
                <Typography variant="body1" fontWeight={500} color="black.400">
                  이메일
                </Typography>
              </LabelGrid>
              <Grid container item xs={9.2} columnSpacing={1.2}>
                <Grid item xs={9.7}>
                  <TextFieldWithoutLabelSet type="email" ref={emailRef} valueState={valueState.email} setValueEvent={() => { }} onKeyPress={() => { }} errorState={!emailVarifiedState} caption={!emailVarifiedState ? "인증되지 않은 이메일입니다." : ""} readOnly={true} />
                </Grid>
                <Grid item xs={2.3}>
                  <StyledButton variant="outlined" color="secondary" fullWidth onClick={() => emailVerifyButtonFunc()}>
                    <Typography variant="subtitle1" fontWeight={400} color="black.500">
                      인증
                    </Typography>
                  </StyledButton>
                </Grid>
              </Grid>
            </CustomGrid>
            <Grid item xs={12}>
              <SubCustomDivider />
            </Grid>
            <CustomGrid container item columnSpacing={1.2}>
              <LabelGrid item xs={2.8}>
                <Typography variant="body1" fontWeight={500} color="black.400">
                  이름
                </Typography>
              </LabelGrid>
              <Grid item xs={9.2}>
                <TextFieldWithoutLabelSet type="text" placeholder="이름" ref={nameRef} valueState={valueState.name} setValueEvent={(event) => setValueEvent("name", event.target.value)} onKeyPress={(event) => onEnterKeyPress(event)} readOnly={false} />
              </Grid>
            </CustomGrid>
            <Grid item xs={12}>
              <SubCustomDivider />
            </Grid>
            <CustomGrid container item columnSpacing={1.2}>
              <LabelGrid item xs={2.8}>
                <Typography variant="body1" fontWeight={500} color="black.400">
                  생년월일
                </Typography>
              </LabelGrid>
              <Grid item xs={9.2}>
                <DateTextFieldSetWithoutLabel inputFormat="yyyy.MM.DD" placeholder="YYYY.MM.DD" ref={birthRef} value={valueState.birth} onChange={(newValue) => setDateValueEvent(newValue)} onKeyPress={(event) => onEnterKeyPress(event)} disablePast={false} disableFuture={true} />
              </Grid>
            </CustomGrid>
            <Grid item xs={12}>
              <SubCustomDivider />
            </Grid>
            <CustomGrid container item columnSpacing={1.2}>
              <LabelGrid item xs={2.8}>
                <Typography variant="body1" fontWeight={500} color="black.400">
                  연락처
                </Typography>
              </LabelGrid>
              <Grid item xs={9.2}>
                <TextFieldWithoutLabelSet type="tel" placeholder="010-0000-0000" ref={phoneRef} valueState={valueState.phone} setValueEvent={(event) => setValueEvent("phone", event.target.value)} inputProps={{ maxLength: 13 }} onKeyPress={(event) => onEnterKeyPress(event)} />
              </Grid>
            </CustomGrid>
            <Grid item xs={12}>
              <SubCustomDivider />
            </Grid>
            <CustomGrid container item columnSpacing={1.2}>
              <Grid item xs={2.8} paddingTop="10px">
                <Typography variant="body1" fontWeight={500} color="black.400">
                  비밀번호
                </Typography>
              </Grid>
              <Grid item xs={2.4}>
                <PasswordChangeButton component={Link} to="/change-password" variant="outlined" color="secondary" fullWidth>
                  <Typography variant="body2" color="black.400">
                    비밀번호 변경
                  </Typography>
                </PasswordChangeButton>
              </Grid>
            </CustomGrid>
            <Grid item xs={12}>
              <SubCustomDivider />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid container item rowSpacing={2.5}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" fontWeight={700} color="#333333">
              회사정보
            </Typography>
            <Grid item xs={12}>
              <CustomDivider />
            </Grid>
          </Grid>
          <Grid container item rowSpacing={3}>
            <CustomGrid container item columnSpacing={1.2}>
              <LabelGrid item xs={2.8}>
                <Typography variant="body1" fontWeight={500} color="black.400">
                  회사명
                </Typography>
              </LabelGrid>
              <Grid item xs={9.2}>
                <TextFieldWithoutLabelSet type="text" ref={companyNameRef} valueState={valueState.companyName} setValueEvent={() => { }} onKeyPress={() => { }} readOnly={true} />
              </Grid>
            </CustomGrid>
            <Grid item xs={12}>
              <SubCustomDivider />
            </Grid>
            <CustomGrid container item columnSpacing={1.2}>
              <LabelGrid item xs={2.8}>
                <Typography variant="body1" fontWeight={500} color="black.400">
                  홈페이지 URL
                </Typography>
              </LabelGrid>
              <Grid item xs={9.2}>
                <TextFieldWithoutLabelSet type="text" ref={companyHomepageRef} valueState={valueState.companyHomepage} setValueEvent={() => { }} onKeyPress={() => { }} readOnly={true} />
              </Grid>
            </CustomGrid>
            <Grid item xs={12}>
              <SubCustomDivider />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MyPageMarketingAgreement checkedState={valueState.marketingAgreement} checkEvent={setCheckValueEvent} />
      <Grid item xs={12} textAlign="end">
        <ButtonBase component={Link} to="/withdrawal">
          <WithdrawalTypo variant="body2" color="black.200">회원탈퇴</WithdrawalTypo>
        </ButtonBase>
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Grid item xs={5.6}>
          <CustomButton variant="contained" color="secondary" fullWidth onClick={() => submitEvent()} disabled={!buttonActiveState}>
            <Typography variant="subtitle1" color="#ffffff">
              저장하기
            </Typography>
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
});
