import React from "react";

import AdminOngoingProject from "../../containers/Admin/AdminOngoingProjectContainer";
import RequireAuthRoute from "../../routes/requireAuth";

export default function AdminOngoingProjectView() {
  return (
    <React.Fragment>
      <RequireAuthRoute Component={AdminOngoingProject} />
    </React.Fragment>
  );
}
