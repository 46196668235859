import { forwardRef } from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const CustomMenuItem = styled(MenuItem)({
  padding: "0 20px",
  "&:hover": {
    background: "transparent",
  }
});

const CustomMenuUpperItem = styled(MenuItem)({
  padding: "11px 20px 44px 20px",
  "&:hover": {
    background: "transparent",
  }
});

const CustomMenuBelowItem = styled(MenuItem)({
  padding: "15px 20px 7px 20px",
  "&:hover": {
    background: "transparent",
  }
});

const UnderlinedTypo = styled(Typography)({
  textDecoration: "underline",
});

// 상단바 메뉴
export const MyMenu = forwardRef((props, forwardRef) => {
  const { myMenuOpenState, myMenuCloseEvent, signOutFunction } = props;
  return (
    <Menu
      anchorEl={myMenuOpenState ? forwardRef.current : null}
      open={myMenuOpenState}
      onClose={() => myMenuCloseEvent()}
      PaperProps={{
        elevation: 1,
        style: {
          width: 183,
          borderRadius: 10,
          border: "1px solid #D9D9D9",
          background: "linear-gradient(to bottom, #FFFFFF 65%, #F5F5F5 65% )",
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <CustomMenuItem component={Link} to={"/my-page"} sx={{ paddingTop: "7px", paddingBottom: "11px" }}>
        <Typography variant="subtitle1" fontWeight={400} color="black.300" align="left">
          마이페이지
        </Typography>
      </CustomMenuItem>
      <CustomMenuItem component={Link} to={"/my-portfolio"} sx={{ paddingTop: "11px", paddingBottom: "15px" }}>
        <Typography variant="subtitle1" fontWeight={400} color="black.300" align="left">
          포트폴리오
        </Typography>
      </CustomMenuItem>
      <CustomMenuBelowItem onClick={() => signOutFunction()}>
        <Typography variant="subtitle1" fontWeight={400} color="black.300" align="left">
          로그아웃
        </Typography>
      </CustomMenuBelowItem>
    </Menu>
  );
});

// 이메일 인증 안 한 기업 회원 상단바 메뉴
export const IncUserNoEmailMenu = forwardRef((props, forwardRef) => {
  const { myMenuOpenState, myMenuCloseEvent, signOutFunction, userEmail } = props;
  return (
    <Menu
      anchorEl={myMenuOpenState ? forwardRef.current : null}
      open={myMenuOpenState}
      onClose={() => myMenuCloseEvent()}
      PaperProps={{
        elevation: 1,
        style: {
          minWidth: 240,
          borderRadius: 10,
          background: "linear-gradient(to bottom, #FFFFFF 65%, #F5F5F5 65% )",
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <CustomMenuUpperItem component={Link} to={"/my-page"}>
        <Grid container direction="column" rowSpacing={1}>
          <Grid item>
            <Typography variant="subtitle1" fontWeight={400} color="#333333">
              {userEmail}
            </Typography>
          </Grid>
          <Grid item>
            <UnderlinedTypo variant="body2" fontWeight={400} color="error">
              이메일 인증이 필요합니다.
            </UnderlinedTypo>
          </Grid>
        </Grid>
      </CustomMenuUpperItem>
      <CustomMenuBelowItem onClick={() => signOutFunction()}>
        <Typography variant="subtitle1" align="left">
          로그아웃
        </Typography>
      </CustomMenuBelowItem>
    </Menu>
  );
});

// 승인 안 된 기업 회원 상단바 메뉴
export const IncUserNoAuthMenu = forwardRef((props, forwardRef) => {
  const { myMenuOpenState, myMenuCloseEvent, signOutFunction, userEmail } = props;
  return (
    <Menu
      anchorEl={myMenuOpenState ? forwardRef.current : null}
      open={myMenuOpenState}
      onClose={() => myMenuCloseEvent()}
      PaperProps={{
        elevation: 1,
        style: {
          minWidth: 240,
          borderRadius: 10,
          background: "linear-gradient(to bottom, #FFFFFF 65%, #F5F5F5 65% )",
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <CustomMenuUpperItem component={Link} to={"/my-page"}>
        <Grid container direction="column" rowSpacing={1}>
          <Grid item>
            <Typography variant="subtitle1" fontWeight={400} color="#333333">
              {userEmail}
            </Typography>
          </Grid>
          <Grid item>
            <UnderlinedTypo variant="body2" fontWeight={400} color="#666666">
              가입 승인 대기중입니다.
            </UnderlinedTypo>
          </Grid>
        </Grid>
      </CustomMenuUpperItem>
      <CustomMenuBelowItem onClick={() => signOutFunction()}>
        <Typography variant="subtitle1" align="left">
          로그아웃
        </Typography>
      </CustomMenuBelowItem>
    </Menu>
  );
});

// 멤버 아닌 기업 회원 상단바 메뉴
export const IncUserNoMemberMenu = forwardRef((props, forwardRef) => {
  const { myMenuOpenState, myMenuCloseEvent, signOutFunction, userEmail } = props;
  return (
    <Menu
      anchorEl={myMenuOpenState ? forwardRef.current : null}
      open={myMenuOpenState}
      onClose={() => myMenuCloseEvent()}
      PaperProps={{
        elevation: 1,
        style: {
          minWidth: 240,
          borderRadius: 10,
          background: "linear-gradient(to bottom, #FFFFFF 65%, #F5F5F5 65% )",
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <CustomMenuUpperItem component={Link} to={"/my-page"}>
        <Grid container direction="column" rowSpacing={1}>
          <Grid item>
            <Typography variant="subtitle1" fontWeight={400} color="#333333">
              {userEmail}
            </Typography>
          </Grid>
          <Grid item>
            <UnderlinedTypo variant="body2" fontWeight={400} color="#666666">
              이용중인 멤버십이 없습니다.
            </UnderlinedTypo>
          </Grid>
        </Grid>
      </CustomMenuUpperItem>
      <CustomMenuBelowItem onClick={() => signOutFunction()}>
        <Typography variant="subtitle1" align="left">
          로그아웃
        </Typography>
      </CustomMenuBelowItem>
    </Menu>
  );
});

// 멤버십 구독중인 기업 회원 상단바 메뉴
export const IncUserMemberMenu = forwardRef((props, forwardRef) => {
  const { myMenuOpenState, myMenuCloseEvent, signOutFunction, userEmail } = props;
  return (
    <Menu
      anchorEl={myMenuOpenState ? forwardRef.current : null}
      open={myMenuOpenState}
      onClose={() => myMenuCloseEvent()}
      PaperProps={{
        elevation: 1,
        style: {
          minWidth: 240,
          borderRadius: 10,
          background: "linear-gradient(to bottom, #FFFFFF 65%, #F5F5F5 65% )",
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <CustomMenuUpperItem component={Link} to={"/my-page"}>
        <Grid container direction="column" rowSpacing={1}>
          <Grid item>
            <Typography variant="subtitle1" fontWeight={400} color="#333333">
              {userEmail}
            </Typography>
          </Grid>
          <Grid container item columnGap={0.1}>
            <Grid item xs="auto">
              <Typography variant="subtitle1" lineHeight="13px" color="#B5D000">
                ・
              </Typography>
            </Grid>
            <Grid item xs>
              <UnderlinedTypo variant="body2" lineHeight="13px" fontWeight={400} color="#B5D000">
                월간 멤버십 이용중
              </UnderlinedTypo>
            </Grid>
          </Grid>
        </Grid>
      </CustomMenuUpperItem>
      <CustomMenuBelowItem onClick={() => signOutFunction()}>
        <Typography variant="subtitle1" align="left">
          로그아웃
        </Typography>
      </CustomMenuBelowItem>
    </Menu>
  );
});
