import React from "react";

import UniversalAppBar from "../../Block/Appbar/UniversalAppBar";
import UniversalAppFooter from "../../Block/Footer/UniversalAppFooter";

import SignUp from "../../containers/Service/Auth/SignUpContainer";

export default function SignUpView() {
  return (
    <React.Fragment>
      <UniversalAppBar />
      <SignUp />
      <UniversalAppFooter />
    </React.Fragment>
  );
}
