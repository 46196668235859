import React from "react";

import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";

import { ChannelTypeTag } from "../../components/ProjectComponent";

const CustomGridContainer = styled(Grid)({
  minHeight: "100vh",
  padding: "120px 0 90px",
});

const BrandImageBox = styled(Box)({
  width: 30,
  height: 30,
  borderRadius: "4px",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  background: "#D9D9D9",
});

const BrandButtonBase = styled(ButtonBase)({
  borderRadius: "4px",
});

const BrandImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

// 프로젝트 상세 페이지 body frame
export default function ProjectDetailBodyFrame(props) {
  const { projectLeftContents, projectRightContents } = props;
  const { projectDetailValue, brandValue, brandInfoDialogOpenEvent } = props;

  const ProjectDetailTags = () => {
    return (
      <Grid container item columnSpacing={1}>
        <Grid item><ChannelTypeTag channelType={projectDetailValue.channel} size="30px" fontSize="14px" /></Grid>
        <Grid item><ChannelTypeTag channelType={projectDetailValue.type} size="30px" fontSize="14px" /></Grid>
      </Grid>
    );
  };

  const ProjectDetailBrandInfo = () => {
    return (
      <Grid item>
        <BrandButtonBase onClick={brandInfoDialogOpenEvent}>
          <Grid container alignItems="center" columnSpacing={1.5}>
            <Grid item>
              <BrandImageBox>
                <BrandImage src={brandValue.photo} alt="브랜드 이미지" />
              </BrandImageBox>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" fontWeight={400} lineHeight="28px" color="black.600">
                {brandValue.name}
              </Typography>
            </Grid>
          </Grid>
        </BrandButtonBase>
      </Grid>
    );
  };

  return (
    <Container maxWidth="lg" disableGutters>
      <CustomGridContainer container columnSpacing={3} rowSpacing={5}>
        <Grid container item xs={12} rowSpacing={2.5}>
          {ProjectDetailBrandInfo()}
          <Grid item xs={12}>
            <Typography variant="h1" fontSize="34px">
              {projectDetailValue.title}
            </Typography>
          </Grid>
          {ProjectDetailTags()}
        </Grid>
        <Grid item xs={8.05}>
          {projectLeftContents}
        </Grid>
        <Grid item xs={3.95}>
          {projectRightContents}
        </Grid>
      </CustomGridContainer>
    </Container>
  );
}
