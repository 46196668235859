import React from "react";

import moment from "moment";

import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import Pagination from "@mui/material/Pagination";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import NaverBlogTag, { NaverPostTag, InstagramTag, YoutubeTag, ProjectTypeTag } from "../../components/ProjectComponent";
import { NaverBlogIcon, NaverPostIcon, InstagramIcon, YoutubeIcon } from "../../components/SnsIconButton";

import { MediumThumbnailBox } from "../../components/ProjectComponent";

const CustomTableHeadRow = styled(TableRow)({
  backgroundColor: "#E4E5E7",
});

const CustomTableBodyRow = styled(TableRow)({
  backgroundColor: "#FFFFFF",
  "& .MuiTableCell-root": {
    borderBottom: "none",
  },
});

const LeftUpperTableCell = styled(TableCell)({
  borderRadius: "10px 0 0 0",
  borderRight: "1px solid #FFFFFF",
});

const UpperTableHeadCell = styled(TableCell)({
  borderRight: "1px solid #FFFFFF",
});

const RightUpperTableCell = styled(TableCell)({
  borderRadius: "0 10px 0 0",
});

const LeftBottomTableCell = styled(TableCell)({
  borderRight: "1px solid #EEEEEE",
  borderRadius: "0 0 0 10px",
  cursor: "pointer",
});

const UpperTableBodyCell = styled(TableCell)({
  borderRight: "1px solid #EEEEEE",
});

const RightBottomTableCell = styled(TableCell)({
  borderRadius: "0 0 10px 0",
});

const IconGridItem = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const TitleNOfferingTypo = styled(Typography)({
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
});

const BelowTableBodyCell = styled(TableCell)({
  borderRight: "1px solid #EEEEEE",
});

const CustomPagination = styled(Pagination)({
  "& .Mui-selected": {
    background: "#ffffff",
    fontWeight: 700,
  },
});

const DialogButtonTypography = styled(Typography)({
  padding: "7px 20px",
});

const PaginationGridContainer = styled(Grid)({
  position: "relative",
});

const PaginationGridItem = styled(Grid)({
  zIndex: 20,
});

const ButtonGridItem = styled(Grid)({
  position: "absolute",
  right: "0",
});

const CancelButtonBase = styled(ButtonBase)({
  borderRadius: "100px",
});

const CancelButtonTypo = styled(Typography)({
  border: "1px solid #ED2727",
  borderRadius: "100px",
  padding: "4px 13px",
});

// 진행 중인 프로젝트 상세 페이지 내 상단 프로젝트 정보 테이블
export default function AdminOngoingProjectUpperTable(props) {
  const { projectDetailValue, projectDetailPageOpenFunc } = props;

  // 날짜 형식 변환(초 -> 월/일)
  const recuitmentStartDate = moment(projectDetailValue.recruitmentStartDate * 1000).format("MM.DD");
  const recuitmentEndDate = moment(projectDetailValue.recruitmentEndDate * 1000).format("MM.DD");
  const appointmentDate = moment(projectDetailValue.appointmentDate * 1000).format("MM.DD");
  const submissionStartDate = moment(projectDetailValue.submissionStartDate * 1000).format("MM.DD");
  const submissionEndDate = moment(projectDetailValue.submissionEndDate * 1000).format("MM.DD");
  const endDate = moment(projectDetailValue.endDate * 1000).format("MM.DD");

  return (
    <Grid container justifyContent="flex-end" rowSpacing={2}>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <CustomTableHeadRow>
                <LeftUpperTableCell align="center" width="36%">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    제공 내역, 채널, 유형
                  </Typography>
                </LeftUpperTableCell>
                <UpperTableHeadCell align="center" width="8%">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    모집인원
                  </Typography>
                </UpperTableHeadCell>
                <UpperTableHeadCell align="center" width="14%">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    모집 기간
                  </Typography>
                </UpperTableHeadCell>
                <UpperTableHeadCell align="center" width="14%">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    선정일
                  </Typography>
                </UpperTableHeadCell>
                <UpperTableHeadCell align="center" width="14%">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    제출기간
                  </Typography>
                </UpperTableHeadCell>
                <RightUpperTableCell align="center" width="14%">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    종료일
                  </Typography>
                </RightUpperTableCell>
              </CustomTableHeadRow>
            </TableHead>
            <TableBody>
              <CustomTableBodyRow>
                <LeftBottomTableCell onClick={() => projectDetailPageOpenFunc()}>
                  <Grid container alignItems="center" columnSpacing={2}>
                    <Grid item xs="auto">
                      <MediumThumbnailBox thumbnailUrl={projectDetailValue.thumbnail} />
                    </Grid>
                    <Grid container item xs rowSpacing={1}>
                      <Grid container item>
                        <Grid item xs={11}>
                          <TitleNOfferingTypo variant="body1" lineHeight="28px" fontWeight={700} color="#333333">
                            {projectDetailValue.title}
                          </TitleNOfferingTypo>
                        </Grid>
                        <IconGridItem item xs={1}>
                          <KeyboardArrowRightIcon />
                        </IconGridItem>
                        {projectDetailValue.offering ? (
                          <Grid item xs={12}>
                            <TitleNOfferingTypo variant="body2" lineHeight="24px" color="#333333">
                              제공: {projectDetailValue.offering}
                            </TitleNOfferingTypo>
                          </Grid>
                        ) : null}
                      </Grid>
                      <Grid container item columnSpacing={1}>
                        <Grid item>{projectDetailValue.channel === "naverBlog" ? <NaverBlogTag /> : projectDetailValue.channel === "naverPost" ? <NaverPostTag /> : projectDetailValue.channel === "instagram" ? <InstagramTag /> : projectDetailValue.channel === "youtube" ? <YoutubeTag /> : null}</Grid>
                        <Grid item>{projectDetailValue.type === "delivery" ? <ProjectTypeTag projectType="배송형" /> : projectDetailValue.type === "purchase" ? <ProjectTypeTag projectType="구매형" /> : projectDetailValue.type === "visit" ? <ProjectTypeTag projectType="방문형" /> : null}</Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </LeftBottomTableCell>
                <UpperTableBodyCell>
                  <Typography variant="body2" lineHeight="24px" color="#333333" align="center">
                    {projectDetailValue.recruitmentLimitNumber}명
                  </Typography>
                </UpperTableBodyCell>
                <UpperTableBodyCell>
                  <Typography variant="body2" lineHeight="24px" color="#333333" align="center">
                    {recuitmentStartDate} ~ {recuitmentEndDate}
                  </Typography>
                </UpperTableBodyCell>
                <UpperTableBodyCell>
                  <Typography variant="body2" lineHeight="24px" color="#333333" align="center">
                    {appointmentDate}
                  </Typography>
                </UpperTableBodyCell>
                <UpperTableBodyCell>
                  <Typography variant="body2" lineHeight="24px" fontWeight={700} color="secondary" align="center">
                    {submissionStartDate} ~ {submissionEndDate}
                  </Typography>
                </UpperTableBodyCell>
                <RightBottomTableCell>
                  <Typography variant="body2" lineHeight="24px" color="#333333" align="center">
                    {endDate}
                  </Typography>
                </RightBottomTableCell>
              </CustomTableBodyRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

// 진행 중인 프로젝트 상세 페이지 하단 참여자 정보 테이블
export function AdminOngoingProjectBelowTable(props) {
  const { projectDetailValue, appointersListValue, snsOpenFunc, tablePageState, tablePageChange, projectDeleteButtonFunc } = props;

  const dataPerPage = 15;

  const TableBody = () => {
    return Object.keys(appointersListValue)
      .slice((tablePageState - 1) * dataPerPage, (tablePageState - 1) * dataPerPage + dataPerPage)
      .map((appointer, index) => {
        return (
          <CustomTableBodyRow key={"ongoingBelow" + index}>
            <BelowTableBodyCell>
              <Typography variant="body2" lineHeight="24px" fontWeight={400} color="#333333" align="center">
                {appointersListValue[appointer].name}
              </Typography>
            </BelowTableBodyCell>
            <BelowTableBodyCell>
              <Typography variant="body2" lineHeight="24px" fontWeight={400} color="#333333" align="center">
                {appointersListValue[appointer].email}
              </Typography>
            </BelowTableBodyCell>
            <BelowTableBodyCell>
              <Typography variant="body2" lineHeight="24px" fontWeight={400} color="#333333" align="center">
                {appointersListValue[appointer].contact}
              </Typography>
            </BelowTableBodyCell>
            <BelowTableBodyCell>
              <Grid container justifyContent="center" columnSpacing={1}>
                {appointersListValue[appointer].sns.naverBlog !== "" && projectDetailValue.channel === "naverBlog" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(appointersListValue[appointer].sns.naverBlog)}>
                      <NaverBlogIcon />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {appointersListValue[appointer].sns.naverPost !== "" && projectDetailValue.channel === "naverPost" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(appointersListValue[appointer].sns.naverPost)}>
                      <NaverPostIcon />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {appointersListValue[appointer].sns.instagram !== "" && projectDetailValue.channel === "instagram" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(appointersListValue[appointer].sns.instagram)}>
                      <InstagramIcon />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {appointersListValue[appointer].sns.youtube !== "" && projectDetailValue.channel === "youtube" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(appointersListValue[appointer].sns.youtube)}>
                      <YoutubeIcon />
                    </ButtonBase>
                  </Grid>
                ) : null}
              </Grid>
            </BelowTableBodyCell>
            <BelowTableBodyCell>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs="auto">
                  <Typography variant="body2" lineHeight="24px" fontWeight={400} color="#333333" align="center">
                    {appointersListValue[appointer].address}
                  </Typography>
                </Grid>
                {!appointersListValue[appointer].activeState ? (
                  <Grid item>
                    <CancelButtonTypo variant="body2" lineHeight="24px" fontWeight={500} color="#ED2727" align="center">
                      참여 취소
                    </CancelButtonTypo>
                  </Grid>
                ) : null}
              </Grid>
            </BelowTableBodyCell>
          </CustomTableBodyRow>
        );
      });
  };

  return (
    <Grid container item rowSpacing={3}>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <CustomTableHeadRow>
                <LeftUpperTableCell align="center" width="17%">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    이름
                  </Typography>
                </LeftUpperTableCell>
                <UpperTableHeadCell align="center" width="17%">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    이메일
                  </Typography>
                </UpperTableHeadCell>
                <UpperTableHeadCell align="center" width="17%">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    연락처
                  </Typography>
                </UpperTableHeadCell>
                <UpperTableHeadCell align="center">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    채널
                  </Typography>
                </UpperTableHeadCell>
                <RightUpperTableCell align="center">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    주소
                  </Typography>
                </RightUpperTableCell>
              </CustomTableHeadRow>
            </TableHead>
            <TableBody>{TableBody()}</TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <PaginationGridContainer container item justifyContent="center" alignItems="center">
        <PaginationGridItem item>
          <CustomPagination shape="rounded" count={Math.ceil(Object.keys(appointersListValue).length / dataPerPage)} page={tablePageState} onChange={tablePageChange} />
        </PaginationGridItem>
        <ButtonGridItem container item justifyContent="flex-end" alignItems="center">
          <Grid item>
            <Button variant="outlined" color="secondary" fullWidth onClick={() => projectDeleteButtonFunc()}>
              <DialogButtonTypography variant="body2" fontWeight={500} color="secondary">
                프로젝트 삭제
              </DialogButtonTypography>
            </Button>
          </Grid>
        </ButtonGridItem>
      </PaginationGridContainer>
    </Grid>
  );
}
