import React, { Fragment, useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useMutation, useQuery, useQueryClient } from "react-query";

import { RequestProjectDetail, RequestProjectAppointersList, UpdateCancelProject } from "../../../utils/requestToSrv";

import ProjectDetailBodyFrame from "../../../Block/IncUserMyPage/ProjectDetailBodyFrame";
import OngoingProjectUpperTable from "../../../Block/IncUserMyPage/OngoingProjectUpperTable";
import OngoingProjectBelowTable from "../../../Block/IncUserMyPage/OngoingProjectBelowTable";

import { ProjectCancelDialog } from "../../../Block/IncUserMyPage/ProjectCancelDialog";
import DialogMessage from "../../../components/DialogMessage";
import DialogWithButton from "../../../components/DialogWithButton";
import LoadingComponent from "../../../components/LoadingComponent";

// 진행 중 프로젝트 상세 페이지
export default function OngoingProjectDetailContainer() {
  // 프로젝트 doc id url 파라미터
  const { targetDocId } = useParams();
  // react-query queryClient
  const queryClient = useQueryClient();

  // 화면 로드 시 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // sns 링크 새창으로 열기
  const snsOpenFunc = (url) => {
    window.open(url);
  };

  // 프로젝트 data load
  const ongoingProjectDetailInfo = useQuery(["ongoingProjectDetail", targetDocId], () => RequestProjectDetail(targetDocId), {
    onError: (error) => {
      console.log(error);
    },
  });

  // 프로젝트 선정자 data load
  const appointersList = useQuery(["appointersList", targetDocId], () => RequestProjectAppointersList(targetDocId), {
    onError: (error) => console.log(error),
  });

  // 테이블 페이지 state
  const [tablePageState, tablePageSetState] = useState(1);

  // 테이블 페이지 이동
  const tablePageChange = (event, newPage) => {
    tablePageSetState(newPage);
  };

  // 프로젝트 취소 dialog open state
  const [projectCancelDialogOpenState, projectCancelDialogOpenSetState] = useState(false);

  // 프로젝트 취소 dialog close function
  const projectCancelDialogCloseFunc = () => {
    projectCancelDialogOpenSetState(false);
  };

  // 프로젝트 취소 사유 toast ui editor ref
  const projectCancelReasonRef = useRef(null);

  // 프로젝트 취소 사유 textfield value state
  const [projectCancelReasonState, projectCancelReasonSetState] = useState("");

  // dialog message text state
  const [dialogMessageState, dialogMessageSetState] = useState("");
  // dialog message custom hook
  const { dialogCall, DialogComponent } = DialogMessage(dialogMessageState);

  // 프로젝트 취소 update function
  const projectCancelFunc = useMutation(() => UpdateCancelProject(targetDocId, projectCancelReasonRef.current.value), {
    onSuccess: () => {
      projectCancelDialogOpenSetState(false);
      dialogMessageSetState(["프로젝트가 취소되었습니다.", <br />, "종료 프로젝트 목록에서 확인해주세요."]);
      dialogCall();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 프로젝트 취소 dialog submit function
  const projectCancelDialogSubmitFunc = () => {
    projectCancelFunc.mutate();
  };

  // 프로젝트 취소 전 경고 dialog open state
  const [cancelWarningDialogOpenState, cancelWarningDialogOpenSetState] = useState(false);

  // 프로젝트 취소 전 경고 dialog open function
  const cancelWarningDialogOpenFunc = () => {
    cancelWarningDialogOpenSetState(true);
  };

  // 프로젝트 취소 전 경고 dialog close function
  const cancelWarningDialogCloseFunc = () => {
    cancelWarningDialogOpenSetState(false);
  };

  // 프로젝트 취소 전 경고 dialog confirm function
  const cancelWarningButtonFunc = () => {
    projectCancelDialogOpenSetState(true);
    cancelWarningDialogOpenSetState(false);
  };

  // 개인 회원 프로젝트 참여 취소 안내 dialog open state
  const [canceledUserGuideDialogOpenState, canceledUserGuideDialogOpenSetState] = useState(false);

  // 참여 취소 안내 dialog open function
  const canceledUserGuideDialogOpenFunc = () => {
    canceledUserGuideDialogOpenSetState(true);
  };

  // 참여 취소 안내 dialog close function
  const canceledUserGuideDialogCloseFunc = () => {
    canceledUserGuideDialogOpenSetState(false);
  };

  // 규정 확인 function
  const openRegulationFunc = () => {
    console.log("규정 보기");
  };

  // 프로젝트 상세 페이지로 이동 function
  const projectDetailPageOpenFunc = () => {
    window.open(`/project-detail/${targetDocId}`);
  };

  // 모집 중 프로젝트 상세 페이지 화면
  if (queryClient.getQueryData(["ongoingProjectDetail", targetDocId]) && appointersList.isFetched) {
    return (
      <Fragment>
        <ProjectDetailBodyFrame title="진행현황" upperTable={<OngoingProjectUpperTable projectDetailValue={ongoingProjectDetailInfo.data} projectDetailPageOpenFunc={projectDetailPageOpenFunc} projectCancelButtonFunc={cancelWarningDialogOpenFunc} />} belowTable={<OngoingProjectBelowTable projectDetailValue={ongoingProjectDetailInfo.data} appointersListValue={appointersList.data} snsOpenFunc={snsOpenFunc} tablePageState={tablePageState} tablePageChange={tablePageChange} canceledUserGuideDialogOpenFunc={canceledUserGuideDialogOpenFunc} />} />
        <ProjectCancelDialog ref={projectCancelReasonRef} projectCancelDialogOpenState={projectCancelDialogOpenState} projectCancelDialogCloseFunc={projectCancelDialogCloseFunc} projectCancelDialogSubmitFunc={projectCancelDialogSubmitFunc} projectCancelReasonState={projectCancelReasonState} projectCancelReasonSetState={projectCancelReasonSetState} />
        <DialogComponent />
        <DialogWithButton width={554} icon="notice" title="프로젝트를 취소하시겠습니까?" subtitle={["진행중인 프로젝트를 취소할 경우", <br />, "이미 제공한 제품의 반환 또는 결제를 요구할 수 없습니다."]} dialogOpenState={cancelWarningDialogOpenState} dialogClose={cancelWarningDialogCloseFunc} button1Text="규정 보기" button2Text="확인" button1Func={openRegulationFunc} button2Func={cancelWarningButtonFunc} />
        <DialogWithButton width={554} message={["프로젝트 참여를 취소한 회원입니다.", <br />, "이미 제품을 제공하셨다면 반환 또는", <br />, "결제를 요구하실 수 있습니다."]} dialogOpenState={canceledUserGuideDialogOpenState} dialogClose={canceledUserGuideDialogCloseFunc} button1Text="규정 보기" button2Text="확인" button1Func={openRegulationFunc} button2Func={canceledUserGuideDialogCloseFunc} />
      </Fragment>
    );
  } else {
    return <LoadingComponent />;
  }
}
