import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";

import AdminBodyFrame from "../../Block/Admin/AdminBodyFrame";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { RequestIncUsersList, UpdateIncUserAuthState, RequestWithdrawal, RequestBrandInfo, UpdateIncUserMembershipApply, UpdateIncUserMembershipCancel } from "../../utils/adminRequestToSrv";
import { doSendPasswordResetEmail } from "../../utils/firebaseUtils";

import AdminIncUserTable, { AdminIncUserModal, AdminBrandModal } from "../../Block/Admin/AdminIncUsersContents";
import { AdminSearchBox } from "../../Block/Admin/AdminSearchBox";

import DialogMessage, { DialogAlertMessage } from "../../components/DialogMessage";
import DialogWithButton from "../../components/DialogWithButton";
import LoadingComponent from "../../components/LoadingComponent";

// admin - 기업 회원 관리
export default function AdminIncUsersContainer() {
  // react-router-dom navigate
  const navigate = useNavigate();
  // react-query queryClient
  const queryClient = useQueryClient();

  // 기업 회원 리스트 data load
  const incUserList = useQuery("incUserList", RequestIncUsersList, {
    onError: (error) => {
      console.log(error);
    },
  });

  // data loading state
  const [loadState, loadSetState] = useState(false);
  // 화면 render 확인 state
  const [renderState, renderSetState] = useState(false);

  // 검색 카테고리 버튼 ref
  const categoryButtonRef = useRef(null);
  // 검색 input ref
  const searchInputRef = useRef(null);

  const searchBoxRefSet = {
    categoryButtonRef: categoryButtonRef,
    searchInputRef: searchInputRef,
  };

  // 페이지 state
  const [tableDataPageState, tableDataPageSetState] = useState(0);

  // 회원 정보 modal에 출력할 user id
  const [userIdState, userIdSetState] = useState("");
  // 회원 정보 수정 modal open state
  const [userInfoModalOpenState, userInfoModalOpenSetState] = useState(false);

  // 검색 카테고리 메뉴 open state
  const [categoryMenuOpenedState, categoryMenuOpenedSetState] = useState(false);
  // 검색 카테고리 state
  const [searchCategoryState, searchCategorySetState] = useState("name");
  // 검색어 state
  const [searchValueState, searchValueSetState] = useState("");

  // 기업 회원 정보 수정 modal 활성화 이벤트
  const userInfoModalOpenEvent = (userId) => {
    userIdSetState(userId);
    userInfoModalOpenSetState(true);
  };

  // 기업 회원 정보 수정 modal 종료 이벤트
  const userInfoModalCloseEvent = () => {
    userIdSetState("");
    userInfoModalOpenSetState(false);
  };

  // 검색 카테고리 메뉴 open function
  const categoryMenuOpenFunc = () => {
    categoryMenuOpenedSetState(true);
  };

  // 검색 카테고리 메뉴 close function
  const categoryMenuCloseFunc = () => {
    categoryMenuOpenedSetState(false);
  };

  // 검색 카테고리 설정 이벤트
  const categoryMenuSelectFunc = (value) => {
    searchCategorySetState(value);
    searchValueSetState("");
    categoryMenuCloseFunc();
  };

  // 검색어 입력 이벤트
  const searchValueChangeFunc = () => {
    searchValueSetState(searchInputRef.current.value);
  };

  // 검색어 입력 후 0.5초 대기 시 데이터 필터링 작동
  useEffect(() => {
    if (renderState) {
      const debouncingTimer = setTimeout(() => {
        // loadSetState(true);
        // 검색 method
      }, 500);
      return () => clearTimeout(debouncingTimer);
    } else {
      renderSetState(true);
    }
  }, [searchValueState]);

  // dialog message text state
  const [dialogMessageState, dialogMessageSetState] = useState("");
  // dialog message custom hook
  const { dialogCall, DialogComponent } = DialogMessage(dialogMessageState);

  // alert dialog message text state
  const [dialogAlertMessageState, dialogAlertMessageSetState] = useState("");
  // alert dialog message custom hook
  const { dialogAlertCall, DialogAlertComponent } = DialogAlertMessage(dialogAlertMessageState);

  // 비밀번호 초기화 메일 발송 function
  const sendPasswordResetFunc = () => {
    doSendPasswordResetEmail({ email: incUserList.data[userIdState].email, loadSetState: loadSetState, dialogCall: dialogCall, setDialogMessage: dialogMessageSetState, alertDialogCall: dialogAlertCall, setAlertDialogMessage: dialogAlertMessageSetState });
  };

  // 회원 탈퇴 dialog open state
  const [withdrawalDialogOpenState, withdrawalDialogOpenSetState] = useState(false);

  // 회원 탈퇴 dialog open function
  const withdrawalFunc = () => {
    withdrawalDialogOpenSetState(true);
  };

  // 회원 탈퇴 dialog close function
  const withdrawalDialogCloseFunc = () => {
    withdrawalDialogOpenSetState(false);
  };

  // 회원 탈퇴 function
  const requestWithdrawal = useMutation(() => RequestWithdrawal(userIdState), {
    onSuccess: () => {
      dialogMessageSetState("회원 탈퇴가 완료되었습니다.");
      dialogCall();
      userInfoModalCloseEvent();
      incUserList.refetch();
    },
    onError: () => {
      dialogAlertMessageSetState("회원 탈퇴에 실패했습니다. 잠시 후 다시 시도해주세요.");
      dialogAlertCall();
    },
  });

  // 회원 탈퇴 submit event
  const withdrawalConfirmFunc = () => {
    withdrawalDialogOpenSetState(false);
    requestWithdrawal.mutate();
  };

  // 데이터 테이블 페이지 전환 이벤트
  const tableDataPageChange = (event, newPage) => {
    tableDataPageSetState(newPage);
  };

  // 기업 회원 홈페이지 새 창에서 열기
  const linkToHomepageEvent = (homepage) => {
    window.open(homepage, "_blank");
  };

  // 브랜드 정보 modal open state
  const [brandInfoModalOpenState, brandInfoModalOpenSetState] = useState(false);

  // 브랜드 정보 data load
  const brandInfo = useMutation((targetUid) => RequestBrandInfo(targetUid), {
    onSuccess: (data) => {
      if (data === null) {
        dialogMessageSetState("브랜드 정보가 존재하지 않습니다.");
        dialogCall();
      } else {
        queryClient.setQueryData("brandInfo", data);
        brandInfoModalOpenSetState(true);
      }
    },
    onError: () => {
      dialogAlertMessageSetState("브랜드 정보를 불러오는데 실패했습니다. 잠시 후 다시 시도해주세요.");
      dialogAlertCall();
    },
  });

  // 브랜드 정보 modal open event
  const brandInfoEvent = (targetUid) => {
    brandInfo.mutate(targetUid);
  };

  // 브랜드 정보 modal close event
  const brandInfoCloseEvent = () => {
    brandInfoModalOpenSetState(false);
  };

  // 기업 회원 승인 dialog open state
  const [authDialogOpenState, authDialogOpenSetState] = useState(false);
  // 승인 대상 uid state
  const [authUidState, authUidSetState] = useState("");

  // 기업 회원 승인 dialog open function
  const authDialogOpenFunc = (uid) => {
    authDialogOpenSetState(true);
    authUidSetState(uid);
  };

  // 기업 회원 승인 dialog close function
  const authDialogCloseFunc = () => {
    authDialogOpenSetState(false);
  };

  // 기업 회원 승인 function
  const updateIncUserAuthState = useMutation((targetUid) => UpdateIncUserAuthState(targetUid), {
    onSuccess: () => {
      dialogMessageSetState("승인이 완료되었습니다.");
      dialogCall();
      authDialogCloseFunc();
      incUserList.refetch();
    },
    onError: (error) => {
      console.log(error);
      dialogAlertMessageSetState("승인에 실패하였습니다.");
      dialogAlertCall();
    },
  });

  // 기업 회원 승인 submit event
  const authConfirmFunc = () => {
    updateIncUserAuthState.mutate(authUidState);
  };

  // 230130 - 기업 회원 멤버십 가입 / 취소
  // 기업 회원 멤버십 가입 dialog open state
  const [membershipApplyDialogOpenState, membershipApplyDialogOpenSetState] = useState(false);
  // 기업 회원 멤버십 취소 dialog open state
  const [membershipCancelDialogOpenState, membershipCancelDialogOpenSetState] = useState(false);

  // 멤버십 적용/취소 대상 uid state
  const [membershipUidState, membershipUidSetState] = useState("");

  // 기업 회원 멤버십 적용 dialog open function
  const membershipApplyDialogOpenFunc = (uid) => {
    membershipApplyDialogOpenSetState(true);
    membershipUidSetState(uid);
  };
  // 기업 회원 멤버십 취소 dialog open function
  const membershipCancelDialogOpenFunc = (uid) => {
    membershipCancelDialogOpenSetState(true);
    membershipUidSetState(uid);
  };

  // 기업 회원 멤버십 적용 dialog close function
  const membershipApplyDialogCloseFunc = () => {
    membershipApplyDialogOpenSetState(false);
  };
  // 기업 회원 멤버십 취소 dialog close function
  const membershipCancelDialogCloseFunc = () => {
    membershipCancelDialogOpenSetState(false);
  };

  // 기업 회원 멤버십 적용 function
  const updateIncUserMembershipApply = useMutation((targetUid) => UpdateIncUserMembershipApply(targetUid), {
    onSuccess: () => {
      dialogMessageSetState("멤버십 적용이 완료되었습니다.");
      dialogCall();
      membershipApplyDialogCloseFunc();
      incUserList.refetch();
    },
    onError: (error) => {
      console.log(error);
      dialogAlertMessageSetState("멤버십 적용에 실패하였습니다.");
      dialogAlertCall();
    },
  });

  // 기업 회원 멤버십 취소 function
  const updateIncUserMembershipCancel = useMutation((targetUid) => UpdateIncUserMembershipCancel(targetUid), {
    onSuccess: () => {
      dialogMessageSetState("멤버십 취소가 완료되었습니다.");
      dialogCall();
      membershipCancelDialogCloseFunc();
      incUserList.refetch();
    },
    onError: (error) => {
      console.log(error);
      dialogAlertMessageSetState("멤버십 적용에 실패하였습니다.");
      dialogAlertCall();
    },
  });

  // 기업 회원 멤버십 적용 submit event
  const membershipApplyConfirmFunc = () => {
    updateIncUserMembershipApply.mutate(membershipUidState);
  };

  // 기업 회원 멤버십 취소 submit event
  const membershipCancelConfirmFunc = () => {
    updateIncUserMembershipCancel.mutate(membershipUidState);
  };

  // 결제 정보 확인 이벤트
  const paymentInfoEvent = (targetUid) => {
    navigate(`/admin-membership/${targetUid}`);
  };

  // 230130 추가 - 멤버십 결제 처리

  // 230130 추가 - 멤버십 미결제 처리

  // 기업 회원 화면
  const IncUsersComponent = () => {
    return (
      <Grid container rowSpacing={3}>
        <Grid item container xs={12} spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-end">
          <Grid item xs={6}>
            <Typography variant="h4">기업회원 관리</Typography>
          </Grid>
          <AdminSearchBox ref={searchBoxRefSet} page="incUsers" categoryMenuOpenedState={categoryMenuOpenedState} categoryMenuOpenFunc={categoryMenuOpenFunc} categoryMenuCloseFunc={categoryMenuCloseFunc} categoryMenuSelectFunc={categoryMenuSelectFunc} selectedCategoryItemState={searchCategoryState} searchValueChangeFunc={searchValueChangeFunc} searchValueState={searchValueState} />
        </Grid>
        <AdminIncUserTable tableData={incUserList.data} tableDataPageState={tableDataPageState} tableDataPageChange={tableDataPageChange} editButtonEvent={userInfoModalOpenEvent} approvalEvent={authDialogOpenFunc} linkToHomepageEvent={linkToHomepageEvent} brandInfoEvent={brandInfoEvent} paymentInfoEvent={paymentInfoEvent} membershipApplyEvent={membershipApplyDialogOpenFunc} membershipCancelEvent={membershipCancelDialogOpenFunc} />
        <AdminIncUserModal loadState={loadState} modalOpenState={userInfoModalOpenState} modalCloseEvent={userInfoModalCloseEvent} userInfo={incUserList.data[userIdState]} sendPasswordResetFunc={sendPasswordResetFunc} withdrawalFunc={withdrawalFunc} />
        <AdminBrandModal modalOpenState={brandInfoModalOpenState} modalCloseEvent={brandInfoCloseEvent} brandInfo={brandInfo.data} />
        <DialogComponent />
        <DialogAlertComponent />
        <DialogWithButton message="승인하시겠습니까?" dialogOpenState={authDialogOpenState} dialogClose={authDialogCloseFunc} button1Text="취소" button2Text="확인" button1Func={authDialogCloseFunc} button2Func={authConfirmFunc} />
        <DialogWithButton message="탈퇴 처리 하시겠습니까?" dialogOpenState={withdrawalDialogOpenState} dialogClose={withdrawalDialogCloseFunc} button1Text="취소" button2Text="확인" button1Func={withdrawalDialogCloseFunc} button2Func={withdrawalConfirmFunc} />
        <DialogWithButton message="멤버십 가입 처리하시겠습니까?" dialogOpenState={membershipApplyDialogOpenState} dialogClose={membershipApplyDialogCloseFunc} button1Text="취소" button2Text="확인" button1Func={membershipApplyDialogCloseFunc} button2Func={membershipApplyConfirmFunc} />
        <DialogWithButton message="멤버십 취소 처리하시겠습니까?" dialogOpenState={membershipCancelDialogOpenState} dialogClose={membershipCancelDialogCloseFunc} button1Text="취소" button2Text="확인" button1Func={membershipCancelDialogCloseFunc} button2Func={membershipCancelConfirmFunc} />
      </Grid>
    );
  };

  if (incUserList.isFetching) {
    return <LoadingComponent />;
  } else {
    return <AdminBodyFrame contentComponent={IncUsersComponent()} />;
  }
}
// }
