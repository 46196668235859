import React from "react";

import AdminPortfolio from "../../containers/Admin/AdminPortfolioContainer";
import RequireAuthRoute from "../../routes/requireAuth";

export default function AdminUpdatePortfolioView() {
  return (
    <React.Fragment>
      <RequireAuthRoute Component={AdminPortfolio} />
    </React.Fragment>
  );
}
