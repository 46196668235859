import axios from "axios";
import { doOnGetToken } from "./firebaseUtils";

const url = "https://asia-northeast3-project-reble-dev.cloudfunctions.net/service_dev-1";
// const url = "http://34.64.111.91:8080";
// const url = "http://34.64.111.91:8888";

const api = axios.create({
  baseURL: url,
  headers: {
    "Content-type": "application/json; charset=UTF-8",
  },
});

const fileHeaders = {
  "Content-Type": "application/octet-stream",
};

// ----------------------------------------------
// root
// ----------------------------------------------

const rootFormData = {
  type: "settings",
};

// ----------------------------------------------
// auth
// ----------------------------------------------

const signUpFormData = {
  type: "auth",
  sub_type: "sign_up",
};

const signInFormData = {
  type: "auth",
  sub_type: "sign_in",
};

const refreshUserInfoFormData = {
  type: "auth",
  sub_type: "refresh_sign_in",
};

const withdrawalFormData = {
  type: "auth",
  sub_type: "inactive",
};

// ----------------------------------------------
// my page
// ----------------------------------------------

const requestDefaultUserInfoFormData = {
  type: "my_page_default_user",
  sub_type: "read",
};

const updateDefaultUserInfoFormData = {
  type: "my_page_default_user",
  sub_type: "update",
};

const requestIncUserInfoFormData = {
  type: "my_page_inc_user",
  sub_type: "read",
};

const updateIncUserInfoFormData = {
  type: "my_page_inc_user",
  sub_type: "update",
};

// ----------------------------------------------
// my profile
// ----------------------------------------------

const requestDefaultUserProfileFormData = {
  type: "my_profile_default_user",
  sub_type: "read",
};

const updateDefaultUserProfileFormData = {
  type: "my_profile_default_user",
  sub_type: "update",
};

// ----------------------------------------------
// file upload
// ----------------------------------------------

const requestProfilePhotoUrlFormData = {
  type: "get_upload_url",
  sub_type: "profilePhoto",
};

const requestProfileProjectPhotoUrlFormData = {
  type: "get_upload_url",
  sub_type: "profileProject",
};

const requestProjectThumbnailUrlFormData = {
  type: "get_upload_url",
  sub_type: "projectThumbnail",
};

const requestBrandImageUrlFormData = {
  type: "get_upload_url",
  sub_type: "brandImage",
};

const requestWysiwygImageUrlFormData = {
  type: "get_upload_url",
  sub_type: "detailedImage",
};

// ----------------------------------------------
// project list
// ----------------------------------------------

const requestProjectListFormData = {
  type: "project_filter",
  sub_type: "read",
};

// 신청한 프로젝트 리스트
const requestAppliedProjectListFormData = {
  type: "project_default_user",
  sub_type: "read",
};

const requestRecruitingProjectListFormData = {
  type: "project_inc_user",
  sub_type: "filter_read",
  projectState: "start",
};

const requestOngoingProjectListFormData = {
  type: "project_inc_user",
  sub_type: "filter_read",
  projectState: "playing",
};

const requestFinishedProjectListFormData = {
  type: "project_inc_user",
  sub_type: "filter_read",
  projectState: "end",
};
// ----------------------------------------------
// project detail info
// ----------------------------------------------

const requestProjectDetailInfoFormData = {
  type: "project_read",
};

const requestProjectAppliedCheckFormData = {
  type: "project_applied_chk",
};

const requestProjectAppointedCheckFormData = {
  type: "project_appointed_chk",
};

// ----------------------------------------------
// project - default user
// ----------------------------------------------

const applyProjectFormData = {
  type: "project_default_user",
  sub_type: "update",
};

const cancelProjectApplicationFormData = {
  type: "project_default_user",
  sub_type: "delete",
};

// ----------------------------------------------
// project - inc user
// ----------------------------------------------

const requestProjectDocIdFormData = {
  type: "project_docId",
};

const setProjectInfoFormData = {
  type: "project_inc_user",
  sub_type: "update",
};

const cancelProjectFormData = {
  type: "project_inc_user",
  sub_type: "cancel",
};

const deleteProjectFormData = {
  type: "project_inc_user",
  sub_type: "delete",
};

const requestProjectApplicantsListFormData = {
  type: "project_applicants",
  sub_type: "read",
};

const requestApplicantsPortfolioDataFormData = {
  type: "profile_inc_user",
  sub_type: "read",
};

const updateAppointmentFormData = {
  type: "project_applicants",
  sub_type: "update",
};

const cancelAppointmentFormData = {
  type: "project_applicants",
  sub_type: "delete",
};

const requestFinishedProjectAppointersListFormData = {
  type: "project_applicants",
  sub_type: "end_project",
};

// ----------------------------------------------
// brand
// ----------------------------------------------

const requestBrandInfoFormData = {
  type: "brand_read",
};

const requestUserBrandInfoFormData = {
  type: "inc_user_brand",
  sub_type: "read",
};

const updateBrandInfoFormData = {
  type: "inc_user_brand",
  sub_type: "update",
};

// ----------------------------------------------
// Payment
// ----------------------------------------------

const requestPaymentHistoryFormData = {
  type: "payment_history",
  sub_type: "stream",
};

const updatePaymentHistoryFormData = {
  type: "payment_history",
  sub_type: "update",
};

// ----------------------------------------------
// axios code
// ----------------------------------------------

// ----------------------------------------------
// root
// ----------------------------------------------
export const RequestRootInfo = () => {
  const queryFunc = api.post("/", JSON.stringify({ ...rootFormData })).then((post) => post.data);
  return queryFunc;
};

// ----------------------------------------------
// auth
// ----------------------------------------------

export const RequestSignUp = ({ token, userInfo }) => {
  const queryFunc = api.post("/", JSON.stringify({ ...signUpFormData, token: token, ...userInfo })).then((post) => post.data);
  return queryFunc;
};

export const RequestSignIn = ({ token, userType }) => {
  const queryFunc = api.post("/", JSON.stringify({ ...signInFormData, token: token, userType: userType })).then((post) => post.data);
  return queryFunc;
};

export const RequestRefreshUserInfo = ({ token }) => {
  const queryFunc = api.post("/", JSON.stringify({ ...refreshUserInfoFormData, token: token })).then((post) => post.data);
  return queryFunc;
};

export const RequestWithdrawal = ({ userType }) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...withdrawalFormData, token: token, userType: userType })).then((post) => post.data);
  return queryFunc;
};

// ----------------------------------------------
// my page
// ----------------------------------------------

export const RequestDefaultUserInfo = () => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestDefaultUserInfoFormData, token: token })).then((post) => post.data);
  return queryFunc;
};

export const UpdateDefaultUserInfo = (userInfo) => {
  console.log(userInfo);
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...updateDefaultUserInfoFormData, token: token, ...userInfo })).then((post) => post.data);
  return queryFunc;
};

export const RequestIncUserInfo = () => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestIncUserInfoFormData, token: token })).then((post) => post.data);
  return queryFunc;
};

export const UpdateIncUserInfo = (userInfo) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...updateIncUserInfoFormData, token: token, ...userInfo })).then((post) => post.data);
  return queryFunc;
};

// ----------------------------------------------
// my profile
// ----------------------------------------------

export const RequestReadDefaultUserProfile = () => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestDefaultUserProfileFormData, token: token })).then((post) => post.data);
  return queryFunc;
};

export const RequestUpdateDefaultUserProfile = (profileInfo) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...updateDefaultUserProfileFormData, token: token, ...profileInfo })).then((post) => post.data);
  return queryFunc;
};

// ----------------------------------------------
// file upload
// ----------------------------------------------

export function RequestUpload({ formData, signedUrl }) {
  axios.put(signedUrl, formData, { headers: fileHeaders }).catch((error) => {
    console.log(error);
  });
}

export const RequestProfilePhotoUrl = (fileName) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestProfilePhotoUrlFormData, token: token, fileName: fileName })).then((post) => post.data);
  return queryFunc;
};

export const RequestProfileProjectPhotoUrl = (fileName) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestProfileProjectPhotoUrlFormData, token: token, fileName: fileName })).then((post) => post.data);
  return queryFunc;
};

export const RequestWysiwygImageUrl = (projectDocId, fileName) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestWysiwygImageUrlFormData, token: token, fileName: fileName, docId: projectDocId })).then((post) => post.data);
  return queryFunc;
};

export const RequestProjectThumbnailUrl = (projectDocId, fileName) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestProjectThumbnailUrlFormData, token: token, fileName: fileName, docId: projectDocId })).then((post) => post.data);
  return queryFunc;
};

export const RequestBrandImageUrl = (fileName) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestBrandImageUrlFormData, token: token, fileName: fileName })).then((post) => post.data);
  return queryFunc;
};

// ----------------------------------------------
// brand
// ----------------------------------------------

export const RequestBrandInfo = (brandId) => {
  const queryFunc = api.post("/", JSON.stringify({ ...requestBrandInfoFormData, incUserUid: brandId })).then((post) => post.data);
  return queryFunc;
};

export const RequestUserBrandInfo = () => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestUserBrandInfoFormData, token: token })).then((post) => post.data);
  return queryFunc;
};

export const UpdateBrandInfo = (brandInfo) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...updateBrandInfoFormData, token: token, ...brandInfo })).then((post) => post.data);
  return queryFunc;
};

// ----------------------------------------------
// payment
// ----------------------------------------------

export const RequestPaymentHistory = () => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestPaymentHistoryFormData, token: token })).then((post) => post.data);
  return queryFunc;
};

// ----------------------------------------------
// project list
// ----------------------------------------------

export const RequestProjectList = (filteringOption, sortingOption, page) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestProjectListFormData, token: token, ...filteringOption, sortingItems: sortingOption, page: page })).then((post) => post.data);
  return queryFunc;
};

export const RequestAppliedProjectList = () => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestAppliedProjectListFormData, token: token })).then((post) => post.data);
  return queryFunc;
};

export const RequestRecrutingProjectList = () => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestRecruitingProjectListFormData, token: token })).then((post) => post.data);
  return queryFunc;
};

export const RequestOngoingProjectList = () => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestOngoingProjectListFormData, token: token })).then((post) => post.data);
  return queryFunc;
};

export const RequestFinishedProjectList = () => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestFinishedProjectListFormData, token: token })).then((post) => post.data);
  return queryFunc;
};

// ----------------------------------------------
// new project
// ----------------------------------------------

export const RequestProjectDocId = () => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestProjectDocIdFormData, token: token })).then((post) => post.data);
  return queryFunc;
};

export const SetNewProjectInfo = (projectId, projectInfo) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...setProjectInfoFormData, token: token, projectId: projectId, project: { ...projectInfo } })).then((post) => post.data);
  return queryFunc;
};

// ----------------------------------------------
// project detail
// ----------------------------------------------

export const RequestProjectDetail = (projectId) => {
  const queryFunc = api.post("/", JSON.stringify({ ...requestProjectDetailInfoFormData, projectId: projectId })).then((post) => post.data);
  return queryFunc;
};

export const UpdateApplicationProject = (projectId) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...applyProjectFormData, token: token, projectId: projectId })).then((post) => post.data);
  return queryFunc;
};

export const UpdateCancelProjectApplication = (projectId) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...cancelProjectApplicationFormData, token: token, projectId: projectId })).then((post) => post.data);
  return queryFunc;
};

export const requestProjectAppliedCheck = (projectId) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestProjectAppliedCheckFormData, token: token, projectId: projectId })).then((post) => post.data);
  return queryFunc;
};

export const requestProjectAppointedCheck = (projectId) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestProjectAppointedCheckFormData, token: token, projectId: projectId })).then((post) => post.data);
  return queryFunc;
};

// ----------------------------------------------
// project detail - inc users
// ----------------------------------------------

export const RequestProjectApplicantsList = (projectId) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestProjectApplicantsListFormData, token: token, projectId: projectId, projectCollection: "applicants" })).then((post) => post.data);
  return queryFunc;
};

export const RequestProjectAppointersList = (projectId) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestProjectApplicantsListFormData, token: token, projectId: projectId, projectCollection: "appointer" })).then((post) => post.data);
  return queryFunc;
};

export const UpdateProjectDetailValue = (projectId, projectInfo) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...setProjectInfoFormData, token: token, projectId: projectId, project: { ...projectInfo } })).then((post) => post.data);
  return queryFunc;
};

export const RequestApplicantsPortfolioData = (targetUid) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestApplicantsPortfolioDataFormData, token: token, defaultUserId: targetUid })).then((post) => post.data);
  return queryFunc;
};

export const UpdateAppointment = (projectId, targetUid) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...updateAppointmentFormData, token: token, projectId: projectId, userUid: targetUid })).then((post) => post.data);
  return queryFunc;
};

export const CancelAppointment = (projectId, targetUid) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...cancelAppointmentFormData, token: token, projectId: projectId, userUid: targetUid })).then((post) => post.data);
  return queryFunc;
};

export const UpdateCancelProject = (projectId, reasonForCancel) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...cancelProjectFormData, token: token, projectId: projectId, reason: reasonForCancel })).then((post) => post.data);
  return queryFunc;
};

export const UpdateDeleteProject = (projectId) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...deleteProjectFormData, token: token, projectId: projectId })).then((post) => post.data);
  return queryFunc;
};

export const RequestFinishedProjectAppointersList = (projectId) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestFinishedProjectAppointersListFormData, token: token, projectId: projectId, projectCollection: "appointer" })).then((post) => post.data);
  return queryFunc;
};
