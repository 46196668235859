import React from "react";
import moment from "moment";

import AdminModal from "./AdminModal";
import AdminTable from "./AdminTable";
import AdminButton, { HeaderCategory, HeaderIconCategory, TextTableCell, IconButtonTableCell, SaveButton, CancelButton } from "../../components/AdminComponents";

import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

import EditIcon from "@mui/icons-material/Edit";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import SecurityUpdateGoodIcon from "@mui/icons-material/SecurityUpdateGood";
import AppBlockingIcon from "@mui/icons-material/AppBlocking";

import { styled } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const CustomBackdrop = styled(Backdrop)({
  borderRadius: "20px",
});

// admin - 개인 회원 관리
export default function AdminUserTable(props) {
  const { tableData, tableDataPageState, tableDataPageChange, editButtonEvent, linkToPortfolioEvent, linkToAppliedProjectEvent } = props;

  // 테이블 페이지 당 데이터 수
  const dataPerPage = 15;

  const AdminTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <HeaderCategory title="No" align="center" />
          <HeaderCategory title="이름" align="left" />
          <HeaderCategory title="거주지" align="left" />
          <HeaderCategory title="생년월일" align="left" />
          <HeaderCategory title="이메일" align="left" />
          <HeaderCategory title="연락처" align="left" />
          <HeaderIconCategory title="프로필" align="center" icon={<ContactPageIcon />} />
          <HeaderIconCategory title="신청 프로젝트" align="center" icon={<ContactMailIcon />} />
          <HeaderIconCategory title="회원 정보 수정" align="center" icon={<EditIcon />} />
        </TableRow>
      </TableHead>
    );
  };

  const AdminTableBody = () => {
    return (
      <TableBody>
        {Object.keys(tableData)
          .slice(tableDataPageState * dataPerPage, tableDataPageState * dataPerPage + dataPerPage)
          .map((data, index) => {
            return (
              <TableRow component={Paper} key={"tableData" + index}>
                <TextTableCell align="center" fontWeight={700} text={index + 1 + tableDataPageState * dataPerPage} />
                <TextTableCell align="left" fontWeight={700} text={tableData[data].name ? tableData[data].name : "-"} />
                <TextTableCell align="left" fontWeight={400} text={tableData[data].city ? tableData[data].city : "-"} />
                <TextTableCell align="left" fontWeight={400} text={tableData[data].birthDate ? moment(tableData[data].birthDate).format("YYYY.MM.DD") : "-"} />
                <TextTableCell align="left" fontWeight={400} text={tableData[data].email} />
                <TextTableCell align="left" fontWeight={400} text={tableData[data].contact ? tableData[data].contact : "-"} />
                <IconButtonTableCell title="포트폴리오" icon={<ContactPageIcon color="secondary" />} buttonEvent={() => linkToPortfolioEvent(tableData[data].uid)} />
                <IconButtonTableCell title="신청 프로젝트" icon={<ContactMailIcon color="secondary" />} buttonEvent={() => linkToAppliedProjectEvent(tableData[data].uid)} />
                <IconButtonTableCell title="회원 정보 관리" icon={<EditIcon color="secondary" />} buttonEvent={() => editButtonEvent(tableData[data].uid)} />
              </TableRow>
            );
          })}
      </TableBody>
    );
  };

  return <AdminTable TableHeader={<AdminTableHeader />} TableBody={<AdminTableBody />} tableDataArray={Object.keys(tableData)} dataPerPage={dataPerPage} tableDataPageState={tableDataPageState} tableDataPageChange={tableDataPageChange} />;
}

// 개인 회원 관리 - 회원 상세 정보 modal
export function AdminUserModal(props) {
  const { loadState, modalOpenState, modalCloseEvent, userInfo, sendPasswordResetFunc, withdrawalFunc } = props;

  const ModalContext = () => {
    return (
      <Grid container item direction="row" justifyContent="center" xs="auto" rowGap={4}>
        <Grid container item xs={11} rowGap={3}>
          <Grid container item xs={6} columnGap={1}>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={700}>
                이름
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography variant="body1" fontWeight={400}>
                {userInfo.name ? userInfo.name : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={6} columnGap={1}>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={700}>
                생년월일
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography variant="body1" fontWeight={400}>
                {userInfo.birthDate ? moment(userInfo.birthDate).format("YYYY.MM.DD") : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={6} columnGap={1}>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={700}>
                연락처
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography variant="body1" fontWeight={400}>
                {userInfo.contact ? userInfo.contact : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} columnGap={1}>
            <Grid item xs={1.5}>
              <Typography variant="body1" fontWeight={700}>
                이메일
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography variant="body1" fontWeight={400}>
                {userInfo.email ? userInfo.email : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item columnGap={1}>
            <Grid item xs={1.5}>
              <Typography variant="body1" fontWeight={700}>
                주소
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography variant="body1" fontWeight={400}>
                {userInfo.address ? userInfo.address + " " + userInfo.addressDetail : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={6} columnGap={1}>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={700}>
                가입일자
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography variant="body1" fontWeight={400}>
                {userInfo.signUpDate ? moment(userInfo.signUpDate).format("YYYY.MM.DD") : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={6} columnGap={1}>
            <Grid item xs>
              <Typography variant="body1" fontWeight={700}>
                마케팅 활용 동의
              </Typography>
            </Grid>
            <Grid container item xs justifyContent="center" columnGap={2}>
              <Grid item xs="auto">
                {userInfo.marketingAgreement.email ? (
                  <Tooltip title="이메일 수신 동의">
                    <MarkEmailReadIcon color="secondary" />
                  </Tooltip>
                ) : (
                  <Tooltip title="이메일 수신 미동의">
                    <UnsubscribeIcon color="disabled" />
                  </Tooltip>
                )}
              </Grid>
              <Grid item xs="auto">
                {userInfo.marketingAgreement.sms ? (
                  <Tooltip title="SMS 수신 동의">
                    <SecurityUpdateGoodIcon color="secondary" />
                  </Tooltip>
                ) : (
                  <Tooltip title="SMS 수신 미동의">
                    <AppBlockingIcon color="disabled" />
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={11} columnSpacing={1}>
          <Grid item xs={6}>
            <AdminButton buttonText="비밀번호 재설정 이메일 발송" onClickEvent={() => sendPasswordResetFunc()} loadState={loadState} />
          </Grid>
          <Grid item xs={6}>
            <AdminButton buttonText="탈퇴" onClickEvent={() => withdrawalFunc()} loadState={loadState} />
          </Grid>
        </Grid>
        <Grid container item xs={11}>
          <SaveButton buttonText="확인" onClickEvent={modalCloseEvent} loadState={loadState} />
        </Grid>
      </Grid>
    );
  };

  return (
    <AdminModal
      modalOpenState={modalOpenState}
      modalCloseEvent={modalCloseEvent}
      ModalTitle="개인 회원 정보"
      ModalContent={
        userInfo ? (
          <ModalContext />
        ) : (
          <CustomBackdrop open={true}>
            <CircularProgress color="secondary" />
          </CustomBackdrop>
        )
      }
    />
  );
}
