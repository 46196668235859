import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { LabeledCheckBoxSet } from "../../Block/Auth/CheckBoxSet.js";

const GridContainer = styled(Grid)({
  marginTop: "48px",
  padding: "28px 30px",
  borderRadius: "10px",
  backgroundColor: "#F8F8F8"
});

// 개인 회원 마케팅 정보 수신 및 활용 동의
export default function MyPageMarketingAgreement(props) {
  const { checkedState, checkEvent } = props;

  return (
    <GridContainer container item alignItems="end">
      <Grid container item xs={8}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="black.300">
            마케팅 정보 수신 및 활용 동의
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption1" fontWeight={300} color="#4C4C4C">
            * 서비스 운영 관련 중요 안내사항은 위 수신 여부와 관계없이 발송됩니다.
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={4}>
        <Grid item xs={6}>
          <LabeledCheckBoxSet labelVariant="body2" labelColor="#333333" labelWeight={500} labelText="이메일" checkedState={checkedState["email"]} checkEvent={(event) => checkEvent("email", event)} />
        </Grid>
        <Grid item xs={6}>
          <LabeledCheckBoxSet labelVariant="body2" labelColor="#333333" labelWeight={500} labelText="문자메시지" checkedState={checkedState["sms"]} checkEvent={(event) => checkEvent("sms", event)} />
        </Grid>
      </Grid>
    </GridContainer>
  );
}
