import React from "react";

import AdminFinishedDetail from "../../containers/Admin/AdminFinishedDetailContainer";
import RequireAuthRoute from "../../routes/requireAuth";

export default function AdminFinishedDetailView() {
  return (
    <React.Fragment>
      <RequireAuthRoute Component={AdminFinishedDetail} />
    </React.Fragment>
  );
}
