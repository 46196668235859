import React from "react";

import { useRecoilState } from "recoil";
import { emailVarified } from "../../recoil/UserState";

import { styled } from "@mui/material/styles";
import { Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import MyPortfolioSNSBox from "./MyPortfolioSNSBox";
import { portfolioProfileDefaultImageUrl } from "../../configs/urls";

const GridContainer = styled(Grid)({
  paddingTop: "50px",
  paddingBottom: "10px",
});

const CustomGridContainer = styled(Grid)({
  padding: "36px 48px",
  borderRadius: "10px",
  backgroundColor: "#F8F8F8"
});

const ProfileImageBox = styled(Box)({
  width: 84,
  height: 84,
  borderRadius: "50%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  background: "#D9D9D9",
});

const ProfileImage = styled("img")(({ profileImageErrorState }) => ({
  width: profileImageErrorState ? "50%" : "100%",
  height: profileImageErrorState ? "50%" : "100%",
  objectFit: "cover",
}));

const NaverIconBox = styled(Box)({
  width: 20,
  height: 20,
  borderRadius: "5px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  background: "#32B34A",
});

const KakaoIconBox = styled(Box)({
  width: 20,
  height: 20,
  borderRadius: "5px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  background: "#FFE800",
});
const GoogleIconBox = styled(Box)({
  width: 20,
  height: 20,
  borderRadius: "5px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  background: "#F5F6F7",
});

export default function MyPageMenuUpperBox(props) {
  const { portfolioData, valueState } = props;

  const [emailVarifiedState, emailVarifiedSetState] = useRecoilState(emailVarified);

  return (
    <GridContainer container rowGap={7}>
      <Grid container item xs={12} justifyContent="center">
        <Typography variant="h1" color="black.200">
          마이 페이지
        </Typography>
      </Grid>
      <CustomGridContainer container item xs={12}>
        <Grid item xs={1.3} justifyContent="start">
          <ProfileImageBox>{portfolioData ? portfolioData.profilePhotoUrl ? <ProfileImage src={portfolioData.profilePhotoUrl} alt="프로필" /> : <ProfileImage src={portfolioProfileDefaultImageUrl} alt="프로필" /> : null}</ProfileImageBox>
        </Grid>
        <Grid container item xs={7.7}>
          <Grid container item alignItems="end">
            <Grid item xs="auto">
              <Typography variant="h4" fontWeight={400} color="black.300">
                <strong>{valueState.name ? valueState.name : "회원"}</strong>님, 안녕하세요.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight={400} color="black.900">
              {valueState.email}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={1} alignItems="center" justifyContent="center">
          <Divider orientation="vertical" sx={{ height: "60px" }} />
        </Grid>
        <Grid container item xs={2} rowGap={1}>
          <Grid container item xs={12} alignItems="end">
            <Typography variant="body1" fontWeight={400} color="#4D4D4D">
              나의 SNS
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {portfolioData && (portfolioData.sns.instagram !== "" || portfolioData.sns.youtube !== "" || portfolioData.sns.naverBlog !== "" || portfolioData.sns.naverPost !== "") ? <MyPortfolioSNSBox portfolioData={portfolioData} size={"24px"} /> : null}
          </Grid>
        </Grid>
      </CustomGridContainer>
    </GridContainer>
  );
}
