import React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const ContainerBox = styled(Box)({
  minHeight: "100vh",
  padding: "120px 0 90px",
});

// Loading 화면
export default function LoadingComponent() {
  return (
    <ContainerBox>
      <Backdrop open={true}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </ContainerBox>
  );
}
