import React, { forwardRef } from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { CurrentBannerUploadImageBox, NewBannerUploadImageBox } from "../../components/UploadImageBoxComponent";
import { AuthTextField } from "../../components/CustomTextField";

const BannerImageBox = styled(Box)(({ theme, bannerNewPage }) => ({
  border: bannerNewPage ? `2px solid ${theme.palette.secondary.main}` : "2px solid #D9D9D9",
  borderRadius: "4px",
  width: "100%",
  overflow: "hidden",
  aspectRatio: "16 / 9",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}));

const CustomButtonBase = styled(ButtonBase)({
  width: "100%",
  height: "100%",
});

const BannerImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

// 배너 추가 modal contents
export const NewBannerDialogContents = forwardRef((props, forwardRef) => {
  const { newBannerState, newBannerNewPageSetFunc, newBannerUrlSetFunc, urlKeyPressFunc, blobImgSrcState, changeImageButtonFunc, changeImageFunc } = props;
  const { newBannerImageBoxRef, newBannerUrlInputRef } = forwardRef;

  return (
    <Grid container rowGap={1}>
      <Grid item xs={12}>
        <NewBannerUploadImageBox ref={newBannerImageBoxRef} blobImgSrcState={blobImgSrcState} changeImageButtonFunc={changeImageButtonFunc} changeImageFunc={changeImageFunc} />
      </Grid>
      <Grid container item alignItems="center">
        <Grid item xs>
          <AuthTextField ref={newBannerUrlInputRef} placeholder="URL을 입력해주세요" autoFocus={true} type="text" valueState={newBannerState.url} setValueEvent={newBannerUrlSetFunc} onKeyPress={(event) => urlKeyPressFunc(event)} />
        </Grid>
        <Grid item xs="auto">
          <Tooltip title="새 창에서 열기">
            <Switch color="secondary" checked={newBannerState.newPage} onClick={(event) => newBannerNewPageSetFunc(event)} />
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
});

// 배너 수정 modal contents
export const CurrentBannerDialogContents = forwardRef((props, forwardRef) => {
  const { currentBannerState, currentBannerNewPageSetFunc, currentBannerUrlSetFunc, urlKeyPressFunc, blobImgSrcState, changeImageButtonFunc, changeImageFunc, setIndexFunc, currentBannerIndex, bannerList } = props;
  const { currentBannerImageBoxRef, currentBannerUrlInputRef } = forwardRef;

  return (
    <Grid container rowGap={1}>
      <Grid item xs={12}>
        <CurrentBannerUploadImageBox ref={currentBannerImageBoxRef} valueState={currentBannerState.image} blobImgSrcState={blobImgSrcState} changeImageButtonFunc={changeImageButtonFunc} changeImageFunc={changeImageFunc} />
      </Grid>
      <Grid container item alignItems="center">
        <Grid item xs>
          <AuthTextField ref={currentBannerUrlInputRef} placeholder="URL을 입력해주세요" autoFocus={true} type="text" valueState={currentBannerState.url} setValueEvent={currentBannerUrlSetFunc} onKeyPress={(event) => urlKeyPressFunc(event)} />
        </Grid>
        <Grid item xs="auto">
          <Tooltip title="새 창에서 열기">
            <Switch color="secondary" checked={currentBannerState.newPage} onClick={(event) => currentBannerNewPageSetFunc(event)} />
          </Tooltip>
        </Grid>
        <Grid item xs="auto">
          <Select value={currentBannerIndex} onChange={(event) => setIndexFunc(event.target.value)}>
            {bannerList.map((banner, index) => {
              return <MenuItem value={index}>{index + 1}</MenuItem>;
            })}
          </Select>
        </Grid>
      </Grid>
    </Grid>
  );
});

// 리스트에 보여지는 배너 이미지 박스
export function CurrentBannerImageBox(props) {
  const { bannerImageUrl, bannerLinkUrl, bannerNewPage, changeValueFunc, bannerIndex, dialogBannerIndex, blobImgSrcState } = props;

  return (
    <BannerImageBox bannerNewPage={bannerNewPage}>
      <CustomButtonBase onClick={changeValueFunc}>
        <Tooltip title={bannerNewPage ? bannerLinkUrl + " / 새 창" : bannerLinkUrl + " / 현재 창"}>{bannerIndex === dialogBannerIndex && blobImgSrcState ? <BannerImage src={blobImgSrcState} /> : <BannerImage src={bannerImageUrl} />}</Tooltip>
      </CustomButtonBase>
    </BannerImageBox>
  );
}
