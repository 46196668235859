import React from "react";

import { Link } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { portfolioProfileDefaultImageUrl } from "../../configs/urls";

const ProfilePaper = styled(Paper)({
  padding: "30px 30px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.08)",
  borderRadius: "20px",
});

const ProfileImageBox = styled(Box)({
  width: 56,
  height: 56,
  borderRadius: "50%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  background: "#D9D9D9",
});

const ProfileImage = styled("img")(({ profileImageErrorState }) => ({
  width: profileImageErrorState ? "50%" : "100%",
  height: profileImageErrorState ? "50%" : "100%",
  objectFit: "cover",
}));

const CustomButton = styled(Button)({
  height: "60px",
  borderRadius: "10px",
});

const IntroductionGrid = styled(Grid)({
  minHeight: "104px",
  padding: "19px 24px",
  background: "#FBFBFB",
  borderRadius: "10px",
});

// 개인 회원 포트폴리오 페이지 프로필 박스
export default function MyPortfolioProfileBox(props) {
  const { portfolioData } = props;

  const profilePhotoError = (event) => {
    event.target.src = portfolioProfileDefaultImageUrl;
  };

  return (
    <ProfilePaper elevation={0}>
      <Grid container direction="row" justifyContent="center" justifyItems="center" rowGap={3}>
        <Grid item xs={3}>
          <ProfileImageBox><ProfileImage src={portfolioData.profilePhotoUrl} onError={profilePhotoError} alt="프로필" /></ProfileImageBox>
        </Grid>
        <Grid item xs={9} alignSelf="center">
          <Typography variant="h6" fontWeight={700} color="black.300">
            {portfolioData.name ? portfolioData.name : "회원"}님
          </Typography>
        </Grid>
        <IntroductionGrid container item>
          <Grid item xs={12} sx={{ "overflowWrap": "anywhere" }}>
            <Typography variant="subtitle2" fontWeight={400} color="black.600" lineHeight="24px">
              {portfolioData.introduction !== "" ? portfolioData.introduction : "등록된 자기소개가 없습니다."}
            </Typography>
          </Grid>
        </IntroductionGrid>
          <Grid item xs={12}>
            <CustomButton variant="contained" color="secondary" fullWidth component={Link} to="/update-portfolio">
              <Typography variant="subtitle1" color="primary">
                프로필 수정
              </Typography>
            </CustomButton>
          </Grid>
      </Grid>
    </ProfilePaper>
  );
}
