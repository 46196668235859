import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { LabeledCheckBoxSet } from "./CheckBoxSet";
import { OutlinedButton, ContainedButton } from "../../components/ButtonComponents";

import CheckIcon from '@mui/icons-material/Check';

const WarningGrid = styled(Grid)(({ theme })=>({
  borderRadius: "10px",
  backgroundColor: "#FCF7F7",
  padding: "17px",
}));

const IconGridItem = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "16px"
});

// 회원 탈퇴 동의 화면
export default function WithdrawalAgreeContent(props) {
  const { userInfo, agreeState, agreeCheckEvent, cancelButtonEvent, submitButtonEvent, loadState } = props;

  return (
    <Grid container item rowSpacing={3.9}>
      <Grid container item rowSpacing={11.5}>
        <Grid item xs={12}>
          <Typography variant="h4" fontWeight={400} lineHeight="33px">
            {userInfo ? (userInfo.name ? userInfo.name + " " : null) : null}회원님,
            <br />
            <strong>on1016 서비스를 탈퇴</strong>하시겠습니까?
          </Typography>
        </Grid>
      </Grid>
      <Grid container item>
        <WarningGrid item xs={12}>
          <Grid item>
            <IconGridItem item >
              <CheckIcon sx={{ color: "#F45452", fontSize: "14px", marginRight: "3px" }} />
              <Typography variant="subtitle1" color="error" fontWeight={500} lineHeight="16px" >
                탈퇴 전, 꼭 확인해주세요!
              </Typography>
            </IconGridItem>
            <Typography variant="body1" color="black.300" lineHeight="24px" >
            {userInfo ? (userInfo.name ? userInfo.name + " " : null) : null} 회원님의 포트폴리오를 포함한 모든 이용 내역이 삭제되며
              <br />
              삭제된 계정과 데이터는 다시 복구되지 않습니다.
            </Typography>
          </Grid>
        </WarningGrid>
      </Grid>
      <Grid item xs="auto">
        <LabeledCheckBoxSet labelText="주의사항을 확인했으며, 이에 동의합니다." labelVariant="subtitle1" labelColor="#222222" labelWeight={500} checkedState={agreeState} checkEvent={agreeCheckEvent} />
      </Grid>
      <Grid container item columnSpacing={1.7}>
        <Grid item xs={6}>
          <OutlinedButton buttonEvent={cancelButtonEvent} buttonText="취소" />
        </Grid>
        <Grid item xs={6}>
          {loadState ? (
            <Button variant="contained" color="secondary" fullWidth style={{ borderRadius: "10px" }}>
              <CircularProgress />
            </Button>
          ) : (
            <ContainedButton buttonEvent={submitButtonEvent} buttonText="탈퇴하기" buttonDisabled={!agreeState} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
