import React from "react";

import UniversalAppBar from "../Block/Appbar/UniversalAppBar";
import UniversalAppFooter from "../Block/Footer/UniversalAppFooter";
import Main from "../containers/Root";

export default function MainViews() {
  return (
    <React.Fragment>
      <UniversalAppBar />
      <Main />
      <UniversalAppFooter />
    </React.Fragment>
  );
}
