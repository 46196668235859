import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";

const CustomPaper = styled(Paper)({
  width: "100vw",
  padding: "24px 0",
  position: "sticky",
  bottom: 0,
  left: 0,
  zIndex: 50,
  borderTop: "1px solid #E6E6E6"
});

const CustomContainer = styled(Container)({
  "&.MuiContainer-root": {
    paddingRight: "0px",
    paddingLeft: "0px",
},
})

const CustomButtonBase = styled(ButtonBase)({
  borderRadius: "30px",
  width: "100%",
});

const CustomButtonTypo = styled(Typography)(({ theme }) => ({
  background: theme.palette.secondary.main,
  borderRadius: "30px",
  padding: "16px 0",
  width: "150px",
}));

// 포트폴리오 수정 페이지 저장 버튼
export default function UpdatePortfolioSaveFixedBox(props) {
  const { onClickEvent } = props;

  return (
    <CustomPaper elevation={0}>
      <CustomContainer maxWidth="lg">
        <Grid container justifyContent="flex-end">
          <Grid item>
            <CustomButtonBase onClick={() => onClickEvent()}>
              <CustomButtonTypo variant="subtitle1" color="primary">
                프로필 저장
              </CustomButtonTypo>
            </CustomButtonBase>
          </Grid>
        </Grid>
      </CustomContainer>
    </CustomPaper>
  );
}
