import React, { forwardRef, useEffect } from "react";

import { useMutation } from "react-query";

import { ToastUIEditor, ImageToastUIEditor } from "../../components/ToastUIEditor";

import { RequestWysiwygImageUrl, RequestUpload } from "../../utils/requestToSrv";

// 이미지 업로드를 위한 ToastUIEditor 구성
export const ImageToastUIEditorBlock = forwardRef((props, forwardRef) => {
  const { uid, projectDocId, initialValue, placeholder, editorSize } = props;

  const fileUploadWysiwygImage = useMutation(({ fileData, signedUrl }) => RequestUpload({ formData: fileData, signedUrl: signedUrl }), {
    onError: (error) => {
      console.log(error);
    },
  });

  const getWysiwygImageUrl = useMutation((fileName) => RequestWysiwygImageUrl(projectDocId, fileName), {
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (forwardRef.current) {
      forwardRef.current.getInstance().removeHook("addImageBlobHook");
      forwardRef.current.getInstance().addHook("addImageBlobHook", async (blob, callback) => {
        const newFileName = blob.name.replaceAll(" ", "_").replaceAll("(", "").replaceAll(")", "");
        getWysiwygImageUrl.mutate(newFileName, {
          onSuccess: (data) => {
            fileUploadWysiwygImage.mutate(
              { fileData: blob, signedUrl: data },
              {
                onSuccess: () => {
                  setTimeout(() => {
                    callback("https://storage.googleapis.com/project-reble-dev/project/" + uid + "/" + projectDocId + "/projectDetail/" + newFileName, "image");
                  }, 2000);
                },
              }
            );
          },
        });
      });
    }
    return () => {};
  }, [forwardRef]);

  useEffect(() => {
    if (forwardRef.current) {
      forwardRef.current.getInstance().setMarkdown(initialValue);
    }
  }, [initialValue]);

  return <ImageToastUIEditor ref={forwardRef} placeholder={placeholder} editorSize={editorSize} />;
});

// 이미지 업로드를 제외한 ToastUIEditor 구성
export const ToastUIEditorWithNoImageBlock = forwardRef((props, forwardRef) => {
  const { placeholder, initialValue, editorSize } = props;

  useEffect(() => {
    if (forwardRef.current) {
      forwardRef.current.getInstance().removeToolbarItem("image");
    }
    return () => {};
  }, [forwardRef]);

  useEffect(() => {
    if (forwardRef.current) {
      forwardRef.current.getInstance().setMarkdown(initialValue);
    }
  }, [initialValue]);

  return <ToastUIEditor ref={forwardRef} placeholder={placeholder} editorSize={editorSize} />;
});
