import React, { Fragment, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

import AuthBodyFrame from "../../../Block/Auth/AuthBodyFrame";
import WithdrawalAgreeContent from "../../../Block/Auth/WithdrawalAgreeContent";
import { WithdrawalConfirmContent } from "../../../Block/Auth/WithdrawalConfirmContent";
import { DialogWithOneButton } from "../../../components/DialogWithButton";
// import { DialogAlertMessage } from "../../../components/DialogMessage";
import { CustomizedSnackbars } from "../../../components/Alert";

import { doDeleteUser } from "../../../utils/firebaseUtils";
import { RequestWithdrawal } from "../../../utils/requestToSrv";

export default function WithdrawalContainer() {
  // 화면 로드 시 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // react-router-dom navigate
  const navigate = useNavigate();

  // react-query queryClient
  const queryClient = useQueryClient();

  // data loading state
  const [loadState, loadSetState] = useState(false);

  // const additionalReasonRef = useRef(null);

  // const [contentsSwitchState, contentsSwitchSetState] = useState(false);

  // 회원 탈퇴 value state
  const [withdrawalValueState, withdrawalValueSetState] = useState({
    agreement: false,
    //   reason: {
    //     rejoin: false,
    //     noUse: false,
    //     otherService: false,
    //     unsatisfied: false,
    //     additionalReason: "",
    //   },
  });

  // useEffect(() => {
  //   return () => {
  //     contentsSwitchSetState(false);
  //     withdrawalValueSetState({
  //       agreement: false,
  //       reason: {
  //         rejoin: false,
  //         noUse: false,
  //         otherService: false,
  //         unsatisfied: false,
  //         additionalReason: "",
  //       },
  //     });
  //   };
  // }, []);

  // 회원 탈퇴 동의 체크박스 이벤트
  const withdrawalAgreeCheckEvent = (event) => {
    withdrawalValueSetState({
      ...withdrawalValueState,
      agreement: event.target.checked,
    });
  };

  // const reasonCheckEvent = (key, event) => {
  //   withdrawalValueSetState({
  //     ...withdrawalValueState,
  //     reason: {
  //       ...withdrawalValueState.reason,
  //       [key]: event.target.checked,
  //     },
  //   });
  // };

  // const additionalReasonEvent = (event) => {
  //   withdrawalValueSetState({
  //     ...withdrawalValueState,
  //     reason: {
  //       ...withdrawalValueState.reason,
  //       additionalReason: event.target.value,
  //     },
  //   });
  // };

  // const [inputFocusState, inputFocusSetState] = useState(false);

  // const inputFocusEvent = () => {
  //   inputFocusSetState(true);
  // };

  // const inputBlurEvent = () => {
  //   inputFocusSetState(false);
  // };

  // 회원 탈퇴 취소 버튼 이벤트
  const cancelButtonEvent = () => {
    // contentsSwitchSetState(false);
    navigate("/my-page");
  };

  // const nextButtonEvent = () => {
  //   contentsSwitchSetState(true);
  // };

  // 회원 탈퇴 완료 dialog open state
  const [dialogOpenState, dialogOpenSetState] = useState(false);
  // alert dialog message text state
  const [alertState, setAlertState] = useState("");
  const [dialogErrorMessageState, dialogErrorMessageSetState] = useState("");
  // alert dialog message custom hook
  // const { alertDialogCall, DialogAlertComponent } = DialogAlertMessage(dialogErrorMessageState);
  const {handleClick, AlertComponent} = CustomizedSnackbars(alertState, dialogErrorMessageState);

  // 회원 탈퇴 request function
  const requestWithdrawal = useMutation(() => RequestWithdrawal({ userType: queryClient.getQueryData("userInfo").userType }), {
    onSuccess: () => {
      setAlertState("success");
      doDeleteUser({
        loadSetState: loadSetState,
        callBack: () => {
          queryClient.clear();
          dialogOpenSetState(true);
        },
        alertDialogCall: handleClick,
        setAlertDialogMessage: dialogErrorMessageSetState,
      });
    },
    onError: () => {
      loadSetState(false);
      setAlertState("error");
      dialogErrorMessageSetState("회원 탈퇴에 실패했습니다. 잠시 후 다시 시도해주세요.");
      handleClick();
    },
  });

  // 회원 탈퇴 submit 이벤트
  const submitButtonEvent = () => {
    loadSetState(true);
    requestWithdrawal.mutate();
  };

  // 회원 탈퇴 dialog close event
  // const dialogCloseEvent = () => {
  //   dialogOpenSetState(false);
  // };

  // 회원 탈퇴 후 메인 페이지로 이동
  const dialogConfirmEvent = () => {
    navigate("/");
  };

  // const contentsSwitcher = () => {
  //   if (contentsSwitchState) {
  //     return <WithdrawalConfirmContent ref={additionalReasonRef} valueState={withdrawalValueState} reasonCheckEvent={reasonCheckEvent} additionalReasonEvent={additionalReasonEvent} inputFocusState={inputFocusState} inputFocusEvent={inputFocusEvent} inputBlurEvent={inputBlurEvent} cancelButtonEvent={cancelButtonEvent} submitButtonEvent={submitButtonEvent} />;
  //   } else {
  //     return <WithdrawalAgreeContent userInfo={{ name: "test" }} agreeState={withdrawalValueState.agreement} agreeCheckEvent={withdrawalAgreeCheckEvent} cancelButtonEvent={cancelButtonEvent} nextButtonEvent={nextButtonEvent} />;
  //   }
  // };

  // 회원 탈퇴 화면
  return (
    <Fragment>
      <AuthBodyFrame title="회원 탈퇴하기" content={<WithdrawalAgreeContent userInfo={queryClient.getQueryData("userInfo")} agreeState={withdrawalValueState.agreement} agreeCheckEvent={withdrawalAgreeCheckEvent} cancelButtonEvent={cancelButtonEvent} submitButtonEvent={submitButtonEvent} loadState={loadState} />} />;{/* <AuthBodyFrame title="탈퇴하기" subTitle={contentsSwitchState ? "소중한 의견 남겨주시면 더 좋은 서비스로 개선하겠습니다." : ""} content={contentsSwitcher()} />; */}
      <DialogWithOneButton width={480} icon={"check"} title={"탈퇴 완료"} subtitle={["탈퇴과 완료되었습니다.", <br />, "그동안 이용해주셔서 감사합니다."]} buttonText="확인" dialogOpenState={dialogOpenState} buttonFunc={dialogConfirmEvent} />
      {/*<DialogAlertComponent />*/}
      <AlertComponent/>
    </Fragment>
  );
}
