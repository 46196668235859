import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";

import { RequestFinishedProjectList } from "../../../utils/requestToSrv";

import MyPageBodyFrame from "../../../Block/IncUserMyPage/MyPageBodyFrame";
import MyPageMenuBox from "../../../Block/IncUserMyPage/MyPageMenuBox";

import ProjectListBodyFrame, { FinishedProjectHead, FinishedProjectList } from "../../../Block/IncUserMyPage/ProjectList";
import LoadingComponent from "../../../components/LoadingComponent";

// 종료된 프로젝트 페이지
export default function FinishedProjectContainer() {
  // react-query queryClient
  const queryClient = useQueryClient();

  // 종료 프로젝트 data load
  const finishedProjectList = useQuery("finishedProjectList", RequestFinishedProjectList, {
    onError: (error) => {
      console.log(error);
    },
  });

  // 화면 로드 시 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // react-router-dom navigate
  const navigate = useNavigate();

  // 페이지 당 데이터 수
  const dataPerPage = 10;

  // 종료 프로젝트 table page state
  const [tableDataPageState, tableDataPageSetState] = useState(1);

  // table page 이동 function
  const tableDataPageChange = (event, newPage) => {
    tableDataPageSetState(newPage);
  };

  // table row 클릭 시 종료 프로젝트 상세 페이지 이동 function
  const tableRowClickFunc = (index, projectId) => {
    queryClient.setQueryData(["finishedProjectDetail", projectId], finishedProjectList.data[index].document);
    navigate("/finished-project/" + projectId);
  };

  // 종료 프로젝트 페이지 화면
  if (finishedProjectList.isFetching) {
    return <LoadingComponent />;
  } else {
    return <MyPageBodyFrame title="종료 프로젝트" text="종료된 프로젝트 목록입니다." MyPageLeftContents={<MyPageMenuBox page="/finished-project" />} MyPageRightContents={<ProjectListBodyFrame tableHeadContents={<FinishedProjectHead />} tableBodyContents={<FinishedProjectList tableDataArray={finishedProjectList.data} dataPerPage={dataPerPage} tableDataPageState={tableDataPageState} tableRowClickFunc={tableRowClickFunc} />} tableDataArray={finishedProjectList.data} dataPerPage={dataPerPage} tableDataPageState={tableDataPageState} tableDataPageChange={tableDataPageChange} />} />;
  }
}
