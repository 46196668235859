import React from "react";

import AdminRecruitingProject from "../../containers/Admin/AdminRecruitingProjectContainer";
import RequireAuthRoute from "../../routes/requireAuth";

export default function AdminRecruitingProjectView() {
  return (
    <React.Fragment>
      <RequireAuthRoute Component={AdminRecruitingProject} />
    </React.Fragment>
  );
}
