import React, { forwardRef, useState } from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputBase from "@mui/material/InputBase";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { passwordTextFieldIconUrl } from "../configs/urls";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SelectStyledTextField = styled(InputBase)(({ theme, errorState }) => ({
  height: 60,
  borderRadius: 10,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "#D9D9D9",
  background: "#ffffff",
  padding: 16,
  fontSize: 16,
  "&:hover": {
    borderColor: "#D9D9D9",
  },
  "&.Mui-focused": {
    borderColor: "#D9D9D9",
  },
  input: {
    "&::placeholder": {
      fontSize: 16,
    },
  },
  " .MuiSelect-icon": {
    right: "16px",
  },
}));

const StyledTextField = styled(InputBase)(({ theme, errorState }) => ({
  height: 48,
  borderRadius: 5,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: errorState ? "#ED2727" : theme.palette.grey[200],
  background: "#ffffff",
  padding: 16,
  fontSize: 14,
  "&:hover": {
    borderColor: "#333333",
  },
  "&.Mui-focused": {
    borderColor: "#333333",
  },
  input: {
    "&::placeholder": {
      fontSize: 14,
    },
  },
}));

const StyledDateTextField = styled(TextField)(({ theme }) => ({
  height: 48,
  background: "#ffffff",
  ".MuiOutlinedInput-root": {
    borderRadius: 5,
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.grey[200],
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#333333",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: "1px solid #333333",
    },
  },
  input: {
    fontSize: 14,
    "&::placeholder": {
      fontSize: 14,
    },
  },
}));

const IntroduceTextFieldBox = styled(Box)(({ theme, inputFocusState }) => ({
  borderRadius: 5,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: inputFocusState ? "#333333" : theme.palette.grey[200],
  padding: 16,
  "&:hover": {
    borderColor: "#333333",
  },
}));

const IntroduceInputBase = styled(InputBase)({
  fontSize: 14,
  input: {
    "&::placeholder": {
      fontSize: 14,
    },
  },
});

const OutlinedTextFieldWithoutLabel = styled(TextField)(({ theme, readOnly }) => ({
  "& .MuiOutlinedInput-root": {
    height: 60,
    background: readOnly ? "#FAFAFA" : "#ffffff",
    fontSize: 16,
    paddingLeft: "6px",
    border: "1px solid #D9D9D9",
    borderRadius: 10,
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
    },
  },
}));

const FilledTextFieldWithLabel = styled(TextField)(({ theme, error, readOnly }) => ({
  backgroundColor: "#ffffff",
  "& .MuiFilledInput-root": {
    height: 60,
    border: "1px solid #D9D9D9",
    borderColor: !error ? theme.palette.grey[200] : "#F45452",
    borderRadius: 10,
    background: readOnly ? "#FAFAFA" : "#ffffff",
    fontSize: 14,
    paddingLeft: "6px",
    "&:hover": {
      backgroundColor: readOnly ? "#FAFAFA" : "#ffffff",
    },
    "&.Mui-focused": {
      backgroundColor: readOnly ? "#FAFAFA" : "#ffffff",
    },
  },
  "& .MuiFormLabel-root": {
    top: "3px",
    left: "8px",
    color: !error ? theme.palette.grey[200] + " !important" : "#F45452",
  },
  "& .MuiFormHelperText-root": {
    color: "#F45452 !important",
  },
}));

const FilledMultilineTextField = styled(TextField)(({ theme, readOnly }) => ({
  "& .MuiFilledInput-root": {
    border: "1px solid #D9D9D9",
    borderColor: theme.palette.grey[200],
    borderRadius: 10,
    background: readOnly ? "#FAFAFA" : "#ffffff",
    fontSize: 14,
    paddingTop: "30px",
    paddingLeft: "18px",
    paddingBottom: "27px",
    lineHeight: "16px",
    "&:hover": {
      backgroundColor: readOnly ? "#FAFAFA" : "#ffffff",
    },
    "&.Mui-focused": {
      backgroundColor: readOnly ? "#FAFAFA" : "#ffffff",
    },
  },
  "& .MuiFormLabel-root": {
    top: "3px",
    left: "8px",
    color: theme.palette.grey[200] + " !important",
  },
  "& .MuiFormHelperText-root": {
    display: "flex",
    position: "absolute",
    color: "#999999",
    alignSelf: "end",
    bottom: "8px",
  },
}));

// custom textfield
export const AuthTextField = forwardRef((props, forwardRef) => {
  const { placeholder, autoFocus, type, valueState, setValueEvent, onKeyPress, inputProps, errorState, readOnly } = props;

  const [showPasswordState, showPasswordSetState] = useState(false);

  const setPasswordShow = () => {
    showPasswordSetState((prevState) => !prevState);
  };

  if (type === "password") {
    return (
      <StyledTextField
        fullWidth
        inputRef={forwardRef}
        type={showPasswordState ? "text" : "password"}
        placeholder={placeholder}
        autoFocus={autoFocus}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={() => setPasswordShow()}>{showPasswordState ? <img src={passwordTextFieldIconUrl.passwordVisible} alt="비밀번호 공개" /> : <img src={passwordTextFieldIconUrl.passwordInvisible} alt="비밀번호 비공개" />}</IconButton>
          </InputAdornment>
        }
        value={valueState}
        onChange={(event) => setValueEvent(event)}
        onKeyPress={(event) => onKeyPress(event)}
        errorState={errorState}
      />
    );
  } else {
    return <StyledTextField fullWidth inputRef={forwardRef} type={type} placeholder={placeholder} autoFocus={autoFocus} value={valueState} onChange={(event) => setValueEvent(event)} onKeyPress={(event) => onKeyPress(event)} inputProps={inputProps ? inputProps : {}} errorState={errorState} readOnly={readOnly} />;
  }
});

// custom textfield - 라벨 있는 Filled 텍스트필드 (on1016 사용)
export const AuthTextFieldWithLabel = forwardRef((props, forwardRef) => {
  const { label, autoFocus, type, valueState, setValueEvent, onKeyPress, onBlur, inputProps, errorState, readOnly = false } = props;

  const [showPasswordState, showPasswordSetState] = useState(false);

  const setPasswordShow = () => {
    showPasswordSetState((prevState) => !prevState);
  };

  if (type === "password") {
    return (
      <FilledTextFieldWithLabel
        fullWidth
        variant="filled"
        label={label}
        inputRef={forwardRef}
        type={showPasswordState ? "text" : "password"}
        autoFocus={autoFocus}
        value={valueState}
        onChange={(event) => setValueEvent(event)}
        onKeyPress={(event) => onKeyPress(event)}
        onBlur={(event) => onBlur(event)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setPasswordShow()}>{showPasswordState ? <img src={passwordTextFieldIconUrl.passwordVisible} alt="비밀번호 공개" /> : <img src={passwordTextFieldIconUrl.passwordInvisible} alt="비밀번호 비공개" />}</IconButton>
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        inputProps={{ readOnly: readOnly, ...inputProps }}
        error={errorState}
        readOnly={readOnly}
      />
    );
  } else {
    return <FilledTextFieldWithLabel fullWidth variant="filled" label={label} inputRef={forwardRef} type={type} autoFocus={autoFocus} value={valueState} onChange={(event) => setValueEvent(event)} onKeyPress={(event) => onKeyPress(event)} onBlur={(event) => onBlur(event)} InputProps={{ disableUnderline: true }} inputProps={{ readOnly: readOnly, ...inputProps }} error={errorState} readOnly={readOnly} />;
  }
});

// custom textfield - 라벨 없는 Outlined 텍스트필드 (on1016 사용)
export const TextFieldWithoutLabel = forwardRef((props, forwardRef) => {
  const { placeholder, autoFocus, type, valueState, setValueEvent, onKeyPress, inputProps, errorState, readOnly } = props;

  return <OutlinedTextFieldWithoutLabel fullWidth placeholder={placeholder} inputRef={forwardRef} type={type} autoFocus={autoFocus} value={valueState} onChange={(event) => setValueEvent(event)} onKeyPress={(event) => onKeyPress(event)} inputProps={{ ...inputProps }} error={errorState} readOnly={readOnly} />;
});

// custom textfield - end adornment
export const TextFieldWithEndAdornment = forwardRef((props, forwardRef) => {
  const { type, valueState, adornment, readOnly } = props;

  return <StyledTextField endAdornment={adornment} fullWidth inputRef={forwardRef} type={type} value={valueState} readOnly={readOnly} />;
});

// custom textfield - date picker에서 사용
export function DateTextField(props) {
  const { placeholder, params } = props;

  return (
    <StyledDateTextField
      fullWidth
      {...params}
      inputProps={{
        ...params.inputProps,
        placeholder: placeholder,
      }}
    />
  );
}

// custom textfield - date picker (on1016 사용)
export function DateTextFieldWithoutLabel(props) {
  const { placeholder, params } = props;

  return (
    <OutlinedTextFieldWithoutLabel
      fullWidth
      {...params}
      inputProps={{
        ...params.inputProps,
        placeholder: placeholder,
      }}
    />
  );
}

// custom textfield - 장문
export const IntroduceTextField = forwardRef((props, forwardRef) => {
  const { valueState, valueChange, inputFocusState, inputFocusEvent, inputBlurEvent, placeholder, maxLength } = props;

  return (
    <Grid container rowSpacing={0.8}>
      <Grid item xs={12}>
        <IntroduceTextFieldBox inputFocusState={inputFocusState}>
          <IntroduceInputBase inputRef={forwardRef} fullWidth placeholder={placeholder} multiline minRows={3} value={valueState} onChange={valueChange} inputProps={{ maxLength: maxLength }} onFocus={() => inputFocusEvent()} onBlur={() => inputBlurEvent()} />
        </IntroduceTextFieldBox>
      </Grid>
      <Grid container item justifyContent="flex-end">
        <Grid item>
          <Typography variant="body2" color="#999999">
            {valueState.length} / {maxLength}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
});

// custom textfield - 장문 (on1016 사용)
export const TextFieldMultiline = forwardRef((props, forwardRef) => {
  const { label, rows, placeholder, autoFocus, type, valueState, setValueEvent, inputProps, errorState, readOnly, characterLimit } = props;

  return <FilledMultilineTextField fullWidth variant="filled" multiline rows={rows} label={label} placeholder={placeholder} inputRef={forwardRef} type={type} autoFocus={autoFocus} value={valueState} onChange={(event) => setValueEvent(event)} InputProps={{ disableUnderline: true, ...inputProps }} inputProps={{ maxLength: characterLimit }} error={errorState} readOnly={readOnly} helperText={`${valueState.length}/${characterLimit}`} />;
});

// custom select
export const SelectTextField = forwardRef((props, forwardRef) => {
  const { menu, placeholder, selectedValue, selectedLabel, selectChange, valueKey } = props;

  const MenuItems = () => {
    return [
      Object.keys(menu).map((data) => {
        return (
          <MenuItem value={data} key={data}>
            <Typography variant="body2" fontWeight={400} lineHeight="24px" color="#333333">
              {menu[data]}
            </Typography>
          </MenuItem>
        );
      }),
    ];
  };

  return (
    <FormControl variant="standard" fullWidth>
      <Select
        ref={forwardRef}
        value={selectedValue}
        onChange={(event) => selectChange(valueKey, event)}
        input={<SelectStyledTextField />}
        displayEmpty
        MenuProps={{ elevation: 0, PaperProps: { sx: { border: "1px solid #D9D9D9" } } }}
        renderValue={(value) =>
          value !== "" ? (
            selectedLabel
          ) : (
            <Typography variant="subtitle1" color="#999999">
              {placeholder}
            </Typography>
          )
        }
        IconComponent={ExpandMoreIcon}
      >
        {MenuItems()}
      </Select>
    </FormControl>
  );
});
