import React, { useState } from "react";
import { useQueryClient } from "react-query";

import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Fade from "@mui/material/Fade";

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessIcon from "@mui/icons-material/Business";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import LogoutIcon from "@mui/icons-material/Logout";

import { doSignOut } from "../../utils/firebaseUtils";

import { logoUrl } from "../../configs/urls";

const DrawerWidth = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "naviBarSlideState",
})(({ naviBarSlideState }) => ({
  width: naviBarSlideState ? "70px" : "250px",
  overflowX: "hidden",
  transition: "all 0.7s ease",
  "& .MuiDrawer-paper": {
    width: naviBarSlideState ? "70px" : "250px",
    overflowX: "hidden",
    transition: "all 0.7s ease",
  },
}));

const ArrowRightIcon = styled(ArrowForwardIosRoundedIcon)({
  color: " #000000",
});
const ArrowLeftIcon = styled(ArrowBackIosNewRoundedIcon)({
  color: " #000000",
});

const NavigationBarText = styled(Typography)(({ naviBarSlideState }) => ({
  width: "100%",
  padding: "5px 30px",
  visibility: naviBarSlideState ? "hidden" : "visible",
}));

const ContentContainer = styled(Container)({
  paddingTop: "40px",
  backgroundColor: "#f6fefa",
  minHeight: "100vh",
});

const LogoGridItem = styled(Grid)(({ naviBarSlideState }) => ({
  visibility: naviBarSlideState ? "hidden" : "visible",
}));

const ArrowGridItem = styled(Grid)({
  padding: "15px 0",
});

const LogoImg = styled("img")({
  width: "100%",
});

// 관리자 화면 frame 구성
export default function AdminBodyFrame(props) {
  const { contentComponent } = props;

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  // 메뉴바 확장, 축소 state
  const [naviBarSlideState, naviBarSlideSetState] = useState(false);

  // 메뉴바 확장, 축소 이벤트
  const naviBarSlideButtonClickEvent = () => {
    naviBarSlideSetState((prevState) => !prevState);
  };

  // sign out callback function
  // react query cache clear 후 메인 페이지로 이동
  const querySignOut = () => {
    queryClient.clear();
    navigate("/");
  };

  // sign out 이벤트
  const signOut = () => {
    doSignOut(querySignOut);
  };

  // 메뉴바 구성
  const navigationBar = () => {
    return (
      <Grid item>
        <DrawerWidth variant="permanent" open={naviBarSlideState} naviBarSlideState={naviBarSlideState} PaperProps={{ elevation: 4 }}>
          <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
            <Grid item xs={2} />
            <LogoGridItem item xs={4} naviBarSlideState={naviBarSlideState}>
              <Fade in={!naviBarSlideState}>
                <LogoImg src={logoUrl.logo} alt="로고" />
              </Fade>
            </LogoGridItem>
            <ArrowGridItem item>
              <IconButton onClick={() => naviBarSlideButtonClickEvent()}>{naviBarSlideState ? <ArrowRightIcon /> : <ArrowLeftIcon />}</IconButton>
            </ArrowGridItem>
          </Grid>
          <Divider />
          <MenuList>
            <MenuItem component={Link} to={"/admin-users"}>
              <AccountCircleIcon fontSize="medium" />
              <NavigationBarText variant="body3" component="p" align="center" fontWeight="bold" naviBarSlideState={naviBarSlideState}>
                개인회원
              </NavigationBarText>
            </MenuItem>
            <MenuItem component={Link} to={"/admin-incusers"}>
              <BusinessIcon fontSize="medium" />
              <NavigationBarText variant="body3" component="p" align="center" fontWeight="bold" naviBarSlideState={naviBarSlideState}>
                기업회원
              </NavigationBarText>
            </MenuItem>
            <MenuItem component={Link} to={"/admin-recruiting-project"}>
              <PersonSearchIcon fontSize="medium" />
              <NavigationBarText variant="body3" component="p" align="center" fontWeight="bold" naviBarSlideState={naviBarSlideState}>
                모집 중 프로젝트
              </NavigationBarText>
            </MenuItem>
            <MenuItem component={Link} to={"/admin-ongoing-project"}>
              <PlayCircleIcon fontSize="medium" />
              <NavigationBarText variant="body3" component="p" align="center" fontWeight="bold" naviBarSlideState={naviBarSlideState}>
                진행 중 프로젝트
              </NavigationBarText>
            </MenuItem>
            <MenuItem component={Link} to={"/admin-finished-project"}>
              <DoNotDisturbOnIcon fontSize="medium" />
              <NavigationBarText variant="body3" component="p" align="center" fontWeight="bold" naviBarSlideState={naviBarSlideState}>
                종료 프로젝트
              </NavigationBarText>
            </MenuItem>
            <MenuItem component={Link} to={"/admin-banner"}>
              <ViewCarouselIcon fontSize="medium" />
              <NavigationBarText variant="body3" component="p" align="center" fontWeight="bold" naviBarSlideState={naviBarSlideState}>
                배너 이미지
              </NavigationBarText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => signOut()}>
              <LogoutIcon fontSize="medium" />
              <NavigationBarText variant="body3" component="p" align="center" fontWeight="bold" naviBarSlideState={naviBarSlideState}>
                로그아웃
              </NavigationBarText>
            </MenuItem>
          </MenuList>
        </DrawerWidth>
      </Grid>
    );
  };

  return (
    <Grid container direction="row">
      {navigationBar()}
      <Grid container item xs>
        <ContentContainer maxWidth="false">{contentComponent}</ContentContainer>
      </Grid>
    </Grid>
  );
}
