import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import { RequestPaymentHistoryList, RequestIncUserInfo } from "../../utils/adminRequestToSrv";

import AdminBodyFrame from "../../Block/Admin/AdminBodyFrame";
import SubscribingMembershipContents from "../../Block/Admin/AdminSubscribingMembershipContents";
import NoSubscribingMembershipContents from "../../Block/Admin/AdminNoSubscribingMembershipContents";

import LoadingComponent from "../../components/LoadingComponent";

import Container from "@mui/material/Container";

// 기업 회원 멤버십 정보
export default function AdminMembershipContainer() {
  // 기업 회원 user id url 파라미터
  const { targetUid } = useParams();

  // 기업 회원 정보 data load
  const incUserInfo = useQuery(["incUserInfo", targetUid], () => RequestIncUserInfo(targetUid), {
    onError: (error) => {
      console.log(error);
    },
  });

  // 기업 회원 결제 내역 data load
  const paymentHistory = useQuery(["paymentHistory", targetUid], () => RequestPaymentHistoryList(targetUid), {
    onError: (error) => {
      console.log(error);
    },
  });

  // 테이블 페이지 state
  const [tablePageState, tablePageSetState] = useState(1);
  // 테이블 페이지 이동
  const tablePageChange = (event, newPage) => {
    tablePageSetState(newPage);
  };

  // 멤버십 정보 화면
  if (paymentHistory.isFetching || incUserInfo.isFetching) {
    return <LoadingComponent />;
  } else {
    return (
      <Fragment>
        <AdminBodyFrame contentComponent={<Container maxWidth="lg">{incUserInfo.data.subscribingState ? <SubscribingMembershipContents membershipInfo={paymentHistory.data} tablePageState={tablePageState} tablePageChange={tablePageChange} /> : <NoSubscribingMembershipContents membershipInfo={paymentHistory.data} tablePageState={tablePageState} tablePageChange={tablePageChange} />}</Container>} />
      </Fragment>
    );
  }
}
