import { useState } from "react";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { checkIconUrl, alertIconUrl } from "../configs/urls";

const CustomDialog = styled(Dialog)({
  "& .MuiBackdrop-root": {
    backgroundColor: "transparent",
  },
  "& .MuiDialog-container": {
    alignItems: "flex-end",
  },
  "& .MuiDialog-paper": {
    background: "rgba(0,0,0,0.8)",
    padding: "18px",
    marginBottom: "70px",
  },
});

// custom dialog message
export default function DialogMessage(message, onCloseEvent) {
  const [dialogOpenState, dialogOpenSetState] = useState(false);
  const [timerState, timerSetState] = useState(null);

  const dialogClose = () => {
    if (onCloseEvent) {
      onCloseEvent();
    }
    dialogOpenSetState(false);
    clearTimeout(timerState);
  };

  const dialogCall = () => {
    dialogOpenSetState(true);
    timerSetState(
      setTimeout(() => {
        dialogClose();
      }, 4000)
    );
  };

  const DialogComponent = () => {
    return (
      <CustomDialog open={dialogOpenState} onClose={dialogClose}>
        <Grid container columnSpacing={2}>
          <Grid item>
            <img src={checkIconUrl} alt="완료" />
          </Grid>
          <Grid item>
            <Typography variant="body2" color="primary" fontWeight={700}>
              {message}
            </Typography>
          </Grid>
        </Grid>
      </CustomDialog>
    );
  };

  return { dialogCall, DialogComponent };
}

// custom dialog message - alert
export function DialogAlertMessage(message, onCloseEvent) {
  const [dialogOpenState, dialogOpenSetState] = useState(false);
  const [timerState, timerSetState] = useState(null);

  const dialogClose = () => {
    if (onCloseEvent) {
      onCloseEvent();
    }
    dialogOpenSetState(false);
    clearTimeout(timerState);
  };

  const alertDialogCall = () => {
    dialogOpenSetState(true);
    timerSetState(
      setTimeout(() => {
        dialogClose();
      }, 4000)
    );
  };

  const DialogAlertComponent = () => {
    return (
      <CustomDialog open={dialogOpenState} onClose={dialogClose}>
        <Grid container columnSpacing={2}>
          <Grid item>
            <img src={alertIconUrl} alt="경고" />
          </Grid>
          <Grid item>
            <Typography variant="body2" color="primary" fontWeight={700}>
              {message}
            </Typography>
          </Grid>
        </Grid>
      </CustomDialog>
    );
  };

  return { alertDialogCall, DialogAlertComponent };
}
