import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";

const CustomPaper = styled(Paper)({
  width: "100vw",
  padding: "24px 0",
  position: "sticky",
  borderTop: "1px solid #CCCCCC",
  bottom: 0,
  left: 0,
  zIndex: 50,
});

const CustomContainer = styled(Container)({
  paddingLeft: "0px !important",
  paddingRight: "0px !important",
});

const CustomButtonBase = styled(ButtonBase)({
  borderRadius: "30px",
  width: "150px",
});

const CustomPreviousButtonTypo = styled(Typography)(({ theme }) => ({
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: theme.palette.secondary.main,
  borderRadius: "30px",
  padding: "20px 0",
  width: "150px",
}));

const CustomPreviewButtonTypo = styled(Typography)({
  background: "#F2F2F2",
  borderRadius: "30px",
  padding: "20px 0",
  width: "150px",
});

const CustomButtonTypo = styled(Typography)(({ theme }) => ({
  background: theme.palette.secondary.main,
  borderRadius: "30px",
  width: "150px",
  height: "60px",
  padding: "20px 0"
}));

// 프로젝트 등록 하단 [다음] 버튼
export default function NewProjectNextFixedBox(props) {
  const { onClickEvent } = props;

  return (
    <CustomPaper elevation={0}>
      <CustomContainer maxWidth="lg">
        <Grid container justifyContent="flex-end">
          <Grid item>
            <CustomButtonBase onClick={() => onClickEvent()}>
              <CustomButtonTypo variant="body1" color="primary" fontWeight={500}>
                다음
              </CustomButtonTypo>
            </CustomButtonBase>
          </Grid>
        </Grid>
      </CustomContainer>
    </CustomPaper>
  );
}

// 프로젝트 등록 하단 [이전, 미리보기, 완료] 버튼
export function NewProjectSubmitFixedBox(props) {
  const { previousEvent, previewEvent, submitEvent } = props;

  return (
    <CustomPaper elevation={0}>
      <CustomContainer maxWidth="lg">
        <Grid container>
          <Grid container item justifyContent="space-between">
            <Grid item xs>
              <CustomButtonBase onClick={() => previousEvent()}>
                <CustomPreviousButtonTypo variant="body1" fontWeight={500} color="secondary">
                  이전
                </CustomPreviousButtonTypo>
              </CustomButtonBase>
            </Grid>
            <Grid container item xs columnSpacing={3} justifyContent="flex-end">
              <Grid item>
                <CustomButtonBase onClick={() => previewEvent()}>
                  <CustomPreviewButtonTypo variant="body1" fontWeight={500} color="#333333">
                    미리보기
                  </CustomPreviewButtonTypo>
                </CustomButtonBase>
              </Grid>
              <Grid item>
                <CustomButtonBase onClick={() => submitEvent()}>
                  <CustomButtonTypo variant="body1" color="primary" fontWeight={500}>
                    완료
                  </CustomButtonTypo>
                </CustomButtonBase>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomContainer>
    </CustomPaper>
  );
}
