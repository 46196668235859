import React, { useState, Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useMutation, useQuery } from "react-query";

import { RequestProjectDetail, RequestProjectAppointersList, DeleteProject } from "../../utils/adminRequestToSrv";

import AdminBodyFrame from "../../Block/Admin/AdminBodyFrame";
import AdminFinishedProjectUpperTable, { AdminFinishedProjectBelowTable } from "../../Block/Admin/AdminFinishedProjectDetailContents";

import LoadingComponent from "../../components/LoadingComponent";
import DialogMessage from "../../components/DialogMessage";
import DialogWithButton from "../../components/DialogWithButton";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// 종료 프로젝트 상세 페이지
export default function FinishedProjectDetailContainer() {
  // 프로젝트 doc id url 파라미터
  const { targetDocId } = useParams();

  // 프로젝트 data load
  const projectDetailInfo = useQuery(["projectDetail", targetDocId], () => RequestProjectDetail(targetDocId), {
    onError: (error) => {
      console.log(error);
    },
  });

  // 프로젝트 선정자 data load
  const appointersList = useQuery(["appointersList", targetDocId], () => RequestProjectAppointersList(targetDocId), {
    onError: (error) => console.log(error),
  });

  // 화면 로드 시 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // 프로젝트 상세 페이지로 이동
  const projectDetailPageOpenFunc = () => {
    window.open(`/admin-project-detail/${targetDocId}`);
  };

  // sns 링크 새창으로 열기
  const snsOpenFunc = (url) => {
    window.open(url);
  };

  // 테이블 페이지 state
  const [tablePageState, tablePageSetState] = useState(1);

  // 테이블 페이지 이동
  const tablePageChange = (event, newPage) => {
    tablePageSetState(newPage);
  };

  // dialog message text state
  const [dialogMessageState, dialogMessageSetState] = useState("");
  // dialog message custom hook
  const { dialogCall, DialogComponent } = DialogMessage(dialogMessageState);

  // 프로젝트 삭제 dialog state
  const [projectDeleteDialogOpenState, projectDeleteDialogOpenSetState] = useState(false);

  // 프로젝트 삭제 dialog open function
  const dialogOpenFunc = () => {
    projectDeleteDialogOpenSetState(true);
  };

  // 프로젝트 삭제 dialog close function
  const dialogCloseFunc = () => {
    projectDeleteDialogOpenSetState(false);
  };

  // 프로젝트 삭제 function
  const deleteProject = useMutation(() => DeleteProject(targetDocId), {
    onSuccess: () => {
      projectDeleteDialogOpenSetState(false);
      dialogMessageSetState("프로젝트가 삭제되었습니다.");
      dialogCall();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 프로젝트 삭제 dialog submit function
  const dialogSubmitFunc = () => {
    deleteProject.mutate();
  };

  // 종료 프로젝트 상세 페이지 화면
  if (projectDetailInfo.isFetching || appointersList.isFetching) {
    return <LoadingComponent />;
  } else {
    return (
      <Fragment>
        <AdminBodyFrame
          contentComponent={
            <Grid container item rowGap={2}>
              <Grid item xs={12}>
                <Typography variant="h5" fontSize={22} lineHeight="28px" color="#333333">
                  종료 프로젝트
                </Typography>
              </Grid>
              <AdminFinishedProjectUpperTable projectDetailValue={projectDetailInfo.data} projectDetailPageOpenFunc={projectDetailPageOpenFunc} />
              <AdminFinishedProjectBelowTable projectDetailValue={projectDetailInfo.data} appointersListValue={appointersList.data} snsOpenFunc={snsOpenFunc} tablePageState={tablePageState} tablePageChange={tablePageChange} projectDeleteButtonFunc={dialogOpenFunc} />
            </Grid>
          }
        />
        <DialogComponent />
        <DialogWithButton message={["프로젝트를 삭제하시겠습니까?"]} dialogOpenState={projectDeleteDialogOpenState} dialogClose={dialogCloseFunc} button1Text="취소" button2Text="확인" button1Func={dialogCloseFunc} button2Func={dialogSubmitFunc} />
      </Fragment>
    );
  }
}
