import { forwardRef } from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { AuthTextField, AuthTextFieldWithLabel, DateTextField, DateTextFieldWithoutLabel, TextFieldWithEndAdornment, TextFieldWithoutLabel } from "../../components/CustomTextField";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const ErrorIcon = styled(ErrorOutlineIcon)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
})

// TextField 구성
export const TextFieldSet = forwardRef((props, forwardRef) => {
  const { label, type, placeholder, valueState, setValueEvent, onKeyPress, autoFocus, inputProps, readOnly } = props;
  return (
    <Grid container item rowSpacing={1}>
      <Grid item xs={12}>
        <Typography variant="body2" fontWeight={500}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AuthTextField type={type} placeholder={placeholder} ref={forwardRef} valueState={valueState} setValueEvent={setValueEvent} onKeyPress={onKeyPress} autoFocus={autoFocus} inputProps={inputProps} readOnly={readOnly} />
      </Grid>
    </Grid>
  );
});

// caption 있는 AuthTextFieldWithLabel(라벨 있는 Filled TextField) 구성
export const TextFieldSetWithCaption = forwardRef((props, forwardRef) => {
  const { label, caption, type, placeholder, valueState, setValueEvent, onKeyPress, onBlur, autoFocus, errorState, readOnly, inputProps } = props;
  return (
    <Grid container item rowSpacing={1}>
      <Grid item xs={12}>
        <AuthTextFieldWithLabel label={label} type={type} errorState={errorState} placeholder={placeholder} ref={forwardRef} valueState={valueState} setValueEvent={setValueEvent} onKeyPress={onKeyPress} onBlur={onBlur} autoFocus={autoFocus} readOnly={readOnly} inputProps={inputProps} />
      </Grid>
      <Grid container item alignItems="center" columnSpacing={0.2}>
        <Grid item>
          {errorState ? <ErrorIcon fontSize="10px" color="error" /> : null}
        </Grid>
        <Grid item>
          <Typography variant="caption1" color={errorState ? "#ED2727" : "#999999"}>
            {caption}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
});

// end adornment(내부 아이콘) TextField 구성
export const TextFieldSetWithEndAdornment = forwardRef((props, forwardRef) => {
  const { label, type, valueState, adornment, readOnly } = props;
  return (
    <Grid container item rowSpacing={1}>
      <Grid item xs={12}>
        <Typography variant="body2" fontWeight={500}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextFieldWithEndAdornment adornment={adornment} type={type} ref={forwardRef} valueState={valueState} readOnly={readOnly} />
      </Grid>
    </Grid>
  );
});

// caption 있는 TextFieldWithoutLabel(라벨 없는 Outlined TextField) 구성
export const TextFieldWithoutLabelSet = forwardRef((props, forwardRef) => {
  const { type, placeholder, valueState, setValueEvent, onKeyPress, autoFocus, inputProps, readOnly, errorState, caption } = props;
  return (
    <Grid container item rowSpacing={1}>
      <Grid item xs={12}>
        <TextFieldWithoutLabel type={type} ref={forwardRef} placeholder={placeholder} valueState={valueState} setValueEvent={setValueEvent} onKeyPress={onKeyPress} autoFocus={autoFocus} inputProps={inputProps} readOnly={readOnly} />
      </Grid>
      {errorState ?
      <Grid container item alignItems="center" spacing={0.2}>
        <Grid item>
          {errorState ? <ErrorIcon fontSize="10px" color="error" /> : null}
        </Grid>
        <Grid item>
          <Typography variant="caption1" color={errorState ? "#ED2727" : "#999999"}>
            {caption}
          </Typography>
        </Grid>
      </Grid>
      : null}
    </Grid>
  );
});

// DatePicker 구성
export const DateTextFieldSet = forwardRef((props, forwardRef) => {
  const { label, inputFormat, placeholder, value, onChange, onKeyPress, disablePast, disableFuture } = props;

  return (
    <Grid container item rowSpacing={1}>
      <Grid item xs={12}>
        <Typography variant="body2" fontWeight={500} color="black.600">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker components={{ OpenPickerIcon: CalendarMonthIcon }} inputFormat={inputFormat} ref={forwardRef} value={value} onChange={onChange} renderInput={(params) => <DateTextFieldWithoutLabel placeholder={placeholder} params={params} />} onKeyPress={onKeyPress} disablePast={disablePast} disableFuture={disableFuture} />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
});

// 라벨 없는 DatePicker 구성
export const DateTextFieldSetWithoutLabel = forwardRef((props, forwardRef) => {
  const { inputFormat, placeholder, value, onChange, onKeyPress, disablePast, disableFuture } = props;

  return (
    <Grid container item rowSpacing={1}>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker components={{ OpenPickerIcon: CalendarMonthIcon }} inputFormat={inputFormat} ref={forwardRef} value={value} onChange={onChange} renderInput={(params) => <DateTextFieldWithoutLabel placeholder={placeholder} params={params} />} onKeyPress={onKeyPress} disablePast={disablePast} disableFuture={disableFuture} />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
});
