import React from "react";

import { Viewer } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import chart from "@toast-ui/editor-plugin-chart";
import tableMergedCell from "@toast-ui/editor-plugin-table-merged-cell";
import "@toast-ui/editor-plugin-table-merged-cell/dist/toastui-editor-plugin-table-merged-cell.css";

import "../configs/ToastUiViewer.css";

// markdown, wysiwyg 뷰어
export default function ToastUIViewer(props) {
  const { markdownText } = props;

  return <Viewer initialValue={markdownText} plugins={[chart, tableMergedCell]} id="projectDetailGuide" />;
}
