import React from "react";

import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";

// 어드민 테이블 frame
export default function AdminTable(props) {
  const { TableHeader, TableBody, tableDataArray, dataPerPage, tableDataPageState, tableDataPageChange } = props;

  return (
    <Grid item xs={12}>
      <TableContainer>
        <Table>
          {TableHeader}
          {TableBody}
        </Table>
      </TableContainer>
      <TablePagination rowsPerPageOptions={[]} labelRowsPerPage="" component="div" count={tableDataArray.length} rowsPerPage={dataPerPage} page={tableDataPageState} onPageChange={tableDataPageChange} />
    </Grid>
  );
}
