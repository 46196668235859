import React, { Fragment } from "react";

import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import { logoUrl } from "../../configs/urls";

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.background.paper,
  height: "84px",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
  justifyContent: "center"
}));

const LogoButtonbase = styled(ButtonBase)({
  width: "100%",
});

const LogoImg = styled("img")({
  width: "100%",
});

// App bar 구성
export default function ProjectPreviewAppBar() {
  const scrollTrigger = useScrollTrigger({
    target: window ? window : undefined,
    disableHysteresis: true,
  });

  // 프로젝트 미리보기 App bar
  const PreviewAppbar = () => {
    return (
      <Fragment>
        <CustomAppBar position="fixed" elevation={0} scrollTrigger={scrollTrigger}>
          <Container disableGutters maxWidth="lg">
            <Grid container direction="row" justifyContent="space-between" alignContent="center">
              <Grid container item xs="auto" alignItems="center">
                <Grid item>
                  <LogoButtonbase>
                    <LogoImg src={logoUrl.logo} alt="로고" />
                  </LogoButtonbase>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </CustomAppBar>
      </Fragment>
    );
  };

  return PreviewAppbar();
}
