import React, { forwardRef } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// import { ToastUIEditorWithNoImageBlock } from "../Common/ToastUIEditorBlock";

import { ComplexDialogWithThreeButton } from "../../components/DialogWithButton";
import { TextFieldMultiline } from "../../components/CustomTextField";

// 프로젝트 취소 modal
export const ProjectCancelDialog = forwardRef((props, forwardRef) => {
  const { projectCancelDialogOpenState, projectCancelDialogCloseFunc, projectCancelDialogSubmitFunc, projectCancelReasonState, projectCancelReasonSetState } = props;

  const ProjectCancelDialogContext = () => {
    return (
      <Grid container item rowSpacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" fontWeight={400} lineHeight="22px" color="#4C4C4C">
            프로젝트를 취소하는 사유를 작성해주세요.(4자 이상)
            <br />
            작성하신 사유는 홍보단들이 확인할 수 있습니다.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {/* <ToastUIEditorWithNoImageBlock ref={forwardRef} placeholder="예) 프로젝트 재등록 필요, 물량 부족 등" initialValue=" " editorSize="25vh" /> */}
          <TextFieldMultiline label="" rows={5} type="text" characterLimit={300} placeholder="예) 프로젝트 재등록 필요, 물량 부족 등" ref={forwardRef} valueState={projectCancelReasonState} setValueEvent={() => projectCancelReasonSetState(forwardRef.current.value)}  />
        </Grid>
      </Grid>
    );
  };

  return <ComplexDialogWithThreeButton title={"취소 사유"} context={ProjectCancelDialogContext()} dialogOpenState={projectCancelDialogOpenState} dialogClose={projectCancelDialogCloseFunc} button1Text="닫기" button2Text="완료" button1Func={projectCancelDialogCloseFunc} button2Func={projectCancelDialogSubmitFunc} maxWidth={700} />;
});
