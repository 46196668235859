import Grid from "@mui/material/Grid";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import InsertLinkIcon from '@mui/icons-material/InsertLink';

import { portfolioProjectDefaultImageUrl } from "../../configs/urls";

const ProjectBoxButton = styled(ButtonBase)({
  borderRadius: "10px",
});

const ProjectBox = styled(Box)({
  width: "242px",
  height: "242px",
  background: "#D9D9D9",
  borderRadius: "20px",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const ProjectThumbnailImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const ProjectLinkIconBg = styled(Box)({
  position: "absolute",
  top: 12,
  left: 12,
  height: "32px",
  width: "32px",
  background: "rgba(0, 0, 0, 0.4)",
  borderRadius: "50%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const ProjectLinkIcon = styled(InsertLinkIcon)({
  color: "#ffffff",
  rotate: "135deg",
});

// 개인 회원 포트폴리오 페이지 대표 프로젝트
export default function MyPortfolioProjectBox(props) {
  const { portfolioData } = props;

  // 프로젝트 링크 새창으로 열기
  const openProjectUrl = (url) => {
    window.open(url, "_blank");
  };

  // 프로필 사진 에러시 기본 이미지로 변경
  const profilePhotoError = (event) => {
    event.target.src = portfolioProjectDefaultImageUrl;
  };

  return (
    <Grid container item rowSpacing={2.8}>
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight={700} color="secondary">
          대표 프로젝트
        </Typography>
      </Grid>
      <Grid container item columnSpacing={4}>
        {portfolioData.project.project1.url !== "" ? (
          <Grid item xs={4}>
            <ProjectBoxButton onClick={() => openProjectUrl(portfolioData.project.project1.url)} disabled={portfolioData.project.project1.url === ""}>
              <ProjectBox>
                <ProjectThumbnailImage src={portfolioData.project.project1.image} onError={profilePhotoError} alt="프로젝트1" />
              </ProjectBox>
              <ProjectLinkIconBg>
                <ProjectLinkIcon />
              </ProjectLinkIconBg>
            </ProjectBoxButton>
          </Grid>
        ) : null}
        {portfolioData.project.project2.url !== "" ? (
          <Grid item xs={4}>
            <ProjectBoxButton onClick={() => openProjectUrl(portfolioData.project.project2.url)} disabled={portfolioData.project.project2.url === ""}>
              <ProjectBox>
                <ProjectThumbnailImage src={portfolioData.project.project2.image} onError={profilePhotoError} alt="프로젝트2" />
              </ProjectBox>
              <ProjectLinkIconBg>
                <ProjectLinkIcon />
              </ProjectLinkIconBg>
            </ProjectBoxButton>
          </Grid>
        ) : null}
        {portfolioData.project.project3.url !== "" ? (
          <Grid item xs={4}>
            <ProjectBoxButton onClick={() => openProjectUrl(portfolioData.project.project3.url)} disabled={portfolioData.project.project3.url === ""}>
              <ProjectBox>
                <ProjectThumbnailImage src={portfolioData.project.project3.image} onError={profilePhotoError} alt="프로젝트3" />
              </ProjectBox>
              <ProjectLinkIconBg>
                <ProjectLinkIcon />
              </ProjectLinkIconBg>
            </ProjectBoxButton>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}
