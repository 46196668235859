import React from "react";

import { styled } from "@mui/material/styles";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const BackgroundBox = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  height: "100%",
}));

const CustomContainer = styled(Container)({
  minHeight: "100vh",
});

const ContainerBody = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  minHeight: "100vh",
  padding: "0 36px",
  backgroundColor: theme.palette.background.paper,
}));

const GridContainer = styled(Grid)({
  padding: "154px 0 90px",
  height: "100%",
});

// 결제 페이지 frame
export default function PaymentBodyFrame(props) {
  const { content } = props;

  return (
    <BackgroundBox>
      <CustomContainer maxWidth="sm">
        <ContainerBody>
          <GridContainer container>
            <Grid container item justifyContent="center" alignContent="center" rowSpacing={8}>
              <Grid item xs={12}>
                <Typography variant="h1" fontWeight={700} align="center" color="black.200">
                  결제 정보
                </Typography>
              </Grid>
              {content}
            </Grid>
          </GridContainer>
        </ContainerBody>
      </CustomContainer>
    </BackgroundBox>
  );
}
