import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import RootContents from "../Block/Root/RootContents";
import { RequestRootInfo, RequestProjectList } from "../utils/requestToSrv";
import LoadingComponent from "../components/LoadingComponent";

const BackgroundBox = styled(Box)({
  minHeight: "100vh",
  height: "100%",
  padding: "84px 0 90px",
});

export default function Root() {
  // react-router-dom navigate
  const navigate = useNavigate();
  // react-query queryClient
  const queryClient = useQueryClient();

  // 배너 이미지 state
  const [bannerImageArrayState, bannerImageArraySetState] = useState([]);

  // 루트 페이지 data load
  const rootInfo = useQuery("rootInfo", RequestRootInfo, {
    onSuccess: (data) => {
      const bannerData = data.banner.list;
      const arrayLength = bannerData.length;

      // 6 이하의 array는 2~3번 반복
      if (arrayLength === 2) {
        bannerImageArraySetState([...bannerData, ...bannerData, ...bannerData]);
        bannerImageSetState({
          prevPrev: 4,
          prev: 5,
          main: 0,
          next: 1,
          nextNext: 2,
        });
      } else if (arrayLength >= 3 && arrayLength < 6) {
        bannerImageArraySetState([...bannerData, ...bannerData]);
        bannerImageSetState({
          prevPrev: arrayLength * 2 - 2,
          prev: arrayLength * 2 - 1,
          main: 0,
          next: 1,
          nextNext: 2,
        });
      } else {
        bannerImageArraySetState([...bannerData]);
        bannerImageSetState({
          prevPrev: arrayLength - 2,
          prev: arrayLength - 1,
          main: 0,
          next: 1,
          nextNext: 2,
        });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 화면 로드 시 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);

    if (rootInfo.data) {
      const bannerData = rootInfo.data.banner.list;
      const arrayLength = bannerData.length;

      // 6 이하의 array는 2~3번 반복
      if (arrayLength === 2) {
        bannerImageArraySetState([...bannerData, ...bannerData, ...bannerData]);
        bannerImageSetState({
          prevPrev: 4,
          prev: 5,
          main: 0,
          next: 1,
          nextNext: 2,
        });
      } else if (arrayLength >= 3 && arrayLength < 6) {
        bannerImageArraySetState([...bannerData, ...bannerData]);
        bannerImageSetState({
          prevPrev: arrayLength * 2 - 2,
          prev: arrayLength * 2 - 1,
          main: 0,
          next: 1,
          nextNext: 2,
        });
      } else {
        bannerImageArraySetState([...bannerData]);
        bannerImageSetState({
          prevPrev: arrayLength - 2,
          prev: arrayLength - 1,
          main: 0,
          next: 1,
          nextNext: 2,
        });
      }
    }
  }, []);

  // 프로젝트 리스트 필터링 옵션
  const filteringOption = {
    typeFilterItems: ["delivery", "visit", "purchase"],
    channelFilterItems: ["instagram", "naverBlog", "naverPost", "youtube"],
    exceptClosedProject: true,
  };

  // 프로젝트 리스트 정렬 옵션
  const sortingOption = "registrationDate";

  // 프로젝트 리스트 data load
  const projectList = useQuery("rootProjectList", () => RequestProjectList(filteringOption, sortingOption), {
    onError: (error) => {
      console.log(error);
    },
  });

  // 배너 이미지 배치 state
  const [bannerImageState, bannerImageSetState] = useState({
    prevPrev: 0,
    prev: 0,
    main: 0,
    next: 0,
    nextNext: 0,
  });

  // 배너 이미지 왼쪽 이동 function
  const backwardEvent = async () => {
    bannerImageSetState((prevState) => {
      const { prevPrev, prev, main, next, nextNext } = prevState;
      const arrayLength = bannerImageArrayState.length;
      return {
        prevPrev: prevPrev === 0 ? arrayLength - 1 : prevPrev - 1,
        prev: prev === 0 ? arrayLength - 1 : prev - 1,
        main: main === 0 ? arrayLength - 1 : main - 1,
        next: next === 0 ? arrayLength - 1 : next - 1,
        nextNext: nextNext === 0 ? arrayLength - 1 : nextNext - 1,
      };
    });
  };

  // 배너 이미지 오른쪽 이동 function
  const frontwardEvent = async () => {
    bannerImageSetState((prevState) => {
      const { prevPrev, prev, main, next, nextNext } = prevState;
      const arrayLength = bannerImageArrayState.length;
      return {
        prevPrev: prevPrev === arrayLength - 1 ? 0 : prevPrev + 1,
        prev: prev === arrayLength - 1 ? 0 : prev + 1,
        main: main === arrayLength - 1 ? 0 : main + 1,
        next: next === arrayLength - 1 ? 0 : next + 1,
        nextNext: nextNext === arrayLength - 1 ? 0 : nextNext + 1,
      };
    });
  };

  // 배너 이미지 자동 슬라이드 interval function
  const [intervalDelayState, intervalDelaySetState] = useState(3000);

  // 배너 이미지 왼쪽 버튼 클릭 function
  const backwardBtnClickFunc = () => {
    intervalDelaySetState(null);
    backwardEvent().then(() => {
      intervalDelaySetState(3000);
    });
  };

  // 배너 이미지 오른쪽 버튼 클릭 function
  const frontwardBtnClickFunc = () => {
    intervalDelaySetState(null);
    frontwardEvent().then(() => {
      intervalDelaySetState(3000);
    });
  };

  // 배너 이미지 자동 슬라이드 interval function
  function useInterval(callback, delay) {
    const savedCallback = useRef(); // 최근에 들어온 callback을 저장할 ref를 하나 만든다.

    useEffect(() => {
      savedCallback.current = callback; // callback이 바뀔 때마다 ref를 업데이트 해준다.
    }, [callback]);

    useEffect(() => {
      function tick() {
        savedCallback.current(); // tick이 실행되면 callback 함수를 실행시킨다.
      }
      if (delay !== null) {
        // 만약 delay가 null이 아니라면
        let id = setInterval(tick, delay); // delay에 맞추어 interval을 새로 실행시킨다.
        return () => clearInterval(id); // unmount될 때 clearInterval을 해준다.
      }
    }, [delay]); // delay가 바뀔 때마다 새로 실행된다.
  }

  // 배너 이미지 자동 슬라이드 interval 실행
  useInterval(frontwardEvent, intervalDelayState);

  // 배너 이미지 클릭 function
  const bannerClickFunc = (url, newPage) => {
    if (newPage) {
      window.open(url, "_blank");
    } else {
      window.open(url, "_self");
    }
  };

  // 프로젝트 리스트 아이템 클릭 function
  const projectItemClickFunc = (projectId, index) => {
    queryClient.setQueryData("projectDetailInfo", projectList.data[index].document);
    navigate("/project-detail/" + projectId);
  };

  // 루트 페이지 화면
  return <BackgroundBox>{rootInfo.isLoading ? <LoadingComponent /> : <RootContents bannerImages={bannerImageArrayState} bannerClickFunc={bannerClickFunc} baseArrayLength={rootInfo.data.banner.list.length} mainBannerImage={bannerImageState} backwardBtnClickFunc={backwardBtnClickFunc} frontwardBtnClickFunc={frontwardBtnClickFunc} projectListValues={projectList.data} dataLoadedState={projectList.isFetched} projectItemClickFunc={projectItemClickFunc} />}</BackgroundBox>;
}
