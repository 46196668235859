import React from "react";

import UniversalAppBar from "../../Block/Appbar/UniversalAppBar";
import UniversalAppFooter from "../../Block/Footer/UniversalAppFooter";

import FindPassword from "../../containers/Service/Auth/FindPasswordContainer";

export default function FindPasswordView() {
  return (
    <React.Fragment>
      <UniversalAppBar />
      <FindPassword />
      <UniversalAppFooter />
    </React.Fragment>
  );
}
