import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "react-query";

import moment from "moment";

import { useRecoilState } from "recoil";
import { emailVarified } from "../../../recoil/UserState";

import { doSignOutWithNoQuery } from "../../../utils/firebaseUtils";

import { RequestDefaultUserInfo, UpdateDefaultUserInfo, RequestReadDefaultUserProfile } from "../../../utils/requestToSrv";
import { doSendEmailVarification } from "../../../utils/firebaseUtils";

import MyPageBodyFrame from "../../../Block/MyPage/MyPageBodyFrame";
import MyPageMenuBox from "../../../Block/MyPage/MyPageMenuBox";
import { MyPageContents } from "../../../Block/MyPage/MyPageContents";
import { DialogWithOneButton } from "../../../components/DialogWithButton";
import DialogMessage, { DialogAlertMessage } from "../../../components/DialogMessage";
import DaumPostCard from "../../../components/DaumPostCode";
import MyPageMenuUpperBox from "../../../Block/MyPage/MyPageMenuUpperBox";

const phoneNumRegEx = new RegExp(/([0-9]{2,3})-([0-9]{3,4})-([0-9]{4})/);
const valueChangePhoneNumRegEx = new RegExp(/([0-9]{2,3})([0-9]{3,4})([0-9]{4})/);

export default function MyPageContainer() {
  // react-router-dom navigate
  const navigate = useNavigate();

  // react-query queryClient
  const queryClient = useQueryClient();

  // 이메일 인증 여부 state - firebase에서 확인
  const [emailVarifiedState, emailVarifiedSetState] = useRecoilState(emailVarified);

  // 각 textField를 위한 ref
  const emailRef = useRef(null);
  const nameRef = useRef(null);
  const birthRef = useRef(null);
  const phoneRef = useRef(null);
  const addressRef = useRef(null);
  const addressDetailRef = useRef(null);

  // user info state - 원본
  const [originalValueState, originalValueSetState] = useState({
    email: "",
    name: "",
    birth: null,
    phone: "",
    address: "",
    addressDetail: "",
    city: "",
    marketingAgreement: {
      email: false,
      sms: false,
    },
    accountType: "",
  });

  // user info state - 변경 가능한 값
  const [valueState, valueSetState] = useState({
    email: "",
    name: "",
    birth: null,
    phone: "",
    address: "",
    addressDetail: "",
    city: "",
    marketingAgreement: {
      email: false,
      sms: false,
    },
  });

  // 포트폴리오 data load
  const portfolioData = useQuery("userPortfolio", RequestReadDefaultUserProfile, {
    onError: (error) => {
      console.log(error);
    },
  });

  // 화면 로드 시 맨 위로 이동
  // my page 진입 시 sign in 완료 한 상태를 가정하여 queryClient에 userInfo data set
  // user info state - 원본 값 설정
  // user info state - 변경 가능한 값 설정

  useEffect(() => {
    window.scrollTo(0, 0);

    originalValueSetState({
      email: queryClient.getQueryData("userInfo").email,
      name: queryClient.getQueryData("userInfo").name,
      birth: queryClient.getQueryData("userInfo").birthDate ? moment(queryClient.getQueryData("userInfo").birthDate).format("yyyy.MM.DD") : null,
      phone: queryClient.getQueryData("userInfo").contact,
      address: queryClient.getQueryData("userInfo").address,
      addressDetail: queryClient.getQueryData("userInfo").addressDetail,
      city: queryClient.getQueryData("userInfo").city,
      marketingAgreement: {
        email: queryClient.getQueryData("userInfo").marketingAgreement.email,
        sms: queryClient.getQueryData("userInfo").marketingAgreement.sms,
      },
      accountType: queryClient.getQueryData("userInfo").accountType,
    });
    valueSetState({
      email: queryClient.getQueryData("userInfo").email,
      name: queryClient.getQueryData("userInfo").name,
      birth: queryClient.getQueryData("userInfo").birthDate ? moment(queryClient.getQueryData("userInfo").birthDate).format("yyyy.MM.DD") : null,
      phone: queryClient.getQueryData("userInfo").contact,
      address: queryClient.getQueryData("userInfo").address,
      addressDetail: queryClient.getQueryData("userInfo").addressDetail,
      city: queryClient.getQueryData("userInfo").city,
      marketingAgreement: {
        email: queryClient.getQueryData("userInfo").marketingAgreement.email,
        sms: queryClient.getQueryData("userInfo").marketingAgreement.sms,
      },
    });
  }, []);

  // 버튼 활성화 여부 state
  const [buttonActiveState, buttonActiveSetState] = useState(false);

  // 버튼 활성화 여부 state - 변경한 값이 있는 경우 활성화
  useEffect(() => {
    const originalValue = JSON.stringify(originalValueState);
    const newValue = JSON.stringify(valueState);

    if (originalValue !== newValue) {
      buttonActiveSetState(true);
    } else {
      buttonActiveSetState(false);
    }
  }, [valueState]);

  // text field value change event - 연락처는 숫자만 입력 가능
  const setValueEvent = (key, newValue) => {
    const onlyNums = newValue.replace(/[^0-9]/g, "");

    if (key === "phone") {
      newValue = onlyNums.replace(valueChangePhoneNumRegEx, "$1-$2-$3");
    }

    valueSetState((prevState) => ({
      ...prevState,
      [key]: newValue,
    }));
  };

  // checkbox value change event
  const setCheckValueEvent = (key, event) => {
    valueSetState((prevState) => ({
      ...prevState,
      marketingAgreement: { ...prevState.marketingAgreement, [key]: event.target.checked },
    }));
  };

  // date picker value change event
  const setDateValueEvent = (newValue) => {
    valueSetState((prevState) => ({
      ...prevState,
      birth: moment(newValue).format("yyyy.MM.DD"),
    }));
  };

  // 인증 메일 전송 완료 dialog open state
  const [dialogOpenState, dialogOpenSetState] = useState(false);
  // dialog message text state
  const [dialogMessageState, dialogMessageSetState] = useState("");
  // alert dialog message text state
  const [dialogAlertMessageState, dialogAlertMessageSetState] = useState("");
  // dialog custom hook
  const { dialogCall, DialogComponent } = DialogMessage(dialogMessageState);
  // alert dialog custom hook
  const { alertDialogCall, DialogAlertComponent } = DialogAlertMessage(dialogAlertMessageState);
  // 다음 주소 찾기 dialog open state
  const [complexDialogOpenState, complexDialogOpenSetState] = useState(false);

  // 인증 메일 전송 button function
  const emailVerifyButtonFunc = () => {
    const callBack = () => {
      dialogOpenSetState(true);
    };
    doSendEmailVarification(callBack);
  };

  // 다음 주소 찾기 dialog open function
  const daumDialogOpenFunc = () => {
    complexDialogOpenSetState(true);
  };

  // 다음 주소 찾기 dialog close function
  const daumDialogCloseFunc = () => {
    complexDialogOpenSetState(false);
  };

  // 주소 변경 button click function
  const addressChangeButtonFunc = () => {
    daumDialogOpenFunc();
  };

  // 다음 주소 찾기 API > value set function
  const setAddressFunc = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    valueSetState((prevState) => ({
      ...prevState,
      address: fullAddress,
      city: data.sido,
    }));
  };

  // 인증 메일 전송 완료 dialog close function
  const dialogCloseFunc = () => {
    doSignOutWithNoQuery();
    queryClient.clear();
    dialogOpenSetState(false);
    navigate("/signin");
  };

  // const naverSignInFunc = () => {};
  const kakaoSignInFunc = () => {};
  const googleSignInFunc = () => {};

  // 개인 회원 정보 request function
  const requestUserInfo = useQuery("userInfo", RequestDefaultUserInfo, {
    enabled: false,
    onSuccess: (data) => {
      originalValueSetState({
        email: data.email,
        name: data.name,
        birth: data.birthDate ? moment(data.birthDate).format("yyyy.MM.DD") : null,
        phone: data.contact,
        address: data.address,
        addressDetail: data.addressDetail,
        city: data.city,
        marketingAgreement: {
          email: data.marketingAgreement.email,
          sms: data.marketingAgreement.sms,
        },
        accountType: data.accountType,
      });
      valueSetState({
        email: data.email,
        name: data.name,
        birth: data.birthDate ? moment(data.birthDate).format("yyyy.MM.DD") : null,
        phone: data.contact,
        address: data.address,
        addressDetail: data.addressDetail,
        city: data.city,
        marketingAgreement: {
          email: data.marketingAgreement.email,
          sms: data.marketingAgreement.sms,
        },
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 개인 회원 정보 update function
  const requestUpdateUserInfo = useMutation(UpdateDefaultUserInfo, {
    onSuccess: () => {
      dialogMessageSetState("변경 사항이 저장되었습니다.");
      dialogCall();
      requestUserInfo.refetch();
    },
  });

  // 개인 회원 정보 저장 button submit function
  const submitEvent = () => {
    if (valueState.phone && !phoneNumRegEx.test(valueState.phone)) {
      dialogAlertMessageSetState("연락처가 형식에 맞지 않습니다.");
      alertDialogCall();
      phoneRef.current.focus();
    } else {
      requestUpdateUserInfo.mutate({
        email: valueState.email,
        name: valueState.name,
        birthDate: valueState.birth === "Invalid date" ? "" : valueState.birth,
        contact: valueState.phone,
        address: valueState.address,
        addressDetail: valueState.addressDetail,
        city: valueState.city,
        marketingAgreement: valueState.marketingAgreement,
      });
    }
  };

  // enter key press event
  const onEnterKeyPress = (event) => {
    if (buttonActiveState) {
      if (event.key === "Enter") {
        submitEvent();
      }
    }
  };
  // ref 묶음
  const refSet = { emailRef: emailRef, nameRef: nameRef, birthRef: birthRef, phoneRef: phoneRef, addressRef: addressRef, addressDetailRef: addressDetailRef };

  // 개인 회원 마이페이지 화면
  return (
    <Fragment>
      <MyPageBodyFrame title="계정설정" text="로그인, 알림 등 계정과 관련해 설정합니다." menu={<MyPageMenuBox page="my-page" />} menuUpper={<MyPageMenuUpperBox portfolioData={portfolioData.data} valueState={originalValueState} />} contents={<MyPageContents ref={refSet} valueState={valueState} setValueEvent={setValueEvent} setCheckValueEvent={setCheckValueEvent} setDateValueEvent={setDateValueEvent} onEnterKeyPress={onEnterKeyPress} emailVerifyButtonFunc={emailVerifyButtonFunc} addressChangeButtonFunc={addressChangeButtonFunc} kakaoSignInFunc={kakaoSignInFunc} googleSignInFunc={googleSignInFunc} submitEvent={submitEvent} thirdPartyAccount={queryClient.getQueryData("userInfo").accountType !== "email"} buttonActiveState={buttonActiveState} emailVarifiedState={emailVarifiedState} />} />
      <DialogWithOneButton message={["인증 메일을 전송했습니다.", <br />, "메일함에 없을 경우 스팸 메일함을 확인해주세요."]} dialogOpenState={dialogOpenState} buttonText="확인" buttonFunc={dialogCloseFunc} />
      <DialogComponent />
      <DialogAlertComponent />
      <DaumPostCard dialogOpenState={complexDialogOpenState} dialogClose={daumDialogCloseFunc} getAddressFunc={setAddressFunc} />
    </Fragment>
  );
}
