import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Input from "@mui/material/Input";

import { TextFieldSetWithCaption } from "../Common/TextFieldSet";
import { TextFieldMultiline } from "../../components/CustomTextField";
import { ToastUIEditorWithNoImageBlock } from "../Common/ToastUIEditorBlock";
import { forwardRef } from "react";

const CustomButtonBase = styled(ButtonBase)({
  width: "100%",
  border: "1px solid #222222",
  borderRadius: "8px",
});

const CustomButtonTypo = styled(Typography)({
  paddingTop: "8px",
  paddingBottom: "8px",
  borderRadius: "4px",
});

const ProfileImageBox = styled(Box)({
  width: 100,
  height: 100,
  borderRadius: "50%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  background: "#F5F5F5",
});

const ProfileImage = styled("img")(({ profileImageErrorState }) => ({
  width: profileImageErrorState ? "50%" : "100%",
  height: profileImageErrorState ? "50%" : "100%",
  objectFit: "cover",
}));

// 포트폴리오 수정 페이지 - 기본 정보
export const UpdatePortfolioBasicInfo = forwardRef((props, forwardRef) => {
  const { blobImgSrcState, changeProfileImgClickFunc, changeProfileImgFunc, deleteProfileImgFunc, myProfileValueState, myProfileValueChange, onKeyPress } = props;
  const { nameRef, introduceRef, fileUploadRef } = forwardRef;

  return (
    <Grid container item rowSpacing={3.5}>
      <Grid container item>
        <Grid container item columnSpacing={2.5}>
          <Grid item xs={2.5}>
              <ProfileImageBox>{myProfileValueState.profilePhotoUrl ? blobImgSrcState.profileImage ? <ProfileImage src={blobImgSrcState.profileImage} alt="프로필" /> : <ProfileImage src={myProfileValueState.profilePhotoUrl} alt="프로필" /> : blobImgSrcState.profileImage ? <ProfileImage src={blobImgSrcState.profileImage} alt="프로필" /> : null}</ProfileImageBox>
          </Grid>
          <Grid container item xs={9.5} rowSpacing={1} alignContent="end">
            <Grid item xs={12}>
              <Typography variant="caption1" component="p" fontWeight={300} color="#B3B3B3">
                *10MB 이내의 이미지 파일을 업로드해주세요.
                <br />
                이미지는 PNG 또는 JPG 파일을 권장합니다.
              </Typography>
            </Grid>
            <Grid container item columnSpacing={1}>
              <Grid item xs={3}>
                <CustomButtonBase onClick={() => changeProfileImgClickFunc()}>
                  <CustomButtonTypo variant="body2" color="#666666" fontWeight={500} align="center">
                    변경
                  </CustomButtonTypo>
                </CustomButtonBase>
                <Input inputRef={fileUploadRef} type="file" onChange={(event) => changeProfileImgFunc(event)} inputProps={{ accept: "image/*" }} sx={{ display: "none" }} />
              </Grid>
              <Grid item xs={3}>
                <CustomButtonBase onClick={() => deleteProfileImgFunc()}>
                  <CustomButtonTypo variant="body2" color="#666666" fontWeight={500} align="center">
                    삭제
                  </CustomButtonTypo>
                </CustomButtonBase>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item rowSpacing={2}>
        <Grid item xs={12}>
          <TextFieldSetWithCaption label="이름" type="text" placeholder="이름을 입력해주세요" ref={nameRef} valueState={myProfileValueState.name} setValueEvent={(event) => myProfileValueChange("name", event)} onKeyPress={onKeyPress} autoFocus={false} readOnly={true} />
        </Grid>
        <Grid item xs={12}>
          {/* <ToastUIEditorWithNoImageBlock ref={introduceRef} placeholder="띄어쓰기 포함 100자 이내로 작성해주세요." initialValue={myProfileValueState.introduction} editorSize="30vh" /> */}
          <TextFieldMultiline label="자기소개" rows={3} type="text" characterLimit={100} placeholder="자기소개" ref={introduceRef} valueState={myProfileValueState.introduction} setValueEvent={(event) => myProfileValueChange("introduction", event)}  />
        </Grid>
      </Grid>
    </Grid>
  );
});
