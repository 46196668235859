import React, { forwardRef } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { UpdatePortfolioBasicInfo } from "./UpdatePortfolioBasicInfo";
import { UpdatePortfolioAdditionalInfo } from "./UpdatePortfolioAdditionalInfo";
import { UpdatePortfolioSnsInfo } from "./UpdatePortfolioSnsInfo";

// 포트폴리오 수정 페이지
export const UpdatePortfolioContents = forwardRef((props, forwardRef) => {
  const { myProfileValueState, blobImgSrcState, myProfileValueChange, changeProfileImgClickFunc, deleteProfileImgFunc, changeProfileImgFunc, onKeyPress, deleteProjectEvent, changeThumbnailClickFunc, changeThumbnailFunc } = props;
  const { nameRef, introduceRef, instagramRef, naverBlogRef, naverPostRef, youtubeRef, project1Ref, project1ThumbnailRef, project2Ref, project2ThumbnailRef, project3Ref, project3ThumbnailRef, fileUploadRef } = forwardRef;

  return (
    <Grid container rowSpacing={8}>
      <Grid container item>
        <Grid item xs={12}>
          <Typography variant="h3" fontWeight={700} color="black.200">
            프로필 정보
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" fontWeight={400} color="black.600">
            의뢰자(브랜드)가 확인하는 나의 프로필을 수정합니다.
          </Typography>
        </Grid>
      </Grid>
      <Grid container item rowSpacing={8}>
        <Grid container item xs={12} rowSpacing={4} columnSpacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" fontWeight={700} color="black.200">
              기본 정보
            </Typography>
          </Grid>
          <Grid item xs={5.8}>
            <UpdatePortfolioBasicInfo ref={{ nameRef: nameRef, introduceRef: introduceRef, fileUploadRef: fileUploadRef }} myProfileValueState={myProfileValueState} blobImgSrcState={blobImgSrcState} myProfileValueChange={myProfileValueChange} changeProfileImgClickFunc={changeProfileImgClickFunc} changeProfileImgFunc={(event) => changeProfileImgFunc(event)} deleteProfileImgFunc={deleteProfileImgFunc} onKeyPress={onKeyPress} />
          </Grid>
          <Grid container item xs={0.4} alignSelf="end" justifyContent="center">
            <Divider orientation="vertical" sx={{ height: "312px" }} />
          </Grid>
          <Grid item xs={5.8}>
            <UpdatePortfolioSnsInfo ref={{ instagramRef: instagramRef, naverBlogRef: naverBlogRef, naverPostRef: naverPostRef, youtubeRef: youtubeRef }} myProfileValueState={myProfileValueState} myProfileValueChange={myProfileValueChange} onKeyPress={onKeyPress} />
          </Grid>
        </Grid>
        <Grid container item rowSpacing={4}>
          <Grid container item>
            <Grid item xs={12}>
              <Typography variant="h5" fontWeight={700} color="black.200">
                대표 프로젝트
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight={400} color="black.600">
                내 포트폴리오에 보여주고 싶은 3개의 작업물 썸네일과 해당 작업물 링크를 등록해주세요.
              </Typography>
            </Grid>
          </Grid>
          <Grid container item>
            <UpdatePortfolioAdditionalInfo ref={{ project1Ref: project1Ref, project1ThumbnailRef: project1ThumbnailRef, project2Ref: project2Ref, project2ThumbnailRef: project2ThumbnailRef, project3Ref: project3Ref, project3ThumbnailRef: project3ThumbnailRef }} blobImgSrcState={blobImgSrcState} myProfileValueState={myProfileValueState} myProfileValueChange={myProfileValueChange} onKeyPress={onKeyPress} deleteProjectEvent={deleteProjectEvent} changeThumbnailClickFunc={changeThumbnailClickFunc} changeThumbnailFunc={changeThumbnailFunc} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
