import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";

import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import { logoUrl } from "../../configs/urls";

import { RequestSignIn } from "../../utils/adminRequestToSrv";
import { doSignInWithEmailAndPassword, doSignOutWithNoQuery } from "../../utils/firebaseUtils";

import AuthButton from "../../components/AuthComponents";
import { AuthTextField } from "../../components/CustomTextField";
import { DialogAlertMessage } from "../../components/DialogMessage";

const BackgroundContainer = styled(Container)(({ theme }) => ({
  overflow: "hidden",
  position: "relative",
  height: "100vh",
  background: "linear-gradient(90deg," + theme.palette.secondary.main + " 0%," + theme.palette.secondary.dark + " 100%)",
  backgroundSize: "cover",
}));

const BackgroundCircleM = styled(Box)({
  position: "absolute",
  zIndex: 1,
  left: "-13.48vw",
  top: "-17.82vh",
  width: "45.625vw",
  height: "45.625vw",
  borderRadius: "50%",
  background: "linear-gradient(295.69deg,rgba(0, 174, 136, 0.6) 12.7%, rgba(1, 174, 153, 0) 42.6%)",
});
const BackgroundCircleL = styled(Box)({
  position: "absolute",
  zIndex: 1,
  left: "48.07vw",
  top: "40.78vh",
  width: "55.58vw",
  height: "55.58vw",
  borderRadius: "50%",
  background: "linear-gradient(157.93deg, rgba(0, 174, 136, 0.83) 15.68%, rgba(1, 174, 153, 0) 38.39%)",
});

const BackgroundGrid = styled(Grid)({
  height: "100%",
});

const LogoImg = styled("img")({
  width: "100%",
});

const SignInFormPaper = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  maxWidth: theme.breakpoints.values.sm,
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
  padding: "40px 70px",
  zIndex: 5,
}));

// 정규표현식
const emailRegEx = new RegExp(/^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/);

// admin - sign in
export default function AdminSignInContainer() {
  // react-router-dom navigate
  const navigate = useNavigate();
  // data loading state
  const [loadState, loadSetState] = useState(false);

  // 각 textField를 위한 ref
  const userEmailRef = useRef(null);
  const userPasswordRef = useRef(null);

  // 각각의 입력값에 대한 state
  const [signInValueState, signInValueSetState] = useState({
    email: "",
    password: "",
  });

  // 각각의 입력값에 대한 state 변경 함수
  const signInValueChangeFunc = (key, value) => {
    signInValueSetState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // 에러 메시지 text state
  const [errorMessageState, errorMessageSetState] = useState("");
  // alert message dialog custom hook
  const { alertDialogCall, DialogAlertComponent } = DialogAlertMessage(errorMessageState);

  // sign in function
  const signInMutation = useMutation((userCredential) => RequestSignIn({ token: userCredential.user.accessToken, userType: "admin" }), {
    onSuccess: (data) => {
      if (data === "userType Error") {
        errorMessageSetState("관리자 계정이 아닙니다.");
        alertDialogCall();
        doSignOutWithNoQuery();
      } else if (data === "deleted user") {
        errorMessageSetState("탈퇴한 계정입니다.");
        alertDialogCall();
        doSignOutWithNoQuery();
      } else {
        navigate("/admin-users");
      }
    },
    onError: (error) => {
      console.log(error);
      errorMessageSetState("로그인에 실패하였습니다");
      alertDialogCall();
      doSignOutWithNoQuery();
    },
    onSettled: () => {
      loadSetState(false);
    },
  });

  // 로그인 버튼 클릭 이벤트
  const signInFunc = () => {
    if (!signInValueState.email) {
      console.log(signInValueState.email);
      errorMessageSetState("이메일 주소를 입력해 주세요!");
      alertDialogCall();
      userEmailRef.current.focus();
      return 0;
    } else if (!emailRegEx.test(signInValueState.email)) {
      errorMessageSetState("올바른 이메일 주소를 입력해 주세요!");
      alertDialogCall();
      userEmailRef.current.focus();
      return 0;
    } else if (!signInValueState.password) {
      errorMessageSetState("패스워드를 입력해 주세요!");
      alertDialogCall();
      userPasswordRef.current.focus();
      return 0;
    } else {
      loadSetState(true);
      doSignInWithEmailAndPassword({ email: signInValueState.email, password: signInValueState.password, callBack: signInMutation.mutate, errorMessageSetState: errorMessageSetState, errorMessageCall: alertDialogCall, loadSetState: loadSetState });
    }
  };

  // 엔터키 입력 이벤트
  const onEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      signInFunc();
    }
  };

  // sign in 화면
  return (
    <BackgroundContainer maxWidth={false} disableGutters={true}>
      <BackgroundCircleM />
      <BackgroundCircleL />
      <BackgroundGrid container direction="column" justifyContent="center">
        <Grid item xs="auto">
          <Container maxWidth="sm">
            <Grid container direction="row" justifyContent="center" alignItems="center" rowSpacing={2}>
              <Grid item xs="auto">
                <SignInFormPaper>
                  <Grid container direction="row" justifyContent="center" alignContent="flex-start" rowSpacing={5}>
                    <Grid container item justifyContent="center" alignItems="flex-end" columnSpacing={1}>
                      <Grid item xs={5}>
                        <LogoImg src={logoUrl.logo} alt="로고" />
                      </Grid>
                      <Grid item xs="auto">
                        <Typography variant="body1" fontWeight={700}>
                          admin
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item rowSpacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="body1" fontWeight={400}>
                          이메일
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <AuthTextField type="email" placeholder="이메일을 입력해주세요" ref={userEmailRef} value={signInValueState.email} setValueEvent={(event) => signInValueChangeFunc("email", event.target.value)} onKeyPress={(event) => onEnterKeyPress(event)} autoFocus={true} />
                      </Grid>
                    </Grid>
                    <Grid container item rowSpacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="body1" fontWeight={400}>
                          비밀번호
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <AuthTextField type="password" placeholder="비밀번호를 입력해주세요" ref={userPasswordRef} value={signInValueState.password} setValueEvent={(event) => signInValueChangeFunc("password", event.target.value)} onKeyPress={(event) => onEnterKeyPress(event)} />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <AuthButton loadState={loadState} buttonText="로그인" activated={true} onClickEvent={() => signInFunc()} />
                    </Grid>
                  </Grid>
                </SignInFormPaper>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </BackgroundGrid>
      <DialogAlertComponent />
    </BackgroundContainer>
  );
}
