import React from "react";

import moment from "moment";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";

const ContainerPaper = styled(Paper)({
  width: "100%",
  borderRadius: "10px",
  padding: "30px 35px",
});

const CustomDivider = styled(Divider)({
  borderColor: "#5E5E5E",
});

const CustomTableHeadRow = styled(TableRow)({
  borderTop: "1px solid #5E5E5E",
  background: "#F9FAFB",
  borderBottom: "1px solid #DDDDDD",
});

const CustomPagination = styled(Pagination)({
  "& .Mui-selected": {
    background: "#ffffff",
    fontWeight: 700,
  },
});

// 기업 회원 미구독 멤버십 정보 화면
export default function AdminNoSubscribingMembershipContents(props) {
  const { membershipInfo, tablePageState, tablePageChange } = props;

  const dataPerPage = 15;

  const membershipInfoArray = Object.keys(membershipInfo);

  const MembershipInfoContext = () => {
    return (
      <Grid item xs={12}>
        <ContainerPaper elevation={0}>
          <Grid container rowSpacing={4}>
            <Grid item xs={12}>
              <Typography variant="h6" lineHeight="30px" color="#333333">
                이용중인 멤버십 정보
              </Typography>
            </Grid>
            <Grid container item rowSpacing={2}>
              <Grid item xs={12}>
                <Grid item>
                  <Typography variant="body2" lineHeight="24px" fontWeight={700} color="secondary">
                    {membershipInfo[membershipInfoArray[0]].membershipName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CustomDivider />
              </Grid>
              <Grid container item rowSpacing={6.5}>
                <Grid container item rowSpacing={2}>
                  <Grid container item>
                    <Grid item xs={2}>
                      <Typography variant="body2" lineHeight="24px" color="#333333">
                        이용 기간
                      </Typography>
                    </Grid>
                    <Grid container item xs={10} alignItems="center" columnSpacing={1}>
                      <Grid item>
                        <Typography variant="body2" lineHeight="24px" color="#333333">
                          {moment(membershipInfo[membershipInfoArray[0]].paymentDate).format("YYYY.MM.DD")}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" lineHeight="24px" color="#333333">
                          ~
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" lineHeight="24px" color="#333333">
                          {moment(membershipInfo[membershipInfoArray[0]].paymentDate).add(1, "month").format("YYYY.MM.DD")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item xs={2}>
                      <Typography variant="body2" lineHeight="24px" color="#333333">
                        결제일
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body2" lineHeight="24px" color="#333333">
                        {moment(membershipInfo[membershipInfoArray[0]].paymentDate).format("YYYY.MM.DD")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item xs={2}>
                      <Typography variant="body2" lineHeight="24px" color="#333333">
                        결제 금액
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body2" lineHeight="24px" color="#333333">
                        {new Intl.NumberFormat("ko-KR").format(membershipInfo[membershipInfoArray[0]].paymentAmount)}원
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ContainerPaper>
      </Grid>
    );
  };

  // 기업 회원 멤버십 결제 내역 화면
  const MembershipPaymentHistoryContext = () => {
    return (
      <Grid container item rowSpacing={3.75} justifyContent="center">
        <Grid item xs={12}>
          <ContainerPaper elevation={0}>
            <Grid container rowSpacing={4}>
              <Grid item xs={12}>
                <Typography variant="h6" lineHeight="30px" color="#333333">
                  결제내역
                </Typography>
              </Grid>
              <Grid container item rowSpacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <CustomTableHeadRow>
                          <TableCell>
                            <Typography variant="body2" lineHeight="24px" color="#333333">
                              멤버십명
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" lineHeight="24px" color="#333333">
                              결제금액
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" lineHeight="24px" color="#333333">
                              결제수단
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" lineHeight="24px" color="#333333">
                              결제일
                            </Typography>
                          </TableCell>
                        </CustomTableHeadRow>
                      </TableHead>
                      <TableBody>
                        {membershipInfoArray.slice((tablePageState - 1) * dataPerPage, (tablePageState - 1) * dataPerPage + dataPerPage).map((payment, index) => {
                          return (
                            <TableRow key={"payment" + index}>
                              <TableCell>
                                <Typography variant="body2" lineHeight="24px" color="#333333">
                                  {membershipInfo[payment].membershipName}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2" lineHeight="24px" color="#333333">
                                  {new Intl.NumberFormat("ko-KR").format(membershipInfo[payment].paymentAmount)}원
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2" lineHeight="24px" color="#333333">
                                  {membershipInfo[payment].paymentCard}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2" lineHeight="24px" color="#333333">
                                  {moment(membershipInfo[payment].paymentDate).format("YYYY.MM.DD")}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </ContainerPaper>
        </Grid>
        <Grid item>
          <CustomPagination shape="rounded" count={Math.ceil(membershipInfoArray.length / dataPerPage)} page={tablePageState} onChange={tablePageChange} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container item rowSpacing={2.5}>
      {MembershipInfoContext()}
      {MembershipPaymentHistoryContext()}
    </Grid>
  );
}
