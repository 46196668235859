import React from "react";

import moment from "moment";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ButtonBase from "@mui/material/ButtonBase";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { TypoButton } from "../../components/ButtonComponents";

const BorderBoxGrid = styled(Grid)({
  padding: "24px",
  border: "1px solid #D9D9D9",
  borderRadius: "10px",
});

const RedBoxGrid = styled(Grid)({
  padding: "18px 24px",
  background: "#FCF7F7",
  borderRadius: "10px",
});

const CustomDivider = styled(Divider)({
  borderColor: "#E6E6E6",
});

const CustomTableBody = styled(TableBody)({
  borderTop: "1px solid #222222",
});

const CustomTableCell = styled(TableCell)({
  padding: "18px",
});

const CustomPagination = styled(Pagination)({
  "& .Mui-selected": {
    background: "#ffffff",
    fontWeight: 700,
  },
});

const GreyBoxContainerGrid = styled(Grid)({
  padding: "9px 16px",
  background: "#FAFAFA",
  borderRadius: "5px",
});

// 구독 중인 멤버십 정보
export default function SubscribingMembershipContents(props) {
  const { membershipInfo, tablePageState, tablePageChange, linkToMembershipInfo, cancelSubscriptionButtonFunc } = props;

  const dataPerPage = 15;

  const membershipInfoArray = Object.keys(membershipInfo);

  const MembershipInfoContext = () => {
    return (
      <Grid container item rowGap={2.3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" lineHeight="30px" fontWeight={700} color="black.200">
            이용중인 멤버십 정보
          </Typography>
        </Grid>
        <BorderBoxGrid container item justifyContent="center" rowGap={2.5}>
          <Grid container item xs={12}>
            <ButtonBase onClick={() => linkToMembershipInfo()} sx={{ width: "100%" }}>
              <Grid container item alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography variant="subtitle1" lineHeight="13px" fontWeight={600} color="black.300">
                    {membershipInfo[membershipInfoArray[0]].membershipName}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography lineHeight="13px" color="black.900">
                    <KeyboardArrowRightIcon sx={{ marginRight: "-6px" }} />
                  </Typography>
                </Grid>
              </Grid>
            </ButtonBase>
          </Grid>
          <Grid item xs={12}>
            <CustomDivider />
          </Grid>
          <Grid container item rowSpacing={6.5}>
            <Grid container item rowSpacing={2}>
              <Grid container item>
                <Grid item xs={2}>
                  <Typography variant="body2" color="black.600">
                    이용 기간
                  </Typography>
                </Grid>
                <Grid container item xs={10} columnSpacing={1} justifyContent="flex-end">
                  <Grid item>
                    <Typography variant="body2" fontWeight={600} color="black.600">
                      {moment(membershipInfo[membershipInfoArray[0]].paymentDate).format("YYYY.MM.DD")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" fontWeight={600} color="black.600">
                      ~
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" fontWeight={600} color="black.600">
                      {moment(membershipInfo[membershipInfoArray[0]].paymentDate).add(1, "month").format("YYYY.MM.DD")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={2}>
                  <Typography variant="body2" color="black.600">
                    결제일
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body2" fontWeight={600} color="black.600" textAlign="right">
                    {moment(membershipInfo[membershipInfoArray[0]].paymentDate).format("YYYY.MM.DD")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={2}>
                  <Typography variant="body2" color="black.600">
                    결제 금액
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body2" fontWeight={600} color="black.600" textAlign="right">
                    {new Intl.NumberFormat("ko-KR").format(membershipInfo[membershipInfoArray[0]].paymentAmount)}원
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </BorderBoxGrid>
      </Grid>
    );
  };

  // 멤버십 다음 결제 정보
  const SubscribeInfoContext = () => {
    return (
      <Grid container item rowGap={2.3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" lineHeight="30px" fontWeight={700} color="black.200">
            다음 결제예정 정보
          </Typography>
        </Grid>
        <Grid container rowGap={3}>
          <Grid container rowGap={1.3}>
            <BorderBoxGrid container item justifyContent="center" rowGap={2}>
              <Grid container item>
                <Grid item xs={2}>
                  <Typography variant="body2" color="black.600">
                    결제일
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body2" fontWeight={600} color="black.600" textAlign="right">
                    {moment(membershipInfo[membershipInfoArray[0]].paymentDate).add(1, "month").format("YYYY.MM.DD")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={2}>
                  <Typography variant="body2" color="black.600">
                    결제 예정 금액
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body2" fontWeight={600} color="black.600" textAlign="right">
                    {new Intl.NumberFormat("ko-KR").format(membershipInfo[membershipInfoArray[0]].paymentAmount)}원
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={2}>
                  <Typography variant="body2" color="black.600">
                    결제 수단
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body2" fontWeight={600} color="black.600" textAlign="right">
                    {membershipInfo[membershipInfoArray[0]].paymentMethod === "card" ? membershipInfo[membershipInfoArray[0]].paymentCard : membershipInfo[membershipInfoArray[0]].paymentMethod}
                  </Typography>
                </Grid>
              </Grid>
            </BorderBoxGrid>
            <RedBoxGrid container item>
              <Grid item xs={12}>
                <Typography variant="body2" fontWeight={300} lineHeight="22px" color="black.600">
                  - 해지 예약은 다음 결제일 3시 전까지만 가능합니다.
                </Typography>
                <Typography variant="body2" fontWeight={300} lineHeight="22px" color="black.600">
                  - 환불 요청은 on1016 고객센터로 문의해주세요.
                </Typography>
              </Grid>
            </RedBoxGrid>
          </Grid>
          <Grid container justifyContent="end" columnGap={1}>
            <Grid item>
              <ButtonBase onClick={() => { }}>
                <TypoButton buttonText="결제수단 변경" buttonColor="secondary" />
              </ButtonBase>
            </Grid>
            <Grid item>
              <ButtonBase onClick={() => cancelSubscriptionButtonFunc()}>
                <TypoButton buttonText="결제 해지" buttonColor="error" />
              </ButtonBase>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  // 멤버십 결제 내역 - 결제 이력이 없는 경우
  const MembershipPaymentNoHistoryContext = () => {
    return (
      <Grid container item rowGap={2.3} justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="subtitle1" lineHeight="30px" fontWeight={700} color="black.200">
            결제내역
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <CustomTableBody>
                <TableRow>
                  <TableCell>
                    <Grid item padding="60px 0">
                      <Typography variant="subtitle1" lineHeight="22px" fontWeight={600} color="black.600" align="center">
                        결제 내역이 없습니다.
                      </Typography>
                    </Grid>
                  </TableCell>
                </TableRow>
              </CustomTableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  };

  // 결제내역
  const MembershipPaymentHistoryContext = () => {
    return (
      <Grid container item rowGap={3} justifyContent="center">
        <Grid container item rowGap={2.3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" lineHeight="30px" fontWeight={700} color="black.200">
              결제내역
            </Typography>
          </Grid>
          <Grid container item rowSpacing={2}>
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <CustomTableBody>
                    {membershipInfoArray.slice((tablePageState - 1) * dataPerPage, (tablePageState - 1) * dataPerPage + dataPerPage).map((payment, index) => {
                      return (
                        <TableRow key={"payment" + index}>
                          <CustomTableCell>
                            <Grid container rowGap={2}>
                              <Grid container>
                                <Grid container item xs={6}>
                                  <Grid container>
                                    <Typography variant="body3" lineHeight="22px" color="#444444">
                                      {moment(membershipInfo[payment].paymentDate).format("YYYY.MM.DD")}
                                    </Typography>
                                  </Grid>
                                  <Grid container>
                                    <Typography variant="subtitle1" lineHeight="22px" fontWeight={600} color="black.300">
                                      {membershipInfo[payment].membershipName}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid container item xs={6} alignItems="center">
                                  <Grid container item columnGap={0.3} justifyContent="flex-end" alignItems="baseline">
                                    <Typography variant="h6" fontWeight={700} color="#444444">
                                      {new Intl.NumberFormat("ko-KR").format(membershipInfo[payment].paymentAmount)}
                                    </Typography>
                                    <Typography variant="body1" color="#444444">
                                      원
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <GreyBoxContainerGrid container>
                                <Grid item xs={6}>
                                  <Typography variant="body2" color="black.900">
                                    결제수단
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="body2" fontWeight={600} color="black.600" textAlign="right">
                                    {membershipInfo[payment].paymentCard}
                                  </Typography>
                                </Grid>
                              </GreyBoxContainerGrid>
                            </Grid>
                          </CustomTableCell>
                        </TableRow>
                      );
                    })}
                  </CustomTableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <CustomPagination shape="rounded" count={Math.ceil(membershipInfoArray.length / dataPerPage)} page={tablePageState} onChange={tablePageChange} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container item rowGap={6}>
      {MembershipInfoContext()}
      {SubscribeInfoContext()}
      {membershipInfoArray.length !== 0 ? MembershipPaymentHistoryContext() : MembershipPaymentNoHistoryContext()}
    </Grid>
  );
}
