import React, { forwardRef } from "react";
import { Link } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";

import { TextFieldWithoutLabelSet, DateTextFieldSetWithoutLabel } from "../Common/TextFieldSet";

import MyPageSnsConnect from "./MyPageSnsConnect";
import MyPageMarketingAgreement from "./MyPageMarketingAgreement";

const PasswordChangeButton = styled(Button)({
  height: "48px",
  width: "121px",
  borderRadius: "10px"
});

const CustomDivider = styled(Divider)({
  borderColor: "#999999",
  paddingTop: "16px",
});

const SubCustomDivider = styled(Divider)({
  borderColor: "#F2F2F2",
});

const CustomButton = styled(Button)({
  paddingTop: "16px",
  paddingBottom: "16px",
  borderRadius: "10px",
  boxShadow: "none",
});

const StyledButton = styled(Button)({
  height: "60px",
  borderRadius: "10px"
});

const WithdrawalTypo = styled(Typography)({
  textDecoration: "underline",
});

const LabelGrid = styled(Grid)({
  paddingTop: "19px",
});

// 개인 회원 - 마이페이지
export const MyPageContents = forwardRef((props, forwardRef) => {
  const { valueState, setDateValueEvent, setCheckValueEvent, setValueEvent, onEnterKeyPress, emailVerifyButtonFunc, addressChangeButtonFunc, kakaoSignInFunc, googleSignInFunc, submitEvent, thirdPartyAccount, buttonActiveState, emailVarifiedState } = props;
  // const { valueState, setDateValueEvent, setCheckValueEvent, setValueEvent, onEnterKeyPress, emailVerifyButtonFunc, naverSignInFunc, kakaoSignInFunc, googleSignInFunc, submitEvent, buttonActiveState } = props;
  const { emailRef, nameRef, birthRef, phoneRef, addressRef, addressDetailRef } = forwardRef;

  return (
    <Grid container item rowSpacing={3}>
      <Grid container item rowSpacing={2.5}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" fontWeight={700} color="black.300">
            회원정보
          </Typography>
          <Grid item xs={12}>
            <CustomDivider />
          </Grid>
        </Grid>
        <Grid container item rowSpacing={3}>
          <Grid container item columnSpacing={1.2}>
            <LabelGrid item xs={2.8}>
              <Typography variant="body1" fontWeight={500} color="black.400">
                이메일
              </Typography>
            </LabelGrid>
            <Grid container item xs={9.2} columnSpacing={1.2}>
              <Grid item xs={9.7}>
                <TextFieldWithoutLabelSet type="email" ref={emailRef} valueState={valueState.email} setValueEvent={() => { }} onKeyPress={() => { }} errorState={!emailVarifiedState && !thirdPartyAccount} caption={!emailVarifiedState && !thirdPartyAccount ? "인증되지 않은 이메일입니다." : ""} readOnly={true} />
              </Grid>
              <Grid item xs={2.3}>
                <StyledButton variant="outlined" color="secondary" fullWidth onClick={() => emailVerifyButtonFunc()}>
                  <Typography variant="subtitle1" fontWeight={400} color="black.500">
                    인증
                  </Typography>
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SubCustomDivider />
          </Grid>
          <Grid container item columnSpacing={1.2}>
            <LabelGrid item xs={2.8}>
              <Typography variant="body1" fontWeight={500} color="black.400">
                이름
              </Typography>
            </LabelGrid>
            <Grid item xs={9.2}>
              <TextFieldWithoutLabelSet type="text" placeholder="이름" ref={nameRef} valueState={valueState.name} setValueEvent={(event) => setValueEvent("name", event.target.value)} onKeyPress={(event) => onEnterKeyPress(event)} readOnly={false} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SubCustomDivider />
          </Grid>
          <Grid container item columnSpacing={1.2}>
            <LabelGrid item xs={2.8}>
              <Typography variant="body1" fontWeight={500} color="black.400">
                생년월일
              </Typography>
            </LabelGrid>
            <Grid item xs={9.2}>
              <DateTextFieldSetWithoutLabel inputFormat="yyyy.MM.DD" placeholder="YYYY.MM.DD" ref={birthRef} value={valueState.birth} onChange={(newValue) => setDateValueEvent(newValue)} onKeyPress={(event) => onEnterKeyPress(event)} disablePast={false} disableFuture={true} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SubCustomDivider />
          </Grid>
          <Grid container item columnSpacing={1.2}>
            <LabelGrid item xs={2.8}>
              <Typography variant="body1" fontWeight={500} color="black.400">
                연락처
              </Typography>
            </LabelGrid>
            <Grid item xs={9.2}>
              <TextFieldWithoutLabelSet type="tel" placeholder="010-0000-0000" ref={phoneRef} valueState={valueState.phone} setValueEvent={(event) => setValueEvent("phone", event.target.value)} inputProps={{ maxLength: 13 }} onKeyPress={(event) => onEnterKeyPress(event)} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SubCustomDivider />
          </Grid>
          {thirdPartyAccount ? null : (
            <Grid container item columnSpacing={1.2} rowGap={3}>
              <Grid item xs={2.8} paddingTop="10px">
                <Typography variant="body1" fontWeight={500} color="black.400">
                  비밀번호
                </Typography>
              </Grid>
              <Grid item xs={2.4}>
                <PasswordChangeButton component={Link} to="/change-password" variant="outlined" color="secondary" fullWidth>
                  <Typography variant="body2" color="black.400">
                    비밀번호 변경
                  </Typography>
                </PasswordChangeButton>
              </Grid>
              <Grid item xs={12}>
                <SubCustomDivider />
              </Grid>
            </Grid>
          )}
          <Grid container item columnSpacing={1.2}>
            <LabelGrid item xs={2.8}>
              <Typography variant="body1" fontWeight={500} color="black.400">
                주소
              </Typography>
            </LabelGrid>
            <Grid container item xs={9.2} rowGap={1}>
              <Grid container item columnSpacing={1.2}>
                <Grid item xs={9.7}>
                  <TextFieldWithoutLabelSet type="text" placeholder="주소" ref={addressRef} valueState={valueState.address} setValueEvent={() => { }} onKeyPress={() => { }} readOnly={true} />
                </Grid>
                <Grid item xs={2.3}>
                  <StyledButton variant="outlined" color="secondary" fullWidth onClick={() => addressChangeButtonFunc()}>
                    <Typography variant="subtitle1" fontWeight={400} color="black.500">
                      검색
                    </Typography>
                  </StyledButton>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12}>
                  <TextFieldWithoutLabelSet type="text" placeholder="상세 주소" ref={addressDetailRef} valueState={valueState.addressDetail} setValueEvent={(event) => setValueEvent("addressDetail", event.target.value)} onKeyPress={(event) => onEnterKeyPress(event)} readOnly={valueState.address === ""} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <MyPageSnsConnect kakaoSignInFunc={kakaoSignInFunc} googleSignInFunc={googleSignInFunc} /> */}
          {/* <MyPageSnsConnect naverSignInFunc={naverSignInFunc} kakaoSignInFunc={kakaoSignInFunc} googleSignInFunc={googleSignInFunc} /> */}
          <Grid item xs={12}>
            <SubCustomDivider />
          </Grid>
        </Grid>
      </Grid>
      <MyPageMarketingAgreement checkedState={valueState.marketingAgreement} checkEvent={setCheckValueEvent} />
      <Grid item xs={12} textAlign="end">
        <ButtonBase component={Link} to="/withdrawal">
          <WithdrawalTypo variant="body2" color="black.200">회원탈퇴</WithdrawalTypo>
        </ButtonBase>
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Grid item xs={5.6}>
          <CustomButton variant="contained" color="secondary" fullWidth onClick={() => submitEvent()} disabled={!buttonActiveState}>
            <Typography variant="subtitle1" color="#ffffff">
              저장하기
            </Typography>
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
});
