import React, { useRef, useState, Fragment, useEffect } from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Divider from "@mui/material/Divider";

import { TextFieldSetWithCaption } from "../../../Block/Common/TextFieldSet";
import AuthButton from "../../../components/AuthComponents";
import AuthBodyFrame from "../../../Block/Auth/AuthBodyFrame";
// import DialogMessage, { DialogAlertMessage } from "../../../components/DialogMessage";
import { CustomizedSnackbars } from "../../../components/Alert";

import { doSendPasswordResetEmail } from "../../../utils/firebaseUtils";
import { DialogWithOneButton } from "../../../components/DialogWithButton";

const CustomDivider = styled(Divider)({
  background: "#EDEDED",
});

const EmailAddressTypography = styled(Typography)({
  textDecoration: "underline",
});

// 정규표현식
const emailRegEx = new RegExp(/^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/);

// 비밀번호 찾기
export default function FindPasswordContainer() {
  // 화면 로드 시 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // data loading state
  const [loadState, loadSetState] = useState(false);

  // email textfield ref
  const emailRef = useRef(null);

  // 입력한 email state
  const [userEmailState, userEmailSetState] = useState("");

  // email textfield value change event
  const emailValueChangeEvent = (event) => {
    userEmailSetState(event.target.value);
  };

  // dialog message text state
  const [alertState, setAlertState] = useState("");
  const [dialogMessageState, dialogMessageSetState] = useState("");
  
  // 이메일 전송 완료 dialog open state
  const [dialogOpenState, dialogOpenSetState] = useState(false);

  // 이메일 전송 완료 dialog open/close event
  const dialogOpen = () => {
    dialogOpenSetState(true);
  }
  const dialogClose = () => {
      dialogOpenSetState(false);
    };
  
  // alert dialog message text state
  // const [alertDialogMessageState, alertDialogMessageSetState] = useState("");

  // dialog message custom hook
  // const { dialogCall, DialogComponent } = DialogMessage(dialogMessageState);
  
  // alert dialog message custom hook
  // const { alertDialogCall, DialogAlertComponent } = DialogAlertMessage(alertDialogMessageState);

  const {handleClick, AlertComponent} = CustomizedSnackbars(alertState, dialogMessageState);

  // dialog message text change function
  const setDialogMessage = (message) => {
    dialogMessageSetState(message);
  };
  
  // alert dialog message text change function
  // const setAlertDialogMessage = (message) => {
  //   alertDialogMessageSetState(message);
  // };

  // 이메일 전송 버튼 클릭 이벤트
  const sendEmailEvent = () => {
    setAlertState("error");
    doSendPasswordResetEmail({
      email: userEmailState,
      loadSetState: loadSetState,
      dialogCall: dialogOpen,
      setDialogMessage: setDialogMessage,
      alertDialogCall: handleClick,
      setAlertDialogMessage: setDialogMessage
    });
  };

  // enter key press event
  const onEnterKeyPress = (event) => {
    if (emailRegEx.test(userEmailState)) {
      if (event.key === "Enter") {
        sendEmailEvent();
      }
    }
  };

  // 이메일 주소 복사하기
  const copyEventFunc = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText("cs@glowide.io")
        .then(() => {
          setAlertState("success");
          setDialogMessage("클립보드에 이메일 주소가 복사되었습니다.");
          handleClick();
        })
        .catch(() => {
          setAlertState("error");
          setDialogMessage("이메일 주소 복사하기에 실패하였습니다.");
          handleClick();
        });
    } else {
      setAlertState("error");
      setDialogMessage("클립보드 복사 기능을 지원하지 않는 브라우저입니다.");
      handleClick();
    }
  };

  // 비밀번호 찾기 화면
  const findPasswordContent = () => {
    return (
      <Fragment>
        <Grid container item rowSpacing={5.3}>
          <Grid container item rowSpacing={3}>
            <Grid container item xs={12} rowGap={1} paddingBottom="36px">
              <Typography variant="h4" fontWeight={700} color="black.200">
                가입 이메일 입력
              </Typography>
              <Typography variant="subtitle1" fontWeight={400} color="black.300">
                가입하신 이메일로 비밀번호를 변경할 수 있는 링크를 보내드립니다.
              </Typography>
            </Grid>
            <TextFieldSetWithCaption label="이메일" type="email" placeholder="reble@email.com" ref={emailRef} valueState={userEmailState} setValueEvent={(event) => emailValueChangeEvent(event)} onBlur={() => {}} onKeyPress={(event) => onEnterKeyPress(event)} autoFocus={true} />
            <Grid item xs={12}>
              <AuthButton loadState={loadState} buttonText="이메일 전송" activated={true} onClickEvent={sendEmailEvent} disabled={!emailRegEx.test(userEmailState)} onKeyPress={onEnterKeyPress} />
            </Grid>
          </Grid>
          <Grid container item rowSpacing={3}>
            <Grid item xs={12}>
              <CustomDivider />
            </Grid>
            <Grid item>
              <Typography variant="body1" lineHeight="24px" color="black.300">
                가입했던 이메일이 기억나지 않을 경우
                <br />
                <ButtonBase onClick={() => copyEventFunc()}>
                  <EmailAddressTypography variant="body1" lineHeight="24px">
                    cs@glowide.io
                  </EmailAddressTypography>
                </ButtonBase>
                으로 문의해주세요.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/*<DialogComponent />*/}
        {/*<DialogAlertComponent />*/}
        <AlertComponent/>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <AuthBodyFrame title="비밀번호 찾기" content={findPasswordContent()} />
      <DialogWithOneButton width={380} message={["이메일이 전송되었습니다.", <br />, "메일함을 확인해주세요."]} dialogOpenState={dialogOpenState} buttonText="확인" buttonFunc={dialogClose} />
    </Fragment>
  );
}
