import React from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const ProfilePaper = styled(Paper)({
  padding: "39px 28px",
});

const ProfileImageBox = styled(Box)({
  width: 120,
  height: 120,
  borderRadius: "50%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  background: "#D9D9D9",
});

const ProfileImage = styled("img")(({ profileImageErrorState }) => ({
  width: profileImageErrorState ? "50%" : "100%",
  height: profileImageErrorState ? "50%" : "100%",
  objectFit: "cover",
}));

// 포트폴리오 - 프로필 박스
export default function MyPortfolioProfileBox(props) {
  const { portfolioData } = props;

  return (
    <ProfilePaper>
      <Grid container direction="row" justifyContent="center" justifyItems="center" rowSpacing={2.6}>
        <Grid item>
          <ProfileImageBox>{portfolioData.profilePhotoUrl ? <ProfileImage src={portfolioData.profilePhotoUrl} alt="프로필" /> : null}</ProfileImageBox>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" fontSize="23px" fontWeight={500} align="center">
            {portfolioData.name ? portfolioData.name : "회원"}님
          </Typography>
        </Grid>
        <Grid container item rowSpacing={1}>
          <Grid item xs={12}>
            <Typography variant="caption1" component="p" color="#999999">
              자기소개
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="#333333" lineHeight="24px">
              {portfolioData.introduction}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </ProfilePaper>
  );
}
