import React from "react";

import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Pagination from "@mui/material/Pagination";

import NaverBlogTag, { InstagramTag, NaverPostTag, YoutubeTag } from "../../components/ProjectComponent";

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const CustomTableHeadRow = styled(TableRow)({
  borderTop: "1px solid #111111",
});

const CustomTableBodyRow = styled(TableRow)(({ activeState }) => ({
  backgroundColor: activeState ? "#FFFFFF" : "#FCF7F7",
}));

const CustomPagination = styled(Pagination)({
  "& .Mui-selected": {
    background: "#ffffff",
    fontWeight: 700,
  },
});

const PaginationGridContainer = styled(Grid)({
  position: "relative",
});

const PaginationGridItem = styled(Grid)({
  zIndex: 20,
});

const CancelButtonBase = styled(ButtonBase)({
  borderRadius: "100px",
});

// 진행 중 프로젝트 상세 페이지 하단 참여자 정보 테이블
export default function OngoingProjectBelowTable(props) {
  const { projectDetailValue, appointersListValue, snsOpenFunc, tablePageState, tablePageChange, canceledUserGuideDialogOpenFunc } = props;

  const dataPerPage = 15;

  const TableBody = () => {
    return Object.keys(appointersListValue)
      .slice((tablePageState - 1) * dataPerPage, (tablePageState - 1) * dataPerPage + dataPerPage)
      .map((appointer, index) => {
        return (
          <CustomTableBodyRow key={"ongoingBelow" + index} activeState={appointersListValue[appointer].activeState}>
            <TableCell>
              {!appointersListValue[appointer].activeState ? (
                <Grid item position="absolute">
                  <CancelButtonBase onClick={() => canceledUserGuideDialogOpenFunc()}>
                    <HelpOutlineIcon color="error"/>
                  </CancelButtonBase>
                </Grid>
              ) : null}
              <Typography variant="subtitle2" lineHeight="24px" fontWeight={400} color="black.300" align="center">
                {appointersListValue[appointer].name}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2" lineHeight="24px" fontWeight={400} color="black.300" align="center">
                {appointersListValue[appointer].email}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2" lineHeight="24px" fontWeight={400} color="black.300" align="center">
                {appointersListValue[appointer].contact}
              </Typography>
            </TableCell>
            <TableCell>
              <Grid container justifyContent="center" columnSpacing={1}>
                {appointersListValue[appointer].sns.naverBlog !== "" && projectDetailValue.channel === "naverBlog" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(appointersListValue[appointer].sns.naverBlog)}>
                      <NaverBlogTag />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {appointersListValue[appointer].sns.naverPost !== "" && projectDetailValue.channel === "naverPost" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(appointersListValue[appointer].sns.naverPost)}>
                      <NaverPostTag />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {appointersListValue[appointer].sns.instagram !== "" && projectDetailValue.channel === "instagram" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(appointersListValue[appointer].sns.instagram)}>
                      <InstagramTag />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {appointersListValue[appointer].sns.youtube !== "" && projectDetailValue.channel === "youtube" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(appointersListValue[appointer].sns.youtube)}>
                      <YoutubeTag />
                    </ButtonBase>
                  </Grid>
                ) : null}
              </Grid>
            </TableCell>
            <TableCell>
              <Grid container justifyContent="space-between">
                <Grid item xs="auto">
                  <Typography variant="subtitle2" lineHeight="24px" fontWeight={400} color="black.300" align="center">
                    {appointersListValue[appointer].address}
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
          </CustomTableBodyRow>
        );
      });
  };

  return (
    <Grid container item rowSpacing={3}>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <CustomTableHeadRow>
                <TableCell align="center" width="15%">
                  <Typography variant="body1" lineHeight="24px" color="#4C4C4C">
                    이름
                  </Typography>
                </TableCell>
                <TableCell align="center" width="20%">
                  <Typography variant="body1" lineHeight="24px" color="#4C4C4C">
                    이메일
                  </Typography>
                </TableCell>
                <TableCell align="center" width="15%">
                  <Typography variant="body1" lineHeight="24px" color="#4C4C4C">
                    연락처
                  </Typography>
                </TableCell>
                <TableCell align="center" width="15%">
                  <Typography variant="body1" lineHeight="24px" color="#4C4C4C">
                    채널
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1" lineHeight="24px" color="#4C4C4C">
                    주소
                  </Typography>
                </TableCell>
              </CustomTableHeadRow>
            </TableHead>
            <TableBody>{TableBody()}</TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <PaginationGridContainer container item justifyContent="center" alignItems="center">
        <PaginationGridItem item>
          <CustomPagination shape="rounded" count={Math.ceil(Object.keys(appointersListValue).length / dataPerPage)} page={tablePageState} onChange={tablePageChange} />
        </PaginationGridItem>
      </PaginationGridContainer>
    </Grid>
  );
}
