import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient, useQuery } from "react-query";
import moment from "moment";

import MyPageBodyFrame from "../../../Block/IncUserMyPage/MyPageBodyFrame";
import MyPageMenuBox from "../../../Block/IncUserMyPage/MyPageMenuBox";

import SubscribingMembershipContents from "../../../Block/IncUserMyPage/SubscribingMembershipContents";
import NoMembershipContents from "../../../Block/IncUserMyPage/NoMembershipContents";
import NoSubscribingMembershipContents from "../../../Block/IncUserMyPage/NoSubscribingMembershipContents";

import DialogWithButton, { DialogWithOneButton } from "../../../components/DialogWithButton";

import LoadingComponent from "../../../components/LoadingComponent";

import { RequestPaymentHistory } from "../../../utils/requestToSrv";

// 기업 회원 멤버십 정보 화면
export default function MembershipContainer() {
  // 화면 로드 시 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // react-router-dom navigate
  const navigate = useNavigate();

  // react-query queryClient
  const queryClient = useQueryClient();

  // 결제 내역 data load
  const paymentHistory = useQuery("paymentInfo", RequestPaymentHistory, {
    onError: (error) => {
      console.log(error);
    },
  });

  // 테이블 페이지 state
  const [tablePageState, tablePageSetState] = useState(1);
  // 테이블 페이지 이동 function
  const tablePageChange = (event, newPage) => {
    tablePageSetState(newPage);
  };

  // 멤버십 해지 다이얼로그 open state
  const [cancelSubscriptionDialogOpenState, cancelSubscriptionDialogOpenSetState] = useState(false);

  // 멤버십 해지 다이얼로그 open function
  const cancelSubscriptionDialogOpenFunc = () => {
    cancelSubscriptionDialogOpenSetState(true);
  };

  // 멤버십 해지 다이얼로그 close function
  const cancelSubscriptionDialogCloseFunc = () => {
    cancelSubscriptionDialogOpenSetState(false);
  };

  // 멤버십 해지 확인 다이얼로그 open state
  const [cancelConfirmDialogOpenState, cancelConfirmDialogOpenSetState] = useState(false);

  // 멤버십 해지 확인 다이얼로그 open function
  const cancelConfirmDialogOpenFunc = () => {
    cancelSubscriptionDialogOpenSetState(false);
    cancelConfirmDialogOpenSetState(true);
  };

  // 멤버십 해지 확인 다이얼로그 close function
  const cancelConfirmDialogCloseFunc = () => {
    cancelConfirmDialogOpenSetState(false);
  };

  // 멤버십 정보 화면 이동 function
  const linkToMembershipInfo = () => {
    navigate("/membership-info");
  };

  // 결제 화면 이동 function
  const subscribeButtonFunc = () => {
    navigate("/payment");
  };

  // 멤버십 정보 화면
  if (paymentHistory.isFetching) {
    return <LoadingComponent />;
  } else {
    return (
      <Fragment>
        <MyPageBodyFrame title="멤버십 관리" MyPageLeftContents={<MyPageMenuBox page="/membership" />} MyPageRightContents={queryClient.getQueryData("userInfo").membershipState ? queryClient.getQueryData("userInfo").subscribingState ? <SubscribingMembershipContents membershipInfo={paymentHistory.data} tablePageState={tablePageState} tablePageChange={tablePageChange} linkToMembershipInfo={linkToMembershipInfo} cancelSubscriptionButtonFunc={cancelSubscriptionDialogOpenFunc} /> : <NoSubscribingMembershipContents membershipInfo={paymentHistory.data} tablePageState={tablePageState} tablePageChange={tablePageChange} linkToMembershipInfo={linkToMembershipInfo} subscribeButtonFunc={subscribeButtonFunc} /> : <NoMembershipContents membershipInfo={paymentHistory.data} tablePageState={tablePageState} tablePageChange={tablePageChange} linkToMembershipInfo={linkToMembershipInfo} />} />
        {queryClient.getQueryData("userInfo").membershipState && queryClient.getQueryData("userInfo").subscribingState ? (
          <Fragment>
            <DialogWithButton message={["멤버십 자동 결제를 해지하시겠습니까?", <br />, "지금 결제를 해지해도 " + moment(paymentHistory.data[Object.keys(paymentHistory.data)[0]].paymentDate).add(1, "month").format("YYYY.MM.DD") + " 까지 이용 가능합니다."]} dialogOpenState={cancelSubscriptionDialogOpenState} dialogClose={cancelSubscriptionDialogCloseFunc} button1Text="취소" button2Text="확인" button1Func={cancelSubscriptionDialogCloseFunc} button2Func={() => cancelConfirmDialogOpenFunc()} />
            <DialogWithOneButton message={"멤버십 자동 결제를 해지했습니다."} dialogOpenState={cancelConfirmDialogOpenState} buttonText="확인" buttonFunc={cancelConfirmDialogCloseFunc} />
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}
