import React, { forwardRef } from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";

import { addImageIconUrl } from "../configs/urls";

const ImageBoxButtonBase = styled(ButtonBase)({
  borderRadius: "10px",
});

const DashedImageBoxButtonBase = styled(ButtonBase)({
  aspectRatio: "1",
  borderRadius: "20px",
  width: "100%",
});

const ImageBox = styled(Box)(({ size }) => ({
  border: "1px solid #E6E6E6",
  borderRadius: "10px",
  background: "#FAFAFA",
  width: size,
  height: size,
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}));

const DashedImageBox = styled(Box)({
  border: "2px dashed #E6E6E6",
  borderRadius: "20px",
  width: "100%",
  height: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  background: "#FAFAFA",
});

const ProfileImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const ThumbnailGridItem = styled(Grid)({
  height: "100%",
});

const BannerImageBoxButtonBase = styled(ButtonBase)({
  borderRadius: "4px",
  width: "100%",
});

const BannerImageBox = styled(Box)({
  border: "1px solid #D9D9D9",
  borderRadius: "4px",
  width: "100%",
  aspectRatio: "16 / 9",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

// 이미지 업로드 박스
export const UploadImageBoxComponent = forwardRef((props, forwardRef) => {
  const { size=136, valueState, blobImgSrcState, changeImageButtonFunc, changeImageFunc } = props;

  return (
    <ImageBoxButtonBase onClick={changeImageButtonFunc}>
      <ImageBox size={size}>
        {valueState ? (
          blobImgSrcState ? (
            <ProfileImage src={blobImgSrcState} alt="업로드 이미지" />
          ) : (
            <ProfileImage src={valueState} alt="저장된 이미지" />
          )
        ) : blobImgSrcState ? (
          <ProfileImage src={blobImgSrcState} alt="업로드 이미지" />
        ) : (
          <Grid container item justifyContent="center" alignItems="center" rowSpacing={1}>
            <Grid item xs={12}>
              <img src={addImageIconUrl} alt="이미지 추가" width="26px" />
            </Grid>
            <Typography variant="body2" color="black.600">이미지 등록</Typography>
          </Grid>
        )}
      </ImageBox>
      <Input inputRef={forwardRef} type="file" onChange={changeImageFunc} inputProps={{ accept: "image/*" }} sx={{ display: "none" }} />
    </ImageBoxButtonBase>
  );
});

export const DashedUploadImageBoxComponent = forwardRef((props, forwardRef) => {
  const { valueState, blobImgSrcState, changeImageButtonFunc, changeImageFunc } = props;

  return (
    <DashedImageBoxButtonBase onClick={changeImageButtonFunc}>
      <DashedImageBox>
        {valueState ? (
          blobImgSrcState ? (
            <ProfileImage src={blobImgSrcState} alt="프로젝트 썸네일" />
          ) : (
            <ProfileImage src={valueState} alt="프로젝트 썸네일" />
          )
        ) : blobImgSrcState ? (
          <ProfileImage src={blobImgSrcState} alt="프로젝트 썸네일" />
        ) : (
          <ThumbnailGridItem container justifyContent="center" alignItems="center">
            <Grid item>
              <img src={addImageIconUrl} alt="썸네일 이미지 추가" />
              <Typography variant="subtitle2" color="black.600">썸네일을 등록해주세요.</Typography>
              <Typography variant="caption1" color="#B3B3B3">*10MB 이하의 JPG, PNG 파일만 등록 가능합니다.</Typography>
            </Grid>
          </ThumbnailGridItem>
        )}
      </DashedImageBox>
      <Input inputRef={forwardRef} type="file" onChange={changeImageFunc} inputProps={{ accept: "image/*" }} sx={{ display: "none" }} />
    </DashedImageBoxButtonBase>
  );
});

// 프로젝트 썸네일 이미지 업로드 박스
export const CurrentBannerUploadImageBox = forwardRef((props, forwardRef) => {
  const { valueState, blobImgSrcState, changeImageButtonFunc, changeImageFunc } = props;

  return (
    <BannerImageBoxButtonBase onClick={changeImageButtonFunc}>
      <BannerImageBox>
        {valueState ? (
          blobImgSrcState ? (
            <ProfileImage src={blobImgSrcState} alt="배너" />
          ) : (
            <ProfileImage src={valueState} alt="배너" />
          )
        ) : blobImgSrcState ? (
          <ProfileImage src={blobImgSrcState} alt="배너" />
        ) : (
          <ThumbnailGridItem container justifyContent="center" alignItems="center">
            <Grid item>
              <img src={addImageIconUrl} alt="배너 이미지 추가" />
            </Grid>
          </ThumbnailGridItem>
        )}
      </BannerImageBox>
      <Input inputRef={forwardRef} type="file" onChange={changeImageFunc} inputProps={{ accept: "image/*" }} sx={{ display: "none" }} />
    </BannerImageBoxButtonBase>
  );
});

export const NewBannerUploadImageBox = forwardRef((props, forwardRef) => {
  const { blobImgSrcState, changeImageButtonFunc, changeImageFunc } = props;

  return (
    <BannerImageBoxButtonBase onClick={changeImageButtonFunc}>
      <BannerImageBox>
        {blobImgSrcState ? (
          <ProfileImage src={blobImgSrcState} alt="배너" />
        ) : (
          <ThumbnailGridItem container justifyContent="center" alignItems="center">
            <Grid item>
              <img src={addImageIconUrl} alt="배너 이미지 추가" />
            </Grid>
          </ThumbnailGridItem>
        )}
      </BannerImageBox>
      <Input inputRef={forwardRef} type="file" onChange={changeImageFunc} inputProps={{ accept: "image/*" }} sx={{ display: "none" }} />
    </BannerImageBoxButtonBase>
  );
});
