import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import { RequestApplicantsPortfolioData } from "../../../utils/requestToSrv";

import MyPortfolioBodyFrame from "../../../Block/MyPage/MyPortfolioBodyFrame";

import PortfolioProfileBox from "../../../Block/MyPage/PortfolioProfileBox";
import MyPortfolioProfileContents from "../../../Block/MyPage/MyPortfolioProfileContents";

import LoadingComponent from "../../../components/LoadingComponent";

// 기업 회원 - 지원자 포트폴리오 화면
export default function PortfolioContainer() {
  // 대상 개인 회원 user id url 파라미터
  const { targetDocId } = useParams();

  // 화면 로드 시 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // 포트폴리오 data load
  const portfolioData = useQuery("Portfolio", () => RequestApplicantsPortfolioData(targetDocId), {
    onError: (error) => {
      console.log(error);
    },
  });

  // 포트폴리오 화면
  return portfolioData.isLoading ? <LoadingComponent /> : <MyPortfolioBodyFrame MyPortfolioLeftContents={<PortfolioProfileBox portfolioData={portfolioData.data} />} MyPortfolioRightContents={<MyPortfolioProfileContents portfolioData={portfolioData.data} />} />;
}
