import React, { useEffect } from "react";

import { useQuery } from "react-query";

import { RequestReadDefaultUserProfile } from "../../../utils/requestToSrv";

import MyPortfolioBodyFrame from "../../../Block/MyPage/MyPortfolioBodyFrame";

import MyPortfolioProfileBox from "../../../Block/MyPage/MyPortfolioProfileBox";
import MyPortfolioProfileContents from "../../../Block/MyPage/MyPortfolioProfileContents";

import LoadingComponent from "../../../components/LoadingComponent";

// 개인 회원 - 내 포트폴리오 화면
export default function MyPortfolioContainer() {
  // 화면 로드 시 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // 포트폴리오 data load
  const portfolioData = useQuery("userPortfolio", RequestReadDefaultUserProfile, {
    onError: (error) => {
      console.log(error);
    },
  });

  // 포트폴리오 화면
  return portfolioData.isLoading ? <LoadingComponent /> : <MyPortfolioBodyFrame MyPortfolioLeftContents={<MyPortfolioProfileBox portfolioData={portfolioData.data} />} MyPortfolioRightContents={<MyPortfolioProfileContents portfolioData={portfolioData.data} />} />;
}
