import React, { Fragment, useState, useEffect, useRef } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { RequestFinishedProjectList } from "../../utils/adminRequestToSrv";

import AdminBodyFrame from "../../Block/Admin/AdminBodyFrame";
import { AdminFinishedProjectsTable } from "../../Block/Admin/AdminProjectsContents";
import { AdminSearchBox } from "../../Block/Admin/AdminSearchBox";

import { ComplexDialogWithOneButton } from "../../components/DialogWithButton";
import LoadingComponent from "../../components/LoadingComponent";

// 종료 프로젝트 페이지
export default function AdminFinishedProjectContainer() {
  // react-router-dom navigate
  const navigate = useNavigate();
  // react-query queryClient
  const queryClient = useQueryClient();

  // 프로젝트 리스트 data load
  const finishedProjectList = useQuery("finishedProjectList", RequestFinishedProjectList, {
    onError: (error) => {
      console.log("error", error);
    },
  });

  // data loading state
  const [loadState, loadSetState] = useState(false);
  // 화면 render 확인 state
  const [renderState, renderSetState] = useState(false);

  // 검색 카테고리 버튼 ref
  const categoryButtonRef = useRef(null);
  // 검색 input ref
  const searchInputRef = useRef(null);

  const searchBoxRefSet = {
    categoryButtonRef: categoryButtonRef,
    searchInputRef: searchInputRef,
  };

  // 테이블 페이지 state
  const [tableDataPageState, tableDataPageSetState] = useState(0);

  // 검색 카테고리 메뉴 open state
  const [categoryMenuOpenedState, categoryMenuOpenedSetState] = useState(false);
  // 검색 카테고리 state
  const [searchCategoryState, searchCategorySetState] = useState("title");
  // 검색어 state
  const [searchValueState, searchValueSetState] = useState("");

  // 검색 카테고리 메뉴 open function
  const categoryMenuOpenFunc = () => {
    categoryMenuOpenedSetState(true);
  };

  // 검색 카테고리 메뉴 close function
  const categoryMenuCloseFunc = () => {
    categoryMenuOpenedSetState(false);
  };

  // 검색 카테고리 설정 이벤트
  const categoryMenuSelectFunc = (value) => {
    searchCategorySetState(value);
    searchValueSetState("");
    categoryMenuCloseFunc();
  };

  // 검색어 입력 이벤트
  const searchValueChangeFunc = () => {
    searchValueSetState(searchInputRef.current.value);
  };

  // 검색어 입력 후 0.5초 대기 시 데이터 필터링 작동
  useEffect(() => {
    if (renderState) {
      const debouncingTimer = setTimeout(() => {
        // loadSetState(true);
      }, 500);
      return () => clearTimeout(debouncingTimer);
    } else {
      renderSetState(true);
    }
  }, [searchValueState]);

  // 데이터 테이블 페이지 전환 이벤트
  const tableDataPageChange = (event, newPage) => {
    tableDataPageSetState(newPage);
  };

  // 취소 사유 dialog open state
  const [cancelReasonDialogOpenState, cancelReasonDialogOpenSetState] = useState(false);
  // 취소 사유 text state
  const [cancelReasonMessageState, cancelReasonMessageSetState] = useState("");

  // 취소 사유 dialog open function
  const cancelReasonDialogOpenFunc = (reason) => {
    cancelReasonMessageSetState(reason);
    cancelReasonDialogOpenSetState(true);
  };

  // 취소 사유 dialog close function
  const cancelReasonDialogCloseFunc = () => {
    cancelReasonDialogOpenSetState(false);
  };

  // 프로젝트 상세 페이지로 이동 function
  const linkToDetailEvent = (index, projectId) => {
    queryClient.setQueryData(["projectDetailInfo", projectId], finishedProjectList.data[index].document);
    navigate(`/admin-finished-detail/${projectId}`);
  };

  // 프로젝트 리스트 화면
  const AdminProjectContainer = () => {
    if (loadState) {
      return <LoadingComponent />;
    } else {
      return (
        <Grid container rowSpacing={3}>
          <Grid item container xs={12} spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-end">
            <Grid item xs={6}>
              <Typography variant="h4">종료 프로젝트 관리</Typography>
            </Grid>
            <AdminSearchBox ref={searchBoxRefSet} page="projects" categoryMenuOpenedState={categoryMenuOpenedState} categoryMenuOpenFunc={categoryMenuOpenFunc} categoryMenuCloseFunc={categoryMenuCloseFunc} categoryMenuSelectFunc={categoryMenuSelectFunc} selectedCategoryItemState={searchCategoryState} searchValueChangeFunc={searchValueChangeFunc} searchValueState={searchValueState} />
          </Grid>
          <AdminFinishedProjectsTable tableDataArrayState={finishedProjectList.data} tableDataPageState={tableDataPageState} tableDataPageChange={tableDataPageChange} cancelReasonDialogOpenFunc={cancelReasonDialogOpenFunc} linkToDetailEvent={linkToDetailEvent} />
        </Grid>
      );
    }
  };

  // 취소 사유 dialog
  const CancelReasonDialogContents = () => {
    return (
      <Grid container item rowGap={3}>
        <Grid item xs={12}>
          <Typography variant="h6">취소 사유</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{cancelReasonMessageState}</Typography>
        </Grid>
      </Grid>
    );
  };

  if (finishedProjectList.isFetching) {
    return <LoadingComponent />;
  } else {
    return (
      <Fragment>
        <AdminBodyFrame contentComponent={AdminProjectContainer()} />
        <ComplexDialogWithOneButton context={CancelReasonDialogContents()} dialogOpenState={cancelReasonDialogOpenState} dialogClose={cancelReasonDialogCloseFunc} buttonText="확인" buttonFunc={cancelReasonDialogCloseFunc} />
      </Fragment>
    );
  }
}
