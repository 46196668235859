import { forwardRef } from "react";

import { TextFieldSetWithCaption } from "../Common/TextFieldSet";
import AgreementAllCheckBoxSet, { AgeAgreementCheckBoxSet, AgreementCheckBoxSet } from "./CheckBoxSet";
import AuthButton from "../../components/AuthComponents";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const WarningGrid = styled(Grid)(({ theme })=>({
  borderRadius: "10px",
  backgroundColor: "#FCF7F7",
  padding: "17px",
}));

const AgreementGridBorder = styled(Grid)(({ theme }) => ({
  borderRadius: "5px",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.grey[200],
  paddingBottom: 8,
}));

const CustomDivider = styled(Divider)({
  borderColor: "#EDEDED",
});

// 기업 회원 회원가입 화면
export const IncUserSignUpContents = forwardRef((props, forwardRef) => {
  const { userInfoState, valueChangeEvent, onEnterKeyPress, agreementAllChkState, agreementAllChkEvent, mandatoryAgreementChkState, agreementChkEvent, termsModalOpenEvent, loadState, signUpButtonEvent, emailErrorCode, passwordErrorCode, passwordConfirmErrorCode, emailErrorMsg, passwordErrorMsg, passwordConfirmErrorMsg, emailInputBlurEvent, passwordInputBlurEvent, passwordConfirmInputBlurEvent } = props;
  const { emailRef, passwordRef, passwordConfirmRef, companyNameRef, homepageUrlRef } = forwardRef;

  const errorMessageSet = ["숫자, 영문, 특수문자를 조합하여 12자리 이상 입력해주세요", "비밀번호가 일치하지 않습니다"];

  return (
    <Grid container item rowSpacing={4.5}>
      <Grid item xs={12}>
        <WarningGrid container item>
          <Grid item xs={1}>
            <ErrorOutlineIcon sx={{ color: "#F45452", fontSize: 16, marginTop: "2px" }} />
          </Grid>
          <Grid item>
            <Typography variant="body1" color="error" lineHeight="22px" >
              안전한 서비스 거래를 위한 가입 승인 절차가 있으며,
              <br />
              승인 여부는 영업일 기준 1~2일 소요됩니다.
            </Typography></Grid>
        </WarningGrid>
      </Grid>
      <Grid container item rowSpacing={4.5}>
        <Grid container item rowSpacing={3}>
          <Grid container item>
            <Grid item xs={12}>
              <Typography variant="h6" fontWeight={700} color="black.200">
                계정정보입력
              </Typography>
              <Typography variant="body2" fontWeight={400} color="black.600">
                계정으로 사용할 이메일 정보를 입력해주세요.
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} rowSpacing={1}>
            <TextFieldSetWithCaption label="이메일" errorState={emailErrorCode === 2} caption={emailErrorCode === 2 ? emailErrorMsg : ""} type="email" placeholder="reble@email.com" ref={emailRef} valueState={userInfoState.email} setValueEvent={(event) => valueChangeEvent("email", event)} onBlur={(event) => emailInputBlurEvent(event)} onKeyPress={(event) => onEnterKeyPress(event)} autoFocus={true} />
            <TextFieldSetWithCaption label="비밀번호" errorState={passwordErrorCode === 2} caption={passwordErrorCode === 2 ? passwordErrorMsg : ""} type="password" placeholder="비밀번호를 입력해주세요" ref={passwordRef} valueState={userInfoState.password} setValueEvent={(event) => valueChangeEvent("password", event)} onBlur={(event) => passwordInputBlurEvent(event)} onKeyPress={(event) => onEnterKeyPress(event)} />
            <TextFieldSetWithCaption label="비밀번호 확인" errorState={passwordConfirmErrorCode === 2} caption={passwordConfirmErrorCode === 2 ? passwordConfirmErrorMsg : ""} type="password" placeholder="비밀번호를 한 번 더 입력해주세요" ref={passwordConfirmRef} valueState={userInfoState.passwordConfirm} setValueEvent={(event) => valueChangeEvent("passwordConfirm", event)} onBlur={(event) => passwordConfirmInputBlurEvent(event)} onKeyPress={(event) => onEnterKeyPress(event)} />
          </Grid>
        </Grid>
        <Grid container item rowSpacing={3}>
          <Grid container item>
            <Grid item xs={12}>
              <Typography variant="h6" fontWeight={700} color="black.200">
                회사정보입력
              </Typography>
              <Typography variant="body2" fontWeight={400} color="black.600">
                회원가입을 위하여 정보를 입력해주세요.
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} rowSpacing={1}>
            <TextFieldSetWithCaption label="회사명" type="text" placeholder="(주)레블글로와이드" ref={companyNameRef} valueState={userInfoState.companyName} setValueEvent={(event) => valueChangeEvent("companyName", event)} onKeyPress={(event) => onEnterKeyPress(event)} />
            {/* 
            <TextFieldSetWithCaption label="사업자등록번호" type="text" placeholder="" />
            */}
            <TextFieldSetWithCaption label="홈페이지 URL" type="text" placeholder="https://www.on1016.com" ref={homepageUrlRef} valueState={userInfoState.homepageUrl} setValueEvent={(event) => valueChangeEvent("homepageUrl", event)} onKeyPress={(event) => onEnterKeyPress(event)} />
          </Grid>
        </Grid>
        <Grid container item rowSpacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight={700} color="black.200">
              레블 회원약관
            </Typography>
          </Grid>
          <AgreementAllCheckBoxSet checkedState={agreementAllChkState} checkEvent={(event) => agreementAllChkEvent(event)} />
          <AgreementGridBorder container item>
            <AgeAgreementCheckBoxSet checkedState={userInfoState.ageValidation} checkEvent={(event) => agreementChkEvent("ageValidation", event)} title="만 14세 이상입니다. (필수)" />
            <AgreementCheckBoxSet checkedState={userInfoState.policyAgreement} checkEvent={(event) => agreementChkEvent("policyAgreement", event)} title="서비스 이용 약관 (필수)" modalOpenEvent={() => termsModalOpenEvent("policy")} />
            <AgreementCheckBoxSet checkedState={userInfoState.privacyAgreement} checkEvent={(event) => agreementChkEvent("privacyAgreement", event)} title="개인정보 수집 및 이용 (필수)" modalOpenEvent={() => termsModalOpenEvent("privacy")} />
            <AgreementCheckBoxSet checkedState={userInfoState.marketingAgreement} checkEvent={(event) => agreementChkEvent("marketingAgreement", event)} title="마케팅 수신 동의 (선택)" modalOpenEvent={() => termsModalOpenEvent("marketing")} />
          </AgreementGridBorder>
        </Grid>
        <Grid item xs={12}>
          <AuthButton loadState={loadState} buttonText="가입하기" activated={true} onClickEvent={() => signUpButtonEvent()} disabled={!mandatoryAgreementChkState} />
        </Grid>
      </Grid>
    </Grid>
  );
});
