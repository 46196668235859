import React from "react";

import { styled } from "@mui/material/styles";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const BackgroundBox = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  height: "100%",
}));

const CustomContainer = styled(Container)({
  minHeight: "100vh",
});

const ContainerBody = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  minHeight: "100vh",
  padding: "40px 36px",
  backgroundColor: theme.palette.background.paper,
}));

const GridContainer = styled(Grid)({
  padding: "120px 0 90px",
  height: "100%",
});

// authorization frame
export default function AuthBodyFrame(props) {
  const { title, subTitle, content, modal } = props;

  return (
    <BackgroundBox>
      <CustomContainer maxWidth="sm">
        <ContainerBody>
          <GridContainer container>
            <Grid container item justifyContent="center" alignContent="center" rowSpacing={6}>
              <Grid container item rowSpacing={2.8}>
                <Grid item xs={12}>
                  <Typography variant="h1" align="center" lineHeight="45px">
                    {title}
                  </Typography>
                </Grid>
                {subTitle ? (
                  <Grid item xs={12}>
                    <Typography variant="body2" align="center" lineHeight="22px">
                      {subTitle}
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
              {content}
            </Grid>
          </GridContainer>
        </ContainerBody>
      </CustomContainer>
      {modal}
    </BackgroundBox>
  );
}
