import React from "react";

import AdminOngoingDetail from "../../containers/Admin/AdminOngoingDetailContainer";
import RequireAuthRoute from "../../routes/requireAuth";

export default function AdminOngoingDetailView() {
  return (
    <React.Fragment>
      <RequireAuthRoute Component={AdminOngoingDetail} />
    </React.Fragment>
  );
}
