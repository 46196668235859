import React from "react";

import AdminMembership from "../../containers/Admin/AdminMembershipContainer";
import RequireAuthRoute from "../../routes/requireAuth";

export default function AdminMembershipView() {
  return (
    <React.Fragment>
      <RequireAuthRoute Component={AdminMembership} />
    </React.Fragment>
  );
}
