import React from "react";

import RequireAuthRoute from "../../routes/requireAuth";

import UniversalAppBar from "../../Block/Appbar/UniversalAppBar";
import UniversalAppFooter from "../../Block/Footer/UniversalAppFooter";

import RecruitingProject from "../../containers/Service/Project/RecruitingProjectContainer";

export default function RecruitingProjectView() {
  return (
    <React.Fragment>
      <UniversalAppBar rootPage={false} />
      <RequireAuthRoute Component={RecruitingProject} />
      <UniversalAppFooter />
    </React.Fragment>
  );
}
