import React from "react";

import AdminRecruitingDetail from "../../containers/Admin/AdminRecruitingDetailContainer";
import RequireAuthRoute from "../../routes/requireAuth";

export default function AdminRecruitingDetailView() {
  return (
    <React.Fragment>
      <RequireAuthRoute Component={AdminRecruitingDetail} />
    </React.Fragment>
  );
}
