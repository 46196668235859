import React from "react";

import RequireAuthRoute from "../../routes/requireAuth";

import UniversalAppBar from "../../Block/Appbar/UniversalAppBar";
import UniversalAppFooter from "../../Block/Footer/UniversalAppFooter";

import FinishedProject from "../../containers/Service/Project/FinishedProjectContainer";

export default function FinishedProjectView() {
  return (
    <React.Fragment>
      <UniversalAppBar />
      <RequireAuthRoute Component={FinishedProject} />
      <UniversalAppFooter />
    </React.Fragment>
  );
}
