import React from "react";

import moment from "moment";

import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";

import { FlexibleThumbnailBox, ChannelTypeTag } from "../../components/ProjectComponent";
import { TypoButton } from "../../components/ButtonComponents";

const CustomTableHeadRow = styled(TableRow)({
  borderTop: "1px solid #111111",
});

const CustomTableBodyRow = styled(TableRow)({
  backgroundColor: "#FFFFFF",
});

const HeadTableCell = styled(TableCell)({
  color: "#4C4C4C",
  fontSize: "14px",
});

const TitleNOfferingTypo = styled(Typography)({
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
});

const GreyBoxGridContainer = styled(Grid)({
  position: "relative",
  padding: "33px 37px",
  background: "#F4F5F5",
  borderRadius: "10px",
});

const CustomLinkGrid = styled(Grid)({
  position: "absolute",
  top: "24px",
  right: "36px",
});

const CustomLinkTypo = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  fontSize: "13px",
  textDecoration: "underline",
  color: theme.palette.black[600],
}));

// 진행 중 프로젝트 상세 페이지 내 상단 프로젝트 정보 테이블
export default function OngoingProjectUpperTable(props) {
  const { projectDetailValue, projectDetailPageOpenFunc, projectCancelButtonFunc } = props;

  // 날짜 형식 변환(초 -> 년/월/일)
  const recuitmentStartDate = moment(projectDetailValue.recruitmentStartDate * 1000).format("YYYY.MM.DD");
  const recuitmentEndDate = moment(projectDetailValue.recruitmentEndDate * 1000).format("YYYY.MM.DD");
  const appointmentDate = moment(projectDetailValue.appointmentDate * 1000).format("YYYY.MM.DD");
  const submissionStartDate = moment(projectDetailValue.submissionStartDate * 1000).format("YYYY.MM.DD");
  const submissionEndDate = moment(projectDetailValue.submissionEndDate * 1000).format("YYYY.MM.DD");
  const endDate = moment(projectDetailValue.endDate * 1000).format("YYYY.MM.DD");

  return (
    <Grid container justifyContent="flex-end" rowGap={2.8}>
      <GreyBoxGridContainer container xs={12}>
        <CustomLinkGrid item xs={12}>
          <CustomLinkTypo onClick={() => projectDetailPageOpenFunc()}>상세보기</CustomLinkTypo>
        </CustomLinkGrid>
        <Grid item xs={1.2}>
          <FlexibleThumbnailBox thumbnailUrl={projectDetailValue.thumbnail} size="88px" />
        </Grid>
        <Grid container item xs={10.8} rowSpacing={1}>
          <Grid container item columnSpacing={1}>
            <Grid item><ChannelTypeTag channelType={projectDetailValue.channel} size="24px" fontSize="12px" /></Grid>
            <Grid item><ChannelTypeTag channelType={projectDetailValue.type} size="24px" fontSize="12px" /></Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={12}>
              <TitleNOfferingTypo variant="h5" lineHeight="28px" fontWeight={700} color="black.300">
                {projectDetailValue.title}
              </TitleNOfferingTypo>
            </Grid>
            {projectDetailValue.offering ? (
              <Grid item xs={12}>
                <TitleNOfferingTypo variant="body1" lineHeight="24px" color="black.600">
                  제공품목 | {projectDetailValue.offering}
                </TitleNOfferingTypo>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </GreyBoxGridContainer>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <CustomTableHeadRow>
                <HeadTableCell align="center" width="10%">
                  <Typography variant="body1" lineHeight="24px" color="black.300">
                    모집인원
                  </Typography>
                </HeadTableCell>
                <HeadTableCell align="center" width="30%">
                  <Typography variant="body1" lineHeight="24px" color="black.300">
                    모집 기간
                  </Typography>
                </HeadTableCell>
                <HeadTableCell align="center" width="15%">
                  <Typography variant="body1" lineHeight="24px" color="black.300">
                    선정일
                  </Typography>
                </HeadTableCell>
                <HeadTableCell align="center" width="30%">
                  <Typography variant="body1" lineHeight="24px" color="black.300">
                    제출기간
                  </Typography>
                </HeadTableCell>
                <HeadTableCell align="center" width="15%">
                  <Typography variant="body1" lineHeight="24px" color="black.300">
                    종료일
                  </Typography>
                </HeadTableCell>
              </CustomTableHeadRow>
            </TableHead>
            <TableBody>
              <CustomTableBodyRow>
                <TableCell>
                  <Typography variant="subtitle1" lineHeight="24px" color="black.200" align="center">
                    {projectDetailValue.recruitmentLimitNumber}명
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" lineHeight="24px" color="black.200" align="center">
                    {recuitmentStartDate} ~ {recuitmentEndDate}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" lineHeight="24px" color="black.200" align="center">
                    {appointmentDate}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" lineHeight="24px" fontWeight={700} color="black.200" align="center">
                    {submissionStartDate} ~ {submissionEndDate}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" lineHeight="24px" color="black.200" align="center">
                    {endDate}
                  </Typography>
                </TableCell>
              </CustomTableBodyRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid container justifyContent="end" columnGap={1}>
        <Grid item>
          <ButtonBase onClick={() => projectCancelButtonFunc()}>
            <TypoButton buttonText="프로젝트 취소" buttonColor="error" />
          </ButtonBase>
        </Grid>
      </Grid>
    </Grid>
  );
}
