import React, { forwardRef } from "react";

import moment from "moment";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CircleIcon from "@mui/icons-material/Circle";

import { sharingIconUrl, snsUrl, typeUrl, portfolioProfileDefaultImageUrl } from "../configs/urls";

const NaverTagTypo = styled(Typography)({
  background: "#32B34A",
  borderRadius: "12px",
  padding: "6px 10px",
});

const InstagramTagTypo = styled(Typography)({
  background: "#DD2A7B",
  borderRadius: "12px",
  padding: "6px 10px",
});

const YoutubeTagTypo = styled(Typography)({
  background: "#D4272D",
  borderRadius: "12px",
  padding: "6px 10px",
});

const ProjectTypeTypo = styled(Typography)({
  background: "#EEEEEE",
  borderRadius: "12px",
  padding: "6px 10px",
});

const SharingButtonBox = styled(Box)({
  height: "60px",
  aspectRatio: "1",
  padding: 13,
  border: "1px solid #D9D9D9",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const SharingButtonBase = styled(ButtonBase)({
  borderRadius: "10px",
});

const FlexibleThumbnailImageBox = styled(Box)(({ size }) => ({
  width: size,
  height: size,
  borderRadius: "10px",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  background: "#D9D9D9",
}));

const ThumbnailImageBox = styled(Box)({
  width: 40,
  height: 40,
  borderRadius: "6px",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  background: "#D9D9D9",
});

const MediumThumbnailImageBox = styled(Box)({
  width: 60,
  height: 60,
  borderRadius: "4px",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  background: "#D9D9D9",
});

const LargeThumbnailImageBox = styled(Box)({
  width: 90,
  height: 90,
  borderRadius: "4px",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  background: "#D9D9D9",
});

const ProfilePhotoImageBox = styled(Box)({
  width: 72,
  height: 72,
  borderRadius: "50%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  background: "#D9D9D9",
});

const ThumbnailImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const ProjectListItemContainer = styled(Grid)({
  paddingBottom: "30px",
  borderRadius: "4px",
});

const ProjectItemThumbnailGridItem = styled(Grid)({
  position: "relative",
});

const ProjectItemThumbnailImage = styled("img")({
  width: "100%",
  height: "100%",
  borderRadius: "20px",
  aspectRatio: "1",
  objectFit: "cover",
});

const ProjectItemClosedBg = styled(Box)({
  position: "absolute",
  top: 0,
  height: "100%",
  width: "100%",
  background: "rgba(255, 255, 255, 0.6)",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const ProjectItemClosed = styled(Typography)({
  position: "absolute",
  bottom: 0,
  height: "20%",
  width: "100%",
  background: "rgba(0, 0, 0, 0.4)",
  borderRadius: "0 0 20px 20px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const ProjectItemAboutClosed = styled(Typography)({
  position: "absolute",
  bottom: 0,
  height: "20%",
  width: "100%",
  background: "rgba(0, 0, 0, 0.4)",
  borderRadius: "0 0 20px 20px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const ProjectItemTitleTypo = styled(Typography)({
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
});

const ProjectItemOfferingTypo = styled(Typography)({
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
});

const CustomButtonBase = styled(ButtonBase)({
  width: "100%",
});

const FilteringButtonBase = styled(ButtonBase)({
  borderRadius: "24px",
  width: "100%",
});

const FilteringButtonBox = styled(Box)({
  border: "1px solid #D9D9D9",
  borderRadius: "24px",
  padding: "11px 29px",
  width: "100%",
});

const FilteringArrowDown = styled(KeyboardArrowDownIcon)(({ filterMenuOpenedState }) => ({
  color: "#333333",
  transform: filterMenuOpenedState ? "rotate(0.5turn)" : "",
  transition: "all ease 0.3s",
}));

const CustomCircleIcon = styled(CircleIcon)({
  fontSize: 8,
  color: "#999999",
});

const ClosedProjectExceptButtonBox = styled(Box)(({ theme }) => ({
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: theme.palette.secondary.main,
  borderRadius: "24px",
  padding: "11px 29px",
  width: "100%",
}));

const TagBox = styled(Box)(({ color, size }) => ({
  background: color,
  borderRadius: "15px",
  padding: "0 16px",
  height: size,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const TagIcon = styled("img")({
  marginRight: "6px"
})

const IOSSwitch = styled(Switch)({
  width: 42,
  height: 22,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#222222',
        opacity: 1,
        border: 0,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    backgroundColor: '#EAEAEA',
    opacity: 1,
  },
});

// SNS 유형 태그 - 네이버 블로그
export default function NaverBlogTag() {
  return (
    <NaverTagTypo component="p" variant="caption1" fontWeight={400} lineHeight="12px" color="#ffffff">
      네이버블로그
    </NaverTagTypo>
  );
}

// SNS 유형 태그 - 네이버 포스트
export function NaverPostTag() {
  return (
    <NaverTagTypo component="p" variant="caption1" fontWeight={400} lineHeight="12px" color="#ffffff">
      네이버포스트
    </NaverTagTypo>
  );
}

// SNS 유형 태그 - 인스타그램
export function InstagramTag() {
  return (
    <InstagramTagTypo component="p" variant="caption1" fontWeight={400} lineHeight="12px" color="#ffffff">
      인스타그램
    </InstagramTagTypo>
  );
}

// SNS 유형 태그 - 유튜브
export function YoutubeTag() {
  return (
    <YoutubeTagTypo component="p" variant="caption1" fontWeight={400} lineHeight="12px" color="#ffffff">
      유튜브
    </YoutubeTagTypo>
  );
}

// 프로젝트 유형 태그
export function ProjectTypeTag(props) {
  const { projectType } = props;

  return (
    <ProjectTypeTypo component="p" variant="caption1" fontWeight={400} lineHeight="12px" color="#666666">
      {projectType}
    </ProjectTypeTypo>
  );
}

// SNS+프로젝트 유형 태그 (on1016 사용)
export function ChannelTypeTag(props) {
  const { channelType, size, fontSize } = props;
  let color, url, text;
  switch (channelType) {
    case "naverBlog":
      color = "#32B34A";
      url = snsUrl.naverBlogUrl;
      text = "네이버블로그";
      break;
    case "naverPost":
      color = "#32B34A";
      url = snsUrl.naverPostUrl;
      text = "네이버포스트";
      break;
    case "youtube":
      color = "#D4272D";
      url = snsUrl.youtube;
      text = "유튜브";
      break;
    case "instagram":
      color = "#DD2A7B";
      url = snsUrl.instagram;
      text = "인스타그램";
      break;
    case "delivery":
      color = "#222222";
      url = typeUrl.delivery;
      text = "배송형";
      break;
    case "visit":
      color = "#222222";
      url = typeUrl.visit;
      text = "방문형";
      break;
    default:
      break;
  }
  return (
    <TagBox color={color} size={size}>
      <TagIcon src={url} />
      <Typography component="p" variant="caption1" fontSize={fontSize} fontWeight={400} color="#ffffff">
        {text}
      </Typography>
    </TagBox>
  );
}

// 공유하기 버튼
export function SharingButton(props) {
  const { sharingButtonFunc } = props;

  return (
    <SharingButtonBase onClick={() => sharingButtonFunc()}>
      <SharingButtonBox>
        <img src={sharingIconUrl} alt="공유하기" />
      </SharingButtonBox>
    </SharingButtonBase>
  );
}

// 이미지 로드 실패 시 대체 이미지
const profilePhotoError = (event) => {
  event.target.src = portfolioProfileDefaultImageUrl;
};

export function FlexibleThumbnailBox(props) {
  const { thumbnailUrl, size } = props;

  return (
    <FlexibleThumbnailImageBox size={size}>
      <ThumbnailImage src={thumbnailUrl} alt="썸네일" onError={(event) => profilePhotoError(event)} />
    </FlexibleThumbnailImageBox>
  );
}

// 썸네일 이미지 박스 - 작은 사이즈
export function ThumbnailBox(props) {
  const { thumbnailUrl } = props;

  return (
    <ThumbnailImageBox>
      <ThumbnailImage src={thumbnailUrl} alt="썸네일" onError={(event) => profilePhotoError(event)} />
    </ThumbnailImageBox>
  );
}

// 썸네일 이미지 박스 - 중간 사이즈
export function MediumThumbnailBox(props) {
  const { thumbnailUrl } = props;

  return (
    <MediumThumbnailImageBox>
      <ThumbnailImage src={thumbnailUrl} alt="썸네일" onError={(event) => profilePhotoError(event)} />
    </MediumThumbnailImageBox>
  );
}

// 썸네일 이미지 박스 - 큰 사이즈
export function LargeThumbnailBox(props) {
  const { thumbnailUrl } = props;

  return (
    <LargeThumbnailImageBox>
      <ThumbnailImage src={thumbnailUrl} alt="썸네일" onError={(event) => profilePhotoError(event)} />
    </LargeThumbnailImageBox>
  );
}

// 프로필 이미지 박스
export function ProfilePhotoBox(props) {
  const { profilePhotoUrl } = props;

  return (
    <ProfilePhotoImageBox>
      <ThumbnailImage src={profilePhotoUrl} alt="프로필사진" onError={(event) => profilePhotoError(event)} />
    </ProfilePhotoImageBox>
  );
}

// 프로젝트 리스트 아이템
export function ProjectListItem(props) {
  const { projectValue, projectItemClickFunc } = props;
  const recruitmentEndDate = moment(projectValue.recruitmentEndDate * 1000).format("YYYY-MM-DD");

  return (
    <ProjectListItemContainer container>
      <Grid item xs={12}>
        <CustomButtonBase onClick={projectItemClickFunc}>
          <Grid container rowGap={1.6}>
            <ProjectItemThumbnailGridItem item xs={12}>
              <ProjectItemThumbnailImage src={projectValue.thumbnail} alt="프로젝트 썸네일" />
              {moment().isAfter(recruitmentEndDate, "day") ? (
                <ProjectItemClosedBg>
                  <ProjectItemClosed variant="body2" fontWeight={700} lineHeight="28px" color="#ffffff">
                    모집 마감
                  </ProjectItemClosed>
                </ProjectItemClosedBg>
              ) : moment().isSame(recruitmentEndDate, "day") ? (
                <ProjectItemAboutClosed variant="body2" fontWeight={700} lineHeight="28px" color="#ffffff">
                  마감 임박
                </ProjectItemAboutClosed>
              ) : null}
            </ProjectItemThumbnailGridItem>
            <Grid container item rowGap={1}>
              <Grid container item columnSpacing={1}>
                <Grid item><ChannelTypeTag channelType={projectValue.channel} size="30px" fontSize="14px" /></Grid>
                <Grid item><ChannelTypeTag channelType={projectValue.type} size="30px" fontSize="14px" /></Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12}>
                  <ProjectItemTitleTypo variant="h6" fontWeight={700} lineHeight="26px" color="#333333" align="left">
                    {projectValue.title}
                  </ProjectItemTitleTypo>
                </Grid>
                <Grid item xs={12}>
                  <ProjectItemOfferingTypo variant="body2" component="p" lineHeight="28px" color="#999999" align="left">
                    {projectValue.offering}
                  </ProjectItemOfferingTypo>
                </Grid>
                <Grid item container>
                  <Grid item xs="auto">
                    <Typography variant="body2" component="p" fontWeight={500} lineHeight="28px" color="black.600">
                      신청 {projectValue.recruitmentNumber}/
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography variant="body2" component="p" fontWeight={400} lineHeight="28px" color="black.600">
                      {projectValue.recruitmentLimitNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CustomButtonBase>
      </Grid>
    </ProjectListItemContainer>
  );
}

// filtering button
export const FilteringButton = forwardRef((props, forwardRef) => {
  const { filterName, filterType, filterMenuOpenedState, filteringMenuOpenFunc, selectedItemLabel } = props;

  return (
    <FilteringButtonBase ref={forwardRef} onClick={() => filteringMenuOpenFunc(filterType)}>
      <FilteringButtonBox>
        <Grid container justifyContent="space-between" alignItems="center" columnGap={1.5}>
          <Grid container item xs columnGap={1.5}>
            <Grid item>
              <Typography variant="subtitle2" lineHeight="24px" color="black.600">
                {filterName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" fontWeight={400} lineHeight="24px" color="black.600">
                {selectedItemLabel}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs="auto">
            <FilteringArrowDown fontSize="small" filterMenuOpenedState={filterMenuOpenedState} />
          </Grid>
        </Grid>
      </FilteringButtonBox>
    </FilteringButtonBase>
  );
});

// 마감 된 프로젝트 제외 버튼
export function ExceptClosedProjectButton(props) {
  const { filterState, closedProjectBtnClickFunc } = props;

  // if (filterState) {
  // return (
  // <FilteringButtonBase onClick={() => closedProjectBtnClickFunc()}>
  //   <ClosedProjectExceptButtonBox>
  //     <Grid container alignItems="center" columnGap={1}>
  //       <Grid item xs="auto">
  //         <CheckIcon fontSize="small" color="secondary" />
  //       </Grid>
  //       <Grid item xs="auto">
  //         <Typography variant="body2" lineHeight="24px" color="secondary">
  //           마감 프로젝트 제외
  //         </Typography>
  //       </Grid>
  //     </Grid>
  //   </ClosedProjectExceptButtonBox>
  // </FilteringButtonBase>
  // );
  // } else {
  //   return (
  //     <FilteringButtonBase onClick={() => closedProjectBtnClickFunc()}>
  //       <FilteringButtonBox>
  //         <Grid container alignItems="center" columnGap={2.5}>
  //           <Grid item xs="auto">
  //             <CustomCircleIcon />
  //           </Grid>
  //           <Grid item xs="auto">
  //             <Typography variant="body2" lineHeight="24px" color="#999999">
  //               마감 프로젝트 제외
  //             </Typography>
  //           </Grid>
  //         </Grid>
  //       </FilteringButtonBox>
  //     </FilteringButtonBase>
  //   );
  // }

  return (
    <Grid container item columnSpacing={2.5}>
      <Grid item>
        <Typography variant="subtitle2" lineHeight="24px" color={filterState ? "secondary" : "#CCCCCC"}>
          마감 프로젝트 제외
        </Typography>
      </Grid>
      <Grid item>
        <FormControlLabel control={<IOSSwitch onChange={() => closedProjectBtnClickFunc()} checked={filterState ? true : false}  />} />
      </Grid>
    </Grid>
  );
}

// sorting button
export const SortingButton = forwardRef((props, forwardRef) => {
  const { filterMenuOpenedState, filteringMenuOpenFunc, selectedItemLabel } = props;

  return (
    <FilteringButtonBase ref={forwardRef} onClick={() => filteringMenuOpenFunc("sorting")}>
      <FilteringButtonBox>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="subtitle2" lineHeight="24px" color="black.600">
              {selectedItemLabel}
            </Typography>
          </Grid>
          <Grid item>
            <FilteringArrowDown fontSize="small" filterMenuOpenedState={filterMenuOpenedState} />
          </Grid>
        </Grid>
      </FilteringButtonBox>
    </FilteringButtonBase>
  );
});
