import React from "react";

import UniversalAppBar from "../../Block/Appbar/UniversalAppBar";
import UniversalAppFooter from "../../Block/Footer/UniversalAppFooter";

import SignIn from "../../containers/Service/Auth/SignInContainer";

export default function SignInView() {
  return (
    <React.Fragment>
      <UniversalAppBar rootPage={false} />
      <SignIn />
      <UniversalAppFooter />
    </React.Fragment>
  );
}
