import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import { useRecoilState } from "recoil";
import { productSelect } from "../../../recoil/ProductState";

import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Divider from "@mui/material/Divider";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { ContainedButton } from "../../../components/ButtonComponents";

const BackgroundBox = styled(Box)({
  minHeight: "100vh",
  height: "100%",
});

const CustomContainer = styled(Container)({
  minHeight: "100vh",
  padding: "81px 0 90px",
});

const CustomGridContainer = styled(Grid)({
  paddingTop: "78px",
});

const ProductSelectGridItem = styled(Grid)(({ selectedProduct, theme }) => ({
  borderRadius: "15px",
  boxShadow: selectedProduct ? "0px 1px 10px 2px rgba(0, 0, 0, 0.15)" : "none",
  border: selectedProduct ? `1px solid ${theme.palette.secondary.main}` : "1px solid #F9FAFB",
  background: selectedProduct ? "none" : "#F9FAFB",
}));

const ProductButtonBase = styled(ButtonBase)({
  padding: "34px 37px",
  borderRadius: "15px",
  width: "100%",
  height: "100%",
});

const TypoGridContainer = styled(Grid)({
  paddingTop: "70px",
});

const CustomButtonBase = styled(ButtonBase)({
  borderRadius: "10px",
  width: "100%",
});

const TypoItemGridContainer = styled(Grid)({
  background: "#F9FAFB",
  borderRadius: "10px",
  padding: "18px 20px",
});

const ExpandMore = styled(ExpandMoreIcon)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const ExpandLess = styled(ExpandLessIcon)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const CustomDivider = styled(Divider)({
  borderColor: "#EEEEEE",
});

const AnswerTypo = styled(Typography)({
  paddingLeft: "18px",
});

// 멤버십 정보 Q&A 페이지 (상품 선택)
export default function MembershipInfoContainer() {
  const navigate = useNavigate();

  const [productSelectState, productSelectSetState] = useRecoilState(productSelect);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [expandedState, expandedSetState] = useState({
    first: false,
    second: false,
    third: false,
  });

  const questionExpandFunc = (question) => {
    expandedSetState((prevState) => ({
      ...prevState,
      [question]: !prevState[question],
    }));
  };

  const selectProductFunc = (product) => {
    productSelectSetState(product);
  };

  const linkToPayment = () => {
    navigate("/payment");
  };

  return (
    <BackgroundBox>
      <CustomContainer maxWidth="lg" disableGutters>
        <CustomGridContainer container justifyContent="center" alignContent="center" rowGap={10}>
          <Grid container item justifyContent="center" rowGap={10}>
            <Grid container item rowGap={1.25}>
              <Grid item xs={12}>
                <Typography variant="h5" fontSize="22px" align="center" lineHeight="28px">
                  on1016 비즈니스 멤버십
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body3" component="p" align="center" color="#999999" lineHeight="21px">
                  on1016 홍보단과 함께 비즈니스 성장을 시작해보세요!
                </Typography>
              </Grid>
            </Grid>
            <Grid container item justifyContent="center" columnGap={3.75} wrap="nowrap">
              <ProductSelectGridItem item xs={4} selectedProduct={productSelectState === "lite"}>
                <ProductButtonBase onClick={() => selectProductFunc("lite")} disabled={selectProductFunc === "lite"}>
                  <Grid container rowGap={3}>
                    <Grid item xs={12}>
                      <Typography variant="h5" fontSize="22px" color="secondary" align="center" lineHeight="28px">
                        Lite
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" lineHeight="30px">
                        55,000 / 월
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" lineHeight="28px" color="#333333">
                        · 프로젝트 등록 월 1회
                        <br />· 모집 인원 10명
                      </Typography>
                    </Grid>
                  </Grid>
                </ProductButtonBase>
              </ProductSelectGridItem>
              <ProductSelectGridItem item xs={4} selectedProduct={productSelectState === "basic"}>
                <ProductButtonBase onClick={() => selectProductFunc("basic")} disabled={selectProductFunc === "basic"}>
                  <Grid container rowGap={3}>
                    <Grid item xs={12}>
                      <Typography variant="h5" fontSize="22px" color="secondary" align="center" lineHeight="28px">
                        Basic
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" lineHeight="30px">
                        110,000 / 월
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" lineHeight="28px" color="#333333">
                        · 프로젝트 등록 무제한
                        <br />· 모집 인원 10명
                      </Typography>
                      <Typography variant="body3" component="p" lineHeight="25px" color="#999999">
                        (프로젝트 1회당 모집 인원)
                      </Typography>
                    </Grid>
                  </Grid>
                </ProductButtonBase>
              </ProductSelectGridItem>
              <ProductSelectGridItem item xs={4} selectedProduct={productSelectState === "premium"}>
                <ProductButtonBase onClick={() => selectProductFunc("premium")} disabled={selectProductFunc === "premium"}>
                  <Grid container rowGap={3}>
                    <Grid item xs={12}>
                      <Typography variant="h5" fontSize="22px" color="secondary" align="center" lineHeight="28px">
                        Premium
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" lineHeight="30px">
                        220,000 / 월
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" lineHeight="28px" color="#333333">
                        · 프로젝트 등록 무제한
                        <br />· 모집 인원 무제한
                        <br />· 전담 매니저 배정
                      </Typography>
                    </Grid>
                  </Grid>
                </ProductButtonBase>
              </ProductSelectGridItem>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body3" component="p" lineHeight="25px" color="#999999">
                · 멤버십은 모두 매달 동일한 날짜에 자동 결제되는 정기 결제로 진행되며, 당월에 해당 일자가 없는 경우 말일에 결제가 진행됩니다.
                <br />
                · 프로젝트 참여자에게 추가로 리워드 지급을 원하시는 경우 관련 비용은 직접 참여자들에게 지급해주셔야 합니다.
                <br />
                · 결제 후 7일간 이용 내역이 없는 경우 전액 환불이 가능합니다.
                <br />
                · 결제 취소는 고객센터 문의를 통해 신청하실 수 있습니다.
                <br />· 결제 수단의 정지, 한도 초과 등의 사유로 결제가 불가능한 경우 결제가 취소되며, 다시 정기 결제를 신청해 서비스를 이용하실 수 있습니다.
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Divider />
          </Grid>
          <TypoGridContainer container item justifyContent="center" xs={6} rowSpacing={4.5}>
            <Grid item>
              <Typography variant="body1" lineHeight="28px" fontWeight={700} align="center">
                자주 묻는 질문
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomButtonBase onClick={() => questionExpandFunc("first")}>
                <TypoItemGridContainer container>
                  <Grid container item rowSpacing={2}>
                    <Grid container item alignItems="center" justifyContent="space-between">
                      <Grid item>
                        <Typography variant="body1" lineHeight="28px" fontWeight={700} color="#333333">
                          Q. 구독을 해지하면 진행중인 프로젝트는 어떻게 되나요?
                        </Typography>
                      </Grid>
                      <Grid item>{expandedState.first ? <ExpandLess /> : <ExpandMore />}</Grid>
                    </Grid>
                    {expandedState.first ? (
                      <Grid container item rowSpacing={2}>
                        <Grid item xs={12}>
                          <CustomDivider />
                        </Grid>
                        <Grid item xs={12}>
                          <AnswerTypo variant="body1" lineHeight="21px" color="#333333" align="left">
                            프로젝트를 해지하셔도 이용 기간에 프로젝트는 종료까지 진행, 관리 가능합니다.
                          </AnswerTypo>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </TypoItemGridContainer>
              </CustomButtonBase>
            </Grid>
            <Grid item xs={12}>
              <CustomButtonBase onClick={() => questionExpandFunc("second")}>
                <TypoItemGridContainer container>
                  <Grid container item rowSpacing={2}>
                    <Grid container item alignItems="center" justifyContent="space-between">
                      <Grid item>
                        <Typography variant="body1" lineHeight="28px" fontWeight={700} color="#333333">
                          Q. 정부 지원 사업을 통해서도 가능한가요?
                        </Typography>
                      </Grid>
                      <Grid item>{expandedState.second ? <ExpandLess /> : <ExpandMore />}</Grid>
                    </Grid>
                    {expandedState.second ? (
                      <Grid container item rowSpacing={2}>
                        <Grid item xs={12}>
                          <CustomDivider />
                        </Grid>
                        <Grid item xs={12}>
                          <AnswerTypo variant="body1" lineHeight="21px" color="#333333" align="left">
                            네! 가능합니다. 멤버십 시작 전 on1016 고객센터 (이메일: business@glowide.io, 카카오톡 채널: @on1016)로 문의 주세요.
                          </AnswerTypo>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </TypoItemGridContainer>
              </CustomButtonBase>
            </Grid>
            <Grid item xs={12}>
              <CustomButtonBase onClick={() => questionExpandFunc("third")}>
                <TypoItemGridContainer container>
                  <Grid container item rowSpacing={2}>
                    <Grid container item alignItems="center" justifyContent="space-between">
                      <Grid item>
                        <Typography variant="body1" lineHeight="28px" fontWeight={700} color="#333333">
                          Q. 모집, 선정 외에 관리하는 것 까지 의뢰하고 싶어요.
                        </Typography>
                      </Grid>
                      <Grid item>{expandedState.third ? <ExpandLess /> : <ExpandMore />}</Grid>
                    </Grid>
                    {expandedState.third ? (
                      <Grid container item rowSpacing={2}>
                        <Grid item xs={12}>
                          <CustomDivider />
                        </Grid>
                        <Grid item xs={12}>
                          <AnswerTypo variant="body1" lineHeight="21px" color="#333333" align="left">
                            on1016 고객센터 (이메일: business@glowide.io, 카카오톡 채널: @on1016)로 문의 주세요.
                          </AnswerTypo>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </TypoItemGridContainer>
              </CustomButtonBase>
            </Grid>
            <Grid item xs={12}>
              <ContainedButton buttonText="멤버십 시작하기" buttonDisabled={false} buttonEvent={() => linkToPayment()} />
            </Grid>
          </TypoGridContainer>
        </CustomGridContainer>
      </CustomContainer>
    </BackgroundBox>
  );
}
