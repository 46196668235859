import React, { Fragment, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useMutation, useQueryClient } from "react-query";

import { useRecoilState } from "recoil";
import { emailVarified } from "../../../recoil/UserState";
import { userTypeTab } from "../../../recoil/AuthState";

import { doInitialzeKakao, doSignInWithKakao, doGetUserInfoFromKakao } from "../../../utils/thirdPartyUtils";
// import { doInitializeNaver} from "../../../utils/thirdPartyUtils";

import { RequestSignIn } from "../../../utils/requestToSrv";
import { doSignInWithEmailAndPassword, doSignInWithGoogle, doSignOutWithNoQuery, doGetEmailValidation } from "../../../utils/firebaseUtils";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import AuthBodyFrame from "../../../Block/Auth/AuthBodyFrame";
import { SignInUpperSection } from "../../../Block/Auth/SignInUpperSection";
import { SignInBelowSection } from "../../../Block/Auth/SignInBelowSection";
// import { DialogAlertMessage } from "../../../components/DialogMessage";
import { CustomizedSnackbars } from "../../../components/Alert";
import { DialogWithOneButton } from "../../../components/DialogWithButton";

const CustomDivider = styled(Divider)({
  backgroundColor: "#EDEDED",
});

// 정규표현식
const emailRegEx = new RegExp(/^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/);

// sign in
export default function SignInContainer() {
  // 화면 로드 시 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // data loading state
  const [loadState, loadSetState] = useState(false);

  // 이메일 인증 여부 state - firebase에서 확인
  const [emailVarifiedState, emailVarifiedSetState] = useRecoilState(emailVarified);

  // useEffect(() => {
  // doInitializeNaver("http://localhost:3000/signin");
  //   doInitialzeKakao();
  // }, []);

  // user type (default / inc) tab state
  const [userTypeTabState, userTypeTabSetState] = useRecoilState(userTypeTab);

  // user type tab change event
  const signInTabChangeEvent = (event, newValue) => {
    userTypeTabSetState(newValue);
  };

  // react-query queryClient
  const queryClient = useQueryClient();
  // react-router-dom navigate
  const navigate = useNavigate();

  // 각 textField를 위한 ref
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  // const naverDefaultIconRef = useRef(null);

  // dialog message open state
  const [dialogOpenState, dialogOpenSetState] = useState(false);
  // dialog message text state
  const [dialogMessageState, dialogMessageSetState] = useState("");

  // dialog message open event
  const dialogOpen = () => {
    dialogOpenSetState(true);
  };

  // dialog message close event
  const dialogClose = () => {
    dialogOpenSetState(false);
  };

  // sign in value state
  const [signInValueState, signInValueSetState] = useState({
    email: "",
    password: "",
  });

  // sign in value change event
  const setSignInValueEvent = (key, event) => {
    signInValueSetState({
      ...signInValueState,
      [key]: event.target.value,
    });
  };

  const [emailErrorCodeState, emailErrorCodeSetState] = useState(0);
  const [passwordErrorCodeState, passwordErrorCodeSetState] = useState(0);
  
  const [emailErrorMsgState, emailErrorMsgSetState] = useState("");
  const [passwordErrorMsgState, passwordErrorMsgSetState] = useState("");
  
  // alert message text state
  const [alertState, setAlertState] = useState("");
  const [errorMessageState, errorMessageSetState] = useState("");
  // alert dialog message custom hook
  // const { alertDialogCall, DialogAlertComponent } = DialogAlertMessage(errorMessageState);
  const {handleClick, AlertComponent} = CustomizedSnackbars(alertState, errorMessageState);

  // sign in with email and password: default user
  const signInMutation = useMutation((userCredential) => RequestSignIn({ token: userCredential.user.accessToken, userType: "default" }), {
    onSuccess: (data) => {
      if (data === "userType Error") {
        dialogMessageSetState(["해당 아이디는 기업 회원으로 가입된 아이디입니다.", <br/>, "기업 회원 탭에서 다시 로그인해주세요."]);
        dialogOpen();
        doSignOutWithNoQuery();
      } else if (data === "deleted user") {
        setAlertState("error");
        errorMessageSetState("탈퇴한 회원입니다.");
        handleClick();
        doSignOutWithNoQuery();
      } else {
        queryClient.setQueryData("userInfo", data);
        emailVarifiedSetState(doGetEmailValidation);
        navigate("/");
      }
    },
    onError: (error) => {
      console.log(error);
      setAlertState("error");
      errorMessageSetState("로그인에 실패하였습니다");
      handleClick();
      doSignOutWithNoQuery();
    },
    onSettled: () => {
      loadSetState(false);
    },
  });

  // sign in with email and password: inc user
  const incSignInMutation = useMutation((userCredential) => RequestSignIn({ token: userCredential.user.accessToken, userType: "incUser" }), {
    onSuccess: (data) => {
      if (data === "userType Error") {
        dialogMessageSetState(["해당 아이디는 개인 회원으로 가입된 아이디입니다.", <br/>, "개인 회원 탭에서 다시 로그인해주세요."]);
        dialogOpen();
        doSignOutWithNoQuery();
      } else if (data === "deleted user") {
        setAlertState("error");
        errorMessageSetState("탈퇴한 회원입니다.");
        handleClick();
        doSignOutWithNoQuery();
      } else {
        queryClient.setQueryData("userInfo", data);
        emailVarifiedSetState(doGetEmailValidation);
        navigate("/my-page");
      }
    },
    onError: (error) => {
      console.log(error);
      setAlertState("error");
      errorMessageSetState("로그인에 실패하였습니다");
      handleClick();
      doSignOutWithNoQuery();
    },
    onSettled: () => {
      loadSetState(false);
    },
  });

  // sign in with email and password function
  const signInFunction = () => {
    if (!signInValueState.email) {
      emailRef.current.focus();
      return 0;
    } else if (!emailRegEx.test(signInValueState.email)) {
      emailRef.current.focus();
      return 0;
    } else if (!signInValueState.password) {
      passwordRef.current.focus();
      return 0;
    } else {
      loadSetState(true);
      if (userTypeTabState === 0) {
        setAlertState("error");
        doSignInWithEmailAndPassword({
          email: signInValueState.email,
          password: signInValueState.password,
          callBack: signInMutation.mutate,
          errorMessageSetState: errorMessageSetState,
          errorMessageCall: handleClick,
          loadSetState: loadSetState
        });
      } else {
        setAlertState("error");
        doSignInWithEmailAndPassword({
          email: signInValueState.email,
          password: signInValueState.password,
          callBack: incSignInMutation.mutate,
          errorMessageSetState: errorMessageSetState,
          errorMessageCall: handleClick,
          loadSetState: loadSetState
        });
      }
    }
  };

  const emailInputBlurEvent = () => {
    if (!signInValueState.email) {
      emailErrorCodeSetState(1);
      emailErrorMsgSetState("이메일을 입력해주세요.");
    } else if (!emailRegEx.test(signInValueState.email)) {
      emailErrorCodeSetState(1);
      emailErrorMsgSetState("이메일 형식이 올바르지 않습니다.");
    } else {
      emailErrorCodeSetState(0);
      emailErrorMsgSetState("");
    }
  };

  const passwordInputBlurEvent = () => {
    if (!signInValueState.password) {
      passwordErrorCodeSetState(1);
      passwordErrorMsgSetState("비밀번호를 입력해주세요.");
    } else {
      passwordErrorCodeSetState(0);
      passwordErrorMsgSetState("");
    }
  };

  // const naverSignInFunction = () => {
  //   console.log("네이버 로그인");
  //   naverDefaultIconRef.current.children[0].click();
  // };

  // const kakaoSignInFunction = () => {
  // console.log("카카오 로그인");

  // useMutation 작성한 이후에 afterAuth로 전달
  // const afterAuth = (response) => {
  // doGetUserInfoFromKakao();
  // console.log(response.access_token);
  // };

  // doSignInWithKakao(afterAuth);
  // };

  // google sign in function
  const googleSignInFunction = () => {
    setAlertState("error");
    doSignInWithGoogle({
      callBack: signInMutation.mutate,
      errorMessageSetState: errorMessageSetState,
      errorMessageCall: handleClick,
      loadSetState: loadSetState
    });
  };

  // enter key press event
  const onEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      signInFunction();
    }
  };

  // sign in 화면
  const signInContent = () => {
    return (
      <Grid container item rowSpacing={6}>
        <SignInUpperSection ref={{ emailRef: emailRef, passwordRef: passwordRef }} emailErrorCode={emailErrorCodeState} passwordErrorCode={passwordErrorCodeState} emailErrorMsg={emailErrorMsgState} passwordErrorMsg={passwordErrorMsgState} userTypeTabState={userTypeTabState} signInTabChangeEvent={signInTabChangeEvent} loadState={loadState} signInFunction={signInFunction} valueState={signInValueState} setValueEvent={setSignInValueEvent} emailBlurEvent={emailInputBlurEvent} passwordBlurEvent={passwordInputBlurEvent} onEnterKeyPress={onEnterKeyPress} />
        {userTypeTabState === 0 ? (
          <Grid container item rowSpacing={6}>
            <SignInBelowSection googleSignInFunction={googleSignInFunction} />
            {/* <SignInBelowSection kakaoSignInFunction={kakaoSignInFunction} googleSignInFunction={googleSignInFunction} /> */}
          </Grid>
        ) : null}
        {/* <SignInBelowSection naverSignInFunction={naverSignInFunction} kakaoSignInFunction={kakaoSignInFunction} googleSignInFunction={googleSignInFunction} ref={naverDefaultIconRef} /> */}
      </Grid>
    );
  };

  return (
    <Fragment>
      <AuthBodyFrame title="로그인" content={signInContent()} />
      <DialogWithOneButton message={dialogMessageState} dialogOpenState={dialogOpenState} buttonText="확인" buttonFunc={dialogClose} />
      {/*<DialogAlertComponent />*/}
      <AlertComponent/>
    </Fragment>
  );
}
