import React from "react";

import { Link } from "react-router-dom";

import { useRecoilState } from "recoil";
import { emailVarified } from "../../recoil/UserState";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";

import { portfolioArrowUrl } from "../../configs/urls";

const PortfolioMenuButton = styled(ButtonBase)(({ page, menu, theme }) => ({
  width: "100%",
  padding: "14px 30px",
  borderRadius: "10px",
  background: page === menu ? "#F9FAFB" : "transparent",
}));

const MenuButton = styled(ButtonBase)(({ page, menu, theme }) => ({
  width: "100%",
  padding: "14px 30px",
  borderRadius: "10px",
  background: page === menu ? theme.palette.info.light : "transparent",
  justifyContent: "flex-start",
}));

// 개인 회원 마이페이지 사이드 메뉴
export default function MyPageMenuBox(props) {
  const { page } = props;

  // const [emailVarifiedState, emailVarifiedSetState] = useRecoilState(emailVarified);

  return (
    <Grid container item rowSpacing={2.5}>
      <Grid item xs={12}>
        <PortfolioMenuButton page={page} menu="my-portfolio" component={Link} to={"/my-portfolio"}>
          <Grid container columnSpacing={2}>
            <Grid item xs="auto">
              <Typography variant="h6" fontWeight={700} color="secondary">
                포트폴리오
              </Typography>
            </Grid>
            <Grid item xs="auto" paddingTop="4px">
              <img src={portfolioArrowUrl} alt="포트폴리오 아이콘" />
            </Grid>
          </Grid>
        </PortfolioMenuButton>
      </Grid>
      <Grid item xs={12}>
        <MenuButton page={page} menu="applied-project" component={Link} to={"/applied-project"}>
          <Grid container justifyContent="space-between">
            <Grid item xs="auto">
              <Typography variant="h6" fontWeight={700} color={page === "applied-project" ? "info.main" : "secondary"}>
                신청 프로젝트
              </Typography>
            </Grid>
          </Grid>
        </MenuButton>
      </Grid>
      <Grid item xs={12}>
        <MenuButton page={page} menu="my-page" component={Link} to={"/my-page"}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs="auto">
              <Typography variant="h6" fontWeight={700} color={page === "my-page" ? "info.main" : "secondary"}>
                계정설정
              </Typography>
            </Grid>
          </Grid>
        </MenuButton>
      </Grid>
    </Grid>
  );
}
