import { forwardRef } from "react";

import { TextFieldSetWithCaption } from "../Common/TextFieldSet";
import AgreementAllCheckBoxSet, { AgeAgreementCheckBoxSet, AgreementCheckBoxSet } from "./CheckBoxSet";
import AuthButton from "../../components/AuthComponents";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { kakaoUrl, googleUrl } from "../../configs/urls";
// import { naverUrl, kakaoUrl, googleUrl } from "../../configs/urls";

const AgreementGridBorder = styled(Grid)(({ theme }) => ({
  borderRadius: "5px",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.grey[200],
  paddingBottom: 8,
}));

const CustomDivider = styled(Divider)({
  borderColor: "#DFDFDF",
});

const DividerText = styled(Typography)({
  color: "#858585",
});

const ThirdPartyButton = styled(Button)({
  height: 60,
  borderRadius: 10,
  boxShadow: "none"
});

const LogoGriditem = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const ThirdPartyLogo = styled("img")({
  width: 16,
});

// 회원 가입 화면
export const SignUpContents = forwardRef((props, forwardRef) => {
  const { userInfoState, valueChangeEvent, onEnterKeyPress, agreementAllChkState, agreementAllChkEvent, mandatoryAgreementChkState, agreementChkEvent, termsModalOpenEvent, loadState, signUpButtonEvent, googleSignUpButtonEvent, emailInputBlurEvent, passwordInputBlurEvent, passwordConfirmInputBlurEvent, emailErrorCode, passwordErrorCode, passwordConfirmErrorCode, emailErrorMsg, passwordErrorMsg, passwordConfirmErrorMsg } = props;
  const { emailRef, passwordRef, passwordConfirmRef } = forwardRef;

  const errorMessageSet = ["숫자, 영문, 특수문자를 조합하여 12자리 이상 입력해주세요", "비밀번호가 일치하지 않습니다"];

  return (
    <Grid container item rowSpacing={4.5}>
      <Grid container item rowSpacing={3}>
        <Grid container item>
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight={700} color="black.200">
              회원정보입력
            </Typography>
            <Typography variant="body2" fontWeight={400} color="black.600">
              회원가입을 위하여 정보를 입력해주세요.
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} rowSpacing={1}>
          <TextFieldSetWithCaption label="이메일" errorState={emailErrorCode === 1} caption={emailErrorCode === 1 ? emailErrorMsg : ""} type="email" placeholder="reble@email.com" ref={emailRef} valueState={userInfoState.email} setValueEvent={(event) => valueChangeEvent("email", event)} onBlur={(event) => emailInputBlurEvent(event)} onKeyPress={(event) => onEnterKeyPress(event)} autoFocus={true} />
          <TextFieldSetWithCaption label="비밀번호" errorState={passwordErrorCode === 1} caption={passwordErrorCode === 1 ? passwordErrorMsg : ""} type="password" placeholder="비밀번호를 입력해주세요" ref={passwordRef} valueState={userInfoState.password} setValueEvent={(event) => valueChangeEvent("password", event)} onBlur={(event) => passwordInputBlurEvent(event)} onKeyPress={(event) => onEnterKeyPress(event)} />
          <TextFieldSetWithCaption label="비밀번호 확인" errorState={passwordConfirmErrorCode === 1} caption={passwordConfirmErrorCode === 1 ? passwordConfirmErrorMsg : ""} type="password" placeholder="비밀번호를 한 번 더 입력해주세요" ref={passwordConfirmRef} valueState={userInfoState.passwordConfirm} setValueEvent={(event) => valueChangeEvent("passwordConfirm", event)} onBlur={(event) => passwordConfirmInputBlurEvent(event)} onKeyPress={(event) => onEnterKeyPress(event)} />
        </Grid>
      </Grid>
      <Grid container item rowSpacing={4.5}>
        <Grid container item rowSpacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight={700} color="black.200">
              레블 회원약관
            </Typography>
          </Grid>
          <AgreementAllCheckBoxSet checkedState={agreementAllChkState} checkEvent={(event) => agreementAllChkEvent(event)} />
          <AgreementGridBorder container item>
            <AgeAgreementCheckBoxSet checkedState={userInfoState.ageValidation} checkEvent={(event) => agreementChkEvent("ageValidation", event)} title="만 14세 이상입니다. (필수)" />
            <AgreementCheckBoxSet checkedState={userInfoState.policyAgreement} checkEvent={(event) => agreementChkEvent("policyAgreement", event)} title="서비스 이용 약관 (필수)" modalOpenEvent={() => termsModalOpenEvent("policy")} />
            <AgreementCheckBoxSet checkedState={userInfoState.privacyAgreement} checkEvent={(event) => agreementChkEvent("privacyAgreement", event)} title="개인정보 수집 및 이용 (필수)" modalOpenEvent={() => termsModalOpenEvent("privacy")} />
            <AgreementCheckBoxSet checkedState={userInfoState.marketingAgreement} checkEvent={(event) => agreementChkEvent("marketingAgreement", event)} title="마케팅 수신 동의 (선택)" modalOpenEvent={() => termsModalOpenEvent("marketing")} />
          </AgreementGridBorder>
        </Grid>
        <Grid item xs={12}>
          <AuthButton loadState={loadState} buttonText="이메일로 가입하기" activated={true} onClickEvent={() => signUpButtonEvent()} disabled={!mandatoryAgreementChkState} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomDivider>
          <DividerText variant="body2">또는</DividerText>
        </CustomDivider>
      </Grid>
      <Grid container item rowSpacing={1.2}>
        {/* <Grid item xs={12}>
          <ThirdPartyButton variant="contained" fullWidth color="naver">
            <Grid container justifyContent="center" alignItems="center" columnSpacing={1}>
              <LogoGriditem item>
                <ThirdPartyLogo src={naverUrl.medium} alt="네이버 로고" />
              </LogoGriditem>
              <Grid item>
                <Typography variant="body2" color="#ffffff" fontWeight={500}>
                  네이버로 시작하기
                </Typography>
              </Grid>
            </Grid>
          </ThirdPartyButton>
        </Grid> */}
        {/* <Grid item xs={12}>
          <ThirdPartyButton variant="contained" fullWidth color="kakao">
            <Grid container justifyContent="center" alignItems="center" columnSpacing={1}>
              <LogoGriditem item>
                <ThirdPartyLogo src={kakaoUrl.medium} alt="카카오 로고" />
              </LogoGriditem>
              <Grid item>
                <Typography variant="body2" color="#392020" fontWeight={500}>
                  카카오로 시작하기
                </Typography>
              </Grid>
            </Grid>
          </ThirdPartyButton>
        </Grid> */}
        <Grid item xs={12}>
          <ThirdPartyButton variant="contained" fullWidth color="google" disabled={!mandatoryAgreementChkState} onClick={() => googleSignUpButtonEvent()}>
            <Grid container justifyContent="center" alignItems="center" columnSpacing={1}>
              <LogoGriditem item>
                <ThirdPartyLogo src={!mandatoryAgreementChkState ? googleUrl.disabled : googleUrl.medium} alt="구글 로고" />
              </LogoGriditem>
              <Grid item>
                <Typography variant="body1" color={!mandatoryAgreementChkState ? "#ffffff" : "#392020"} fontWeight={500}>
                  구글로 가입하기
                </Typography>
              </Grid>
            </Grid>
          </ThirdPartyButton>
        </Grid>
      </Grid>
    </Grid>
  );
});
