import React from "react";

import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";

const CustomContainer = styled(Container)({
  width: "100vw",
  paddingBottom: "60px",
  position: "sticky",
  bottom: 0,
  left: 0,
  zIndex: 50,
});

const CustomButtonBase = styled(ButtonBase)({
  borderRadius: "4px",
  width: "100%",
});

const CustomButtonTypo = styled(Typography)(({ theme, disabled }) => ({
  background: disabled ? theme.palette.grey[300] : theme.palette.secondary.main,
  borderRadius: "100px",
  padding: "16px 0",
  width: "100%",
}));

// 모집 중 프로젝트 상세 페이지 하단 선정 버튼
export default function AppointFixedButton(props) {
  const { onClickEvent, projectDetailValue, appointersList, applicantsSelectArrayState } = props;

  const disabledCondition = appointersList ? applicantsSelectArrayState.length + Object.keys(appointersList).length > projectDetailValue.recruitmentLimitNumber : applicantsSelectArrayState.length > projectDetailValue.recruitmentLimitNumber;
  const overStackNumber = appointersList ? applicantsSelectArrayState.length + Object.keys(appointersList).length - projectDetailValue.recruitmentLimitNumber : applicantsSelectArrayState.length - projectDetailValue.recruitmentLimitNumber;

  if (applicantsSelectArrayState.length > 0) {
    return (
      <CustomContainer maxWidth="lg">
        <Grid container justifyContent="center">
          <Grid item xs={2.5}>
            <CustomButtonBase onClick={() => onClickEvent()} disabled={disabledCondition}>
              {disabledCondition ? (
                <CustomButtonTypo variant="body1" color="primary" fontWeight={500} disabled={disabledCondition}>
                  {applicantsSelectArrayState.length}명 선정하기 ({overStackNumber}명 초과)
                </CustomButtonTypo>
              ) : (
                <CustomButtonTypo variant="body1" color="primary" fontWeight={500} disabled={disabledCondition}>
                  {applicantsSelectArrayState.length}명 선정하기
                </CustomButtonTypo>
              )}
            </CustomButtonBase>
          </Grid>
        </Grid>
      </CustomContainer>
    );
  } else {
    return null;
  }
}

// 모집 중 프로젝트 상세 페이지 하단 선정 취소 버튼
export function CancelAppointFixedButton(props) {
  const { onClickEvent, appointerSelectArrayState } = props;

  if (appointerSelectArrayState.length > 0) {
    return (
      <CustomContainer maxWidth="lg">
        <Grid container justifyContent="center">
          <Grid item xs={2.5}>
            <CustomButtonBase onClick={() => onClickEvent()}>
              <CustomButtonTypo variant="body1" color="primary" fontWeight={500} disabled={false}>
                {appointerSelectArrayState.length}명 선정 취소하기
              </CustomButtonTypo>
            </CustomButtonBase>
          </Grid>
        </Grid>
      </CustomContainer>
    );
  } else {
    return null;
  }
}
