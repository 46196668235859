import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";

import { useRecoilState } from "recoil";
import { emailVarified } from "../recoil/UserState";

import { RequestRefreshUserInfo } from "../utils/requestToSrv";
import { doOnAuthStateChanged, doGetEmailValidation } from "../utils/firebaseUtils";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const requireAuthPathList = ["/change-password", "/withdrawal", "/my-page", "/my-portfolio", "/update-portfolio", "/applied-project", "/new-project", "/project-preview", "/recruiting-project", "/recruiting-project/:targetDocId", "/ongoing-project", "/ongoing-project/:targetDocId", "/finished-project", "/finished-project/:targetDocId", "/brand", "/membership", "/membership-info", "/payment"];
const requireAuthAdminPathList = ["/admin-users", "/admin-portfolio/:targetUid", "/admin-applied-project/:targetUid", "/admin-incusers", "/admin-membership/:targetUid", "/admin-recruiting-project", "/admin-ongoing-project", "/admin-finished-project", "/admin-project-detail/:targetDocId", "/admin-recruiting-detail/:targetDocId", "/admin-ongoing-detail/:targetDocId", "/admin-finished-detail/:targetDocId", "/admin-banner"];

export default function RequireAuthRoute({ Component, ...rest }) {
  const nowCompPath = { ...rest }.path;

  const queryClient = useQueryClient();

  const location = useLocation();
  const navigate = useNavigate();

  const [emailVarifiedState, emailVarifiedSetState] = useRecoilState(emailVarified);

  const [authedState, authedSetState] = useState(true);
  const [loadState, loadSetState] = useState(true);

  // 경우의 수
  // 1. 로그인을 한 상태
  // 2. 로그인을 안한 상태
  // 3. 로그인을 했으나 새로고침 해서 recoil에 데이터가 없는 상태

  const RefreshUserInfo = useMutation((token) => RequestRefreshUserInfo(token), {
    onSuccess: (data) => {
      queryClient.setQueryData("userInfo", data);
      emailVarifiedSetState(doGetEmailValidation);
      loadSetState(false);
      authedSetState(true);
    },
    onError: (error) => {
      console.log(error);
      authedSetState(false);
      loadSetState(false);
    },
  });

  useEffect(() => {
    if (!queryClient.getQueryData("userInfo")) {
      doOnAuthStateChanged(authedSetState, loadSetState, RefreshUserInfo.mutate);
    } else {
      loadSetState(false);
    }
  }, []);

  // 회원 판별시 호출할 뒤로가기 함수
  const moveToSignIn = () => {
    // alert("회원만 접근 가능한 기능입니다.");
    // history(-1);
    navigate("/signin");
  };

  const adminMoveToSignIn = () => {
    navigate("/admin-signin");
  };

  if (loadState) {
    return (
      <Backdrop open={loadState}>
        <CircularProgress color="secondary" />
      </Backdrop>
    );
  } else {
    if (requireAuthPathList.includes(location.pathname)) {
      if (authedState === true) {
        return <Component {...rest} />;
      } else {
        moveToSignIn();
      }
    } else if (requireAuthAdminPathList.includes(location.pathname)) {
      if (authedState === true) {
        return <Component {...rest} />;
      } else {
        adminMoveToSignIn();
      }
    } else {
      return <Component {...rest} />;
    }
  }
}
