import { Fragment } from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { AuthCheckbox } from "../../components/AuthComponents";

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.grey[300],
}));

const CheckBoxSetGridContainer = styled(Grid)({
  padding: "0 10px",
});

// 전체 동의 체크박스
export default function AgreementAllCheckBoxSet(props) {
  const { checkedState, checkEvent } = props;
  return (
    <FormControlLabel
      label={
        <Typography variant="body2" fontWeight={500}>
          약관 전체 동의
        </Typography>
      }
      control={<AuthCheckbox checkedState={checkedState} checkEvent={checkEvent} />}
    />
  );
}

// 약관 동의 체크박스
export function AgreementCheckBoxSet(props) {
  const { checkedState, checkEvent, title, modalOpenEvent } = props;

  return (
    <CheckBoxSetGridContainer container item alignItems="center">
      <Grid item xs="auto">
        <FormControlLabel
          label={
            <Typography variant="body3" component="p">
              {title}
            </Typography>
          }
          control={<AuthCheckbox checkedState={checkedState} checkEvent={checkEvent} />}
        />
      </Grid>
      <Grid container item xs justifyContent="flex-end">
        <Grid item>
          <ButtonBase onClick={modalOpenEvent}>
            <Typography variant="body3" component="p" color="secondary" align="right">
              전체보기 &gt;
            </Typography>
          </ButtonBase>
        </Grid>
      </Grid>
    </CheckBoxSetGridContainer>
  );
}

// 만 14세 이상 동의 체크박스
export function AgeAgreementCheckBoxSet(props) {
  const { checkedState, checkEvent, title } = props;

  return (
    <CheckBoxSetGridContainer item xs={12}>
      <FormControlLabel
        label={
          <Typography variant="body3" component="p">
            {title}
          </Typography>
        }
        control={<AuthCheckbox checkedState={checkedState} checkEvent={checkEvent} />}
      />
    </CheckBoxSetGridContainer>
  );
}

// 라벨이 있는 체크박스
export function LabeledCheckBoxSet(props) {
  const { checkedState, checkEvent, labelText, labelVariant, labelColor, labelWeight } = props;

  return (
    <FormControlLabel
      label={
        <Typography component="p" variant={labelVariant} color={labelColor} fontWeight={labelWeight}>
          {labelText}
        </Typography>
      }
      control={<CustomCheckbox icon={<CheckCircleOutlineIcon />} checkedIcon={<CheckCircleIcon />} variant="contained" color="secondary" checked={checkedState} onChange={(event) => checkEvent(event)} />}
    />
  );
}
