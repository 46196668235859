import React from "react";

import AdminAppliedProject from "../../containers/Admin/AdminAppliedProjectContainer";
import RequireAuthRoute from "../../routes/requireAuth";

export default function AdminAppliedProjectView() {
  return (
    <React.Fragment>
      <RequireAuthRoute Component={AdminAppliedProject} />
    </React.Fragment>
  );
}
