import React from "react";

import moment from "moment";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import NaverBlogTag, { NaverPostTag, InstagramTag, YoutubeTag, ProjectTypeTag, SharingButton } from "../../components/ProjectComponent";

import { ContainedButton, CancelButton } from "../../components/ButtonComponents";

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

const StickyBox = styled(Box)({
  border: "1px solid #CCCCCC",
  borderRadius: "20px",
  position: "sticky",
  top: 95.5,
  padding: "30px 36px",
});

const GreyCustomGrid = styled(Grid)({
  background: "#F9F9F9",
  borderRadius: "10px",
  padding: "21px 22px",
})

// 프로젝트 상세 페이지 메뉴 박스
export default function ProjectDetailMenuBox(props) {
  const { projectDetailValue, applicationState, sharingButtonFunc, applicationButtonFunc, cancelButtonFunc } = props;

  const ProjectDetailMenuTags = () => {
    return (
      <Grid container item columnSpacing={1}>
        {projectDetailValue.channel === "naverBlog" ? (
          <Grid item>
            <Typography variant="body2" lineHeight="14px" color="black.600">
              네이버블로그
            </Typography>
          </Grid>
        ) : projectDetailValue.channel === "naverPost" ? (
          <Grid item>
            <Typography variant="body2" lineHeight="14px" color="black.600">
              네이버포스트
            </Typography>
          </Grid>
        ) : projectDetailValue.channel === "instagram" ? (
          <Grid item>
            <Typography variant="body2" lineHeight="14px" color="black.600">
              인스타그램
            </Typography>
          </Grid>
        ) : projectDetailValue.channel === "youtube" ? (
          <Grid item>
            <Typography variant="body2" lineHeight="14px" color="black.600">
              유튜브
            </Typography>
          </Grid>
        ) : null}
        <Grid item display="flex" alignItems="center">
          <Divider orientation="vertical" sx={{ height: "10px" }}  />
        </Grid>
        <Grid item>
          {projectDetailValue.type === "delivery" ? (
          <Typography variant="body2" lineHeight="14px" color="black.600">
            배송형
          </Typography>
          ) : projectDetailValue.type === "purchase" ? (
            <Typography variant="body2" lineHeight="14px" color="black.600">
              구매형
            </Typography>
          ) : projectDetailValue.type === "visit" ? (
            <Typography variant="body2" lineHeight="14px" color="black.600">
              방문형
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    );
  };

  // 프로젝트 상세 페이지 일정
  const ProjectDetailMenuSchedule = () => {
    return (
      <Grid container item rowGap={1}>
        <Grid item xs={12} display="flex" columnGap={1}>
          <CalendarMonthIcon />
          <Typography variant="body1" fontWeight={500} lineHeight="24px" color="secondary">
             프로젝트 일정
          </Typography>
        </Grid>
        <GreyCustomGrid container rowGap={0.5}>
          <Grid container item>
            <Grid item xs={3}>
              <Typography variant="caption1" fontWeight={400} lineHeight="24px" color="black.600">
                모집기간
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body1" lineHeight="24px" color="secondary">
                {moment(projectDetailValue.recruitmentStartDate * 1000).format("YYYY.MM.DD")} ~ {moment(projectDetailValue.recruitmentEndDate * 1000).format("YYYY.MM.DD")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={3}>
              <Typography variant="caption1" fontWeight={400} lineHeight="24px" color="black.600">
                선정일
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body1" lineHeight="24px" color="secondary">
                {moment(projectDetailValue.appointmentDate * 1000).format("YYYY.MM.DD")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={3}>
              <Typography variant="caption1" fontWeight={400} lineHeight="24px" color="black.600">
                제출기간
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body1" lineHeight="24px" color="secondary">
                {moment(projectDetailValue.submissionStartDate * 1000).format("YYYY.MM.DD")} ~ {moment(projectDetailValue.submissionEndDate * 1000).format("YYYY.MM.DD")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={3}>
              <Typography variant="caption1" fontWeight={400} lineHeight="24px" color="black.600">
                종료일
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body1" lineHeight="24px" color="secondary">
                {moment(projectDetailValue.endDate * 1000).format("YYYY.MM.DD")}
              </Typography>
            </Grid>
          </Grid>
        </GreyCustomGrid>
      </Grid>
    );
  };

  // 프로젝트 상세 페이지 신청자 수 / 모집 인원
  const ProjectDetailMenuNumberOfApplicants = () => {
    return (
      <Grid container item justifyContent="space-between" alignItems="center">
        <Grid item display="flex" columnGap={1}>
          <PeopleOutlineIcon />
          <Typography variant="body1" fontWeight={500} lineHeight="24px" color="secondary">
            모집인원
          </Typography>
        </Grid>
        <Grid item display="flex" alignItems="baseline">
          <Typography variant="h6" lineHeight="30px">
            {projectDetailValue.recruitmentNumber}명 
          </Typography>
          <Typography variant="body2" lineHeight="30px">
            / {projectDetailValue.recruitmentLimitNumber}명
          </Typography>
        </Grid>
      </Grid>
    );
  };

  // 프로젝트 상세 페이지 신청 /신청 취소 버튼
  const ProjectDetailMenuButtons = () => {
    return (
      <Grid container item columnSpacing={1.3}>
        <Grid item xs="auto">
          <SharingButton sharingButtonFunc={sharingButtonFunc} />
        </Grid>
        <Grid item xs>
          {applicationState === "applied" ? <CancelButton buttonEvent={cancelButtonFunc} buttonText="신청취소" /> : <ContainedButton buttonEvent={applicationButtonFunc} buttonText="신청하기" buttonDisabled={false} />}
        </Grid>
      </Grid>
    );
  };

  return (
    <StickyBox>
      <Grid container rowGap={3}>
        <Grid container item xs={12} rowGap={1}>
          <Typography variant="h5" lineHeight="30px" color="secondary">
            {projectDetailValue.title}
          </Typography>
          {ProjectDetailMenuTags()}
        </Grid>
        {ProjectDetailMenuSchedule()}
        {ProjectDetailMenuNumberOfApplicants()}
        {ProjectDetailMenuButtons()}
      </Grid>
    </StickyBox>
  );
}
