import React, { forwardRef } from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import { NewProjectBasicInfo } from "./NewProjectBasicInfo";
import { NewProjectAdditionalInfo } from "./NewProjectAdditionalInfo";

const ContainerPaper = styled(Paper)({
  width: "100%",
  borderRadius: "10px",
});

const StepperGrid = styled(Grid)({
  padding: "17px 0",
  borderBottom: "1px solid #222222"
});

const StepCircleGrid = styled(Grid)(({ theme, activeProjectStep }) => ({
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  background: activeProjectStep ? theme.palette.secondary.main : "#E6E6E6",
  textAlign: "center",
}));

const RedBoxGridContainer = styled(Grid)({
  padding: "15px 22px",
  borderRadius: "10px",
  background: "#FCF7F7",
});

const CustomTableBody = styled(TableBody)({
  borderTop: "1px solid #222222",
});

const CustomLeftTableCell = styled(TableCell)({
  background: "#FAFAFA",
  padding: "20px",
});

const CustomRightTableCell = styled(TableCell)({
  padding: "10px 20px",
});

// 프로젝트 등록 화면 구성
export const NewProjectContents = forwardRef((props, forwardRef) => {
  const { uid, projectDocId, projectStepState, projectValueState, projectValueChange, projectSelectValueChange, projectDateValueChange, onNextKeyPress, brandInfo, channelMenu, typeMenu, dialogOpenEvent, changeThumbnailClickFunc, blobImgSrcState, changeThumbnailFunc, deleteThumbnailFunc } = props;
  const { titleRef, brandRef, channelRef, typeRef, recruitmentStartDateRef, recruitmentEndDateRef, appointmentDateRef, submissionStartDateRef, submissionEndDateRef, endDateRef, recruitmentNumberRef, contactRef, thumbnailRef, uploadedthumbnailRef, detailedImageRef, offeringRef, hashTagsRef, guideRef, precautionRef, visitingInfoRef } = forwardRef;

  // container에서 받은 ref set 중 기본 정보 입력에 필요한 ref set
  const basicRefSet = {
    titleRef: titleRef,
    brandRef: brandRef,
    channelRef: channelRef,
    typeRef: typeRef,
    recruitmentStartDateRef: recruitmentStartDateRef,
    recruitmentEndDateRef: recruitmentEndDateRef,
    appointmentDateRef: appointmentDateRef,
    submissionStartDateRef: submissionStartDateRef,
    submissionEndDateRef: submissionEndDateRef,
    endDateRef: endDateRef,
    recruitmentNumberRef: recruitmentNumberRef,
    contactRef: contactRef,
  };

  // container에서 받은 ref set 중 추가 정보 입력에 필요한 ref set
  const additioanlRefSet = {
    thumbnailRef: thumbnailRef,
    uploadedthumbnailRef: uploadedthumbnailRef,
    detailedImageRef: detailedImageRef,
    offeringRef: offeringRef,
    hashTagsRef: hashTagsRef,
    guideRef: guideRef,
    precautionRef: precautionRef,
    visitingInfoRef: visitingInfoRef,
  };

  // 활성화 된 탭
  const StepsInfo = () => {
    const ActivatedStepLabel = (step, text) => {
      return (
        <Grid container item xs={6} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h5" fontWeight={700} lineHeight="24px" color="black.300" align="left">
              {step}. {text}
            </Typography>
          </Grid>
        </Grid>
      );
    };

    // Stepper
    const Stepper = (projectStepState) => {
      return (
        <Grid container item xs={6} columnGap={4.2} alignItems="center" justifyContent="flex-end">
          <Grid container item xs="auto" columnGap={1} alignItems="center">
            <StepCircleGrid item activeProjectStep={projectStepState === 0}>
              <Typography variant="body1" fontWeight={700} lineHeight="24px" color="#FFFFFF">
                1
              </Typography>
            </StepCircleGrid>
            <Grid item>
              <Typography variant="subtitle1" fontWeight={700} color={projectStepState === 0 ? "black.200" : "#D9D9D9"}>
                기본정보 입력
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs="auto">
            <Typography variant="subtitle1" fontWeight={700} color={projectStepState === 0 ? "black.200" : "#D9D9D9"}>
             &gt;
            </Typography>
          </Grid>
          <Grid container item xs="auto" columnGap={1} alignItems="center">
            <StepCircleGrid item activeProjectStep={projectStepState === 1}>
              <Typography variant="body1" fontWeight={700} lineHeight="24px" color="#FFFFFF">
                2
              </Typography>
            </StepCircleGrid>
            <Grid item>
              <Typography variant="subtitle1" fontWeight={700} color={projectStepState === 1 ? "black.200" : "#D9D9D9"}>
                상세정보 입력
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <StepperGrid container item>
        {projectStepState === 0 ? ActivatedStepLabel("STEP1", "기본정보") : ActivatedStepLabel("STEP2", "상세정보")}
        {Stepper(projectStepState)}
      </StepperGrid>
    );
  };

  return (
    <Grid container item>
      <ContainerPaper elevation={0}>
        <Grid container>
          {StepsInfo()}
          {projectStepState === 1 ? <NewProjectAdditionalInfo ref={additioanlRefSet} uid={uid} projectDocId={projectDocId} projectValueState={projectValueState} projectValueChange={projectValueChange} onNextKeyPress={onNextKeyPress} changeThumbnailClickFunc={changeThumbnailClickFunc} blobImgSrcState={blobImgSrcState} changeThumbnailFunc={changeThumbnailFunc} deleteThumbnailFunc={deleteThumbnailFunc} /> : <NewProjectBasicInfo ref={basicRefSet} projectDocId={projectDocId} projectValueState={projectValueState} projectValueChange={projectValueChange} projectSelectValueChange={projectSelectValueChange} projectDateValueChange={projectDateValueChange} onNextKeyPress={onNextKeyPress} brandInfo={brandInfo} channelMenu={channelMenu} typeMenu={typeMenu} dialogOpenEvent={dialogOpenEvent} />}
        </Grid>
      </ContainerPaper>
    </Grid>
  );
});

// 일정 안내 modal
export function PeriodInfoGuideContext() {
  return (
    <Grid container item rowGap={3}>
      <RedBoxGridContainer container item>
        <Typography variant="body1" color="black.600">
          프로젝트 일정은 모집 시작 &gt; 모집 마감 &gt; 선정일 &gt; 제출시작 &gt; 제출마감 &gt; 종료일 순으로 진행되며,
          <br />
          앞 단계의 일자는 뒷 단계의 일자보다 뒤로 갈 수 없습니다.
        </Typography>
      </RedBoxGridContainer>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <CustomTableBody>
              <TableRow>
                <CustomLeftTableCell>
                  <Typography variant="body2" lineHeight="24px" fontWeight={400} color="black.600">
                    모집 시작일, 마감일
                  </Typography>
                </CustomLeftTableCell>
                <CustomRightTableCell>
                  <Typography variant="body1" lineHeight="24px" fontWeight={400} color="#4C4C4C">
                    프로젝트 수행 인원을 모집하는 기간입니다.
                    <br />
                    개인회원들은 이 기간동안 프로젝트를 신청할 수 있습니다.
                  </Typography>
                </CustomRightTableCell>
              </TableRow>
              <TableRow>
                <CustomLeftTableCell>
                  <Typography variant="body2" lineHeight="24px" fontWeight={400} color="black.600">
                    선정일
                  </Typography>
                </CustomLeftTableCell>
                <CustomRightTableCell>
                  <Typography variant="body1" lineHeight="24px" fontWeight={400} color="#4C4C4C">
                    신청자들 중 프로젝트를 수행할 회원들을 선택 완료하는 날입니다.
                    <br />
                    선정 완료 시 수행자들에게 프로젝트 선정 알림이 메일로 발송됩니다.
                  </Typography>
                </CustomRightTableCell>
              </TableRow>
              <TableRow>
                <CustomLeftTableCell>
                  <Typography variant="body2" lineHeight="24px" fontWeight={400} color="black.600">
                    제출 시작일, 마감일
                  </Typography>
                </CustomLeftTableCell>
                <CustomRightTableCell>
                  <Typography variant="body1" lineHeight="24px" fontWeight={400} color="#4C4C4C">
                    프로젝트를 수행하는 기간입니다.
                    <br />
                    선택된 수행자들은 이 기간동안 프로젝트를 수행하고 결과물을 제출해야 합니다.                    <br />
                  </Typography>
                </CustomRightTableCell>
              </TableRow>
              <TableRow>
                <CustomLeftTableCell>
                  <Typography variant="body2" lineHeight="24px" fontWeight={400} color="black.600">
                    종료일
                  </Typography>
                </CustomLeftTableCell>
                <CustomRightTableCell>
                  <Typography variant="body1" lineHeight="24px" fontWeight={400} color="#4C4C4C">
                    프로젝트 종료 일자입니다.
                    <br />
                    담당자들은 종료일 전까지 프로젝트에 대한 피드백을 보내고 수정을 요청할 수 있습니다.                    <br />
                  </Typography>
                </CustomRightTableCell>
              </TableRow>
            </CustomTableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
