import React, { useState, useEffect, Fragment } from "react";

import { useRecoilState } from "recoil";
import { productSelect } from "../../../recoil/ProductState";

import { useQueryClient } from "react-query";

import { loadTossPayments } from "@tosspayments/payment-sdk";

import PaymentBodyFrame from "../../../Block/Payment/PaymentBodyFrame";
import { PaymentContents } from "../../../Block/Payment/ProductSelectPaymentContents";
import TermsDialogContext from "../../../Block/Payment/TermsDialogContext";

import { DialogWithOneButton, ComplexDialogWithOneButton } from "../../../components/DialogWithButton";
import moment from "moment";

const dummyTermsText = "멤버십 정책\n테스트";

// 결제 페이지(상품 선택)
export default function PaymentContainer() {
  // 화면 로드 시 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // 선택한 상품 state
  const [productSelectState, productSelectSetState] = useRecoilState(productSelect);

  // react-query queryClient
  const queryClient = useQueryClient();

  // 선택한 결제 수단 state(기본 값 : toss)
  const [paymentSelectState, paymentSelectSetState] = useState("toss");

  // 결제 수단 선택 function
  const paymentSelectFunc = (method) => {
    paymentSelectSetState(method);
  };

  // 전체 동의 체크박스 state
  const [agreementAllChkState, agreementAllChkSetState] = useState(false);

  // 약관 동의 체크박스 state
  const [agreementChkState, agreementChkSetState] = useState({
    subscribe: false,
    policy: false,
  });

  // 전체 약관 동의 체크박스 function
  const agreementAllChkEvent = (event) => {
    agreementAllChkSetState(event.target.checked);
    agreementChkSetState({
      subscribe: event.target.checked,
      policy: event.target.checked,
    });
  };

  // 약관 동의 체크박스 function
  const agreementChkEvent = (key, event) => {
    if (key === "subscribe") {
      agreementChkSetState((prevState) => ({
        ...prevState,
        subscribe: event.target.checked,
      }));
    } else if (key === "policy") {
      agreementChkSetState((prevState) => ({
        ...prevState,
        policy: event.target.checked,
      }));
    }
  };

  // 약관 동의 체크박스 state 변경 시 전체 약관 동의 체크박스 state 변경
  useEffect(() => {
    if (agreementChkState.subscribe && agreementChkState.policy) {
      agreementAllChkSetState(true);
    } else {
      agreementAllChkSetState(false);
    }
  }, [agreementChkState.subscribe, agreementChkState.policy]);

  // 약관 dialog open state
  const [termsDialogOpenState, termsDialogOpenSetState] = useState(false);

  // 약관 dialog open function
  const termsDialogOpenFunc = () => {
    termsDialogOpenSetState(true);
  };

  // 약관 dialog close function
  const termsDialogCloseFunc = () => {
    termsDialogOpenSetState(false);
  };

  // const tossPayFunc = () => {
  //   loadTossPayments(process.env.REACT_APP_TOSSPAY_CLIENT_KEY).then((tossPayments) => {
  //     tossPayments.requestPayment("카드", {
  //       amount: productSelectState === "lite" ? 55000 : productSelectState === "basic" ? 110000 : 220000,
  //       orderId: queryClient.getQueryData("userInfo").uid + moment().format("YYYYMMDDHHmmss"),
  //       orderName: productSelectState === "lite" ? "on1016 비즈니스 월간 멤버십 (Lite)" : productSelectState === "basic" ? "on1016 비즈니스 월간 멤버십 (Basic)" : "on1016 비즈니스 월간 멤버십 (Premium)",
  //       customerName: queryClient.getQueryData("userInfo").name ? queryClient.getQueryData("userInfo").name : queryClient.getQueryData("userInfo").email,
  //       successUrl: "https://localhost:3000/payment/success",
  //       failUrl: "https://localhost:3000/payment/failed",
  //     });
  //   });
  // };

  // toss 결제 function (상품 선택)
  const tossPayFunc = () => {
    loadTossPayments(process.env.REACT_APP_TOSSPAY_CLIENT_KEY).then((tossPayments) => {
      tossPayments.requestPayment("카드", {
        amount: productSelectState === "lite" ? 55000 : productSelectState === "basic" ? 110000 : 220000,
        orderId: queryClient.getQueryData("userInfo").uid + moment().format("YYYYMMDDHHmmss"),
        orderName: productSelectState === "lite" ? "on1016 비즈니스 월간 멤버십 (Lite)" : productSelectState === "basic" ? "on1016 비즈니스 월간 멤버십 (Basic)" : "on1016 비즈니스 월간 멤버십 (Premium)",
        customerName: queryClient.getQueryData("userInfo").name ? queryClient.getQueryData("userInfo").name : queryClient.getQueryData("userInfo").email,
        successUrl: "https://on1016.com/payment/success",
        failUrl: "https://on1016.com/payment/failed",
      });
    });
  };

  // 결제 완료 dialog open state
  const [finishedDialogOpenState, finishedDialogOpenSetState] = useState(false);

  // 결제 버튼 function
  const submitFunc = () => {
    if (paymentSelectState === "toss") {
      tossPayFunc();
    } else {
      console.log("kakaopay");
    }
    // finishedDialogOpenSetState(true);
  };

  // 결제 완료 dialog close function
  const finishedDialogCloseFunc = () => {
    finishedDialogOpenSetState(false);
  };

  // 결제 페이지 화면
  return (
    <Fragment>
      <PaymentBodyFrame content={<PaymentContents productSelectState={productSelectState} paymentSelectState={paymentSelectState} paymentSelectFunc={paymentSelectFunc} agreementAllChkState={agreementAllChkState} agreementChkState={agreementChkState} agreementAllChkEvent={agreementAllChkEvent} agreementChkEvent={agreementChkEvent} termsDialogOpenFunc={termsDialogOpenFunc} submitFunc={submitFunc} />} />;
      <DialogWithOneButton width={480} icon="check" title="멤버십 결제 완료" subtitle={["멤버십 결제가 완료되었습니다.", <br />, "지금 바로 서비스를 이용해보세요."]} dialogOpenState={finishedDialogOpenState} dialogClose={finishedDialogCloseFunc} buttonText="확인" buttonFunc={finishedDialogCloseFunc} />
      <ComplexDialogWithOneButton context={<TermsDialogContext membershipTermsText={dummyTermsText} />} dialogOpenState={termsDialogOpenState} dialogClose={termsDialogCloseFunc} buttonText="확인" buttonFunc={termsDialogCloseFunc} />
    </Fragment>
  );
}
