import React from "react";

import AdminUsers from "../../containers/Admin/AdminUsersContainer";
import RequireAuthRoute from "../../routes/requireAuth";

export default function AdminUsersView() {
  return (
    <React.Fragment>
      <RequireAuthRoute Component={AdminUsers} />
    </React.Fragment>
  );
}
