import React, { useRef, useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

import { useRecoilState } from "recoil";
import { emailVarified } from "../../../recoil/UserState";
import { userTypeTab } from "../../../recoil/AuthState";

import AuthBodyFrame from "../../../Block/Auth/AuthBodyFrame";
import { SignUpAgreementModal } from "../../../Block/Auth/SignUpAgreementModal";
import { SignUpContents } from "../../../Block/Auth/SignUpContents";
import { IncUserSignUpContents } from "../../../Block/Auth/IncUserSignUpContents";
// import { DialogAlertMessage } from "../../../components/DialogMessage";
import { CustomizedSnackbars } from "../../../components/Alert";
import { DialogWithOneButton } from "../../../components/DialogWithButton";

import { doCreateUserWithEmailAndPassword, doSignInWithGoogle, doSignOutWithNoQuery, doDeleteUser, doGetEmailValidation } from "../../../utils/firebaseUtils";
import { RequestSignUp, RequestDefaultUserInfo } from "../../../utils/requestToSrv";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

const TabLabelTypo = styled(Typography)({
  color: "#999999",
});

const SelectedTabLabelTypo = styled(Typography)({
  color: "#222222",
});

const CustomTabs = styled(Tabs)({
  backgroundColor: "#F2F2F2",
  borderRadius: 10,

  "& .Mui-selected": {
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    border: "2px solid #4C4C4C",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  },
});

const CustomTab = styled(Tab)({
  padding: "16px 16px",
});

// 정규표현식
const emailRegEx = new RegExp(/^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/);
const passwordRegEx = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{12,}$/);

// 회원 가입
export default function SignUpContainer() {
  // 화면 로드 시 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // data loading state
  const [loadState, loadSetState] = useState(false);
  // react-router-dom navigate
  const navigate = useNavigate();
  // react-query queryClient
  const queryClient = useQueryClient();

  // 각 textField를 위한 ref : default user
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordConfirmRef = useRef(null);

  // 각 textField를 위한 ref : inc user
  const incUserEmailRef = useRef(null);
  const incUserPasswordRef = useRef(null);
  const incUserPasswordConfirmRef = useRef(null);
  const companyNameRef = useRef(null);
  const homepageUrlRef = useRef(null);

  // 이메일 인증 여부 state - firebase에서 확인
  const [emailVarifiedState, emailVarifiedSetState] = useRecoilState(emailVarified);

  // user type (default / inc) tab state
  const [userTypeTabState, userTypeTabSetState] = useRecoilState(userTypeTab);

  // user type tab change event
  const signUpTabChangeEvent = (event, newValue) => {
    userTypeTabSetState(newValue);
  };

  // 회원 가입 완료 dialog open state
  const [dialogOpenState, dialogOpenSetState] = useState(false);

  // 회원 가입 완료 dialog open event
  const dialogOpen = () => {
    dialogOpenSetState(true);
  };

  // 회원 가입 완료 dialog close event
  // const dialogClose = () => {
  //   dialogOpenSetState(false);
  // };

  // 회원 가입 완료 후 마이페이지로 이동
  const linkToEmailVerification = () => {
    navigate("/my-page");
  };

  // 기업 회원 가입 완료 dialog open state
  const [incUserDialogOpenState, incUserDialogOpenSetState] = useState(false);

  // 기업 회원 가입 완료 dialog open event
  const incUserDialogOpen = () => {
    incUserDialogOpenSetState(true);
  };

  // 기업 회원 가입 완료 dialog close event
  // const incUserDialogClose = () => {
  //   incUserDialogOpenSetState(false);
  // };

  const incUserSignOut = () => {
    navigate("/my-page");
  };

  // 개인 회원 user info state
  const [userInfoState, userInfoSetState] = useState({
    email: "",
    password: "",
    passwordConfirm: "",
    ageValidation: false,
    policyAgreement: false,
    privacyAgreement: false,
    marketingAgreement: false,
  });

  // 기업 회원 user info state
  const [incUserInfoState, incUserInfoSetState] = useState({
    email: "",
    password: "",
    passwordConfirm: "",
    companyName: "",
    homepageUrl: "",
    ageValidation: false,
    policyAgreement: false,
    privacyAgreement: false,
    marketingAgreement: false,
  });

  // 개인 회원 이용 약관 전체 동의 state
  const [agreementAllChkState, agreementAllChkSetState] = useState(false);
  // 개인 회원 이용 약관 필수 동의 state
  const [agreementMandatoryChkState, agreementMandatoryChkSetState] = useState(false);
  // 기업 회원 이용 약관 전체 동의 state
  const [incUserAgreementAllChkState, incUserAgreementAllChkSetState] = useState(false);
  // 기업 회원 이용 약관 필수 동의 state
  const [incAgreementMandatoryChkState, incAgreementMandatoryChkSetState] = useState(false);

  useEffect(() => {
    // 개인 회원 필수 약관 동의 state check
    if (userInfoState.ageValidation && userInfoState.policyAgreement && userInfoState.privacyAgreement) {
      agreementMandatoryChkSetState(true);
    } else {
      agreementMandatoryChkSetState(false);
    }
    // 개인 회원 전체 약관 동의 state check
    if (userInfoState.ageValidation && userInfoState.policyAgreement && userInfoState.privacyAgreement && userInfoState.marketingAgreement) {
      agreementAllChkSetState(true);
    } else {
      agreementAllChkSetState(false);
    }
  }, [userInfoState.ageValidation, userInfoState.policyAgreement, userInfoState.privacyAgreement, userInfoState.marketingAgreement]);

  useEffect(() => {
    // 기업 회원 필수 약관 동의 state check
    if (incUserInfoState.ageValidation && incUserInfoState.policyAgreement && incUserInfoState.privacyAgreement) {
      incAgreementMandatoryChkSetState(true);
    } else {
      incAgreementMandatoryChkSetState(false);
    }
    // 기업 회원 전체 약관 동의 state check
    if (incUserInfoState.ageValidation && incUserInfoState.policyAgreement && incUserInfoState.privacyAgreement && incUserInfoState.marketingAgreement) {
      incUserAgreementAllChkSetState(true);
    } else {
      incUserAgreementAllChkSetState(false);
    }
  }, [incUserInfoState.ageValidation, incUserInfoState.policyAgreement, incUserInfoState.privacyAgreement, incUserInfoState.marketingAgreement]);

  useEffect(() => {
    // 개인 회원 비밀번호 정규식 체크
    if (!passwordRegEx.test(userInfoState.password)) {
      passwordErrorCodeSetState(1);
      passwordErrorMsgSetState("영문, 숫자, 기호를 모두 조합하여 12자 이상 입력해주세요.");
    } else {
      passwordErrorCodeSetState(0);
      passwordErrorMsgSetState("");
    }
    // 개인 회원 비밀번호 / 비밀번호 확인 일치 체크
    if (userInfoState.password !== userInfoState.passwordConfirm) {
      passwordConfirmErrorCodeSetState(1);
      passwordConfirmErrorMsgSetState("비밀번호가 일치하지 않습니다.");
    } else {
      passwordConfirmErrorCodeSetState(0);
      passwordConfirmErrorMsgSetState("");
    }
  }, [userInfoState.password, userInfoState.passwordConfirm]);

  useEffect(() => {
    // 기업 회원 비밀번호 정규식 체크
    if (!passwordRegEx.test(incUserInfoState.password)) {
      passwordErrorCodeSetState(2);
      passwordErrorMsgSetState("영문, 숫자, 기호를 모두 조합하여 12자 이상 입력해주세요.");
    } else {
      passwordErrorCodeSetState(0);
      passwordErrorMsgSetState("");
    }
    // 기업 회원 비밀번호 / 비밀번호 확인 일치 체크
    if (incUserInfoState.password !== incUserInfoState.passwordConfirm) {
      passwordConfirmErrorCodeSetState(2);
      passwordConfirmErrorMsgSetState("비밀번호가 일치하지 않습니다.");
    } else {
      passwordConfirmErrorCodeSetState(0);
      passwordConfirmErrorMsgSetState("");
    }
  }, [incUserInfoState.password, incUserInfoState.passwordConfirm]);

  // 이메일 에러 코드 state
  const [emailErrorCodeState, emailErrorCodeSetState] = useState(0);
  // 비밀번호 에러 코드 state
  const [passwordErrorCodeState, passwordErrorCodeSetState] = useState(0);
  // 비밀번호 확인 에러 코드 state
  const [passwordConfirmErrorCodeState, passwordConfirmErrorCodeSetState] = useState(0);

  const [emailErrorMsgState, emailErrorMsgSetState] = useState("");
  const [passwordErrorMsgState, passwordErrorMsgSetState] = useState("");
  const [passwordConfirmErrorMsgState, passwordConfirmErrorMsgSetState] = useState("");

  // 개인 회원 value change event
  const valueChangeEvent = (key, event) => {
    userInfoSetState((prevState) => ({
      ...prevState,
      [key]: event.target.value,
    }));
  };

  // 개인 회원 약관 전체 동의 event
  const agreementAllChkEvent = (event) => {
    agreementAllChkSetState(event.target.checked);
    userInfoSetState((prevState) => ({
      ...prevState,
      ageValidation: event.target.checked,
      policyAgreement: event.target.checked,
      privacyAgreement: event.target.checked,
      marketingAgreement: event.target.checked,
    }));
  };

  // 개인 회원 약관 동의 event
  const agreementChkEvent = (key, event) => {
    userInfoSetState((prevState) => ({
      ...prevState,
      [key]: event.target.checked,
    }));
  };

  // 기업 회원 value change event
  const incUserValueChangeEvent = (key, event) => {
    incUserInfoSetState((prevState) => ({
      ...prevState,
      [key]: event.target.value,
    }));
  };

  // 기업 회원 약관 전체 동의 event
  const incUserAgreementAllChkEvent = (event) => {
    incUserAgreementAllChkSetState(event.target.checked);
    incUserInfoSetState((prevState) => ({
      ...prevState,
      ageValidation: event.target.checked,
      policyAgreement: event.target.checked,
      privacyAgreement: event.target.checked,
      marketingAgreement: event.target.checked,
    }));
  };

  // 기업 회원 약관 동의 event
  const incUserAgreementChkEvent = (key, event) => {
    incUserInfoSetState((prevState) => ({
      ...prevState,
      [key]: event.target.checked,
    }));
  };

  // 약관 확인 function
  const termsModalOpenEvent = (terms) => {
    if (terms === "policy") {
      window.open("https://glowide.notion.site/d0f6d19f69f24628ad53b032674e5933");
    } else if (terms === "privacy") {
    } else if (terms === "marketing") {
    }
  };

  // alert message text state
  const [alertState, setAlertState] = useState("");
  const [dialogErrorMessageState, dialogErrorMessageSetState] = useState("");
  // alert dialog message custom hook
  // const { alertDialogCall, DialogAlertComponent } = DialogAlertMessage(dialogErrorMessageState);
  const {handleClick, AlertComponent} = CustomizedSnackbars(alertState, dialogErrorMessageState);

  // 1. 이메일을 입력하지 않음
  // 2. 이메일 형식이 아님
  // 3. 이미 가입된 이메일
  // 4. 비밀번호를 입력하지 않음
  // 5. 비밀번호 형식이 아님
  // 6. 비밀번호 확인을 입력하지 않음
  // 7. 비밀번호와 비밀번호 확인이 일치하지 않음

  const emailInputBlurEvent = () => {
    if (!userInfoState.email) {
      emailErrorCodeSetState(1);
      emailErrorMsgSetState("이메일을 입력해주세요.");
    } else if (!emailRegEx.test(userInfoState.email)) {
      emailErrorCodeSetState(1);
      emailErrorMsgSetState("이메일 형식이 올바르지 않습니다.");
    } else {
      emailErrorCodeSetState(0);
      emailErrorMsgSetState("");
    }
  };

  const passwordInputBlurEvent = () => {
    if (!userInfoState.password) {
      passwordErrorCodeSetState(1);
      passwordErrorMsgSetState("비밀번호를 입력해주세요.");
    }
  };

  const passwordConfirmInputBlurEvent = () => {
    if (!userInfoState.passwordConfirm) {
      passwordConfirmErrorCodeSetState(1);
      passwordConfirmErrorMsgSetState("비밀번호를 다시 입력해주세요.");
    }
  };

  const incEmailInputBlurEvent = () => {
    if (!incUserInfoState.email) {
      emailErrorCodeSetState(2);
      emailErrorMsgSetState("이메일을 입력해주세요.");
    } else if (!emailRegEx.test(incUserInfoState.email)) {
      emailErrorCodeSetState(2);
      emailErrorMsgSetState("이메일 형식이 올바르지 않습니다.");
    } else {
      emailErrorCodeSetState(0);
      emailErrorMsgSetState("");
    }
  };

  const incPasswordInputBlurEvent = () => {
    if (!incUserInfoState.password) {
      passwordErrorCodeSetState(2);
      passwordErrorMsgSetState("비밀번호를 입력해주세요.");
    }
  };

  const incPasswordConfirmInputBlurEvent = () => {
    if (!incUserInfoState.passwordConfirm) {
      passwordConfirmErrorCodeSetState(2);
      passwordConfirmErrorMsgSetState("비밀번호를 다시 입력해주세요.");
    }
  };

  // user info data request function
  const requestUserInfo = useMutation(RequestDefaultUserInfo, {
    onSuccess: (data) => {
      queryClient.setQueryData("userInfo", data);
      dialogOpen();
    },
    onError: (error) => {
      console.log(error);
    },
    onSettled: () => {
      loadSetState(false);
    },
  });

  // 회원 가입 실패 > 에러 메시지 출력
  const signUpFailure = () => {
    setAlertState("error");
    dialogErrorMessageSetState("회원 가입에 실패했습니다.");
    handleClick();
  };

  // 개인 회원 sign up function
  const defaultSignUpMutation = useMutation(
    (token) =>
      RequestSignUp({
        token: token,
        userInfo: {
          userType: "default",
          accountType: "email",
          email: userInfoState.email,
          ageValidation: userInfoState.ageValidation,
          policyAgreement: userInfoState.policyAgreement,
          privacyAgreement: userInfoState.privacyAgreement,
          marketingAgreement: { email: userInfoState.marketingAgreement, sms: userInfoState.marketingAgreement },
        },
      }),
    {
      onSuccess: (data) => {
        // 회원 가입 성공
        if (data === "done") {
          requestUserInfo.mutate();
          emailVarifiedSetState(doGetEmailValidation);
          // 서버에서 오류 메시지 출력 시 firebase user 삭제
        } else {
          doDeleteUser({ loadSetState: loadSetState, callBack: signUpFailure });
        }
      },
      onError: (error) => {
        doDeleteUser({ loadSetState: loadSetState, callBack: signUpFailure });
      },
    }
  );

  // 기업 회원 sign up function
  const incSignUpMutation = useMutation(
    (token) =>
      RequestSignUp({
        token: token,
        userInfo: {
          userType: "incUser",
          accountType: "email",
          email: incUserInfoState.email,
          companyName: incUserInfoState.companyName,
          companyHomepage: incUserInfoState.homepageUrl,
          ageValidation: incUserInfoState.ageValidation,
          policyAgreement: incUserInfoState.policyAgreement,
          privacyAgreement: incUserInfoState.privacyAgreement,
          marketingAgreement: { email: incUserInfoState.marketingAgreement, sms: incUserInfoState.marketingAgreement },
        },
      }),
    {
      onSuccess: (data) => {
        // 회원 가입 성공
        if (data === "done") {
          loadSetState(false);
          emailVarifiedSetState(doGetEmailValidation);
          incUserDialogOpen();
          // 서버에서 오류 메시지 출력 시 firebase user 삭제
        } else {
          doDeleteUser({ loadSetState: loadSetState, callBack: signUpFailure });
        }
      },
      onError: (error) => {
        doDeleteUser({ loadSetState: loadSetState, callBack: signUpFailure });
      },
    }
  );

  // 개인 회원 가입 function

  // 1. 이메일을 입력하지 않음
  // 2. 이메일 형식이 아님
  // 3. 비밀번호를 입력하지 않음
  // 4. 비밀번호 형식이 아님
  // 5. 비밀번호 확인을 입력하지 않음
  // 6. 비밀번호와 비밀번호 확인이 일치하지 않음

  const signUpButtonEvent = () => {
    if (!userInfoState.email) {
      emailRef.current.focus();
    } else if (!emailRegEx.test(userInfoState.email)) {
      emailRef.current.focus();
    } else if (!userInfoState.password) {
      passwordRef.current.focus();
    } else if (!passwordRegEx.test(userInfoState.password)) {
      passwordRef.current.focus();
    } else if (!userInfoState.passwordConfirm) {
      passwordConfirmRef.current.focus();
    } else if (userInfoState.password !== userInfoState.passwordConfirm) {
      passwordConfirmRef.current.focus();
    } else {
      loadSetState(true);
      setAlertState("error");
      doCreateUserWithEmailAndPassword({
        email: userInfoState.email,
        password: userInfoState.password,
        callBack: defaultSignUpMutation.mutate,
        errorMessageSetState: dialogErrorMessageSetState,
        errorMessageCall: handleClick,
        loadSetState: loadSetState,
      });
    }
  };

  // 기업 회원 가입 function

  // 1. 이메일을 입력하지 않음
  // 2. 이메일 형식이 아님
  // 3. 비밀번호를 입력하지 않음
  // 4. 비밀번호 형식이 아님
  // 5. 비밀번호 확인을 입력하지 않음
  // 6. 비밀번호와 비밀번호 확인이 일치하지 않음
  // 7. 회사명을 입력하지 않음
  // 8. 회사 홈페이지 URL을 입력하지 않음

  const incUserSignUpButtonEvent = () => {
    if (!incUserInfoState.email) {
      incUserEmailRef.current.focus();
    } else if (!emailRegEx.test(incUserInfoState.email)) {
      incUserEmailRef.current.focus();
    } else if (!incUserInfoState.password) {
      incUserPasswordRef.current.focus();
    } else if (!passwordRegEx.test(incUserInfoState.password)) {
      incUserPasswordRef.current.focus();
    } else if (!incUserInfoState.passwordConfirm) {
      incUserPasswordConfirmRef.current.focus();
    } else if (incUserInfoState.password !== incUserInfoState.passwordConfirm) {
      incUserPasswordConfirmRef.current.focus();
    } else if (!incUserInfoState.companyName) {
      setAlertState("error");
      dialogErrorMessageSetState("회사명을 입력해주세요.");
      handleClick();
      companyNameRef.current.focus();
    } else if (!incUserInfoState.homepageUrl) {
      setAlertState("error");
      dialogErrorMessageSetState("홈페이지 URL을 입력해주세요.");
      handleClick();
      homepageUrlRef.current.focus();
    } else {
      loadSetState(true);
      setAlertState("error");
      doCreateUserWithEmailAndPassword({
        email: incUserInfoState.email,
        password: incUserInfoState.password,
        callBack: incSignUpMutation.mutate,
        errorMessageSetState: dialogErrorMessageSetState,
        errorMessageCall: handleClick,
        loadSetState: loadSetState,
      });
    }
  };

  // 구글 회원 가입 function
  const googleSignUpMutation = useMutation(
    (credential) =>
      RequestSignUp({
        token: credential.user.accessToken,
        userInfo: {
          userType: "default",
          accountType: "google",
          email: credential.user.email,
          ageValidation: userInfoState.ageValidation,
          policyAgreement: userInfoState.policyAgreement,
          privacyAgreement: userInfoState.privacyAgreement,
          marketingAgreement: { email: userInfoState.marketingAgreement, sms: userInfoState.marketingAgreement },
        },
      }),
    {
      onSuccess: (data) => {
        loadSetState(false);
        dialogOpen();
      },
      onError: (error) => {
        console.log(error);
        doDeleteUser({ loadSetState: loadSetState, callBack: signUpFailure });
      },
    }
  );

  // 구글 회원 가입 버튼 클릭 function
  const googleSignUpButtonEvent = () => {
    setAlertState("error");
    loadSetState(true);
    doSignInWithGoogle({
      callBack: googleSignUpMutation.mutate,
      errorMessageCall: handleClick,
      errorMessageSetState: dialogErrorMessageSetState
    });
  };

  // 개인 회원 enter key press event
  const onEnterKeyPress = (event) => {
    if (agreementAllChkState) {
      if (event.key === "Enter") {
        signUpButtonEvent();
      }
    }
  };

  // 기업 회원 enter key press event
  const incUserOnEnterKeyPress = (event) => {
    if (incUserAgreementAllChkState) {
      if (event.key === "Enter") {
        incUserSignUpButtonEvent();
      }
    }
  };

  // 개인 회원 / 기업 회원 탭 변경 function
  const signUpContentsSwitcher = () => {
    return (
      <Grid container item rowSpacing={4}>
        <Grid item xs={12}>
          <CustomTabs value={userTypeTabState} onChange={signUpTabChangeEvent} variant="fullWidth" indicatorColor="#4C4C4C"  TabIndicatorProps={{ sx: { transition: "none",  } }}>
            <CustomTab label={userTypeTabState === 0 ? <SelectedTabLabelTypo variant="h6">개인회원</SelectedTabLabelTypo> : <TabLabelTypo variant="h6">개인회원</TabLabelTypo>} value={0} />
            <CustomTab label={userTypeTabState === 1 ? <SelectedTabLabelTypo variant="h6">기업회원</SelectedTabLabelTypo> : <TabLabelTypo variant="h6">기업회원</TabLabelTypo>} value={1} />
          </CustomTabs>
        </Grid>
        <Grid container item>
          {userTypeTabState === 0 ? 
            <SignUpContents userInfoState={userInfoState} emailErrorCode={emailErrorCodeState} passwordErrorCode={passwordErrorCodeState} passwordConfirmErrorCode={passwordConfirmErrorCodeState} emailErrorMsg={emailErrorMsgState} passwordErrorMsg={passwordErrorMsgState} passwordConfirmErrorMsg={passwordConfirmErrorMsgState} emailInputBlurEvent={emailInputBlurEvent} passwordInputBlurEvent={passwordInputBlurEvent} passwordConfirmInputBlurEvent={passwordConfirmInputBlurEvent} valueChangeEvent={valueChangeEvent} onEnterKeyPress={onEnterKeyPress} agreementAllChkState={agreementAllChkState} agreementAllChkEvent={agreementAllChkEvent} mandatoryAgreementChkState={agreementMandatoryChkState} agreementChkEvent={agreementChkEvent} termsModalOpenEvent={termsModalOpenEvent} loadState={loadState} signUpButtonEvent={signUpButtonEvent} googleSignUpButtonEvent={googleSignUpButtonEvent} ref={{ emailRef: emailRef, passwordRef: passwordRef, passwordConfirmRef: passwordConfirmRef }} /> : 
            <IncUserSignUpContents userInfoState={incUserInfoState} emailErrorCode={emailErrorCodeState} passwordErrorCode={passwordErrorCodeState} passwordConfirmErrorCode={passwordConfirmErrorCodeState} emailErrorMsg={emailErrorMsgState} passwordErrorMsg={passwordErrorMsgState} passwordConfirmErrorMsg={passwordConfirmErrorMsgState} emailInputBlurEvent={incEmailInputBlurEvent} passwordInputBlurEvent={incPasswordInputBlurEvent} passwordConfirmInputBlurEvent={incPasswordConfirmInputBlurEvent} valueChangeEvent={incUserValueChangeEvent} onEnterKeyPress={incUserOnEnterKeyPress} agreementAllChkState={incUserAgreementAllChkState} agreementAllChkEvent={incUserAgreementAllChkEvent} mandatoryAgreementChkState={incAgreementMandatoryChkState} agreementChkEvent={incUserAgreementChkEvent} termsModalOpenEvent={termsModalOpenEvent} loadState={loadState} signUpButtonEvent={incUserSignUpButtonEvent} ref={{ emailRef: incUserEmailRef, passwordRef: incUserPasswordRef, passwordConfirmRef: incUserPasswordConfirmRef, companyNameRef: companyNameRef, homepageUrlRef: homepageUrlRef }} />}
        </Grid>
      </Grid>
    );
  };

  return (
    <Fragment>
      <AuthBodyFrame title="회원가입" content={signUpContentsSwitcher()} />
      <DialogWithOneButton width={480} icon={"check"} title={"회원가입 완료"} subtitle={["이메일 인증을 완료하고", <br />, "on1016 서비스를 본격적으로 이용해보세요."]} dialogOpenState={dialogOpenState} buttonText="확인" buttonFunc={linkToEmailVerification} />
      <DialogWithOneButton width={480} icon={"check"} title={"회원가입 완료"} subtitle={["안전한 서비스 거래를 위한 가입 승인 절차가 있으며", <br />, "승인 여부는 영업일 기준 1~2일 소요됩니다."]} dialogOpenState={incUserDialogOpenState} buttonText="확인" buttonFunc={incUserSignOut} />
      {/*<DialogAlertComponent />*/}
      <AlertComponent/>
    </Fragment>
  );
}
