import React from "react";

import ProjectPreviewAppBar from "../../Block/Appbar/ProjectPreviewAppBar";
import ProjectPreview from "../../containers/Service/Project/ProjectPreviewContainer";

export default function ProjectPreviewView() {
  return (
    <React.Fragment>
      <ProjectPreviewAppBar />
      <ProjectPreview />
    </React.Fragment>
  );
}
