import React from "react";

import AdminProjectDetail from "../../containers/Admin/AdminProjectDetailContainer";
import RequireAuthRoute from "../../routes/requireAuth";

export default function AdminProjectDetailView() {
  return (
    <React.Fragment>
      <RequireAuthRoute Component={AdminProjectDetail} />
    </React.Fragment>
  );
}
