import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { RequestProjectDetail, RequestBrandInfo } from "../../utils/adminRequestToSrv";

import ProjectDetailBodyFrame from "../../Block/Project/ProjectDetailBodyFrame";
import ProjectDetailInfo from "../../Block/Project/ProjectDetailInfo";
import ProjectDetailMenuBox from "../../Block/Project/ProjectDetailMenuBox";
import ProjectDetailBrandInfo from "../../Block/Project/ProjectDetailDialogContents";

import AdminBodyFrame from "../../Block/Admin/AdminBodyFrame";

import DialogMessage, { DialogAlertMessage } from "../../components/DialogMessage";
import { ComplexDialogWithOneButton } from "../../components/DialogWithButton";
import LoadingComponent from "../../components/LoadingComponent";

import Container from "@mui/material/Container";

// 프로젝트 상세 페이지
export default function AdminProjectDetailContainer() {
  // 프로젝트 doc id url 파라미터
  const { targetDocId } = useParams();
  // react-query queryClient
  const queryClient = useQueryClient();

  // 브랜드 정보 data load
  const brandInfo = useMutation((incUserUid) => RequestBrandInfo(incUserUid), {
    onSuccess: (data) => {
      queryClient.setQueryData(["projectBrandInfo", targetDocId], data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 프로젝트 상세 data load
  const projectDetailData = useQuery(["projectDetailData", targetDocId], () => RequestProjectDetail(targetDocId), {
    onSuccess: (data) => {
      brandInfo.mutate(data.incUserUid);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 상세 이미지 더보기 state
  const [detailImageOpenState, detailImageOpenSetState] = useState(false);

  // 상세 이미지 더보기 open function
  const detailImageOpenEvent = () => {
    detailImageOpenSetState(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // 상세 이미지 더보기 close function
  const detailImageCloseEvent = () => {
    detailImageOpenSetState(false);
  };

  // dialog message text state
  const [dialogMessageState, dialogMessageSetState] = useState("");
  // dialog message custom hook
  const { dialogCall, DialogComponent } = DialogMessage(dialogMessageState);

  // alert dialog message text state
  const [alertDialogMessageState, alertDialogMessageSetState] = useState("");
  // alert dialog message custom hook
  const { alertDialogCall, DialogAlertComponent } = DialogAlertMessage(alertDialogMessageState);

  // 신청하기 버튼 function (관리자 페이지에서 작동 막음)
  const applicationButtonFunc = () => {
    alertDialogMessageSetState("개인 회원만 신청 가능합니다.");
    alertDialogCall();
  };

  // 공유하기 버튼 function
  const sharingButtonFunc = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          dialogMessageSetState("클립보드에 링크가 복사되었습니다.");
          dialogCall();
        })
        .catch(() => {
          alertDialogMessageSetState("링크 복사하기에 실패하였습니다.");
          alertDialogCall();
        });
    } else {
      alertDialogMessageSetState("클립보드 복사 기능을 지원하지 않는 브라우저입니다.");
      alertDialogCall();
    }
  };

  // 브랜드 정보 dialog open state
  const [brandInfoDialogOpenState, brandInfoDialogOpenSetState] = useState(false);

  // 브랜드 정보 dialog open function
  const brandInfoDialogOpenEvent = () => {
    brandInfoDialogOpenSetState(true);
  };

  // 브랜드 정보 dialog close function
  const brandInfoDialogCloseEvent = () => {
    brandInfoDialogOpenSetState(false);
  };

  // 프로젝트 상세 화면
  if (!queryClient.getQueryData(["projectBrandInfo", targetDocId]) || projectDetailData.isFetching) {
    return <LoadingComponent />;
  } else {
    return (
      <Fragment>
        <AdminBodyFrame
          contentComponent={
            <Container maxWidth="lg">
              <ProjectDetailBodyFrame projectDetailValue={projectDetailData.data} brandValue={queryClient.getQueryData(["projectBrandInfo", targetDocId])} brandInfoDialogOpenEvent={brandInfoDialogOpenEvent}  projectLeftContents={<ProjectDetailInfo projectDetailValue={projectDetailData.data} brandValue={queryClient.getQueryData(["projectBrandInfo", targetDocId])} detailImageOpenState={detailImageOpenState} detailImageOpenEvent={detailImageOpenEvent} detailImageCloseEvent={detailImageCloseEvent} brandInfoDialogOpenEvent={brandInfoDialogOpenEvent} projectAppointedCheck={projectDetailData.data.contactOfManager} />} projectRightContents={<ProjectDetailMenuBox projectDetailValue={projectDetailData.data} applicationState="None" sharingButtonFunc={sharingButtonFunc} applicationButtonFunc={applicationButtonFunc} />} />
              <DialogComponent />
              <DialogAlertComponent />
              <ComplexDialogWithOneButton context={<ProjectDetailBrandInfo brandValue={queryClient.getQueryData(["projectBrandInfo", targetDocId])} />} dialogOpenState={brandInfoDialogOpenState} dialogClose={brandInfoDialogCloseEvent} buttonText="확인" buttonFunc={brandInfoDialogCloseEvent} />
            </Container>
          }
        />
      </Fragment>
    );
  }
}
