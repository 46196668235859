import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import Box from "@mui/material/Box";

const DialogButtonTypography = styled(Typography)({
  padding: "10px 0",
});

const CancelButtonBase = styled(ButtonBase)({
  borderRadius: "10px",
  width: "100%",
});

const CancelButtonTypo = styled(Typography)({
  padding: "22px 0",
  borderRadius: "10px",
  background: "#333333",
  width: "100%",
  height: "60px",
});

const CustomButtonBase = styled(ButtonBase)({
  width: "100%",
  borderRadius: "30px",
});

const ButtonBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "60px",
  border: "2px solid #C3E000",
  borderRadius: "30px",
  background: theme.palette.primary.main,
  filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08))",
  display: "flex",
}));

const CustomButton = styled(Button)(({ theme, maxWidth }) => ({
  width: "100%",
  maxWidth: maxWidth,
  height: "60px",
  borderRadius: "10px",
  boxShadow: "none",
  
  '&:hover': {
    boxShadow: "none",
  }
}));

const CustomButtonTypo = styled(Typography)(({ theme, bordercolor }) => ({
  minWidth: "111px",
  padding: "8px 19px",
  backgroundColor: bordercolor === "reverse" ? theme.palette.secondary.main : "#FFFFFF",
  borderRadius: "6px",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: bordercolor === "secondary" || bordercolor === "reverse" ? theme.palette.secondary.main : theme.palette.error.main,
}));

// contained 버튼 - Dark
export function ContainedButton(props) {
  const { buttonEvent, buttonText, buttonDisabled, maxWidth } = props;
  return (
    <CustomButton variant="contained" color="secondary" onClick={() => buttonEvent()} disabled={buttonDisabled} maxWidth={maxWidth}>
      <DialogButtonTypography variant="subtitle1" fontWeight={500} lineHeight="16px" color="#ffffff">
        {buttonText}
      </DialogButtonTypography>
    </CustomButton>
  );
}

// contained 버튼 - Light
export function LightContainedButton(props) {
  const { buttonEvent, buttonText, maxWidth } = props;
  return (
    <CustomButton variant="contained" color="lightButton" onClick={() => buttonEvent()} maxWidth={maxWidth}>
      <DialogButtonTypography variant="subtitle1" fontWeight={500} lineHeight="16px" color="black.300">
        {buttonText}
      </DialogButtonTypography>
    </CustomButton>
  );
}

// outlined 버튼
export function OutlinedButton(props) {
  const { buttonEvent, buttonText, maxWidth } = props;
  return (
    <CustomButton variant="outlined" color="secondary" onClick={() => buttonEvent()} maxWidth={maxWidth}>
      <DialogButtonTypography variant="subtitle1" fontWeight={500} color="secondary">
        {buttonText}
      </DialogButtonTypography>
    </CustomButton>
  );
}

// cancel 버튼
export function CancelButton(props) {
  const { buttonEvent, buttonText } = props;
  return (
    <CancelButtonBase onClick={() => buttonEvent()}>
      <CancelButtonTypo variant="subtitle1" fontWeight={500} lineHeight="16px" color="#ffffff">
        {buttonText}
      </CancelButtonTypo>
    </CancelButtonBase>
  );
}

// 텍스트 아닌 요소가 있는 버튼
export function ContainedButtonWithComplex(props) {
  const { buttonEvent, buttonText, buttonDisabled } = props;
  return (
    <CustomButtonBase onClick={() => buttonEvent()} disabled={buttonDisabled}>
      <ButtonBox>{buttonText}</ButtonBox>
    </CustomButtonBase>
  );
}

// Typograpy 버튼
export function TypoButton(props) {
  const { buttonText, buttonColor } = props;
  return (
    <CustomButtonTypo variant="body2" fontWeight={500} lineHeight="24px" color={buttonColor === "reverse" ? "#FFFFFF" : buttonColor} bordercolor={buttonColor}>
      {buttonText}
    </CustomButtonTypo>
  );
}
