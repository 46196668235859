import Grid from "@mui/material/Grid";

import { styled } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";

import { YoutubeIcon, NaverBlogIcon, NaverPostIcon, InstagramIcon } from "../../components/SnsIconButton";

const SnsButtonBase = styled(ButtonBase)({
  width: "100%",
  borderRadius: "100px",
});

// 개인 회원 포트폴리오 페이지 SNS 박스
export default function MyPortfolioSNSBox(props) {
  const { portfolioData, size } = props;

  // SNS 링크 새창으로 열기
  const openSnsUrl = (url) => {
    window.open(url, "_blank");
  };

  const snsButton = (logo, label, url) => {
    return (
      <Grid item>
        <SnsButtonBase onClick={() => openSnsUrl(url)}>
          <Grid container justifyContent="center" alignItems="center" columnSpacing={1.5}>
            <Grid item xs="auto">
              {logo}
            </Grid>
          </Grid>
        </SnsButtonBase>
      </Grid>
    );
  };

  return (
    <Grid container item columnSpacing={2}>
      {portfolioData.sns.instagram !== "" ? snsButton(<InstagramIcon size={size} />, "인스타그램", portfolioData.sns.instagram) : null}
      {portfolioData.sns.naverBlog !== "" ? snsButton(<NaverBlogIcon size={size} />, "네이버 블로그", portfolioData.sns.naverBlog) : null}
      {portfolioData.sns.naverPost !== "" ? snsButton(<NaverPostIcon size={size} />, "네이버 포스트", portfolioData.sns.naverPost) : null}
      {portfolioData.sns.youtube !== "" ? snsButton(<YoutubeIcon size={size} />, "유튜브", portfolioData.sns.youtube) : null}
    </Grid>
  );
}
