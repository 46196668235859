// import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { KakaoIconButton, GoogleIconButton } from "../../components/SnsIconButton";
// import NaverIconButton, { KakaoIconButton, GoogleIconButton } from "../../components/SnsIconButton";
import { forwardRef } from "react";

// const NaverDefaultIcon = styled("div")({
//   display: "none",
// });

// SNS 로그인 버튼
export const SignInBelowSection = forwardRef((props) => {
  // export const SignInBelowSection = forwardRef((props, forwardRef) => {
  const { kakaoSignInFunction, googleSignInFunction } = props;
  // const { naverSignInFunction, kakaoSignInFunction, googleSignInFunction } = props;

  return (
    <Grid container item justifyContent="center" alignItems="center" rowSpacing={2}>
      <Grid item>
        <Typography variant="body2" align="center" color="black.600">
          또는
        </Typography>
      </Grid>
      <Grid container item columnSpacing={4} >
        {/* <Grid container item justifyContent="center"> */}
        {/* <Grid container item xs={4} justifyContent="flex-end">
          <Grid item>
            <NaverDefaultIcon id="naverIdLogin" ref={forwardRef} />
            <NaverIconButton buttonFunc={naverSignInFunction} />
          </Grid>
        </Grid> */}
        {/* <Grid container item xs={4} justifyContent="center"> */}
        {/* <Grid item>
          <KakaoIconButton buttonFunc={kakaoSignInFunction} />
        </Grid> */}
        {/* </Grid> */}
        {/* <Grid container item xs={4} justifyContent="flex-start"> */}
        <Grid container item direction="column" alignItems="center">
          <Grid item>
            <GoogleIconButton buttonFunc={googleSignInFunction} />
          </Grid>
          <Grid item>
            <Typography variant="body2" lineHeight="40px" color="black.600">
              구글로 로그인
            </Typography>
          </Grid>
        </Grid>
        {/* </Grid> */}
      </Grid>
    </Grid>
  );
});
