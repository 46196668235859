import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "react-query";

import ProjectDetailBodyFrame from "../../../Block/Project/ProjectDetailBodyFrame";
import ProjectDetailInfo from "../../../Block/Project/ProjectDetailInfo";
import ProjectPreviewMenuBox from "../../../Block/Project/ProjectPreviewMenuBox";
import ProjectDetailBrandInfo from "../../../Block/Project/ProjectDetailDialogContents";

import { ComplexDialogWithOneButton } from "../../../components/DialogWithButton";
import LoadingComponent from "../../../components/LoadingComponent";

import { RequestUserBrandInfo } from "../../../utils/requestToSrv";

// 프로젝트 미리보기 페이지
export default function ProjectPreviewContainer() {
  // 프로젝트 value state
  const [projectValueState, projectValueSetState] = useState({
    title: "",
    channel: "",
    type: "",
    recruitmentStartDate: null,
    recruitmentEndDate: null,
    appointmentDate: null,
    submissionStartDate: null,
    submissionEndDate: null,
    endDate: null,
    recruitmentLimitNumber: null,
    contact: "",
    thumbnail: "",
    detailedImage: "",
    offering: "",
    hashTags: "",
    guide: "",
    visitingInfo: "",
  });

  // 프로젝트 미리보기 데이터 localStorage에서 가져오기
  const previewData = JSON.parse(localStorage.getItem("previewData"));

  // 화면 로드 시 맨 위로 이동
  // 프로젝트 미리보기 데이터 state에 저장
  useEffect(() => {
    window.scrollTo(0, 0);

    projectValueSetState({
      ...previewData,
    });
  }, []);

  // 브랜드 정보 data load
  const brandInfo = useQuery("brandInfo", RequestUserBrandInfo, {
    onError: (error) => {
      console.log(error);
    },
  });

  // 상세 이미지 더보기 state
  const [detailImageOpenState, detailImageOpenSetState] = useState(false);

  // 상세 이미지 더보기 open function
  const detailImageOpenEvent = () => {
    detailImageOpenSetState(true);
  };

  // 상세 이미지 더보기 close function
  const detailImageCloseEvent = () => {
    detailImageOpenSetState(false);
  };

  // 브랜드 정보 dialog open state
  const [brandInfoDialogOpenState, brandInfoDialogOpenSetState] = useState(false);

  // 브랜드 정보 dialog open function
  const brandInfoDialogOpenEvent = () => {
    brandInfoDialogOpenSetState(true);
  };

  // 브랜드 정보 dialog close function
  const brandInfoDialogCloseEvent = () => {
    brandInfoDialogOpenSetState(false);
  };

  // 프로젝트 미리보기 페이지 화면
  if (brandInfo.isLoading) {
    return <LoadingComponent />;
  } else {
    return (
      <Fragment>
        <ProjectDetailBodyFrame projectDetailValue={projectValueState} brandValue={brandInfo.data} brandInfoDialogOpenEvent={brandInfoDialogOpenEvent} projectLeftContents={<ProjectDetailInfo projectDetailValue={projectValueState} brandValue={brandInfo.data} detailImageOpenState={detailImageOpenState} detailImageOpenEvent={detailImageOpenEvent} detailImageCloseEvent={detailImageCloseEvent} brandInfoDialogOpenEvent={brandInfoDialogOpenEvent} />} projectRightContents={<ProjectPreviewMenuBox projectDetailValue={projectValueState} />} />
        <ComplexDialogWithOneButton context={<ProjectDetailBrandInfo brandValue={brandInfo.data} />} dialogOpenState={brandInfoDialogOpenState} dialogClose={brandInfoDialogCloseEvent} buttonText="확인" buttonFunc={brandInfoDialogCloseEvent} />
      </Fragment>
    );
  }
}
