import React from "react";

import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

import { styled } from "@mui/material/styles";

import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

import { logoUrl, footerUrl } from "../../configs/urls";

const LogoButtonBase = styled(ButtonBase)({
  width: "100%",
});

const CustomFooterBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const UpperContainer = styled(Container)({
  padding: "40px 0px",
});

const BelowGridContainer = styled(Grid)({
  padding: "35px 0px",
  backgroundColor: "#F8F8F8",
});

const LogoImg = styled("img")({
  width: "100%",
});

const CompanyRegInfoVerifyTypo = styled(Typography)({
  backgroundColor: "#D9D9D9",
  padding: "3px 6px",
});

const CustomDividerBox = styled(Box)({
  width: 1,
  height: 18,
  backgroundColor: "#E6E6E6",
});

// Footer
export default function UniversalAppFooter() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const LogoClickEvent = () => {
    const userInfo = queryClient.getQueryData("userInfo");
    if (!userInfo) {
      navigate("/");
    } else {
      if (userInfo.userType === "incUser") {
        navigate("/my-page");
      } else {
        navigate("/");
      }
    }
  };

  const policyOpenEvent = () => {
    window.open("https://glowide.notion.site/d0f6d19f69f24628ad53b032674e5933", "_blank");
  };

  const youtubeOpenEvent = () => {
    window.open("https://www.youtube.com/channel/UCYZhx3VIPncNlowMcK_eXbQ", "_blank");
  };

  const instagramOpenEvent = () => {
    window.open("https://instagram.com/reble.official?igshid=NDRkN2NkYzU=", "_blank");
  };

  const companyRegInfoOpenEvent = () => {
    // window.open("https://www.ftc.go.kr/www/bizCommView.do?key=3765&apv_perm_no=2022320022530201061&pageUnit=10&searchCnd=wrkr_no&searchKrwd=7358702463&pageIndex=1", "_blank");
    window.open("http://www.ftc.go.kr/bizCommPop.do?wrkr_no=7358702463", "_blank");
  };

  return (
    <CustomFooterBar position="static" elevation={0}>
      <Grid container>
        <Grid item xs={12}>
          <UpperContainer disableGutters maxWidth="lg">
            <Grid container item direction="row" justifyContent="space-between" alignItems="center">
              <Grid container item xs justifyContent="flex-start" alignItems="center" columnSpacing={5}>
                <Grid item>
                  <LogoButtonBase onClick={() => LogoClickEvent()}>
                    <LogoImg src={logoUrl.logoFooter} alt="로고" />
                  </LogoButtonBase>
                </Grid>
                <Grid item>
                  <ButtonBase>
                    <Typography variant="subtitle2" fontWeight={400} color="#666666">
                      회사소개
                    </Typography>
                  </ButtonBase>
                </Grid>
                <Grid item>
                  <ButtonBase onClick={() => policyOpenEvent()}>
                    <Typography variant="subtitle2" fontWeight={400} color="#666666">
                      이용약관
                    </Typography>
                  </ButtonBase>
                </Grid>
                <Grid item>
                  <ButtonBase>
                    <Typography variant="subtitle2" fontWeight={400} color="#666666">
                      개인정보처리방침
                    </Typography>
                  </ButtonBase>
                </Grid>
                <Grid item>
                  <ButtonBase>
                    <Typography variant="subtitle2" fontWeight={400} color="#666666">
                      고객센터
                    </Typography>
                  </ButtonBase>
                </Grid>
              </Grid>
              <Grid container item xs justifyContent="flex-end" columnSpacing={5}>
                <Grid item>
                  <IconButton onClick={() => youtubeOpenEvent()}>
                    <img src={footerUrl.youtube} alt="유튜브" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => instagramOpenEvent()}>
                    <img src={footerUrl.instagram} alt="인스타그램" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </UpperContainer>
        </Grid>
        <BelowGridContainer item xs={12}>
          <Container disableGutters maxWidth="lg">
            <Grid container justifyContent="space-between">
              <Grid item xs={6}>
                <Typography variant="body2" color="#BFBFBF" fontFamily="NotoSansCJKkr">
                  Copyright © GLOWIDE Co., Ltd. All Rights Reserved.
                </Typography>
              </Grid>
              <Grid container alignItems="center" item xs={6} spacing={2}>
                <Grid item>
                  <Typography variant="body2" color="#999999">
                    글로와이드(주)
                  </Typography>
                </Grid>
                <Grid item>
                  <CustomDividerBox />
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="#999999">
                    대표 : 채승희
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="#999999">
                    서울특별시 관악구 낙성대로 2, 낙성벤처창업센터 B1층 (봉천동)
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="#999999">
                    사업자등록번호 : 735-87-02463
                  </Typography>
                </Grid>
                <Grid item>
                  <ButtonBase onClick={() => companyRegInfoOpenEvent()}>
                    <CompanyRegInfoVerifyTypo variant="body2" color="#999999">
                      사업자정보확인
                    </CompanyRegInfoVerifyTypo>
                  </ButtonBase>
                </Grid>
                <Grid item>
                  <CustomDividerBox />
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="#999999">
                    통신판매번호 : 2022-서울관악-1034
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </BelowGridContainer>
      </Grid>
    </CustomFooterBar>
  );
}
