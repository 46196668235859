import React from "react";
import { styled } from "@mui/material/styles";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { CancelIconButton } from "../../components/AdminComponents";

const ModalContainerBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});

const EditModalComponentPaper = styled(Paper)({
  width: "30vw",
  minHeight: "40vh",
  padding: "30px",
  borderRadius: "20px",
});

const ModalContainer = styled(Container)({
  height: "100%",
});

const ModalComponentGridContainer = styled(Grid)({
  height: "100%",
});

// admin modal frame
export default function AdminModal(props) {
  const { modalOpenState, modalCloseEvent, ModalTitle, ModalContent } = props;

  return (
    <Modal open={modalOpenState} onClose={() => modalCloseEvent()}>
      <ModalContainerBox>
        <EditModalComponentPaper>
          <ModalContainer maxWidth={false} disableGutters>
            <ModalComponentGridContainer container direction="column" justifyContent="space-evenly" rowSpacing={4}>
              <Grid container item direction="row" alignItems="center" xs={1}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" fontWeight={600}>
                    {ModalTitle}
                  </Typography>
                </Grid>
                <Grid container item justifyContent="flex-end" xs={6}>
                  <Grid item>
                    <CancelIconButton onClickEvent={() => modalCloseEvent()} />
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </Grid>
              {ModalContent}
            </ModalComponentGridContainer>
          </ModalContainer>
        </EditModalComponentPaper>
      </ModalContainerBox>
    </Modal>
  );
}
