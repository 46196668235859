import React, { forwardRef } from "react";

import { styled } from "@mui/material/styles";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const FilterMenuItem = styled(MenuItem)({
  padding: "8px 16px",
});

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.grey[300],
}));

// filtering popover menu - 유형
export const TypeFilteringMenu = forwardRef((props, forwardRef) => {
  const { filterMenuOpenedState, filterItems, selectedFilterItemState, filteringMenuCloseFunc, typeMenuSelectFunc } = props;

  return (
    <Popover
      open={filterMenuOpenedState}
      anchorEl={forwardRef.current}
      onClose={() => filteringMenuCloseFunc()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        sx: {
          width: forwardRef.current === null ? "" : forwardRef.current.offsetWidth,
        },
      }}
    >
      {Object.keys(filterItems).map((item, index) => {
        return (
          <FilterMenuItem key={"type" + index} onClick={() => typeMenuSelectFunc(item)}>
            <Grid container>
              <Grid item>
                <Typography variant="subtitle2" color="black.600" fontWeight={selectedFilterItemState === item ? 700 : 400}>
                  {filterItems[item]}
                </Typography>
              </Grid>
            </Grid>
          </FilterMenuItem>
        );
      })}
    </Popover>
  );
});

// filtering popover menu - 채널
export const ChannelFilteringMenu = forwardRef((props, forwardRef) => {
  const { filterMenuOpenedState, filterItems, selectedFilterItemState, filteringMenuCloseFunc, channelMenuSelectFunc } = props;

  return (
    <Popover
      open={filterMenuOpenedState}
      anchorEl={forwardRef.current}
      onClose={() => filteringMenuCloseFunc()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        sx: {
          width: forwardRef.current === null ? "" : forwardRef.current.offsetWidth,
        },
      }}
    >
      {Object.keys(filterItems).map((item, index) => {
        return (
          <FilterMenuItem key={"channel" + index} onClick={() => channelMenuSelectFunc(item)}>
            <Grid container columnSpacing={1} alignItems="center">
              <Grid item>
                <CustomCheckbox icon={<CheckCircleOutlineIcon />} checkedIcon={<CheckCircleIcon />} variant="contained" checked={selectedFilterItemState.includes(item)} color="secondary" />
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" color="black.600">
                  {filterItems[item]}
                </Typography>
              </Grid>
            </Grid>
          </FilterMenuItem>
        );
      })}
    </Popover>
  );
});

// sorting popover menu - 유형
export const SortingMenu = forwardRef((props, forwardRef) => {
  const { filterMenuOpenedState, sortingItems, selectedFilterItemState, filteringMenuCloseFunc, sortingMenuSelectFunc } = props;

  return (
    <Popover
      open={filterMenuOpenedState}
      anchorEl={forwardRef.current}
      onClose={() => filteringMenuCloseFunc()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        sx: {
          width: forwardRef.current === null ? "" : forwardRef.current.offsetWidth,
        },
      }}
    >
      {Object.keys(sortingItems).map((item, index) => {
        return (
          <FilterMenuItem key={"sorting" + index} onClick={() => sortingMenuSelectFunc(item)}>
            <Grid container>
              <Grid item>
                <Typography variant="subtitle2" color="black.600" fontWeight={selectedFilterItemState === item ? 700 : 400}>
                  {sortingItems[item]}
                </Typography>
              </Grid>
            </Grid>
          </FilterMenuItem>
        );
      })}
    </Popover>
  );
});
