import { forwardRef } from "react";

import { styled } from "@mui/material/styles";

import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import SearchIcon from "@mui/icons-material/Search";

import { SearchCategoryButton } from "../../components/AdminComponents";

const InputBox = styled(Box)({
  border: "1px solid #D9D9D9",
  backgroundColor: "#ffffff",
  borderRadius: "4px",
  padding: "5px 10px",
});

const SearchMenuItem = styled(MenuItem)({
  padding: "8px 16px",
});

// 관리자 페이지 검색 박스
export const AdminSearchBox = forwardRef((props, forwardRef) => {
  const { page, categoryMenuOpenedState, categoryMenuOpenFunc, categoryMenuCloseFunc, categoryMenuSelectFunc, selectedCategoryItemState, searchValueChangeFunc, searchValueState } = props;
  const { categoryButtonRef, searchInputRef } = forwardRef;

  // 개인 회원 검색 카테고리 메뉴
  const userSearchMenuItems = {
    name: "이름",
    contact: "연락처",
    email: "이메일",
    city: "주소",
  };

  // 기업 회원 검색 카테고리 메뉴
  const incUserSearchMenuItems = {
    name: "이름",
    contact: "연락처",
    email: "이메일",
    companyName: "회사명",
  };

  // 프로젝트 검색 카테고리 메뉴
  const projectSearchMenuItems = {
    title: "제목",
    channel: "채널",
    type: "유형",
  };

  // 페이지에 맞는 검색 카테고리 메뉴 아이템 설정
  const searchMenuItems = page === "users" ? userSearchMenuItems : page === "incUsers" ? incUserSearchMenuItems : projectSearchMenuItems;
  // 페이지에 맞는 검색 카테고리 라벨 설정
  const selectedItemLabel = page === "users" ? userSearchMenuItems[selectedCategoryItemState] : page === "incUsers" ? incUserSearchMenuItems[selectedCategoryItemState] : projectSearchMenuItems[selectedCategoryItemState];

  // 카테고리 메뉴
  const CategoryButtonMenu = () => {
    return (
      <Popover
        open={categoryMenuOpenedState}
        anchorEl={categoryButtonRef.current}
        onClose={() => categoryMenuCloseFunc()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            width: categoryButtonRef.current === null ? "" : categoryButtonRef.current.offsetWidth,
          },
        }}
      >
        {Object.keys(searchMenuItems).map((item, index) => {
          return (
            <SearchMenuItem key={"category" + index} onClick={() => categoryMenuSelectFunc(item)}>
              <Grid container justifyContent="center">
                <Grid item>
                  <Typography variant="body2" color="#333333" fontWeight={500}>
                    {searchMenuItems[item]}
                  </Typography>
                </Grid>
              </Grid>
            </SearchMenuItem>
          );
        })}
      </Popover>
    );
  };

  return (
    <Grid item container xs={6} spacing={2} direction="row" justifyContent="flex-end" alignItems="flex-end">
      <Grid item xs={6} lg={2}>
        <SearchCategoryButton ref={categoryButtonRef} categoryMenuOpenedState={categoryMenuOpenedState} categoryMenuOpenFunc={categoryMenuOpenFunc} selectedItemLabel={selectedItemLabel} />
        {CategoryButtonMenu()}
      </Grid>
      <Grid item xs={6} lg={5}>
        <InputBox>
          <Input
            inputRef={searchInputRef}
            placeholder={selectedItemLabel}
            disableUnderline
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            value={searchValueState}
            onChange={() => searchValueChangeFunc()}
          />
        </InputBox>
      </Grid>
    </Grid>
  );
});
