import React from "react";

import { styled } from "@mui/material/styles";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

const BackgroundBox = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  height: "100%",
  background: "#FFFFFF",
}));

const BackgroundImgBox = styled(Box)(({ theme }) => ({
  height: "209px",
  background: theme.palette.black[200],
  marginTop: "84px",
}));

const CustomContainer = styled(Container)({
  minHeight: "100vh",
  padding: "30px 0 90px",
});

const LeftGridItem = styled(Grid)({
  position: "relative",
  top: "-180px",
});

// 개인 회원 포트폴리오 페이지 frame
export default function MyPortfolioBodyFrame(props) {
  const { MyPortfolioLeftContents, MyPortfolioRightContents } = props;
  return (
    <BackgroundBox>
      <BackgroundImgBox></BackgroundImgBox>
      <CustomContainer maxWidth="lg">
        <Grid container alignItems="baseline">
          <LeftGridItem container item xs={3.6} rowSpacing={8}>
            <Grid item xs={12}>
              <Typography variant="h1" fontWeight={700} color="#FFFFFF">
                포트폴리오
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {MyPortfolioLeftContents}
            </Grid>
          </LeftGridItem>
          <Grid container item xs={8.4} alignItems="start">
            {MyPortfolioRightContents}
          </Grid>
        </Grid>
      </CustomContainer>
    </BackgroundBox>
  );
}
