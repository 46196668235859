import React, { Fragment } from "react";

import moment from "moment";

import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import Pagination from "@mui/material/Pagination";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import NaverBlogTag, { NaverPostTag, InstagramTag, YoutubeTag, ProjectTypeTag } from "../../components/ProjectComponent";

import { MediumThumbnailBox, LargeThumbnailBox } from "../../components/ProjectComponent";
import { NaverBlogIcon, NaverPostIcon, InstagramIcon, YoutubeIcon } from "../../components/SnsIconButton";
import { ProfilePhotoBox } from "../../components/ProjectComponent";
import ToastUIViewer from "../../components/ToastUIViewer";
import { substituteImageUrl } from "../../configs/urls";

const LeftBottomTableCell = styled(TableCell)({
  borderRight: "1px solid #EEEEEE",
  borderRadius: "0 0 0 10px",
  cursor: "pointer",
});

const UpperTableBodyCell = styled(TableCell)({
  borderRight: "1px solid #EEEEEE",
});

const RightBottomTableCell = styled(TableCell)({
  borderRadius: "0 0 10px 0",
});

const IconGridItem = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const TitleNOfferingTypo = styled(Typography)({
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
});

const CustomTableHeadRow = styled(TableRow)({
  backgroundColor: "#E4E5E7",
});

const CustomTableBodyRow = styled(TableRow)({
  backgroundColor: "#FFFFFF",
});

const LeftUpperTableCell = styled(TableCell)({
  borderRadius: "10px 0 0 0",
  borderRight: "1px solid #FFFFFF",
});

const UpperTableHeadCell = styled(TableCell)({
  borderRight: "1px solid #FFFFFF",
});

const RightUpperTableCell = styled(TableCell)({
  borderRadius: "0 10px 0 0",
});

const ProfileBelowTableBodyCell = styled(TableCell)({
  borderRight: "1px solid #EEEEEE",
  cursor: "pointer",
});

const BelowTableBodyCell = styled(TableCell)({
  borderRight: "1px solid #EEEEEE",
});

const CustomTabButtonTypo = styled(Typography)(({ selectedTab, type }) => ({
  padding: "9px 25px",
  backgroundColor: selectedTab === type ? "#333333" : "transparent",
  borderRadius: "100px",
}));

const CustomPagination = styled(Pagination)({
  "& .Mui-selected": {
    background: "#ffffff",
    fontWeight: 700,
  },
});

const DialogButtonTypography = styled(Typography)({
  padding: "7px 20px",
});

const PaginationGridContainer = styled(Grid)({
  position: "relative",
});

const PaginationGridItem = styled(Grid)({
  zIndex: 20,
});

const ButtonGridItem = styled(Grid)({
  position: "absolute",
  right: "0",
});

// 모집 중인 프로젝트 상세 페이지 내 상단 프로젝트 정보 테이블
export default function RecruitingProjectUpperTable(props) {
  const { projectDetailValue, projectDetailPageOpenFunc } = props;

  // 날짜 형식 변환(초 -> 월/일)
  const recuitmentStartDate = moment(projectDetailValue.recruitmentStartDate * 1000).format("MM.DD");
  const recuitmentEndDate = moment(projectDetailValue.recruitmentEndDate * 1000).format("MM.DD");
  const appointmentDate = moment(projectDetailValue.appointmentDate * 1000).format("MM.DD");
  const submissionStartDate = moment(projectDetailValue.submissionStartDate * 1000).format("MM.DD");
  const submissionEndDate = moment(projectDetailValue.submissionEndDate * 1000).format("MM.DD");
  const endDate = moment(projectDetailValue.endDate * 1000).format("MM.DD");

  return (
    <Grid container justifyContent="flex-end" rowSpacing={2}>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <CustomTableHeadRow>
                <LeftUpperTableCell align="center" width="36%">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    제공 내역, 채널, 유형
                  </Typography>
                </LeftUpperTableCell>
                <UpperTableHeadCell align="center" width="8%">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    모집인원
                  </Typography>
                </UpperTableHeadCell>
                <UpperTableHeadCell align="center" width="14%">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    모집 기간
                  </Typography>
                </UpperTableHeadCell>
                <UpperTableHeadCell align="center" width="14%">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    선정일
                  </Typography>
                </UpperTableHeadCell>
                <UpperTableHeadCell align="center" width="14%">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    제출기간
                  </Typography>
                </UpperTableHeadCell>
                <RightUpperTableCell align="center" width="14%">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    종료일
                  </Typography>
                </RightUpperTableCell>
              </CustomTableHeadRow>
            </TableHead>
            <TableBody>
              <CustomTableBodyRow>
                <LeftBottomTableCell onClick={() => projectDetailPageOpenFunc()}>
                  <Grid container alignItems="center" columnSpacing={2}>
                    <Grid item xs="auto">
                      <MediumThumbnailBox thumbnailUrl={projectDetailValue.thumbnail} />
                    </Grid>
                    <Grid container item xs rowSpacing={1}>
                      <Grid container item>
                        <Grid item xs={11}>
                          <TitleNOfferingTypo variant="body1" lineHeight="28px" fontWeight={700} color="#333333">
                            {projectDetailValue.title}
                          </TitleNOfferingTypo>
                        </Grid>
                        <IconGridItem item xs={1}>
                          <KeyboardArrowRightIcon />
                        </IconGridItem>
                        {projectDetailValue.offering ? (
                          <Grid item xs={12}>
                            <TitleNOfferingTypo variant="body2" lineHeight="24px" color="#333333">
                              제공: {projectDetailValue.offering}
                            </TitleNOfferingTypo>
                          </Grid>
                        ) : null}
                      </Grid>
                      <Grid container item columnSpacing={1}>
                        <Grid item>{projectDetailValue.channel === "naverBlog" ? <NaverBlogTag /> : projectDetailValue.channel === "naverPost" ? <NaverPostTag /> : projectDetailValue.channel === "instagram" ? <InstagramTag /> : projectDetailValue.channel === "youtube" ? <YoutubeTag /> : null}</Grid>
                        <Grid item>{projectDetailValue.type === "delivery" ? <ProjectTypeTag projectType="배송형" /> : projectDetailValue.type === "purchase" ? <ProjectTypeTag projectType="구매형" /> : projectDetailValue.type === "visit" ? <ProjectTypeTag projectType="방문형" /> : null}</Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </LeftBottomTableCell>
                <Fragment>
                  <UpperTableBodyCell>
                    <Typography variant="body2" lineHeight="24px" color="#333333" align="center">
                      {projectDetailValue.recruitmentLimitNumber}명
                    </Typography>
                  </UpperTableBodyCell>
                  <UpperTableBodyCell>
                    <Typography variant="body2" lineHeight="24px" fontWeight={700} color="secondary" align="center">
                      {recuitmentStartDate} ~ {recuitmentEndDate}
                    </Typography>
                  </UpperTableBodyCell>
                  <UpperTableBodyCell>
                    <Typography variant="body2" lineHeight="24px" color="#333333" align="center">
                      {appointmentDate}
                    </Typography>
                  </UpperTableBodyCell>
                  <UpperTableBodyCell>
                    <Typography variant="body2" lineHeight="24px" color="#333333" align="center">
                      {submissionStartDate} ~ {submissionEndDate}
                    </Typography>
                  </UpperTableBodyCell>
                  <RightBottomTableCell>
                    <Typography variant="body2" lineHeight="24px" color="#333333" align="center">
                      {endDate}
                    </Typography>
                  </RightBottomTableCell>
                </Fragment>
              </CustomTableBodyRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

// 모집 중인 프로젝트 상세 페이지 하단 참여자 정보 테이블
export function AdminRecruitingProjectBelowTable(props) {
  const { projectDetailValue, applicantsListValue, appointersListValue, belowTabSelectState, belowTabSelectFunc, portfolioPageOpenFunc, snsOpenFunc, applicantsPageState, appointerPageState, applicantsTableDataPageChange, appointerTableDataPageChange, projectDeleteButtonFunc } = props;

  const dataPerPage = 15;

  const applicantsTableBody = () => {
    return Object.keys(applicantsListValue)
      .slice((applicantsPageState - 1) * dataPerPage, (applicantsPageState - 1) * dataPerPage + dataPerPage)
      .map((applicant, index) => {
        return (
          <CustomTableBodyRow key={"applicants" + index}>
            <ProfileBelowTableBodyCell onClick={() => portfolioPageOpenFunc(applicant)}>
              <Grid container alignItems="center" columnSpacing={1}>
                <Grid item xs="auto">
                  <ProfilePhotoBox profilePhotoUrl={applicantsListValue[applicant].profilePhotoUrl} />
                </Grid>
                <Grid container item xs columnSpacing={1}>
                  <Grid item>
                    <Typography variant="body2" lineHeight="24px" fontWeight={700} color="#333333">
                      {applicantsListValue[applicant].name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" lineHeight="24px" color="#333333">
                      {moment().diff(moment(applicantsListValue[applicant].birthDate).format("YYYY"), "years") + 1}세 / {applicantsListValue[applicant].city}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ToastUIViewer markdownText={applicantsListValue[applicant].introduction} />
                  </Grid>
                </Grid>
              </Grid>
            </ProfileBelowTableBodyCell>
            <BelowTableBodyCell>
              <Grid container justifyContent="center" columnSpacing={1}>
                {applicantsListValue[applicant].sns.naverBlog !== "" && projectDetailValue.channel === "naverBlog" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(applicantsListValue[applicant].sns.naverBlog)}>
                      <NaverBlogIcon />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {applicantsListValue[applicant].sns.naverPost !== "" && projectDetailValue.channel === "naverPost" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(applicantsListValue[applicant].sns.naverPost)}>
                      <NaverPostIcon />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {applicantsListValue[applicant].sns.instagram !== "" && projectDetailValue.channel === "instagram" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(applicantsListValue[applicant].sns.instagram)}>
                      <InstagramIcon />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {applicantsListValue[applicant].sns.youtube !== "" && projectDetailValue.channel === "youtube" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(applicantsListValue[applicant].sns.youtube)}>
                      <YoutubeIcon />
                    </ButtonBase>
                  </Grid>
                ) : null}
              </Grid>
            </BelowTableBodyCell>
            <TableCell>
              <Grid container justifyContent="center" columnSpacing={1}>
                {applicantsListValue[applicant].project.project1.image ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(applicantsListValue[applicant].project.project1.url)}>
                      <LargeThumbnailBox thumbnailUrl={applicantsListValue[applicant].project.project1.image ? applicantsListValue[applicant].project.project1.image : substituteImageUrl} />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {applicantsListValue[applicant].project.project2.image ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(applicantsListValue[applicant].project.project2.url)}>
                      <LargeThumbnailBox thumbnailUrl={applicantsListValue[applicant].project.project2.image ? applicantsListValue[applicant].project.project2.image : substituteImageUrl} />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {applicantsListValue[applicant].project.project3.image ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(applicantsListValue[applicant].project.project3.url)}>
                      <LargeThumbnailBox thumbnailUrl={applicantsListValue[applicant].project.project3.image ? applicantsListValue[applicant].project.project3.image : substituteImageUrl} />
                    </ButtonBase>
                  </Grid>
                ) : null}
              </Grid>
            </TableCell>
          </CustomTableBodyRow>
        );
      });
  };

  const appointerTableBody = () => {
    return Object.keys(appointersListValue)
      .slice((appointerPageState - 1) * dataPerPage, (appointerPageState - 1) * dataPerPage + dataPerPage)
      .map((appointer, index) => {
        return (
          <CustomTableBodyRow key={"appointers" + index}>
            <BelowTableBodyCell>
              <Grid container alignItems="center" columnSpacing={1}>
                <Grid item xs="auto">
                  <ProfilePhotoBox profilePhotoUrl={appointersListValue[appointer].profilePhotoUrl} />
                </Grid>
                <Grid container item xs columnSpacing={1}>
                  <Grid item>
                    <Typography variant="body2" lineHeight="24px" fontWeight={700} color="#333333">
                      {appointersListValue[appointer].name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" lineHeight="24px" color="#333333">
                      {moment().diff(moment(appointersListValue[appointer].birthDate).format("YYYY"), "years") + 1}세 / {appointersListValue[appointer].city}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ToastUIViewer markdownText={appointersListValue[appointer].introduction} />
                  </Grid>
                </Grid>
              </Grid>
            </BelowTableBodyCell>
            <BelowTableBodyCell>
              <Grid container justifyContent="center" columnSpacing={1}>
                {appointersListValue[appointer].sns.naverBlog !== "" && projectDetailValue.channel === "naverBlog" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(appointersListValue[appointer].sns.naverBlog)}>
                      <NaverBlogIcon />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {appointersListValue[appointer].sns.naverPost !== "" && projectDetailValue.channel === "naverPost" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(appointersListValue[appointer].sns.naverPost)}>
                      <NaverPostIcon />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {appointersListValue[appointer].sns.instagram !== "" && projectDetailValue.channel === "instagram" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(appointersListValue[appointer].sns.instagram)}>
                      <InstagramIcon />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {appointersListValue[appointer].sns.youtube !== "" && appointer.channel === "youtube" ? (
                  <Grid item>
                    <ButtonBase onClick={() => snsOpenFunc(appointersListValue[appointer].sns.youtube)}>
                      <YoutubeIcon />
                    </ButtonBase>
                  </Grid>
                ) : null}
              </Grid>
            </BelowTableBodyCell>
            <TableCell>
              <Grid container justifyContent="center" columnSpacing={1}>
                {appointersListValue[appointer].project.project1.image ? (
                  <Grid item>
                    <ButtonBase onclick={() => snsOpenFunc(appointersListValue[appointer].project.project1.url)}>
                      <LargeThumbnailBox thumbnailUrl={appointersListValue[appointer].project.project1.image ? appointersListValue[appointer].project.project1.image : substituteImageUrl} />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {appointersListValue[appointer].project.project2.image ? (
                  <Grid item>
                    <ButtonBase onclick={() => snsOpenFunc(appointersListValue[appointer].project.project2.url)}>
                      <LargeThumbnailBox thumbnailUrl={appointersListValue[appointer].project.project2.image ? appointersListValue[appointer].project.project2.image : substituteImageUrl} />
                    </ButtonBase>
                  </Grid>
                ) : null}
                {appointersListValue[appointer].project.project3.image ? (
                  <Grid item>
                    <ButtonBase onclick={() => snsOpenFunc(appointersListValue[appointer].project.project3.url)}>
                      <LargeThumbnailBox thumbnailUrl={appointersListValue[appointer].project.project3.image ? appointersListValue[appointer].project.project3.image : substituteImageUrl} />
                    </ButtonBase>
                  </Grid>
                ) : null}
              </Grid>
            </TableCell>
          </CustomTableBodyRow>
        );
      });
  };

  return (
    <Grid container item rowSpacing={3}>
      <Grid container item xs={4} columnSpacing={1}>
        <Grid item>
          <ButtonBase onClick={() => belowTabSelectFunc("applicants")}>
            <CustomTabButtonTypo variant="body2" type="applicants" selectedTab={belowTabSelectState} color={belowTabSelectState === "applicants" ? "#ffffff" : "#333333"}>
              신청자 ({Object.keys(applicantsListValue).length})
            </CustomTabButtonTypo>
          </ButtonBase>
        </Grid>
        <Grid item>
          <ButtonBase onClick={() => belowTabSelectFunc("appointer")}>
            <CustomTabButtonTypo variant="body2" type="appointer" selectedTab={belowTabSelectState} color={belowTabSelectState === "appointer" ? "#ffffff" : "#333333"}>
              선정자 ({Object.keys(appointersListValue).length}/{projectDetailValue.recruitmentLimitNumber})
            </CustomTabButtonTypo>
          </ButtonBase>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <CustomTableHeadRow>
                <LeftUpperTableCell align="center" width="45%">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    프로필
                  </Typography>
                </LeftUpperTableCell>
                <UpperTableHeadCell align="center">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    채널
                  </Typography>
                </UpperTableHeadCell>
                <RightUpperTableCell align="center" width="30%">
                  <Typography variant="body2" lineHeight="24px" color="#333333">
                    대표 프로젝트
                  </Typography>
                </RightUpperTableCell>
              </CustomTableHeadRow>
            </TableHead>
            <TableBody>
              {/* 신청자 */}
              {belowTabSelectState === "applicants" ? applicantsTableBody() : appointerTableBody()}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <PaginationGridContainer container item justifyContent="center" alignItems="center">
        <PaginationGridItem item>{belowTabSelectState === "applicants" ? <CustomPagination shape="rounded" count={Math.ceil(Object.keys(applicantsListValue).length / dataPerPage)} page={applicantsPageState} onChange={applicantsTableDataPageChange} /> : <CustomPagination shape="rounded" count={Math.ceil(Object.keys(appointersListValue).length / dataPerPage)} page={appointerPageState} onChange={appointerTableDataPageChange} />}</PaginationGridItem>
        <ButtonGridItem container item justifyContent="flex-end" alignItems="center">
          <Grid item>
            <Button variant="contained" color="secondary" fullWidth onClick={() => projectDeleteButtonFunc()}>
              <DialogButtonTypography variant="body2" fontWeight={500} color="#ffffff">
                프로젝트 삭제
              </DialogButtonTypography>
            </Button>
          </Grid>
        </ButtonGridItem>
      </PaginationGridContainer>
    </Grid>
  );
}
