import React from "react";

import moment from "moment";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TableHead from "@mui/material/TableHead";

import NaverBlogTag, { NaverPostTag, InstagramTag, YoutubeTag, ProjectTypeTag } from "../../components/ProjectComponent";
import { ThumbnailBox } from "../../components/ProjectComponent";

const ContainerPaper = styled(Paper)({
  width: "100%",
  borderRadius: "10px",
});

const ListTitleTypo = styled(Typography)({
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
  color: "#222222",
});

const CustomTableHead = styled(TableRow)({
  borderTop: "1px solid #111111"
});

const HeadTableCell = styled(TableCell)({
  color: "#4C4C4C",
  fontSize: "14px",
});

const CustomTableRow = styled(TableRow)({
  cursor: "pointer",
});

const CustomPagination = styled(Pagination)({
  "& .Mui-selected": {
    backgroundColor: "#ffffff",
    fontWeight: 700,
  },
});

// 프로젝트 리스트 frame
export default function ProjectListBodyFrame(props) {
  const { tableHeadContents, tableBodyContents, tableDataArray, dataPerPage, tableDataPageState, tableDataPageChange } = props;

  return (
    <Grid container justifyContent="center" rowSpacing={4.8}>
      <Grid item xs={12}>
        <ContainerPaper elevation={0}>
          <TableContainer>
            <Table>
              <TableHead>{tableHeadContents}</TableHead>
              <TableBody>{tableBodyContents}</TableBody>
            </Table>
          </TableContainer>
        </ContainerPaper>
      </Grid>
      <Grid item>
        <CustomPagination shape="rounded" count={Math.ceil(Object.keys(tableDataArray).length / dataPerPage)} page={tableDataPageState} onChange={tableDataPageChange} />
      </Grid>
    </Grid>
  );
}

export function RecruitingProjectHead() {
  return (
    <CustomTableHead>
      <HeadTableCell align="center">프로젝트명</HeadTableCell>
      <HeadTableCell align="center">채널</HeadTableCell>
      <HeadTableCell align="center">유형</HeadTableCell>
      <HeadTableCell align="center">지원/모집자</HeadTableCell>
      <HeadTableCell align="center">상태</HeadTableCell>
    </CustomTableHead>
  );
}

// 모집 중 프로젝트 리스트
export function RecruitingProjectList(props) {
  const { tableDataArray, tableDataPageState, dataPerPage, tableRowClickFunc } = props;

  // 테이블 데이터 > 페이지 별로 나누기
  return tableDataArray.slice((tableDataPageState - 1) * dataPerPage, (tableDataPageState - 1) * dataPerPage + dataPerPage).map((data, index) => {
    const today = moment().format("YYYY-MM-DD");
    const recruitmentEndDate = data.document.recruitmentEndDate;
    const appointmentDate = data.document.appointmentDate;

    return (
      <CustomTableRow key={"recruitingProject" + index} hover onClick={() => tableRowClickFunc(index + (tableDataPageState - 1) * dataPerPage, data.document.id)}>
        <TableCell align="center" width="50%">
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <ThumbnailBox thumbnailUrl={data.document.thumbnail} />
            </Grid>
            <Grid item xs={10}>
              <ListTitleTypo varitant="body1" lineHeight="28px" align="left">
                {data.document.title}
              </ListTitleTypo>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="center">{data.document.channel === "naverBlog" ? <NaverBlogTag /> : data.document.channel === "naverPost" ? <NaverPostTag /> : data.document.channel === "instagram" ? <InstagramTag /> : data.document.channel === "youtube" ? <YoutubeTag /> : null}</TableCell>
        <TableCell align="center">{data.document.type === "delivery" ? <ProjectTypeTag projectType="배송형" /> : data.document.type === "purchase" ? <ProjectTypeTag projectType="구매형" /> : data.document.type === "visit" ? <ProjectTypeTag projectType="방문형" /> : null}</TableCell>
        <TableCell align="center">
          <Typography variant="body1" fontWeight={400} color="secondary">
            {data.document.recruitmentNumber + "/" + data.document.recruitmentLimitNumber}
          </Typography>
        </TableCell>
        <TableCell align="center" width="12%">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body3" fontWeight={400} color="black.900">
                {moment(recruitmentEndDate * 1000).diff(today, "days") >= 0 ? "모집마감일" : "선정일" }
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight={500} color="secondary">
                {moment(recruitmentEndDate * 1000).diff(today, "days") >= 0 ? moment(recruitmentEndDate * 1000).diff(today, "days") : moment(appointmentDate * 1000).diff(today, "days")}일 남음
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
      </CustomTableRow>
    );
  });
}

export function OngoingProjectHead() {
  return (
    <CustomTableHead>
      <HeadTableCell align="center">프로젝트명</HeadTableCell>
      <HeadTableCell align="center">채널</HeadTableCell>
      <HeadTableCell align="center">유형</HeadTableCell>
      <HeadTableCell align="center">참여자</HeadTableCell>
      <HeadTableCell align="center">제출마감일</HeadTableCell>
    </CustomTableHead>
  );
}

// 진행 중 프로젝트 리스트
export function OngoingProjectList(props) {
  const { tableDataArray, tableDataPageState, dataPerPage, tableRowClickFunc } = props;
  const today = moment().format("YYYY-MM-DD");
  
  // 테이블 데이터 > 페이지 별로 나누기
  return tableDataArray.slice((tableDataPageState - 1) * dataPerPage, (tableDataPageState - 1) * dataPerPage + dataPerPage).map((data, index) => {
    return (
      <CustomTableRow key={"ongoingProject" + index} hover onClick={() => tableRowClickFunc(index + (tableDataPageState - 1) * dataPerPage, data.document.id)}>
        <TableCell align="center" width="50%">
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <ThumbnailBox thumbnailUrl={data.document.thumbnail} />
            </Grid>
            <Grid item xs={10}>
              <ListTitleTypo varitant="body1" lineHeight="28px" align="left">
                {data.document.title}
              </ListTitleTypo>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="center">{data.document.channel === "naverBlog" ? <NaverBlogTag /> : data.document.channel === "naverPost" ? <NaverPostTag /> : data.document.channel === "instagram" ? <InstagramTag /> : data.document.channel === "youtube" ? <YoutubeTag /> : null}</TableCell>
        <TableCell align="center">{data.document.type === "delivery" ? <ProjectTypeTag projectType="배송형" /> : data.document.type === "purchase" ? <ProjectTypeTag projectType="구매형" /> : data.document.type === "visit" ? <ProjectTypeTag projectType="방문형" /> : null}</TableCell>
        <TableCell align="center">{data.document.appointerNumber}</TableCell>
        <TableCell align="center" width="12%">
          <Typography variant="body1" lineHeight="24px" color="secondary">
            {moment(data.document.submissionEndDate * 1000).diff(today, "days")}일 남음
          </Typography>
        </TableCell>
      </CustomTableRow>
    );
  });
}

export function FinishedProjectHead() {
  return (
    <CustomTableHead>
      <HeadTableCell align="center">프로젝트명</HeadTableCell>
      <HeadTableCell align="center">채널</HeadTableCell>
      <HeadTableCell align="center">유형</HeadTableCell>
      <HeadTableCell align="center">참여자</HeadTableCell>
      <HeadTableCell align="center">종료일</HeadTableCell>
    </CustomTableHead>
  );
}

// 종료된 프로젝트 리스트
export function FinishedProjectList(props) {
  const { tableDataArray, tableDataPageState, dataPerPage, tableRowClickFunc } = props;

  // 테이블 데이터 > 페이지 별로 나누기
  return tableDataArray.slice((tableDataPageState - 1) * dataPerPage, (tableDataPageState - 1) * dataPerPage + dataPerPage).map((data, index) => {
    return (
      <CustomTableRow key={"recruitingProject" + index} hover onClick={() => tableRowClickFunc(index + (tableDataPageState - 1) * dataPerPage, data.document.id)}>
        <TableCell align="center" width="50%">
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <ThumbnailBox thumbnailUrl={data.document.thumbnail} />
            </Grid>
            <Grid item xs={10}>
              <ListTitleTypo varitant="body1" lineHeight="28px" align="left">
                {data.document.title}
              </ListTitleTypo>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="center">{data.document.channel === "naverBlog" ? <NaverBlogTag /> : data.document.channel === "naverPost" ? <NaverPostTag /> : data.document.channel === "instagram" ? <InstagramTag /> : data.document.channel === "youtube" ? <YoutubeTag /> : null}</TableCell>
        <TableCell align="center">{data.document.type === "delivery" ? <ProjectTypeTag projectType="배송형" /> : data.document.type === "purchase" ? <ProjectTypeTag projectType="구매형" /> : data.document.type === "visit" ? <ProjectTypeTag projectType="방문형" /> : null}</TableCell>
        <TableCell align="center">{data.document.appointerNumber}</TableCell>
        <TableCell align="center">
          {data.document.activeState === "active" ? (
            <Typography variant="body2" lineHeight="24px">
              {moment(data.document.endDate * 1000).format("YYYY.MM.DD")} 종료
            </Typography>
          ) : (
            <Typography variant="body2" lineHeight="24px">
              {moment(data.document.cancelDate * 1000).format("YYYY.MM.DD")} 취소
            </Typography>
          )}
        </TableCell>
      </CustomTableRow>
    );
  });
}
