import React, { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";

import { RequestAppliedProjectData } from "../../utils/adminRequestToSrv";

import AdminBodyFrame from "../../Block/Admin/AdminBodyFrame";
import AdminAppliedProjectContents from "../../Block/Admin/AdminAppliedProjectContents";

import LoadingComponent from "../../components/LoadingComponent";

import Container from "@mui/material/Container";

// 프로젝트 신청 내역 페이지
export default function AdminAppliedProjectContainer() {
  // 개인 회원 user id url 파라미터
  const { targetUid } = useParams();
  // react-router-dom navigate
  const navigate = useNavigate();
  // react-query queryClient
  const queryClient = useQueryClient();

  // 프로젝트 신청 내역 데이터
  const appliedProjectData = useQuery(["appliedProjectData", targetUid], () => RequestAppliedProjectData(targetUid), {
    onError: (error) => {
      console.log(error);
    },
  });

  // 테이블 페이지 state
  const [tableDataPageState, tableDataPageSetState] = useState(1);

  // 테이블 페이지 변경 함수
  const tableDataPageChange = (event, newPage) => {
    tableDataPageSetState(newPage);
  };

  // 테이블 아이템 클릭 - 프로젝트 상세 페이지로 이동
  const tableRowClickFunc = (projectId) => {
    queryClient.setQueryData(["projectDetailData", projectId], appliedProjectData.data[projectId]);
    navigate("/admin-project-detail/" + projectId);
  };

  if (appliedProjectData.isFetching) {
    return <LoadingComponent />;
  } else {
    return (
      <Fragment>
        <AdminBodyFrame
          contentComponent={
            <Container maxWidth="lg">
              <AdminAppliedProjectContents projectListValue={appliedProjectData.data} tableDataPageState={tableDataPageState} tableDataPageChange={tableDataPageChange} tableRowClickFunc={tableRowClickFunc} />
            </Container>
          }
        />
      </Fragment>
    );
  }
}
