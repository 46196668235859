const { Kakao } = window;
// const { naver } = window;

// export function doInitializeNaver(callbackUrl) {
//   const naverLogin = new naver.LoginWithNaverId({
//     clientId: process.env.REACT_APP_NAVER_CLIENT_ID,
//     callbackUrl: callbackUrl,
//     callbackHandle: true,
//     isPopup: false,
//     loginButton: { color: "green", type: 1, height: 48 },
//   });

// naverLogin.init();

//   naverLogin.getLoginStatus((status) => {
//     if (status) {
//       // 로그인 상태
//       console.log(naverLogin.user);
//       console.log(naverLogin);
//     } else {
//       // 로그아웃 상태
//       console.log("로그아웃 상태");
//     }
//   });
// }

// export function doUnlinkNaver(access_token) {
//   // 토큰 유효성 검증 후 unlink
//   // "https://nid.naver.com/oauth2.0/token?grant_type=delete&client_id=" + process.env.REACT_APP_NAVER_CLIENT_ID + "&client_secret=" + process.env.REACT_APP_NAVER_CLIENT_SECRET + "&access_token=" + accessToken +"&service_provider=NAVER"
//   // token refresh 불가한 지 확인
// }

export function doInitialzeKakao() {
  if (!Kakao.isInitialized()) {
    Kakao.init(process.env.REACT_APP_KAKAO_APP_KEY);
  }
}

export function doSignInWithKakao(afterAuth) {
  Kakao.Auth.login({
    success: function (response) {
      afterAuth(response);
    },
    fail: function (error) {
      console.log("error: " + error);
    },
  });
}

export function doGetUserInfoFromKakao() {
  Kakao.API.request({
    url: "/v2/user/me",
    data: {
      property_keys: ["kakao_account.email", "kakao_account.name"],
    },
  })
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function doGetAuthKakao() {
  if (Kakao.Auth.getAccessToken() === null) {
    return false;
  } else {
    return Kakao.Auth.getAccessToken();
  }
}

export function doSignOutKakao() {
  if (!Kakao.Auth.getAccessToken()) {
    alert("카카오 로그인이 되어있지 않습니다.");
    return false;
  }
  Kakao.Auth.logout(function () {
    console.log("kakao logout check: " + Kakao.Auth.getAccessToken());
    alert("로그아웃이 완료되었습니다.");
    return true;
  });
}

export function doUnlinkKakao() {
  if (!Kakao.Auth.getAccessToken()) {
    alert("카카오 계정으로 로그인 해주세요.");
    return false;
  }
  Kakao.API.request({
    url: "/v1/user/unlink",
  })
    .then(function (response) {
      console.log(response);
      alert("카카오 계정이 해제되었습니다.");
      return true;
    })
    .catch(function (error) {
      console.log(error);
      alert("카카오 계정 해제에 실패하였습니다.");
      return false;
    });
}
