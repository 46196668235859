import React from "react";
import AdminTable from "./AdminTable";
import { HeaderCategory, HeaderIconCategory, TextTableCell, IconTableCell, IconButtonTableCell } from "../../components/AdminComponents";
import moment from "moment";

import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";

import NaverBlogTag, { NaverPostTag, InstagramTag, YoutubeTag, ProjectTypeTag } from "../../components/ProjectComponent";

// 모집 중, 진행 중 프로젝트 목록 테이블
export default function AdminProjectsTable(props) {
  const { tableDataArrayState, tableDataPageState, tableDataPageChange, linkToDetailEvent } = props;

  const dataPerPage = 15;

  const AdminTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <HeaderCategory title="No" align="center" />
          <HeaderCategory title="제목" align="center" width="20%" />
          <HeaderCategory title="제공항목" align="center" />
          <HeaderCategory title="신청현황" align="center" />
          <HeaderCategory title="채널" align="center" width="5%" />
          <HeaderCategory title="유형" align="center" width="5%" />
          <HeaderCategory title="모집기간" align="center" />
          <HeaderCategory title="선정일" align="center" />
          <HeaderCategory title="제출기간" align="center" />
          <HeaderCategory title="종료일" align="center" />
          <HeaderIconCategory title="상세" align="center" icon={<EditIcon />} />
        </TableRow>
      </TableHead>
    );
  };

  const AdminTableBody = () => {
    return (
      <TableBody>
        {tableDataArrayState.slice(tableDataPageState * dataPerPage, tableDataPageState * dataPerPage + dataPerPage).map((data, index) => {
          return (
            <TableRow component={Paper} key={"tableData" + index}>
              <TextTableCell align="center" fontWeight={700} text={index + 1 + tableDataPageState * dataPerPage} />
              <TextTableCell align="left" fontWeight={700} text={data.document.title} />
              <TextTableCell align="center" fontWeight={400} text={data.document.offering} />
              <TextTableCell align="center" fontWeight={400} text={data.document.recruitmentNumber + "/" + data.document.recruitmentLimitNumber} />
              {data.document.channel === "naverBlog" ? <IconTableCell align="center" fontWeight={400} title="네이버 블로그" icon={<NaverBlogTag />} /> : data.document.channel === "naverPost" ? <IconTableCell align="center" fontWeight={400} title="네이버 포스트" icon={<NaverPostTag />} /> : data.document.channel === "instagram" ? <IconTableCell align="center" fontWeight={400} title="인스타그램" icon={<InstagramTag />} /> : data.document.channel === "youtube" ? <IconTableCell align="left" fontWeight={400} title="유튜브" icon={<YoutubeTag />} /> : null}
              {data.document.type === "delivery" ? <IconTableCell align="center" fontWeight={400} title="배송형" icon={<ProjectTypeTag projectType="배송형" />} /> : data.document.type === "purchase" ? <IconTableCell align="center" fontWeight={400} title="구매형" icon={<ProjectTypeTag projectType="구매형" />} /> : data.document.type === "visit" ? <IconTableCell align="center" fontWeight={400} title="방문형" icon={<ProjectTypeTag projectType="방문형" />} /> : null}
              <TextTableCell align="center" fontWeight={400} text={moment(data.document.recruitmentStartDate * 1000).format("YY.MM.DD") + " - " + moment(data.document.recruitmentEndDate * 1000).format("YY.MM.DD")} />
              <TextTableCell align="center" fontWeight={400} text={moment(data.document.appointmentDate * 1000).format("YY.MM.DD")} />
              <TextTableCell align="center" fontWeight={400} text={moment(data.document.submissionStartDate * 1000).format("YY.MM.DD") + " - " + moment(data.document.submissionEndDate * 1000).format("YY.MM.DD")} />
              <TextTableCell align="center" fontWeight={400} text={moment(data.document.endDate * 1000).format("YY.MM.DD")} />
              <IconButtonTableCell title="상세" icon={<EditIcon color="secondary" />} buttonEvent={() => linkToDetailEvent(index + tableDataPageState * dataPerPage, data.document.projectId)} />
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  return <AdminTable TableHeader={<AdminTableHeader />} TableBody={<AdminTableBody />} tableDataArray={tableDataArrayState} dataPerPage={dataPerPage} tableDataPageState={tableDataPageState} tableDataPageChange={tableDataPageChange} />;
}

// 종료 프로젝트 목록 테이블
export function AdminFinishedProjectsTable(props) {
  const { tableDataArrayState, tableDataPageState, tableDataPageChange, cancelReasonDialogOpenFunc, linkToDetailEvent } = props;

  const dataPerPage = 15;

  const AdminTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <HeaderCategory title="No" align="center" />
          <HeaderCategory title="제목" align="center" width="20%" />
          <HeaderCategory title="제공항목" align="center" />
          <HeaderCategory title="신청현황" align="center" />
          <HeaderCategory title="채널" align="center" width="5%" />
          <HeaderCategory title="유형" align="center" width="5%" />
          <HeaderCategory title="모집기간" align="center" />
          <HeaderCategory title="선정일" align="center" />
          <HeaderCategory title="제출기간" align="center" />
          <HeaderCategory title="종료일" align="center" />
          <HeaderIconCategory title="취소여부" align="center" icon={<CancelIcon />} />
          <HeaderIconCategory title="상세" align="center" icon={<EditIcon />} />
        </TableRow>
      </TableHead>
    );
  };

  const AdminTableBody = () => {
    return (
      <TableBody>
        {tableDataArrayState.slice(tableDataPageState * dataPerPage, tableDataPageState * dataPerPage + dataPerPage).map((data, index) => {
          return (
            <TableRow component={Paper} key={"tableData" + index}>
              <TextTableCell align="center" fontWeight={700} text={index + 1 + tableDataPageState * dataPerPage} />
              <TextTableCell align="left" fontWeight={700} text={data.document.title} />
              <TextTableCell align="center" fontWeight={400} text={data.document.offering} />
              <TextTableCell align="center" fontWeight={400} text={data.document.recruitmentNumber + "/" + data.document.recruitmentLimitNumber} />
              {data.document.channel === "naverBlog" ? <IconTableCell align="center" fontWeight={400} title="네이버 블로그" icon={<NaverBlogTag />} /> : data.document.channel === "naverPost" ? <IconTableCell align="center" fontWeight={400} title="네이버 포스트" icon={<NaverPostTag />} /> : data.document.channel === "instagram" ? <IconTableCell align="center" fontWeight={400} title="인스타그램" icon={<InstagramTag />} /> : data.document.channel === "youtube" ? <IconTableCell align="left" fontWeight={400} title="유튜브" icon={<YoutubeTag />} /> : null}
              {data.document.type === "delivery" ? <IconTableCell align="center" fontWeight={400} title="배송형" icon={<ProjectTypeTag projectType="배송형" />} /> : data.document.type === "purchase" ? <IconTableCell align="center" fontWeight={400} title="구매형" icon={<ProjectTypeTag projectType="구매형" />} /> : data.document.type === "visit" ? <IconTableCell align="center" fontWeight={400} title="방문형" icon={<ProjectTypeTag projectType="방문형" />} /> : null}
              <TextTableCell align="center" fontWeight={400} text={moment(data.document.recruitmentStartDate * 1000).format("YY.MM.DD") + " - " + moment(data.document.recruitmentEndDate * 1000).format("YY.MM.DD")} />
              <TextTableCell align="center" fontWeight={400} text={moment(data.document.appointmentDate * 1000).format("YY.MM.DD")} />
              <TextTableCell align="center" fontWeight={400} text={moment(data.document.submissionStartDate * 1000).format("YY.MM.DD") + " - " + moment(data.document.submissionEndDate * 1000).format("YY.MM.DD")} />
              <TextTableCell align="center" fontWeight={400} text={moment(data.document.endDate * 1000).format("YY.MM.DD")} />
              {data.document.activeState === "canceled" ? <IconButtonTableCell title="취소" icon={<CancelIcon color="secondary" />} buttonEvent={() => cancelReasonDialogOpenFunc(data.document.reason)} /> : data.document.activeState === "active" ? <IconTableCell title="종료" icon={<CancelIcon color="disabled" />} /> : <IconTableCell title="삭제" icon={<CancelIcon color="disabled" />} />}
              <IconButtonTableCell title="상세" icon={<EditIcon color="secondary" />} buttonEvent={() => linkToDetailEvent(index + tableDataPageState * dataPerPage, data.document.projectId)} />
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  return <AdminTable TableHeader={<AdminTableHeader />} TableBody={<AdminTableBody />} tableDataArray={tableDataArrayState} dataPerPage={dataPerPage} tableDataPageState={tableDataPageState} tableDataPageChange={tableDataPageChange} />;
}
