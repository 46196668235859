import { initializeApp, getApps, getApp } from "firebase/app";
// import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, setPersistence, browserSessionPersistence, createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, updatePassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, OAuthProvider, signOut, onAuthStateChanged, deleteUser, reauthenticateWithCredential } from "firebase/auth";

const errorCodeMsg = {
  "auth/user-not-found": "입력하신 정보와 일치하는 계정이 없습니다. 다시 확인해주세요.",
  "auth/email-already-in-use": "이미 가입된 이메일 입니다.",
  "auth/wrong-password": "비밀번호가 일치하지 않습니다. 다시 확인해주세요.",
  "auth/popup-closed-by-user": "로그인을 취소하셨습니다.",
};

const firebaseConfigAppKey = {
  apiKey: "AIzaSyDWk4kMcytHkbIo51Lc8qOYDDHvMKQf6fU",
  authDomain: "project-reble-dev.firebaseapp.com",
  projectId: "project-reble-dev",
  storageBucket: "project-reble-dev.appspot.com",
  messagingSenderId: "533580627801",
  appId: "1:533580627801:web:c2ef5c92dd427f304f1648",
  measurementId: "G-DHN3GFSQPL",
};

const provider = new GoogleAuthProvider();

// function alertError(errorCode) {
//   if (errorCode === "auth/user-not-found") {
//     alert(errorCodeMsg["auth/user-not-found"]);
//   } else if (errorCode === "auth/email-already-in-use") {
//     alert(errorCodeMsg["auth/email-already-in-use"]);
//   } else if (errorCode === "auth/wrong-password") {
//     alert(errorCodeMsg["auth/wrong-password"]);
//   } else {
//     alert("알 수 없는 이유로 가입에 실패하였습니다.");
//   }
// }

function doCreateUserWithEmailAndPassword({ email, password, callBack, errorMessageSetState, errorMessageCall, loadSetState }) {
  const app = getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  createUserWithEmailAndPassword(getAuth(), email, password)
    .then((userCredential) => {
      callBack(userCredential.user.accessToken);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      errorMessageSetState(errorCodeMsg[errorCode]);
      errorMessageCall();
      loadSetState(false);
    });
}

function doSignInWithEmailAndPassword({ email, password, callBack, errorMessageCall, errorMessageSetState, loadSetState }) {
  const app = getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  setPersistence(getAuth(), browserSessionPersistence)
    .then(() => {
      signInWithEmailAndPassword(getAuth(), email, password)
        .then((userCredential) => {
          callBack(userCredential);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          errorMessageSetState(errorCodeMsg[errorCode]);
          errorMessageCall();
          loadSetState(false);
        });
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      errorMessageSetState(errorCodeMsg[errorCode]);
      errorMessageCall();
      loadSetState(false);
    });
}

function doSignInWithGoogle({ callBack, errorMessageCall, errorMessageSetState }) {
  const app = getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  signInWithPopup(getAuth(), provider)
    .then((userCredential) => {
      callBack(userCredential);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      errorMessageSetState(errorCodeMsg[errorCode]);
      errorMessageCall();
    });
}

function doSendEmailVarification(callBack) {
  const app = getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  sendEmailVerification(getAuth().currentUser)
    .then(() => {
      callBack();
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

function doOnAuthStateChanged(authedSetState, loadSetState, callBack) {
  const app = getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  onAuthStateChanged(getAuth(), (userCredential) => {
    if (userCredential) {
      callBack({ token: userCredential.accessToken });
    } else {
      authedSetState(false);
      loadSetState(false);
      return false;
    }
  });
}

function doOnGetAuth(loadSetState, successSetState, userSetState, RequestData) {
  const app = getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  onAuthStateChanged(getAuth(), (user) => {
    if (user) {
      successSetState(true);

      RequestData({
        setLoadingState: loadSetState,
        setRequestResult: userSetState,
        formData: {
          type: "sign_in",
          token: user.accessToken,
        },
      });

      return true;
    } else {
      successSetState(false);
      loadSetState(false);
      return false;
    }
  });
}

function doOnGetToken() {
  const app = getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  if (getAuth().currentUser == null) {
    console.log("get_token_none");
    return false;
  } else {
    console.log("get_token_done");
    return getAuth().currentUser;
  }
}

function doGetEmailValidation() {
  const app = getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();
  return getAuth().currentUser.emailVerified;
}

function doSignOut(querySignOut) {
  const app = getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  signOut(getAuth())
    .then(() => {
      querySignOut();
    })
    .catch((error) => {
      console.log(error);
    });
}

function doSignOutWithNoQuery() {
  const app = getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  signOut(getAuth()).catch((error) => {
    console.log(error);
  });
}

function doSendPasswordResetEmail({ loadSetState, email, dialogCall, setDialogMessage, alertDialogCall, setAlertDialogMessage }) {
  const app = getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();
  sendPasswordResetEmail(getAuth(), email)
    .then(() => {
      setDialogMessage("이메일을 전송했습니다.");
      dialogCall();
      loadSetState(false);
    })
    .catch((error) => {
      console.log(error);
      loadSetState(false);
      setAlertDialogMessage(errorCodeMsg[error.code]);
      alertDialogCall();
    });
}

function doUpdatePassword({ loadSetState, callBack, newPassword, setAlertDialogMessage, alertDialogCall }) {
  const app = getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  updatePassword(getAuth().currentUser, newPassword)
    .then(() => {
      loadSetState(false);
      callBack();
    })
    .catch((error) => {
      loadSetState(false);
      console.log(error);
      setAlertDialogMessage(errorCodeMsg[error.code]);
      alertDialogCall();
    });
}

function adminDoSendPasswordResetEmail(loadSetState, email) {
  sendPasswordResetEmail(getAuth(), email)
    .then(() => {
      loadSetState(false);
    })
    .catch((error) => {
      console.log(error);
      loadSetState(false);
    });
}

function doDeleteUser({ loadSetState, callBack, setAlertDialogMessage, alertDialogCall }) {
  const app = getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  deleteUser(getAuth().currentUser)
    .then(() => {
      loadSetState(false);
      callBack();
    })
    .catch((error) => {
      console.log(error);
      setAlertDialogMessage(errorCodeMsg[error.code]);
      alertDialogCall();
      loadSetState(false);
    });
}

export { doCreateUserWithEmailAndPassword, doSignInWithEmailAndPassword, doSignInWithGoogle, doSendEmailVarification, doOnAuthStateChanged, doOnGetAuth, doOnGetToken, doGetEmailValidation, doSignOut, doSignOutWithNoQuery, doUpdatePassword, doSendPasswordResetEmail, adminDoSendPasswordResetEmail, doDeleteUser };
