import axios from "axios";
import { doOnGetToken } from "./firebaseUtils";

const url = "https://asia-northeast3-project-reble-dev.cloudfunctions.net/admin_dev";

const api = axios.create({
  baseURL: url,
  headers: {
    "Content-type": "application/json; charset=UTF-8",
  },
});

const fileHeaders = {
  "Content-Type": "application/octet-stream",
};

// ----------------------------------------------
// ----------------- form data  -----------------
// ----------------------------------------------

// ----------------------------------------------
// auth
// ----------------------------------------------

const signInFormData = {
  type: "auth",
  sub_type: "sign_in",
};

const refreshUserInfoFormData = {
  type: "auth",
  sub_type: "refresh_sign_in",
};

const withdrawalUserFormData = {
  type: "userAuth",
  sub_type: "inactive_user",
};

// ----------------------------------------------
// default user
// ----------------------------------------------
const requestDefaultUsersListFormData = {
  type: "default_user_info",
  sub_type: "stream",
};

const requestDefaultUserPortfolioFormData = {
  type: "profile_default_user",
  sub_type: "profile_read",
};

const requestAppliedProjectFormData = {
  type: "default_user_info",
  sub_type: "join_project_list",
};

// ----------------------------------------------
// inc user
// ----------------------------------------------

const requestIncUsersListFormData = {
  type: "inc_user_info",
  sub_type: "stream",
};

const requestIncUserInfoFormData = {
  type: "inc_user_info",
  sub_type: "read",
};

const updateIncUserAuthStateFormData = {
  type: "userAuth",
  sub_type: "authState_update",
};

const requestPaymentHistoryListFromData = {
  type: "paymentHistory",
  sub_type: "stream",
};

// 230130 기업 회원 멤버십 적용 / 취소

const updateIncUserMembershipApplyFormData = {
  type: "inc_user_info",
  sub_type: "membershipTrue",
};

const updateIncUserMembershipCancelFormData = {
  type: "inc_user_info",
  sub_type: "membershipFalse",
};

// ----------------------------------------------
// project
// ----------------------------------------------

const requestProjectDetailFormData = {
  type: "project",
  sub_type: "project_detail",
};

const requestProjectApplicantsListFormData = {
  type: "project_applicants",
  sub_type: "read",
};

const deleteProjectFormData = {
  type: "project",
  sub_type: "delete",
};

// ----------------------------------------------
// brand
// ----------------------------------------------

const requestBrandInfoFormData = {
  type: "brand",
};

// ----------------------------------------------
// recruting project
// ----------------------------------------------
const requestRecruitingProjectListFormData = {
  type: "project",
  sub_type: "stream",
  projectState: "start",
};
// ----------------------------------------------
// ongoing project
// ----------------------------------------------

const requestOngoingProjectListFormData = {
  type: "project",
  sub_type: "stream",
  projectState: "playing",
};

// ----------------------------------------------
// finished project
// ----------------------------------------------

const requestFinishedProjectListFormData = {
  type: "project",
  sub_type: "stream",
  projectState: "end",
};

// ----------------------------------------------
// banner
// ----------------------------------------------

const requestBannerDataFormData = {
  type: "settings",
};

const requestBannerImageUrlFormData = {
  type: "get_upload_url",
  sub_type: "banner",
};

const updateBannerDataFormData = {
  type: "settings_update",
  sub_type: "bannerUpdate",
};

// ----------------------------------------------
// ----------------  axios code  ----------------
// ----------------------------------------------

// ----------------------------------------------
// auth
// ----------------------------------------------

export const RequestSignIn = ({ token, userType }) => {
  const queryFunc = api.post("/", JSON.stringify({ ...signInFormData, token: token })).then((post) => post.data);
  return queryFunc;
};

export const RequestRefreshUserInfo = ({ token }) => {
  const queryFunc = api.post("/", JSON.stringify({ ...refreshUserInfoFormData, token: token })).then((post) => post.data);
  return queryFunc;
};

// ----------------------------------------------
// default user
// ----------------------------------------------

export const RequestDefaultUsersList = () => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestDefaultUsersListFormData, token: token })).then((post) => post.data);
  return queryFunc;
};

export const RequestPortfolioData = (targetUid) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestDefaultUserPortfolioFormData, token: token, defaultUserUid: targetUid })).then((post) => post.data);
  return queryFunc;
};

export const RequestAppliedProjectData = (targetUid) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestAppliedProjectFormData, token: token, defaultUserUid: targetUid })).then((post) => post.data);
  return queryFunc;
};

export const RequestWithdrawal = (targetUid) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...withdrawalUserFormData, token: token, userUid: targetUid })).then((post) => post.data);
  return queryFunc;
};

// ----------------------------------------------
// inc user
// ----------------------------------------------

export const RequestIncUsersList = () => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestIncUsersListFormData, token: token })).then((post) => post.data);
  return queryFunc;
};

export const RequestIncUserInfo = (targetUid) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestIncUserInfoFormData, token: token, defaultUserUid: targetUid })).then((post) => post.data);
  return queryFunc;
};

export const UpdateIncUserAuthState = (targetUid) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...updateIncUserAuthStateFormData, token: token, userUid: targetUid })).then((post) => post.data);
  return queryFunc;
};

export const RequestPaymentHistoryList = (targetUid) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestPaymentHistoryListFromData, token: token, userUid: targetUid })).then((post) => post.data);
  return queryFunc;
};

// 230130 기업 회원 멤버십 적용 / 취소

export const UpdateIncUserMembershipApply = (targetUid) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...updateIncUserMembershipApplyFormData, token: token, incUseruid: targetUid })).then((post) => post.data);
  return queryFunc;
};

export const UpdateIncUserMembershipCancel = (targetUid) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...updateIncUserMembershipCancelFormData, token: token, incUseruid: targetUid })).then((post) => post.data);
  return queryFunc;
};

// ----------------------------------------------
// project
// ----------------------------------------------

export const RequestProjectDetail = (ProjectId) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestProjectDetailFormData, token: token, projectId: ProjectId })).then((post) => post.data);
  return queryFunc;
};

export const RequestProjectApplicantsList = (projectId) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestProjectApplicantsListFormData, token: token, projectCollection: "applicants", projectId: projectId })).then((post) => post.data);
  return queryFunc;
};

export const RequestProjectAppointersList = (projectId) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestProjectApplicantsListFormData, token: token, projectCollection: "appointer", projectId: projectId })).then((post) => post.data);
  return queryFunc;
};

export const DeleteProject = (projectId) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...deleteProjectFormData, token: token, projectId: projectId })).then((post) => post.data);
  return queryFunc;
};

// ----------------------------------------------
// brand
// ----------------------------------------------

export const RequestBrandInfo = (incUserUid) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestBrandInfoFormData, token: token, incUserUid: incUserUid })).then((post) => post.data);
  return queryFunc;
};

// ----------------------------------------------
// recruting project
// ----------------------------------------------

export const RequestRecruitingProjectList = () => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestRecruitingProjectListFormData, token: token })).then((post) => post.data);
  return queryFunc;
};

// ----------------------------------------------
// ongoing project
// ----------------------------------------------

export const RequestOngoingProjectList = () => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestOngoingProjectListFormData, token: token })).then((post) => post.data);
  return queryFunc;
};

// ----------------------------------------------
// finished project
// ----------------------------------------------

export const RequestFinishedProjectList = () => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestFinishedProjectListFormData, token: token })).then((post) => post.data);
  return queryFunc;
};

// ----------------------------------------------
// banner
// ----------------------------------------------
export const RequestBannerData = () => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestBannerDataFormData, token: token })).then((post) => post.data);
  return queryFunc;
};

export const RequestBannerImageUrl = (fileName) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...requestBannerImageUrlFormData, token: token, fileName: fileName })).then((post) => post.data);
  return queryFunc;
};

export const UpdateBannerData = (bannerData) => {
  const token = doOnGetToken().accessToken;
  const queryFunc = api.post("/", JSON.stringify({ ...updateBannerDataFormData, token: token, bannerList: bannerData })).then((post) => post.data);
  return queryFunc;
};

export function RequestUpload({ formData, signedUrl }) {
  axios.put(signedUrl, formData, { headers: fileHeaders }).catch((error) => {
    console.log(error);
  });
}
