import React, { useState, useRef, Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

import { useMutation, useQuery, useQueryClient } from "react-query";

import { RequestProjectDetail, RequestProjectApplicantsList, RequestProjectAppointersList, UpdateProjectDetailValue, UpdateAppointment, CancelAppointment, UpdateCancelProject } from "../../../utils/requestToSrv";

import ProjectDetailBodyFrame from "../../../Block/IncUserMyPage/ProjectDetailBodyFrame";
import { RecruitingProjectUpperTable } from "../../../Block/IncUserMyPage/RecruitingProjectUpperTable";
import RecruitingProjectBelowTable from "../../../Block/IncUserMyPage/RecruitingProjectBelowTable";
import AppointFixedButton, { CancelAppointFixedButton } from "../../../Block/IncUserMyPage/RecruitingProjectButtons";
import { ProjectCancelDialog } from "../../../Block/IncUserMyPage/ProjectCancelDialog";

import LoadingComponent from "../../../components/LoadingComponent";
import DialogMessage from "../../../components/DialogMessage";
import DialogWithTwoButton from "../../../components/DialogWithButton";

// 모집 중 프로젝트 상세 페이지
export default function RecruitingProjectDetailContainer() {
  // 프로젝트 doc id url 파라미터
  const { targetDocId } = useParams();
  // react-query queryClient
  const queryClient = useQueryClient();

  // 변경 가능한 프로젝트 value state
  const [projectDetailValueState, projectDetailValueSetState] = useState({
    recruitmentStartDate: null,
    recruitmentEndDate: null,
    appointmentDate: null,
    submissionStartDate: null,
    submissionEndDate: null,
    endDate: null,
    recruitmentLimitNumber: 0,
  });

  // 프로젝트 data load
  // value setState
  const recruitingProjectDetailInfo = useQuery(["recruitingProjectDetail", targetDocId], () => RequestProjectDetail(targetDocId), {
    onSuccess: (data) => {
      projectDetailValueSetState({
        ...data,
        recruitmentStartDate: moment(data.recruitmentStartDate * 1000).format("YYYY.MM.DD"),
        recruitmentEndDate: moment(data.recruitmentEndDate * 1000).format("YYYY.MM.DD"),
        appointmentDate: moment(data.appointmentDate * 1000).format("YYYY.MM.DD"),
        submissionStartDate: moment(data.submissionStartDate * 1000).format("YYYY.MM.DD"),
        submissionEndDate: moment(data.submissionEndDate * 1000).format("YYYY.MM.DD"),
        endDate: moment(data.endDate * 1000).format("YYYY.MM.DD"),
        recruitmentLimitNumber: data.recruitmentLimitNumber,
      });
    },
    onError: (error) => console.log(error),
  });

  // 프로젝트 신청자 data load
  const applicantsList = useQuery(["applicantsList", targetDocId], () => RequestProjectApplicantsList(targetDocId), {
    onError: (error) => console.log(error),
  });

  // 프로젝트 선정자 data load
  const appointersList = useQuery(["appointersList", targetDocId], () => RequestProjectAppointersList(targetDocId), {
    onError: (error) => console.log(error),
  });

  // 화면 로드 시 맨 위로 이동
  // 프로젝트 리스트에서 접근한 경우 데이터가 있으므로 바로 value setState
  useEffect(() => {
    window.scrollTo(0, 0);
    if (queryClient.getQueryData(["recruitingProjectDetail", targetDocId])) {
      const recruitingProjectValue = queryClient.getQueryData(["recruitingProjectDetail", targetDocId]);
      projectDetailValueSetState({
        ...recruitingProjectValue,
        recruitmentStartDate: moment(recruitingProjectValue.recruitmentStartDate * 1000).format("YYYY.MM.DD"),
        recruitmentEndDate: moment(recruitingProjectValue.recruitmentEndDate * 1000).format("YYYY.MM.DD"),
        appointmentDate: moment(recruitingProjectValue.appointmentDate * 1000).format("YYYY.MM.DD"),
        submissionStartDate: moment(recruitingProjectValue.submissionStartDate * 1000).format("YYYY.MM.DD"),
        submissionEndDate: moment(recruitingProjectValue.submissionEndDate * 1000).format("YYYY.MM.DD"),
        endDate: moment(recruitingProjectValue.endDate * 1000).format("YYYY.MM.DD"),
        recruitmentLimitNumber: recruitingProjectValue.recruitmentLimitNumber,
      });
    }
  }, []);

  // 변경 가능한 요소 textfield ref
  const recruitmentStartDateRef = useRef(null);
  const recruitmentEndDateRef = useRef(null);
  const appointmentDateRef = useRef(null);
  const submissionStartDateRef = useRef(null);
  const submissionEndDateRef = useRef(null);
  const endDateRef = useRef(null);
  const recruitmentLimitNumberRef = useRef(null);

  // ref 묶음
  const refSet = {
    recruitmentStartDateRef: recruitmentStartDateRef,
    recruitmentEndDateRef: recruitmentEndDateRef,
    appointmentDateRef: appointmentDateRef,
    submissionStartDateRef: submissionStartDateRef,
    submissionEndDateRef: submissionEndDateRef,
    endDateRef: endDateRef,
    recruitmentLimitNumberRef: recruitmentLimitNumberRef,
  };

  // 선택한 신청자, 선정자 array state
  const [applicantsSelectArrayState, applicantsSelectArraySetState] = useState([]);
  const [appointerSelectArrayState, appointerSelectArraySetState] = useState([]);

  // 모집 인원 value set function > 모집 인원 변경 시 숫자만 입력 가능
  const projectRecruiteNumberChange = () => {
    const onlyNums = recruitmentLimitNumberRef.current.value.replace(/[^0-9]/g, "");

    projectDetailValueSetState((prevState) => ({
      ...prevState,
      recruitmentLimitNumber: onlyNums,
    }));
  };

  // datepicker value set function
  const projectDateValueChange = (key, newValue) => {
    projectDetailValueSetState((prevState) => ({
      ...prevState,
      [key]: moment(newValue).format("yyyy.MM.DD"),
    }));
  };

  // 프로젝트 인원/일정 변경 open state
  const [valueRevisingState, valueRevisingSetState] = useState(false);

  // 프로젝트 인원/일정 변경 버튼 function
  const reviseButtonFunc = () => {
    valueRevisingSetState(true);
  };

  // 프로젝트 인원/일정 변경 function
  const updateProjectDetailValues = useMutation(() => UpdateProjectDetailValue(targetDocId, projectDetailValueState), {
    onSuccess: () => {
      valueRevisingSetState(false);
      dialogMessageSetState("변경 사항이 저장되었습니다.");
      dialogCall();
      recruitingProjectDetailInfo.refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 프로젝트 인원/일정 변경 사항 저장 submit button function
  const submitButtonFunc = () => {
    if (projectDetailValueState.recruitmentLimitNumber === 0) {
      dialogMessageSetState("모집 인원은 0명이 될 수 없습니다.");
      dialogCall();
    } else {
      updateProjectDetailValues.mutate();
    }
  };

  // enter key press event
  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      submitButtonFunc();
    }
  };

  // 신청자 / 선정자 탭 선택 state
  const [belowTabSelectState, belowTabSelectSetState] = useState("applicants");

  // 신청자 / 선정자 탭 선택 function
  const belowTabSelectFunc = (tabName) => {
    belowTabSelectSetState(tabName);
  };

  // 신청자 선택 checkbox function
  const applicantsCheckBoxEvent = (applicant) => {
    if (applicantsSelectArrayState.includes(applicant)) {
      applicantsSelectArraySetState((prevState) => prevState.filter((item) => item !== applicant));
    } else {
      applicantsSelectArraySetState((prevState) => [...prevState, applicant]);
    }
  };

  // 선정자 선택 checkbox function
  const appointerCheckBoxEvent = (appointer) => {
    if (appointerSelectArrayState.includes(appointer)) {
      appointerSelectArraySetState((prevState) => prevState.filter((item) => item !== appointer));
    } else {
      appointerSelectArraySetState((prevState) => [...prevState, appointer]);
    }
  };

  // 프로젝트 상세 페이지 이동 function
  const projectDetailPageOpenFunc = () => {
    window.open(`/project-detail/${targetDocId}`);
  };

  // 포트폴리오 페이지 이동 function
  const portfolioPageOpenFunc = (userId) => {
    window.open(`/portfolio/${userId}`);
  };

  // sns 페이지 이동 function
  const snsOpenFunc = (url) => {
    window.open(url);
  };

  // 신청자 테이블 페이지 state
  const [applicantsPageState, applicantsPageSetState] = useState(1);
  // 선정자 테이블 페이지 state
  const [appointerPageState, appointerPageSetState] = useState(1);

  // 신청자 테이블 페이지 변경 function
  const applicantsTableDataPageChange = (event, newPage) => {
    applicantsPageSetState(newPage);
  };

  // 선정자 테이블 페이지 변경 function
  const appointerTableDataPageChange = (event, newPage) => {
    appointerPageSetState(newPage);
  };

  // dialog message text state
  const [dialogMessageState, dialogMessageSetState] = useState("");
  // dialog message custom hook
  const { dialogCall, DialogComponent } = DialogMessage(dialogMessageState);

  // 프로젝트 취소 dialog open state
  const [projectCancelDialogOpenState, projectCancelDialogOpenSetState] = useState(false);

  // 프로젝트 취소 버튼 function
  const projectCancelButtonFunc = () => {
    projectCancelDialogOpenSetState(true);
  };

  // 프로젝트 취소 dialog close function
  const projectCancelDialogCloseFunc = () => {
    projectCancelDialogOpenSetState(false);
  };

  // 프로젝트 취소 사유 textfield ref
  const projectCancelReasonRef = useRef(null);

  // 프로젝트 취소 사유 textfield value state
  const [projectCancelReasonState, projectCancelReasonSetState] = useState("");

  // 프로젝트 취소 function
  const projectCancelFunc = useMutation(() => UpdateCancelProject(targetDocId, projectCancelReasonRef.current.value), {
    onSuccess: () => {
      projectCancelDialogOpenSetState(false);
      dialogMessageSetState(["프로젝트가 취소되었습니다.", <br />, "종료 프로젝트 목록에서 확인해주세요."]);
      dialogCall();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 프로젝트 취소 dialog submit function
  const projectCancelDialogSubmitFunc = () => {
    projectCancelFunc.mutate();
  };

  // 신청자 선정 function
  const appointmentFunc = useMutation(() => UpdateAppointment(targetDocId, applicantsSelectArrayState), {
    onSuccess: () => {
      applicantsList.refetch();
      appointersList.refetch();
      applicantsSelectArraySetState([]);
      appointerSelectArraySetState([]);
      dialogMessageSetState("선정자 목록에 추가되었습니다.");
      dialogCall();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 선정 취소 function
  const cancelAppointmentFunc = useMutation(() => CancelAppointment(targetDocId, appointerSelectArrayState), {
    onSuccess: () => {
      applicantsList.refetch();
      appointersList.refetch();
      applicantsSelectArraySetState([]);
      appointerSelectArraySetState([]);
      cancelAppointDialogOpenSetState(false);
      dialogMessageSetState("선정 취소되었습니다.");
      dialogCall();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 선정자 선정 버튼 function
  const appointButtonFunc = () => {
    appointmentFunc.mutate();
  };

  // 선정 취소 dialog open state
  const [cancelAppointDialogOpenState, cancelAppointDialogOpenSetState] = useState(false);

  // 선정 취소 버튼 function
  const cancelAppointButtonFunc = () => {
    cancelAppointDialogOpenSetState(true);
  };

  // 선정 취소 dialog close function
  const cancelAppointDialogCloseFunc = () => {
    cancelAppointDialogOpenSetState(false);
  };

  // 선정 취소 dialog submit function
  const cancelAppointDialogButtonFunc = () => {
    cancelAppointmentFunc.mutate();
  };

  // 모집 중 프로젝트 상세 페이지 화면
  if (queryClient.getQueryData(["recruitingProjectDetail", targetDocId]) && applicantsList.isFetched && appointersList.isFetched) {
    return (
      <Fragment>
        <ProjectDetailBodyFrame title="모집현황" fixedButton={belowTabSelectState === "applicants" ? <AppointFixedButton onClickEvent={appointButtonFunc} projectDetailValue={queryClient.getQueryData(["recruitingProjectDetail", targetDocId])} appointersList={appointersList.data} applicantsSelectArrayState={applicantsSelectArrayState} /> : <CancelAppointFixedButton onClickEvent={cancelAppointButtonFunc} appointerSelectArrayState={appointerSelectArrayState} />} upperTable={<RecruitingProjectUpperTable ref={refSet} projectDetailOriginalValue={recruitingProjectDetailInfo.data} projectDetailValue={projectDetailValueState} projectDetailPageOpenFunc={projectDetailPageOpenFunc} valueRevisingState={valueRevisingState} reviseButtonFunc={reviseButtonFunc} projectCancelButtonFunc={projectCancelButtonFunc} submitButtonFunc={submitButtonFunc} projectRecruiteNumberChange={projectRecruiteNumberChange} projectDateValueChange={projectDateValueChange} onKeyPress={onKeyPress} />} belowTable={<RecruitingProjectBelowTable projectDetailValue={queryClient.getQueryData(["recruitingProjectDetail", targetDocId])} applicantsListValue={applicantsList.data} appointersListValue={appointersList.data} belowTabSelectState={belowTabSelectState} belowTabSelectFunc={belowTabSelectFunc} applicantsSelectArray={applicantsSelectArrayState} appointerSelectArray={appointerSelectArrayState} applicantsCheckBoxEvent={applicantsCheckBoxEvent} appointerCheckBoxEvent={appointerCheckBoxEvent} portfolioPageOpenFunc={portfolioPageOpenFunc} snsOpenFunc={snsOpenFunc} applicantsPageState={applicantsPageState} appointerPageState={appointerPageState} applicantsTableDataPageChange={applicantsTableDataPageChange} appointerTableDataPageChange={appointerTableDataPageChange} />} />
        <ProjectCancelDialog ref={projectCancelReasonRef} projectCancelDialogOpenState={projectCancelDialogOpenState} projectCancelDialogCloseFunc={projectCancelDialogCloseFunc} projectCancelDialogSubmitFunc={projectCancelDialogSubmitFunc} projectCancelReasonState={projectCancelReasonState} projectCancelReasonSetState={projectCancelReasonSetState} />
        <DialogWithTwoButton width={554} icon="notice" title="선정 취소하시겠습니까?" subtitle={["선택한 홍보단들을 선정 취소하시겠습니까?", <br />, "해당 홍보단들은 선정자 목록에서 제거됩니다."]} dialogOpenState={cancelAppointDialogOpenState} dialogClose={cancelAppointDialogCloseFunc} button1Text="취소" button2Text="확인" button1Func={cancelAppointDialogCloseFunc} button2Func={cancelAppointDialogButtonFunc} />
        <DialogComponent />
      </Fragment>
    );
  } else {
    return <LoadingComponent />;
  }
}
