import React from "react";

import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import { RequestPortfolioData } from "../../utils/adminRequestToSrv";

import AdminBodyFrame from "../../Block/Admin/AdminBodyFrame";
import AdminPortfolioProfileBox from "../../Block/Admin/AdminPortfolioProfileBox";
import AdminPortfolioProfileContents from "../../Block/Admin/AdminPortfolioProfileContents";

import LoadingComponent from "../../components/LoadingComponent";

// 개인 회원 포트폴리오
export default function AdminPortfolioContainer() {
  // 개인 회원 user id url 파라미터
  const { targetUid } = useParams();

  // 개인 회원 포트폴리오 data load
  const portfolioData = useQuery(["portfolioData", targetUid], () => RequestPortfolioData(targetUid), {
    onError: (error) => {
      console.log(error);
    },
  });

  // 포트폴리오 정보 화면
  if (portfolioData.isFetching) {
    return <LoadingComponent />;
  } else {
    return (
      <AdminBodyFrame
        contentComponent={
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={3}>
                {<AdminPortfolioProfileBox portfolioData={portfolioData.data} />}
              </Grid>
              <Grid item xs={9}>
                {<AdminPortfolioProfileContents portfolioData={portfolioData.data} />}
              </Grid>
            </Grid>
          </Container>
        }
      />
    );
  }
}
