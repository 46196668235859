import React, { forwardRef } from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { LabeledCheckBoxSet } from "./CheckBoxSet";
import { OutlinedButton, ContainedButton } from "../../components/ButtonComponents";
import { IntroduceTextField } from "../../components/CustomTextField";

const CustomDivider = styled(Divider)({
  backgroundColor: "#EDEDED",
});

// 회원 탈퇴 사유 선택 화면
export const WithdrawalConfirmContent = forwardRef((props, forwardRef) => {
  const { valueState, reasonCheckEvent, additionalReasonEvent, cancelButtonEvent, submitButtonEvent, inputFocusState, inputFocusEvent, inputBlurEvent } = props;
  return (
    <Grid container item justifyContent="center" rowSpacing={3.7}>
      <Grid container item rowSpacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight={700}>
            탈퇴 사유 선택
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomDivider />
        </Grid>
      </Grid>
      <Grid container item rowSpacing={1.5}>
        <Grid container item>
          <Grid item xs={12}>
            <LabeledCheckBoxSet labelText="탈퇴 후 재가입을 위해서" labelVariant="body1" labelColor="#333333" labelWeight={400} checkedState={valueState.reason.rejoin} checkEvent={(event) => reasonCheckEvent("rejoin", event)} />
          </Grid>
          <Grid item xs={12}>
            <LabeledCheckBoxSet labelText="자주 이용하지 않아서" labelVariant="body1" labelColor="#333333" labelWeight={400} checkedState={valueState.reason.noUse} checkEvent={(event) => reasonCheckEvent("noUse", event)} />
          </Grid>
          <Grid item xs={12}>
            <LabeledCheckBoxSet labelText="비슷한 타 사이트를 이용하기 위해서" labelVariant="body1" labelColor="#333333" labelWeight={400} checkedState={valueState.reason.otherService} checkEvent={(event) => reasonCheckEvent("otherService", event)} />
          </Grid>
          <Grid item xs={12}>
            <LabeledCheckBoxSet labelText="고객 지원이 만족스럽지 않아서" labelVariant="body1" labelColor="#333333" labelWeight={400} checkedState={valueState.reason.unsatisfied} checkEvent={(event) => reasonCheckEvent("unsatisfied", event)} />
          </Grid>
        </Grid>
        <Grid container item rowSpacing={0.5}>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight={400}>
              기타
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <IntroduceTextField ref={forwardRef} placeholder="이 외에 불편하셨던 점이 있다면 알려주세요." maxLength={1000} valueState={valueState.reason.additionalReason} valueChange={(event) => additionalReasonEvent(event)} inputFocusState={inputFocusState} inputFocusEvent={() => inputFocusEvent()} inputBlurEvent={() => inputBlurEvent()} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomDivider />
      </Grid>
      <Grid container item columnSpacing={1.7}>
        <Grid item xs={6}>
          <OutlinedButton buttonEvent={cancelButtonEvent} buttonText="취소" />
        </Grid>
        <Grid item xs={6}>
          <ContainedButton buttonEvent={submitButtonEvent} buttonText="탈퇴하기" buttonDisabled={!(valueState.reason.rejoin || valueState.reason.noUse || valueState.reason.otherService || valueState.reason.unsatisfied || valueState.reason.additionalReason)} />
        </Grid>
      </Grid>
    </Grid>
  );
});
