import React from "react";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import CheckIcon from "@mui/icons-material/Check";

const GreyBoxGridContainer = styled(Grid)({
  padding: "15px",
  background: "#F9FAFB",
  borderRadius: "10px",
});

const RedBoxGridContainer = styled(Grid)({
  padding: "18px",
  background: "#FCF7F7",
  borderRadius: "10px",
});

const CustomCheckIcon = styled(CheckIcon)({
  color: "#B3B3B3",
  boxSizing:"content-box",
  fontSize: "10px",
});

// 신청한 프로젝트 : 방문형 - 내 정보 확인 modal
export default function AppliedProjectDialogContents(props) {
  const { userInfoValue } = props;

  return (
    <Grid container rowGap={2.5}>
      <GreyBoxGridContainer container item rowGap={1}>
        <Grid container item>
          <Grid item xs={1.2}>
            <Typography variant="body1" lineHeight="24px" color="black.600">
              이름
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1" lineHeight="24px" fontWeight={500} color={userInfoValue.name === "" ? "#B3B3B3" : "black.300"}>
              {userInfoValue.name === "" ? "정보 수정하기를 눌러 이름을 등록해주세요." : userInfoValue.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={1.2}>
            <Typography variant="body1" lineHeight="24px" color="black.600">
              연락처
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1" lineHeight="24px" fontWeight={500} color={userInfoValue.contact === "" ? "#B3B3B3" : "black.300"}>
              {userInfoValue.contact === "" ? "정보 수정하기를 눌러 연락처를 등록해주세요." : userInfoValue.contact}
            </Typography>
          </Grid>
        </Grid>
      </GreyBoxGridContainer>
      <Grid item xs={12}>
        <Typography variant="subtitle2" fontWeight={700}>
          프로그램 신청 전, 신청정보를 꼭 확인해주세요!
        </Typography>
      </Grid>
      <Grid container item rowGap={1}>
        <Grid container item columnGap={1}>
          <Grid item xs="auto">
            <CustomCheckIcon />
          </Grid>
          <Grid item xs>
            <Typography variant="body2" lineHeight="22px" color="#4C4C4C">
              정보를 잘못 입력하여 오배송 등 프로젝트에 차질이 생기는 경우 신청자에게 과실이 있으며, 이 경우 브랜드는 회원에게 배송비 등을 청구할 수 있으므로 정보를 꼼꼼히 확인해주세요.
            </Typography>
          </Grid>
        </Grid>
        <Grid container item columnGap={1}>
          <Grid item xs="auto">
            <CustomCheckIcon />
          </Grid>
          <Grid item xs>
            <Typography variant="body2" lineHeight="22px" color="#4C4C4C">
              정보는 신청마감일까지 <strong>마이페이지&gt;계정설정</strong>에서 수정 가능합니다.
            </Typography>
          </Grid>
        </Grid>
        <Grid container item columnGap={1}>
          <Grid item xs="auto">
            <CustomCheckIcon />
          </Grid>
          <Grid item xs>
            <Typography variant="body2" lineHeight="22px" color="#4C4C4C">
              선정일 이후 정보 변경이 필요할 경우 브랜드 담당자에게 직접 연락해 주세요. 이 경우 변경이 불가능할 수 있습니다.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

// 신청한 프로젝트 : 배송형 - 내 정보 확인 modal
export function AppliedProjectDialogContentsWithAddress(props) {
  const { userInfoValue } = props;

  return (
    <Grid container rowGap={2.5}>
      <GreyBoxGridContainer container item rowGap={1}>
        <Grid container item>
          <Grid item xs={1.2}>
            <Typography variant="body1" lineHeight="24px" color="black.600">
              이름
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1" lineHeight="24px" fontWeight={500} color={userInfoValue.name === "" ? "#B3B3B3" : "black.300"}>
              {userInfoValue.name === "" ? "정보 수정하기를 눌러 이름을 등록해주세요." : userInfoValue.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={1.2}>
            <Typography variant="body1" lineHeight="24px" color="black.600">
              연락처
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1" lineHeight="24px" fontWeight={500} color={userInfoValue.contact === "" ? "#B3B3B3" : "black.300"}>
              {userInfoValue.contact === "" ? "정보 수정하기를 눌러 연락처를 등록해주세요." : userInfoValue.contact}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={1.2}>
            <Typography variant="body1" lineHeight="24px" color="black.600">
              주소
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1" lineHeight="24px" fontWeight={500} color={userInfoValue.address === "" ? "#B3B3B3" : "black.300"}>
              {userInfoValue.address === "" ? "정보 수정하기를 눌러 주소를 등록해주세요." : userInfoValue.address}
            </Typography>
          </Grid>
        </Grid>
      </GreyBoxGridContainer>
      <Grid item xs={12}>
        <Typography variant="subtitle2" fontWeight={700}>
          프로그램 신청 전, 신청정보를 꼭 확인해주세요!
        </Typography>
      </Grid>
      <Grid container item rowGap={1}>
        <Grid container item columnGap={1}>
          <Grid item xs="auto">
            <CustomCheckIcon />
          </Grid>
          <Grid item xs>
            <Typography variant="body2" lineHeight="22px" color="#4C4C4C">
            정보를 잘못 입력하여 오배송 등 프로젝트에 차질이 생기는 경우 신청자에게 과실이 있으며, 이 경우 브랜드는 회원에게 배송비 등을 청구할 수 있으므로 정보를 꼼꼼히 확인해주세요.
            </Typography>
          </Grid>
        </Grid>
        <Grid container item columnGap={1}>
          <Grid item xs="auto">
            <CustomCheckIcon />
          </Grid>
          <Grid item xs>
            <Typography variant="body2" lineHeight="22px" color="#4C4C4C">
              정보는 신청마감일까지 <strong>마이페이지&gt;계정설정</strong>에서 수정 가능합니다.
            </Typography>
          </Grid>
        </Grid>
        <Grid container item columnGap={1}>
          <Grid item xs="auto">
            <CustomCheckIcon />
          </Grid>
          <Grid item xs>
            <Typography variant="body2" lineHeight="22px" color="#4C4C4C">
              선정일 이후 배송 정보 변경이 필요할 경우 브랜드 담당자에게 직접 연락하여 변경해주세요. 이 경우 변경이 불가능할 수 있습니다.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

// 진행 중 프로젝트 취소 확인 modal
export function OngoingProjectCancelDialogContents() {
  return (
    <Grid container rowGap={2.5}>
      <Grid container item rowGap={3.1}>
        <Grid container item xs={12} columnGap={1}>
          <Grid item xs="auto" display="flex" alignItems="center">
            <CheckIcon color="error" fontSize="15px" />
          </Grid>
          <Grid item xs>
            <Typography variant="subtitle1" lineHeight="28px" fontWeight={500} color="error">
              브랜드 담당자에게 프로젝트 취소 사실을 알리셨나요?
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <RedBoxGridContainer container item rowGap={1}>
        <Grid item>
          <Typography variant="body1" lineHeight="24px" color="black.200">
            ・ 진행 중 취소하시면 브랜드 담당자는 회원님에게 배송비, 제품 또는 서비스 결제를 청구할 수 있습니다.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" lineHeight="24px" color="black.200">
            ・ 프로젝트 취소 전 반드시 브랜드 담당자에게 직접 연락해 취소 사실을 알리셔야 합니다.
          </Typography>
        </Grid>
      </RedBoxGridContainer>
    </Grid>
  );
}
