import React from "react";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";
import Divider from "@mui/material/Divider";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";

import { FlexibleThumbnailBox, ChannelTypeTag } from "../../components/ProjectComponent";


const CustomDivider = styled(Divider)({
  borderColor: "#333333",
});

const ListTitleTypo = styled(Typography)({
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
});

const ButtonTableCell = styled(TableCell)({
  cursor: "pointer",
});

const CustomPagination = styled(Pagination)({
  "& .Mui-selected": {
    backgroundColor: "#ffffff",
    fontWeight: 700,
  },
});

const CustomButtonBase = styled(ButtonBase)(({ customcolor }) => ({
  borderWidth: 1,
  borderStyle: "solid",
  borderRadius: "8px",
  borderColor: customcolor,
  padding: "10px 18px"
}));

// 개인 회원 신청한 프로젝트 목록
export default function AppliedProjectContents(props) {
  const { projectListData, tableRowClickFunc, tableDataPageState, tableDataPageChange, userInfoCheckFunc, applicationCancelFunc, openGuidelineLink } = props;

  const projectListValue = projectListData ? Object.keys(projectListData) : [];

  // 테이블 페이지 당 데이터 수
  const dataPerPage = 10;

  // 가이드
  const guideBox = () => {
    return (
      <Grid container item rowGap={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="black.300">
            꼭 확인해주세요!
          </Typography>
        </Grid>
        <Grid container item>
          <Grid container item columnGap={1.2} alignItems="center">
            <Grid item>
              <Typography variant="body2" lineHeight="24px" color="black.600">
                ・ 프로젝트 선정 결과는 이메일로 전송되므로 선정일에 메일함을 확인해주세요.
              </Typography>
            </Grid>
          </Grid>
          <Grid container item columnGap={1.2} alignItems="center">
            <Grid container item xs="auto" columnGap={1.2}>
              <Grid item>
                <Typography variant="body2" lineHeight="24px" color="black.600">
                  ・ 결과물은 가이드라인에 맞게, 경제적 대가 관계를 명시하여 제출해주세요.
                </Typography>
              </Grid>
              {/* <Grid item>
                <ButtonBase onClick={() => openGuidelineLink()}>
                  <LinkToGuideTypo variant="body3" component="p" lineHeight="24px" color="#ED2727" fontFamily="SpoqaHanSansNeo, Noto Sans KR">
                    방법 확인하기 &gt;
                  </LinkToGuideTypo>
                </ButtonBase>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  // 신청한 프로젝트 리스트
  const ProjectList = () => {
    return (
      <Grid container justifyContent="center" rowSpacing={4.8}>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableBody>
                {projectListValue.slice((tableDataPageState - 1) * dataPerPage, (tableDataPageState - 1) * dataPerPage + dataPerPage).map((data, index) => {
                  return (
                    <TableRow key={"AppliedProject" + index}>
                      <ButtonTableCell width="100" align="center" onClick={() => tableRowClickFunc(data)}>
                        <FlexibleThumbnailBox thumbnailUrl={projectListData[data].thumbnail} size="84px" />
                      </ButtonTableCell>
                      <TableCell>
                        <Grid container rowSpacing={1}>
                          <Grid container item xs={12} columnSpacing={1}>
                            <Grid item>
                              <ChannelTypeTag channelType={projectListData[data].channel} size="24px" fontSize="12px" />
                            </Grid>
                            <Grid item>
                              <ChannelTypeTag channelType={projectListData[data].type} size="24px" fontSize="12px" />
                            </Grid>
                          </Grid>
                          <Grid container item xs={12}>
                            <ButtonBase align="center" width="70%" onClick={() => tableRowClickFunc(data)}>
                              <ListTitleTypo variant="subtitle1" lineHeight="24px" fontWeight={500} align="left">
                                {projectListData[data].title}
                              </ListTitleTypo>
                            </ButtonBase>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="right">
                        <Grid container rowSpacing={1}>
                          <Grid item xs={12}>
                            <CustomButtonBase customcolor="#222222" onClick={() => userInfoCheckFunc(projectListData[data].type)}>
                              <Typography variant="caption1" lineHeight="12px" color="black.200" fontWeight={400}>
                                신청 정보
                              </Typography>
                            </CustomButtonBase>
                          </Grid>
                          <Grid item xs={12}>
                            <CustomButtonBase customcolor="#F45452" onClick={() => applicationCancelFunc(data)}>
                              <Typography variant="caption1" lineHeight="12px" color="error" fontWeight={400}>
                                신청 취소
                              </Typography>
                            </CustomButtonBase>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item>
          <CustomPagination shape="rounded" count={Math.ceil(projectListValue.length / dataPerPage)} page={tableDataPageState} onChange={tableDataPageChange} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container item rowSpacing={3}>
      {guideBox()}
      <Grid item xs={12}>
        <CustomDivider />
      </Grid>
      {ProjectList()}
    </Grid>
  );
}
