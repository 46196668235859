import {useState} from "react";

import {styled} from "@mui/material/styles";
import Snackbar from '@mui/material/Snackbar';
import Alert from "@mui/material/Alert";
import CheckIcon from '@mui/icons-material/Check';

const CustomAlert = styled(Alert)(({theme}) => ({
  borderRadius: "10px",
  "& .MuiAlert-filledSuccess": {
    background: theme.palette.secondary.main
  },
  "& .MuiAlert-filledError": {
    background: theme.palette.error.main
  },
}));

/**
 * 스낵바(토스트)
 * @param severity - 'success' | 'error'
 * @param message
 * @constructor
 */
export function CustomizedSnackbars(severity, message) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const AlertComponent = () => {
    return (
        <Snackbar open={open} onClose={handleClose} autoHideDuration={4000}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
          <CustomAlert icon={severity === 'success' ? <CheckIcon fontSize="inherit"/> : ''} severity={severity}
                       variant="filled" sx={{width: '100%'}}>
            {message}
          </CustomAlert>
        </Snackbar>
    );
  };
  return {handleClick, AlertComponent};
}