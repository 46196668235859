import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import MainView from "../views/MainView";

import SignInView from "../views/Service/SignInView";
import SignUpView from "../views/Service/SignUpView";
import FindPasswordView from "../views/Service/FindPasswordView";
import ChangePasswordView from "../views/Service/ChangePasswordView";

import WithdrawalView from "../views/Service/WithdrawalView";

import MyPageView from "../views/Service/MyPageView";
import MyPortfolioView from "../views/Service/MyPortfolioView";
import UpdatePortfolioView from "../views/Service/UpdatePortfolioView";
import AppliedProjectView from "../views/Service/AppliedProjectView";
import ProjectListView from "../views/Service/ProjectListView";
import PortfolioView from "../views/Service/PortfolioView";

import ProjectDetailView from "../views/Service/ProjectDetailView";
import NewProjectView from "../views/Service/NewProjectView";
import ProjectPreviewView from "../views/Service/ProjectPreviewView";
import RecruitingProjectView from "../views/Service/RecruitingProjectView";
import RecruitingProjectDetailView from "../views/Service/RecruitingProjectDetailView";
import OngoingProjectView from "../views/Service/OngoingProjectView";
import OngoingProjectDetailView from "../views/Service/OngoingProjectDetailView";
import FinishedProjectView from "../views/Service/FinishedProjectView";
import FinishedProjectDetailView from "../views/Service/FinishedProjectDetailView";

import BrandView from "../views/Service/BrandView";

import PaymentView from "../views/Service/PaymentView";

import MembershipView from "../views/Service/MembershipView";
import MembershipInfoView from "../views/Service/MembershipInfoView";

import AdminSignInView from "../views/Admin/AdminSignInView";

import AdminUsersView from "../views/Admin/AdminUsersView";
import AdminAppliedProjectView from "../views/Admin/AdminAppliedProjectView";

import AdminIncUsersView from "../views/Admin/AdminIncUsersView";
import AdminMembershipView from "../views/Admin/AdminMembershipView";

import AdminRecruitingProjectView from "../views/Admin/AdminRecruitingProjectView";
import AdminOngoingProjectView from "../views/Admin/AdminOngoingProjectView";
import AdminFinishedProjectView from "../views/Admin/AdminFinishedProjectView";
import AdminProjectDetailView from "../views/Admin/AdminProjectDetailView";
import AdminRecruitingDetailView from "../views/Admin/AdminRecruitingDetailView";
import AdminOngoingDetailView from "../views/Admin/AdminOngoingDetailView";
import AdminFinishedDetailView from "../views/Admin/AdminFinishedDetailView";

import AdminBannerView from "../views/Admin/AdminBannerView";

import AdminPortfolioView from "../views/Admin/AdminPortfolioView";

// 로그인 여부 판단
import RequireAuthRoute from "./requireAuth";

const createRoutes = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<RequireAuthRoute Component={MainView} />} />
        {/* service */}
        <Route exact path="/signin" element={<SignInView />} />
        <Route exact path="/signup" element={<SignUpView />} />
        <Route exact path="/find-password" element={<FindPasswordView />} />
        <Route exact path="/change-password" element={<RequireAuthRoute Component={ChangePasswordView} />} />

        <Route exact path="/withdrawal" element={<RequireAuthRoute Component={WithdrawalView} />} />

        <Route exact path="/my-page" element={<RequireAuthRoute Component={MyPageView} />} />
        <Route exact path="/my-portfolio" element={<RequireAuthRoute Component={MyPortfolioView} />} />
        <Route exact path="/update-portfolio" element={<RequireAuthRoute Component={UpdatePortfolioView} />} />
        <Route exact path="/applied-project" element={<RequireAuthRoute Component={AppliedProjectView} />} />
        <Route exact path="/portfolio/:targetDocId" element={<RequireAuthRoute Component={PortfolioView} />} />

        <Route exact path="/project-list" element={<RequireAuthRoute Component={ProjectListView} />} />
        <Route exact path="/new-project" element={<RequireAuthRoute Component={NewProjectView} />} />
        <Route exact path="/project-preview" element={<ProjectPreviewView />} />
        <Route exact path="/project-detail/:targetDocId" element={<RequireAuthRoute Component={ProjectDetailView} />} />
        <Route exact path="/recruiting-project" element={<RequireAuthRoute Component={RecruitingProjectView} />} />
        <Route exact path="/recruiting-project/:targetDocId" element={<RequireAuthRoute Component={RecruitingProjectDetailView} />} />
        <Route exact path="/ongoing-project" element={<RequireAuthRoute Component={OngoingProjectView} />} />
        <Route exact path="/ongoing-project/:targetDocId" element={<RequireAuthRoute Component={OngoingProjectDetailView} />} />
        <Route exact path="/finished-project" element={<RequireAuthRoute Component={FinishedProjectView} />} />
        <Route exact path="/finished-project/:targetDocId" element={<RequireAuthRoute Component={FinishedProjectDetailView} />} />

        <Route exact path="/brand" element={<RequireAuthRoute Component={BrandView} />} />

        <Route exact path="/membership" element={<RequireAuthRoute Component={MembershipView} />} />
        <Route exact path="/membership-info" element={<RequireAuthRoute Component={MembershipInfoView} />} />

        <Route exact path="/payment" element={<RequireAuthRoute Component={PaymentView} />} />

        {/* Admin */}
        <Route exact path="/admin-signin" element={<AdminSignInView />} />

        <Route exact path="/admin-users" element={<RequireAuthRoute Component={AdminUsersView} />} />
        <Route exact path="/admin-portfolio/:targetUid" element={<RequireAuthRoute Component={AdminPortfolioView} />} />
        <Route exact path="/admin-applied-project/:targetUid" element={<RequireAuthRoute Component={AdminAppliedProjectView} />} />

        <Route exact path="/admin-incusers" element={<RequireAuthRoute Component={AdminIncUsersView} />} />
        <Route exact path="/admin-membership/:targetUid" element={<RequireAuthRoute Component={AdminMembershipView} />} />

        <Route exact path="/admin-recruiting-project" element={<RequireAuthRoute Component={AdminRecruitingProjectView} />} />
        <Route exact path="/admin-ongoing-project" element={<RequireAuthRoute Component={AdminOngoingProjectView} />} />
        <Route exact path="/admin-finished-project" element={<RequireAuthRoute Component={AdminFinishedProjectView} />} />
        <Route exact path="/admin-project-detail/:targetDocId" element={<RequireAuthRoute Component={AdminProjectDetailView} />} />
        <Route exact path="/admin-recruiting-detail/:targetDocId" element={<RequireAuthRoute Component={AdminRecruitingDetailView} />} />
        <Route exact path="/admin-ongoing-detail/:targetDocId" element={<RequireAuthRoute Component={AdminOngoingDetailView} />} />
        <Route exact path="/admin-finished-detail/:targetDocId" element={<RequireAuthRoute Component={AdminFinishedDetailView} />} />

        <Route exact path="/admin-banner" element={<RequireAuthRoute Component={AdminBannerView} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default createRoutes;
