import React from "react";

import moment from "moment";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";

const BorderBoxGrid = styled(Grid)({
  padding: "68px 0",
  border: "1px solid #D9D9D9",
  borderRadius: "10px",
});

const CustomButtonBase = styled(ButtonBase)({
  borderRadius: "6px",
});

const ButtonTypo = styled(Typography)(({ theme }) => ({
  borderRadius: "6px",
  background: theme.palette.secondary.main,
  padding: "12px 34px",
}));

const CustomTableBody = styled(TableBody)({
  borderTop: "1px solid #222222",
});

const CustomTableCell = styled(TableCell)({
  padding: "18px",
});

const GreyBoxContainerGrid = styled(Grid)({
  padding: "9px 16px",
  background: "#FAFAFA",
  borderRadius: "5px",
});

const CustomPagination = styled(Pagination)({
  "& .Mui-selected": {
    background: "#ffffff",
    fontWeight: 700,
  },
});

// 멤버 아닌 기업 회원의 멤버십 정보
export default function NoMembershipContents(props) {
  const { membershipInfo, tablePageState, tablePageChange, linkToMembershipInfo } = props;

  const dataPerPage = 15;

  const membershipInfoArray = Object.keys(membershipInfo);

  const MembershipInfoContext = () => {
    return (
      <Grid container item rowGap={2.3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" lineHeight="30px" fontWeight={700} color="black.200">
            이용중인 멤버십 정보
          </Typography>
        </Grid>
        <BorderBoxGrid container item justifyContent="center" rowGap={1.8}>
          <Grid container item justifyContent="center" rowGap={0.5}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight={600} color="black.600" align="center">
                이용중인 멤버십이 없습니다.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p" color="black.900" align="center">
                on1016 비즈니스 멤버십으로 브랜드 홍보 효과를 높여보세요!
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <CustomButtonBase onClick={linkToMembershipInfo}>
              <ButtonTypo variant="body2" lineHeight="16px" fontWeight={400} color="#FFFFFF" align="center">
                비즈니스 멤버십 둘러보기
              </ButtonTypo>
            </CustomButtonBase>
          </Grid>
        </BorderBoxGrid>
      </Grid>
    );
  };

  // 결제 내역 - 결제 이력이 없는 경우
  const MembershipPaymentNoHistoryContext = () => {
    return (
      <Grid container item rowGap={2.3} justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="subtitle1" lineHeight="30px" fontWeight={700} color="black.200">
            결제내역
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <CustomTableBody>
                <TableRow>
                  <TableCell>
                    <Grid item padding="60px 0">
                      <Typography variant="subtitle1" lineHeight="22px" fontWeight={600} color="black.600" align="center">
                        결제 내역이 없습니다.
                      </Typography>
                    </Grid>
                  </TableCell>
                </TableRow>
              </CustomTableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  };

  // 결제 내역 - 결제 이력이 있는 경우
  const MembershipPaymentHistoryContext = () => {
    return (
      <Grid container item rowGap={3} justifyContent="center">
        <Grid container item rowGap={2.3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" lineHeight="30px" fontWeight={700} color="black.200">
              결제내역
            </Typography>
          </Grid>
          <Grid container item rowSpacing={2}>
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <CustomTableBody>
                    {membershipInfoArray.slice((tablePageState - 1) * dataPerPage, (tablePageState - 1) * dataPerPage + dataPerPage).map((payment, index) => {
                      return (
                        <TableRow key={"payment" + index}>
                          <CustomTableCell>
                            <Grid container rowGap={2}>
                              <Grid container>
                                <Grid container item xs={6}>
                                  <Grid container>
                                    <Typography variant="body3" lineHeight="22px" color="#444444">
                                      {moment(membershipInfo[payment].paymentDate).format("YYYY.MM.DD")}
                                    </Typography>
                                  </Grid>
                                  <Grid container>
                                    <Typography variant="subtitle1" lineHeight="22px" fontWeight={600} color="black.300">
                                      {membershipInfo[payment].membershipName}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid container item xs={6} alignItems="center">
                                  <Grid container item columnGap={0.3} justifyContent="flex-end" alignItems="baseline">
                                    <Typography variant="h6" fontWeight={700} color="#444444">
                                      {new Intl.NumberFormat("ko-KR").format(membershipInfo[payment].paymentAmount)}
                                    </Typography>
                                    <Typography variant="body1" color="#444444">
                                      원
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <GreyBoxContainerGrid container>
                                <Grid item xs={6}>
                                  <Typography variant="body2" color="black.900">
                                    결제수단
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="body2" fontWeight={600} color="black.600" textAlign="right">
                                    {membershipInfo[payment].paymentCard}
                                  </Typography>
                                </Grid>
                              </GreyBoxContainerGrid>
                            </Grid>
                          </CustomTableCell>
                        </TableRow>
                      );
                    })}
                  </CustomTableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <CustomPagination shape="rounded" count={Math.ceil(membershipInfoArray.length / dataPerPage)} page={tablePageState} onChange={tablePageChange} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container item rowGap={7.5}>
      {MembershipInfoContext()}
      {membershipInfo && membershipInfoArray.length !== 0 ? MembershipPaymentHistoryContext() : MembershipPaymentNoHistoryContext()}
    </Grid>
  );
}
