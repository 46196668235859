import React from "react";

import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const BackgroundBox = styled(Box)({
  minHeight: "100vh",
  height: "100%",
  background: "#FFFFFF",
});

const CustomContainer = styled(Container)({
  minHeight: "100vh",
  padding: "156px 0 90px",
  paddingLeft: "0px !important",
  paddingRight: "0px !important",
});

// 프로젝트 상세 페이지 frame
export default function ProjectDetailBodyFrame(props) {
  const { title, upperTable, belowTable, fixedButton } = props;

  return (
    <BackgroundBox>
      <CustomContainer maxWidth="lg">
        <Grid container rowSpacing={6.2}>
          <Grid container item rowSpacing={5.5}>
            <Grid item xs={12}>
              <Typography variant="h3" lineHeight="28px" color="black.200">
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {upperTable}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {belowTable}
          </Grid>
        </Grid>
      </CustomContainer>
      {fixedButton ? fixedButton : null}
    </BackgroundBox>
  );
}
