import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient, useQuery, useMutation } from "react-query";

import ProjectDetailBodyFrame from "../../../Block/Project/ProjectDetailBodyFrame";
import ProjectDetailInfo from "../../../Block/Project/ProjectDetailInfo";
import ProjectDetailMenuBox from "../../../Block/Project/ProjectDetailMenuBox";
import ProjectDetailBrandInfo, { ProjectDetailUserInfo } from "../../../Block/Project/ProjectDetailDialogContents";

import DialogWithButton, { DialogWithOneButton, ComplexDialogWithOneButton, ComplexDialogWithThreeButton, DialogWithTwoButton } from "../../../components/DialogWithButton";
import DialogMessage, { DialogAlertMessage } from "../../../components/DialogMessage";
import LoadingComponent from "../../../components/LoadingComponent";
import { OngoingProjectCancelDialogContents } from "../../../Block/MyPage/AppliedProjectDialogContents";

import { RequestProjectDetail, RequestBrandInfo, UpdateApplicationProject, requestProjectAppliedCheck, requestProjectAppointedCheck, UpdateCancelProjectApplication } from "../../../utils/requestToSrv";

// 프로젝트 상세 페이지
export default function ProjectDetailContainer() {
  // 프로젝트 doc id url 파라미터
  const { targetDocId } = useParams();

  // react-router-dom navigate
  const navigate = useNavigate();

  // react-query queryClient
  const queryClient = useQueryClient();

  // 브랜드 정보 data load
  const brandInfo = useMutation((brandId) => RequestBrandInfo(brandId), {
    onSuccess: (data) => {
      queryClient.setQueryData("projectBrandInfo", data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 프로젝트 정보 data load
  const projectDetailInfo = useQuery("projectDetailInfo", () => RequestProjectDetail(targetDocId), {
    onSuccess: (data) => {
      const brandId = data.incUserUid;
      brandInfo.mutate(brandId);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 프로젝트 지원 여부 data load
  const projectAppliedCheck = useQuery("projectAppliedCheck", () => requestProjectAppliedCheck(targetDocId), {
    onError: (error) => {
      console.log(error);
    },
  });

  // 프로젝트 선정 여부 data load
  const projectAppointedCheck = useQuery("projectAppointedCheck", () => requestProjectAppointedCheck(targetDocId), {
    onError: (error) => {
      console.log(error);
    },
  });

  // 화면 로드 시 맨 위로 이동
  // 프로젝트 data는 있으나 브랜드 data가 없을 경우 브랜드 data load
  useEffect(() => {
    window.scrollTo(0, 0);
    if (queryClient.getQueryData("projectDetailInfo") && !queryClient.getQueryData("projectBrandInfo")) {
      const brandId = queryClient.getQueryData("projectDetailInfo").incUserUid;
      brandInfo.mutate(brandId);
    }
  }, []);

  // 상세 이미지 더보기 state
  const [detailImageOpenState, detailImageOpenSetState] = useState(false);

  // 상세 이미지 더보기 open function
  const detailImageOpenEvent = () => {
    detailImageOpenSetState(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // 상세 이미지 더보기 close function
  const detailImageCloseEvent = () => {
    detailImageOpenSetState(false);
  };

  // 지원하기 버튼 시 sign in 하지 않은 경우 sign in 페이지로 이동
  const linkToSignIn = () => {
    navigate("/signin");
  };

  // dialog message text state
  const [dialogMessageState, dialogMessageSetState] = useState("");
  // alert dialog message text state
  const [alertDialogMessageState, alertDialogMessageSetState] = useState("");
  // dialog message custom hook
  const { dialogCall, DialogComponent } = DialogMessage(dialogMessageState);
  // alert dialog message custom hook : 클립 보드 복사 기능 지원 안 함
  const { alertDialogCall: shareAlertDialogCall, DialogAlertComponent: ShareDialogAlertComponent } = DialogAlertMessage(alertDialogMessageState);
  // alert dialog message custom hook : sign in 하지 않음
  const { alertDialogCall: requestSignInAlertDialogCall, DialogAlertComponent: RequestSignInDialogAlertComponent } = DialogAlertMessage(alertDialogMessageState, linkToSignIn);

  // 진행 중인 프로젝트 취소 dialog open state
  const [recruitingProjectCancelDialogState, recruitingProjectCancelDialogSetState] = useState(false);
  // 모집 중인 프로젝트 취소 dialog open state
  const [ongoingProjectCancelDialogState, ongoingProjectCancelDialogSetState] = useState(false);
  // 종료 프로젝트 취소 dialog open state
  const [finishedProjectCancelDialogState, finishedProjectCancelDialogSetState] = useState(false);

  // 공유하기 버튼 function
  const sharingButtonFunc = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          dialogMessageSetState("클립보드에 링크가 복사되었습니다.");
          dialogCall();
        })
        .catch(() => {
          alertDialogMessageSetState("링크 복사하기에 실패하였습니다.");
          shareAlertDialogCall();
        });
    } else {
      alertDialogMessageSetState("클립보드 복사 기능을 지원하지 않는 브라우저입니다.");
      shareAlertDialogCall();
    }
  };

  // 프로젝트 지원 function
  const applicationProject = useMutation(() => UpdateApplicationProject(targetDocId), {
    onSuccess: () => {
      projectDetailInfo.refetch();
      projectAppliedCheck.refetch();
      userInfoDialogOpenSetState(false);
      submitDialogOpenSetState(true);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 프로젝트 지원 dialog open state
  const [submitDialogOpenState, submitDialogOpenSetState] = useState(false);

  // 프로젝트 지원 function
  const projectApplicationEvent = () => {
    applicationProject.mutate();
  };

  // 프로젝트 지원 dialog close function
  const submitDialogCloseEvent = () => {
    submitDialogOpenSetState(false);
  };

  // 내 정보 보기 dialog open state
  const [userInfoDialogOpenState, userInfoDialogOpenSetState] = useState(false);

  // 지원하기 버튼 function
  const applicationButtonFunc = () => {
    if (queryClient.getQueryData("userInfo")) {
      if (queryClient.getQueryData("userInfo").userType === "default") {
        userInfoDialogOpenSetState(true);
      } else {
        alertDialogMessageSetState("개인 회원만 신청 가능합니다.");
        shareAlertDialogCall();
      }
    } else {
      alertDialogMessageSetState("로그인이 필요한 서비스입니다.");
      requestSignInAlertDialogCall();
    }
  };

  // 지원 취소 dialog close state
  const applicationCancelDialogCloseFunc = () => {
    recruitingProjectCancelDialogSetState(false);
    ongoingProjectCancelDialogSetState(false);
    finishedProjectCancelDialogSetState(false);
  };

  // 지원 취소 function
  const cancelProjectApplication = useMutation(() => UpdateCancelProjectApplication(targetDocId), {
    onSuccess: () => {
      projectDetailInfo.refetch();
      projectAppliedCheck.refetch();
      applicationCancelDialogCloseFunc();
      dialogMessageSetState("신청 취소되었습니다.");
      dialogCall();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 지원 취소 확인 function
  const confirmApplicationCancelFunc = () => {
    cancelProjectApplication.mutate();
  };

  // 지원 취소 버튼 function
  const cancelButtonFunc = () => {
    if (moment().isAfter(moment(projectDetailInfo.data.endDate * 1000))) {
      finishedProjectCancelDialogSetState(true);
    } else if (moment().isBetween(moment(projectDetailInfo.data.appointmentDate * 1000), moment(projectDetailInfo.data.endDate * 1000).add(-1, "d"))) {
      ongoingProjectCancelDialogSetState(true);
    } else {
      recruitingProjectCancelDialogSetState(true);
    }
  };

  // 내 정보 보기 dialog close function
  const userInfoDialogCloseEvent = () => {
    userInfoDialogOpenSetState(false);
  };

  // 내 정보 변경을 위한 마이 페이지 이동 function
  const linkToMyPage = () => {
    navigate("/my-page");
  };

  // 지원 완료 후 지원한 프로젝트 페이지 이동 function
  const linkToAppliedProject = () => {
    navigate("/applied-project");
    submitDialogOpenSetState(false);
  };

  // 브랜드 정보 dialog open state
  const [brandInfoDialogOpenState, brandInfoDialogOpenSetState] = useState(false);

  // 브랜드 정보 dialog open function
  const brandInfoDialogOpenEvent = () => {
    brandInfoDialogOpenSetState(true);
  };

  // 브랜드 정보 dialog close function
  const brandInfoDialogCloseEvent = () => {
    brandInfoDialogOpenSetState(false);
  };

  // 프로젝트 상세 페이지 화면
  if (queryClient.getQueryData("projectBrandInfo") && projectAppliedCheck.isFetched) {
    return (
      <Fragment>
        <ProjectDetailBodyFrame projectDetailValue={queryClient.getQueryData("projectDetailInfo")} brandValue={queryClient.getQueryData("projectBrandInfo")} brandInfoDialogOpenEvent={brandInfoDialogOpenEvent} projectLeftContents={<ProjectDetailInfo projectDetailValue={queryClient.getQueryData("projectDetailInfo")} brandValue={queryClient.getQueryData("projectBrandInfo")} detailImageOpenState={detailImageOpenState} detailImageOpenEvent={detailImageOpenEvent} detailImageCloseEvent={detailImageCloseEvent} brandInfoDialogOpenEvent={brandInfoDialogOpenEvent} projectAppointedCheck={projectAppointedCheck.data} />} projectRightContents={<ProjectDetailMenuBox projectDetailValue={queryClient.getQueryData("projectDetailInfo")} applicationState={projectAppliedCheck.data} sharingButtonFunc={sharingButtonFunc} applicationButtonFunc={applicationButtonFunc} cancelButtonFunc={cancelButtonFunc} />} />
        <DialogComponent />
        <ShareDialogAlertComponent />
        <RequestSignInDialogAlertComponent />
        <DialogWithTwoButton width={554} icon={"check"} title={"프로젝트 신청 완료"} subtitle={["프로젝트 신청을 완료했습니다.", <br />, "신청 목록으로 이동해서 확인하시겠습니까?"]} dialogOpenState={submitDialogOpenState} dialogClose={submitDialogCloseEvent} button1Text="취소" button2Text="신청 목록에서 확인" button1Func={submitDialogCloseEvent} button2Func={linkToAppliedProject} />
        <ComplexDialogWithOneButton context={<ProjectDetailBrandInfo brandValue={queryClient.getQueryData("projectBrandInfo")} />} dialogOpenState={brandInfoDialogOpenState} dialogClose={brandInfoDialogCloseEvent} buttonText="확인" buttonFunc={brandInfoDialogCloseEvent} maxWidth={496} />
        <ComplexDialogWithThreeButton title={"신청 정보 확인"} context={<ProjectDetailUserInfo userInfoValue={queryClient.getQueryData("userInfo")} />} dialogOpenState={userInfoDialogOpenState} dialogClose={userInfoDialogCloseEvent} button1Text="정보 수정하기" button2Text="신청하기" button1Func={linkToMyPage} button2Func={projectApplicationEvent} maxWidth={800} />
        <ComplexDialogWithThreeButton title={"취소 전, 확인해주세요."} context={<OngoingProjectCancelDialogContents />} dialogOpenState={ongoingProjectCancelDialogState} dialogClose={applicationCancelDialogCloseFunc} button1Text="닫기" button2Text="네, 취소해주세요." button1Func={applicationCancelDialogCloseFunc} button2Func={confirmApplicationCancelFunc} maxWidth={700} />
        <DialogWithOneButton width={380} message="종료된 프로젝트입니다." dialogOpenState={finishedProjectCancelDialogState} buttonText="확인" buttonFunc={applicationCancelDialogCloseFunc} />
        <DialogWithTwoButton width={554} icon={"cancel"} title={"신청을 취소하시겠습니까?"} dialogOpenState={recruitingProjectCancelDialogState} dialogClose={applicationCancelDialogCloseFunc} button1Text="닫기" button2Text="신청 취소" button1Func={applicationCancelDialogCloseFunc} button2Func={confirmApplicationCancelFunc} />
      </Fragment>
    );
  } else {
    return <LoadingComponent />;
  }
}
