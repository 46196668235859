import React from "react";
import { useQueryClient } from "react-query";
import RequireAuthRoute from "../../routes/requireAuth";

import UniversalAppBar from "../../Block/Appbar/UniversalAppBar";
import UniversalAppFooter from "../../Block/Footer/UniversalAppFooter";

import IncUserMyPage from "../../containers/Service/MyPage/IncUserMyPageContainer";
import MyPage from "../../containers/Service/MyPage/MyPageContainer";

export default function MyProfileView() {
  const queryClient = useQueryClient();

  const ContainerSwitcher = () => {
    if (queryClient.getQueryData("userInfo").userType === "incUser") {
      return IncUserMyPage;
    } else {
      return MyPage;
    }
  };

  return (
    <React.Fragment>
      <UniversalAppBar />
      <RequireAuthRoute Component={ContainerSwitcher()} />
      <UniversalAppFooter />
    </React.Fragment>
  );
}
