import React, { forwardRef } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import { Editor } from "@toast-ui/react-editor";
import chart from "@toast-ui/editor-plugin-chart";
import colorSyntax from "@toast-ui/editor-plugin-color-syntax";
import tableMergedCell from "@toast-ui/editor-plugin-table-merged-cell";

import "@toast-ui/editor/dist/toastui-editor.css";
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css";
import "tui-color-picker/dist/tui-color-picker.css";
import "@toast-ui/editor-plugin-table-merged-cell/dist/toastui-editor-plugin-table-merged-cell.css";

// markdown, wysiwyg 에디터
export const ToastUIEditor = forwardRef((props, forwardRef) => {
  const { placeholder, editorSize } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper>
          <Editor previewStyle="tab" usageStatistics={false} placeholder={placeholder} height={editorSize} initialEditType="wysiwyg" useCommandShortcut={true} plugins={[chart, colorSyntax, tableMergedCell]} ref={forwardRef} />
        </Paper>
      </Grid>
    </Grid>
  );
});

// markdown, wysiwyg 에디터 - 이미지 업로드 전용
export const ImageToastUIEditor = forwardRef((props, forwardRef) => {
  const { placeholder, editorSize } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper>
          <Editor previewStyle="tab" usageStatistics={false} placeholder={placeholder} height={editorSize} initialEditType="wysiwyg" useCommandShortcut={true} ref={forwardRef} toolbarItems={[["image"]]} />
        </Paper>
      </Grid>
    </Grid>
  );
});
