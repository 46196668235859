import React, { Fragment, useState, useEffect, useRef } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { RequestOngoingProjectList } from "../../utils/adminRequestToSrv";

import AdminBodyFrame from "../../Block/Admin/AdminBodyFrame";
import AdminProjectsTable from "../../Block/Admin/AdminProjectsContents";
import { AdminSearchBox } from "../../Block/Admin/AdminSearchBox";

import LoadingComponent from "../../components/LoadingComponent";

// 진행 중 프로젝트 페이지
export default function AdminOngoingProjectContainer() {
  // react-router-dom navigate
  const navigate = useNavigate();
  // react-query queryClient
  const queryClient = useQueryClient();

  // 프로젝트 리스트 data load
  const ongoingProjectList = useQuery("ongoingProjectList", RequestOngoingProjectList, {
    onError: (error) => {
      console.log("error", error);
    },
  });

  // data loading state
  const [loadState, loadSetState] = useState(false);
  // 화면 render 확인 state
  const [renderState, renderSetState] = useState(false);

  // 검색 카테고리 버튼 ref
  const categoryButtonRef = useRef(null);
  // 검색 input ref
  const searchInputRef = useRef(null);

  const searchBoxRefSet = {
    categoryButtonRef: categoryButtonRef,
    searchInputRef: searchInputRef,
  };

  // 테이블 페이지 state
  const [tableDataPageState, tableDataPageSetState] = useState(0);

  // 검색 카테고리 메뉴 open state
  const [categoryMenuOpenedState, categoryMenuOpenedSetState] = useState(false);
  // 검색 카테고리 state
  const [searchCategoryState, searchCategorySetState] = useState("title");
  // 검색어 state
  const [searchValueState, searchValueSetState] = useState("");

  // 검색 카테고리 메뉴 open function
  const categoryMenuOpenFunc = () => {
    categoryMenuOpenedSetState(true);
  };

  // 검색 카테고리 메뉴 close function
  const categoryMenuCloseFunc = () => {
    categoryMenuOpenedSetState(false);
  };

  // 검색 카테고리 설정 이벤트
  const categoryMenuSelectFunc = (value) => {
    searchCategorySetState(value);
    searchValueSetState("");
    categoryMenuCloseFunc();
  };

  // 검색어 입력 이벤트
  const searchValueChangeFunc = () => {
    searchValueSetState(searchInputRef.current.value);
  };

  // 검색어 입력 후 0.5초 대기 시 데이터 필터링 작동
  useEffect(() => {
    if (renderState) {
      const debouncingTimer = setTimeout(() => {
        // loadSetState(true);
      }, 500);
      return () => clearTimeout(debouncingTimer);
    } else {
      renderSetState(true);
    }
  }, [searchValueState]);

  // 데이터 테이블 페이지 전환 이벤트
  const tableDataPageChange = (event, newPage) => {
    tableDataPageSetState(newPage);
  };

  // 프로젝트 상세 페이지로 이동 function
  const linkToDetailEvent = (index, projectId) => {
    queryClient.setQueryData(["projectDetailInfo", projectId], ongoingProjectList.data[index].document);
    navigate(`/admin-ongoing-detail/${projectId}`);
  };

  // 프로젝트 리스트 화면
  const AdminProjectContainer = () => {
    return (
      <Grid container rowSpacing={3}>
        <Backdrop open={loadState}>
          <CircularProgress />
        </Backdrop>
        <Grid item container xs={12} spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-end">
          <Grid item xs={6}>
            <Typography variant="h4">진행 중 프로젝트 관리</Typography>
          </Grid>
          <AdminSearchBox ref={searchBoxRefSet} page="projects" categoryMenuOpenedState={categoryMenuOpenedState} categoryMenuOpenFunc={categoryMenuOpenFunc} categoryMenuCloseFunc={categoryMenuCloseFunc} categoryMenuSelectFunc={categoryMenuSelectFunc} selectedCategoryItemState={searchCategoryState} searchValueChangeFunc={searchValueChangeFunc} searchValueState={searchValueState} />
        </Grid>
        <AdminProjectsTable tableDataArrayState={ongoingProjectList.data} tableDataPageState={tableDataPageState} tableDataPageChange={tableDataPageChange} linkToDetailEvent={linkToDetailEvent} />
      </Grid>
    );
  };

  if (ongoingProjectList.isFetching) {
    return <LoadingComponent />;
  } else {
    return (
      <Fragment>
        <AdminBodyFrame contentComponent={AdminProjectContainer()} />
      </Fragment>
    );
  }
}
