import React from "react";

import { Link } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Divider } from "@mui/material";

import MyPortfolioSNSBox from "./MyPortfolioSNSBox";
import MyPortfolioProjectBox from "./MyPortfolioProjectBox";

const CustomGridContainer = styled(Grid)({
  paddingLeft: "60px",
});

const ProfileTitleButtonBox = styled(Box)({
  padding: "6px 0",
  borderBottom: "3px solid #222222"
});

const CustomPaper = styled(Paper)({
  padding: "168px 0",
  border: "1px solid #EEEEEE",
  borderRadius: "20px",
  boxShadow: "none",
});

const CustomDivider = styled(Divider)({
  height: "1px",
  borderColor: "#EEEEEE",
});

// 개인 회원 포트폴리오 페이지
export default function MyPortfolioProfileContents(props) {
  const { portfolioData } = props;

  const profileIntroduction = () => {
    return (
      <Grid container item sx={{ "overflowWrap": "anywhere" }}>
        <Typography variant="h4" fontWeight={500} lineHeight="36px" color="black.300">
          {portfolioData.introduction}
        </Typography>
      </Grid>
    );
  };

  return (
    <CustomGridContainer container item rowSpacing={3.1}>
      <Grid item>
        <ProfileTitleButtonBox>
          <Typography variant="h5" fontWeight={700} color="secondary">
            프로필
          </Typography>
        </ProfileTitleButtonBox>
      </Grid>
      {portfolioData.introduction !== "" || portfolioData.sns.instagram !== "" || portfolioData.sns.youtube !== "" || portfolioData.sns.naverBlog !== "" || portfolioData.sns.naverPost !== "" || portfolioData.project.project1.url !== "" || portfolioData.project.project2.url !== "" || portfolioData.project.project3.url !== "" ? (
        <Grid item xs={12}>
          <Grid container rowSpacing={5}>
            {portfolioData.introduction !== "" ? profileIntroduction() : null}
            {portfolioData.sns.instagram !== "" || portfolioData.sns.youtube !== "" || portfolioData.sns.naverBlog !== "" || portfolioData.sns.naverPost !== "" ? <MyPortfolioSNSBox portfolioData={portfolioData} size={"48px"} /> : null}
            <Grid item xs={12}>
              <CustomDivider />
            </Grid>
            {portfolioData.project.project1.url !== "" || portfolioData.project.project2.url !== "" || portfolioData.project.project3.url !== "" ? <MyPortfolioProjectBox portfolioData={portfolioData} /> : null}
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <CustomPaper>
            <Grid container justifyContent="center" rowSpacing={2}>
              <Grid item xs={12}>
                <Typography variant="h2" fontWeight={700} color="black.600" align="center" lineHeight="24px">
                  등록한 프로필 정보가 없습니다.
                  <br />
                </Typography>
              </Grid>
              <Grid container item justifyContent="center" alignItems="center">
                <Typography variant="subtitle1" fontWeight={400} color="black.900" align="center" lineHeight="24px">
                  프로필을 등록하고 나만의 포트폴리오를 만들어보세요!
                </Typography>
              </Grid>
            </Grid>
          </CustomPaper>
        </Grid>
      )}
    </CustomGridContainer>
  );
}
