import React, { forwardRef } from "react";

import { styled } from "@mui/material/styles";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#999999",
  "&:hover": {
    backgroundColor: "#4287f5",
  },
}));

const CloseButtonIcon = styled(CancelOutlinedIcon)(({ theme }) => ({
  color: theme.palette.grey[300],
  "&:hover": {
    color: theme.palette.grey[400],
  },
}));

const DialogButtonTypography = styled(Typography)({
  padding: "10px 0",
});

const SearchCategoryButtonBase = styled(ButtonBase)({
  borderRadius: "4px",
  width: "100%",
});

const SearchCategoryButtonBox = styled(Box)({
  border: "1px solid #D9D9D9",
  borderRadius: "4px",
  padding: "10px 14px",
  width: "100%",
  background: "#ffffff",
});

const SearchCategoryArrowDown = styled(KeyboardArrowDownIcon)(({ categoryMenuOpenedState }) => ({
  color: "#333333",
  transform: categoryMenuOpenedState ? "rotate(0.5turn)" : "",
  transition: "all ease 0.3s",
}));

const TableCellTypo = styled(Typography)({
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
});

// ============================= Button ===========================

// admin 버튼 - contained + 회색
export default function AdminButton(props) {
  const { onClickEvent, buttonText, loadState } = props;

  return (
    <StyledButton variant="contained" fullWidth onClick={() => onClickEvent()}>
      {loadState ? (
        <CircularProgress color="secondary" />
      ) : (
        <DialogButtonTypography variant="body1" fontWeight={500} color="#ffffff">
          {buttonText}
        </DialogButtonTypography>
      )}
    </StyledButton>
  );
}

// admin 버튼 - contained + 초록색
export function SaveButton(props) {
  const { onClickEvent, buttonText, loadState } = props;

  return (
    <Button variant="contained" color="secondary" fullWidth onClick={() => onClickEvent()}>
      {loadState ? (
        <CircularProgress color="secondary" />
      ) : (
        <DialogButtonTypography variant="body1" fontWeight={500} color="#ffffff">
          {buttonText}
        </DialogButtonTypography>
      )}
    </Button>
  );
}

// admin 버튼 - modal close 버튼
export function CancelIconButton(props) {
  const { onClickEvent } = props;

  return (
    <IconButton variant="contained" fullWidth onClick={() => onClickEvent()}>
      <CloseButtonIcon />
    </IconButton>
  );
}

// admin 버튼 - outlined + 초록색
export function CancelButton(props) {
  const { onClickEvent, buttonText, loadState } = props;
  return (
    <Button variant="outlined" color="secondary" fullWidth onClick={() => onClickEvent()}>
      {loadState ? (
        <CircularProgress color="secondary" />
      ) : (
        <DialogButtonTypography variant="body1" fontWeight={500} color="secondary">
          {buttonText}
        </DialogButtonTypography>
      )}
    </Button>
  );
}

// ============================= Table ===========================

// admin table cell - head : text
export function HeaderCategory(props) {
  const { title, align, width } = props;

  return (
    <TableCell variant="head" align={align} width={width}>
      <Typography variant="body1">{title}</Typography>
    </TableCell>
  );
}

// admin table cell - head : icon
export function HeaderIconCategory(props) {
  const { title, align, icon } = props;

  return (
    <TableCell padding="checkbox" variant="head" align={align}>
      <Tooltip title={title}>{icon}</Tooltip>
    </TableCell>
  );
}

// admin table cell - body : text
export function TextTableCell(props) {
  const { align, fontWeight, text } = props;

  return (
    <TableCell align={align}>
      <TableCellTypo variant="body2" fontWeight={fontWeight}>
        {text}
      </TableCellTypo>
    </TableCell>
  );
}

// admin table cell - body : icon
export function IconTableCell(props) {
  const { title, icon } = props;

  return (
    <TableCell align="center" padding="checkbox">
      <Tooltip title={title}>{icon}</Tooltip>
    </TableCell>
  );
}

// admin table cell - body : button
export function IconButtonTableCell(props) {
  const { title, icon, buttonEvent } = props;

  return (
    <TableCell align="center" padding="checkbox">
      <Tooltip title={title}>
        <IconButton onClick={() => buttonEvent()}>{icon}</IconButton>
      </Tooltip>
    </TableCell>
  );
}

// ============================= Search ===========================

// admin 검색 카테고리 버튼
export const SearchCategoryButton = forwardRef((props, forwardRef) => {
  const { categoryMenuOpenedState, categoryMenuOpenFunc, selectedItemLabel } = props;

  return (
    <SearchCategoryButtonBase ref={forwardRef} onClick={() => categoryMenuOpenFunc()}>
      <SearchCategoryButtonBox>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs>
            <Typography variant="body2" lineHeight="24px" fontWeight={700} color="#333333">
              {selectedItemLabel}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <SearchCategoryArrowDown fontSize="small" categoryMenuOpenedState={categoryMenuOpenedState} />
          </Grid>
        </Grid>
      </SearchCategoryButtonBox>
    </SearchCategoryButtonBase>
  );
});
