import React from "react";
import RequireAuthRoute from "../../routes/requireAuth";

import UniversalAppBar from "../../Block/Appbar/UniversalAppBar";
import UniversalAppFooter from "../../Block/Footer/UniversalAppFooter";

import Membership from "../../containers/Service/MyPage/MembershipContainer";

export default function MembershipView() {
  return (
    <React.Fragment>
      <UniversalAppBar />
      <RequireAuthRoute Component={Membership} />
      <UniversalAppFooter />
    </React.Fragment>
  );
}
